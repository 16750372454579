/**
 * Tries to extract readable text form an IRI.
 * @param {string} iri
 * @return {string}
 */
export const iriToShortText = (iri: string) => {
    const iriLabelIndex = iri.search(/[^/]*$/);
    const iriLabel = iri.slice(iriLabelIndex, iri.length);

    const iriHashIndex = iri.search(/[^#]*$/);
    const iriHash = iriHashIndex > 0 ? iri.slice(iriHashIndex, iri.length) : '';

    return iriHash || iriLabel;
};
