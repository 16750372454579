import Axios from 'axios';

const managementApi = Axios.create({
    baseURL: process.env.VUE_APP_MANAGEMENT_URL,
});

export default {
    getManagementData() {
        return managementApi.get('/info');
    },
};
