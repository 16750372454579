export const APP_INIT = 'app_initialisation';
export const APP_STARTED = 'app_started';
export const AUTH_INIT = 'auth_initialisation';
export const AUTH_REQ = 'auth_request';
export const AUTH_IN = 'auth_login';
export const AUTH_OUT = 'auth_logout';
export const AUTH_ATTEMPTED = 'auth_attempted';
export const AUTH_EDITED = 'auth_edited_class';
export const AUTH_VIEWEDCLASS = 'auth_viewed_class';
export const AUTH_REMOVED = 'auth_removed_class';
export const AUTH_REVIEWED = 'auth_reviewed_suggestion';
export const AUTH_ROOTMAX = 'auth_hit_rootmax';
export const EDITS_RECOVERY_MODE = 'edits_transaction_id';
export const SEARCH_QUERY = 'search_query';
export const SEARCH_EXACT = 'search_exact';
export const SEARCH_CLASS = 'search_class';

export const SEARCH_TYPE = 'search_type';
export const SEARCH_OBSOLETE = 'search_obsolete';
export const SEARCH_INDEXONTOS = 'search_index_ontologies';
export const SEARCH_INDEXADD = 'search_index_add';
export const SEARCH_INDEXDEL = 'search_index_del';
export const SEARCH_LOADSTART = 'search_loading_add';
export const SEARCH_LOADEND = 'search_loading_end';
export const SEARCH_FLUSHONTOS = 'search_flush_ontologies';
export const SEARCH_PASTONTOS = 'search_past_ontologies';
export const SEARCH_RESETONTOS = 'search_reset_ontologies';
export const SEARCH_FILTERS = 'search_filters_active';
export const CLIPBOARD_ADD = 'clipboard_copylist_add';
export const CLIPBOARD_CLEAR = 'clipboard_copylist_clear';
export const NOTIFICATIONS_SOURCE = 'notifications_source';
export const NOTIFICATIONS_ADD = 'notifications_add';
export const NOTIFICATIONS_DEL = 'notifications_del';
export const NOTIFICATIONS_READ = 'notifications_read';
export const NOTIFICATIONS_NEW = 'notifications_set_count';
export const NOTIFICATIONS_CLEAR = 'notifications_clear';
export const NOTIFICATIONS_CLOSE = 'notifications_close';
export const SUGGESTIONS_UPDATE = 'suggestions_update';
export const SUGGESTIONS_ADD = 'suggestions_add';
export const SUGGESTIONS_DEL = 'suggestions_del';
export const SUGGESTIONS_STATUS = 'suggestions_status';
export const SUGGESTIONS_CLEAR = 'suggestions_clear';
export const MANAGEMENT_SET_DATA = 'management_set_experimental';
