//TODO Replace with flag variable that can be toggled in the Admin UI.
import { NAME_MAPPINGS } from '@/config';

const isReplaceEnabled = true;

/**
 * Converts string to the preferred custom property name if a match is found
 * @param {string} name - Single string to be converted.
 */
export const propertyNameFromString = (name: string) => {
    if (isReplaceEnabled && typeof name !== 'undefined') {
        // eslint-disable-next-line no-prototype-builtins
        if (NAME_MAPPINGS.hasOwnProperty(name)) {
            // @ts-ignore
            return NAME_MAPPINGS[name];
        }
    }
    return name;
};
