import * as MutationTypes from './mutation-types';
import { useEdits } from '@/compositions';

const state = {
    isRecoveryMode: false,
    uncommittedTransaction: {},
};

const mutations = {
    [MutationTypes.EDITS_RECOVERY_MODE](state, isRecoveryMode) {
        state.isRecoveryMode = isRecoveryMode;
    },
};

const getters = {
    isRecoveryMode: (state) => state.isRecoveryMode,
    uncommittedTransaction: (state) => state.uncommittedTransaction,
};

const actions = {
    setRecoveryMode({ commit }, isRecoveryMode) {
        useEdits().setIsRecoveryMode(isRecoveryMode);
        commit(MutationTypes.EDITS_RECOVERY_MODE, isRecoveryMode);
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
