import ApiEdit from '../api/edit';
import RecoveryMixin from '../components/ClassMain/RecoveryMixin';
import { useEdits } from '@/compositions';

/**
 * Handles notifications that need to store to be available.
 * For this purpose uses the `updated` hook to fire notifications.
 */
export default {
    updated() {
        this.notifyUnsavedTransaction();
    },
    methods: {
        /**
         * If a user has an unsaved edit transaction it will display a dialogue
         * asking if we want to recover or discard the saved transaction.
         * If the user chooses to restore the transaction it will redirect to the recovery page.
         * If the user chooses to discard the transaction it will delete the local storace with the unsaved session details.
         *
         * @returns {void|undefined}
         */
        async notifyUnsavedTransaction() {
            if (!this.isLoggedIn) {
                return;
            }

            if (this.isRecoveryMode) {
                return;
            }

            if (useEdits().getEditIsActive.value) {
                return;
            }

            let uncommittedTransaction = localStorage.getItem(
                'uncommittedTransaction'
            );

            // No uncommitted uncommittedTransaction found
            if (!uncommittedTransaction) {
                return;
            }

            uncommittedTransaction = JSON.parse(uncommittedTransaction);

            // Uncommitted changes found but do not belong to the current user
            if (
                uncommittedTransaction.username !== this.$store.getters.username
            ) {
                return;
            }

            const transactionData = await ApiEdit.transaction(
                uncommittedTransaction
            );

            // The transaction has no unsaved data, discarding it.
            if (!transactionData.data.length) {
                localStorage.removeItem('uncommittedTransaction');
                return;
            }

            this.$eventBus.$emit(
                'notification:show',
                'critical',
                'You have unsaved edits pending. Do you wish to recover your unsaved data?',
                'Unsaved session pending',
                [
                    {
                        text: 'Discard',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);

                            this.$nextTick(() => {
                                this.$eventBus.$emit(
                                    'notification:close',
                                    toast
                                );
                                this.$_recovery_reset();
                            });
                        },
                    },
                    {
                        text: 'Restore',
                        action: (toast) => {
                            this.$eventBus.$emit('notification:close', toast);
                            this.$nextTick(() => {
                                this.$router.push({
                                    name: 'recovery',
                                    params: {
                                        ontologyID:
                                            uncommittedTransaction.ontologyID,
                                        recoveryTransactionID:
                                            uncommittedTransaction.transactionID,
                                    },
                                });
                            });
                        },
                    },
                ],
                'centerTop'
            );
        },
    },
    mixins: [RecoveryMixin],
};
