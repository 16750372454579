import { useStore } from '@/compositions';

export const interceptDuplicateClassId = (response) => {
    if (!response.headers['warning']) return;
    if (!response.headers['warning'].toLowerCase().includes('the primaryid'))
        return;

    const eventBus = useStore().$eventBus;

    eventBus.$emit(
        'notification:show',
        'warning',
        `A duplicate primary ID entry '${response.data.primaryId}' has been found; obsolete the class if this is unintended.`,
        `Duplicate ID found`,
        [
            {
                text: 'I understand',
                action: (toast) => {
                    eventBus.$emit('notification:close', toast);
                },
            },
        ],
        'centerTop',
        {
            timeout: 0,
        }
    );
};
