import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllMappingsPathParams = operations["deleteAllMappingsUsingDELETE"]["parameters"]["path"];
type AllMappingsBodyParams = operations["deleteAllMappingsUsingDELETE"]["requestBody"]["content"]["application/json"];
type AllMappingsResponse = operations["deleteAllMappingsUsingDELETE"]["responses"]["201"]['content']["*/*"];

/**
 * Remove all mappings from the specified class
 * @param {string} ontologyId
 * @param {string} classId
 * @param {AllMappingsPathParams} bodyParams
 * @return {AllMappingsResponse}
*/
export const deleteAllMappings = async (
    { ontologyId, classId }: AllMappingsPathParams,
    bodyParams: AllMappingsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/mappings`;
    const response = await Axios.delete(url, { data: bodyParams });

    return response && response.data as AllMappingsResponse;
}
