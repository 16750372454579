import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ColumnOptionsForQttExportV2PathParams = operations["getColumnOptionsForQttExportV2UsingGET"]["parameters"]["path"];
type ColumnOptionsForQttExportV2Response = operations["getColumnOptionsForQttExportV2UsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * getColumnOptionsForQttExportV2
 * @param {string} ontologyId
 * @return {ColumnOptionsForQttExportV2Response}
*/
export const getColumnOptionsForQttExportV2 = async (
    { ontologyId }: ColumnOptionsForQttExportV2PathParams,
) => {
    const url = `/ontologies/${ontologyId}/getColumnOptionsForQttExport_V2`;
    const response = await Axios.get(url);

    return response && response.data as ColumnOptionsForQttExportV2Response;
}
