import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyMetadataPathParams = operations["getOntologyMetadataUsingGET"]["parameters"]["path"];
type OntologyMetadataResponse = operations["getOntologyMetadataUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * getOntologyMetadata
 * @param {string} ontologyId
 * @return {OntologyMetadataResponse}
*/
export const getOntologyMetadata = async (
    { ontologyId }: OntologyMetadataPathParams,
) => {
    const url = `/ontologies/${ontologyId}`;
    const response = await Axios.get(url);

    return response && response.data as OntologyMetadataResponse;
}
