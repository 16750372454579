import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyPropertyByPrimaryIdPathParams = operations["getOntologyPropertyByPrimaryIdUsingGET"]["parameters"]["path"];
type OntologyPropertyByPrimaryIdQueryParams = operations["getOntologyPropertyByPrimaryIdUsingGET"]["parameters"]["query"];
type OntologyPropertyByPrimaryIdResponse = operations["getOntologyPropertyByPrimaryIdUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get properties in a specific ontology based on primary ID (property URI)
 * @param {string} ontologyId
 * @param {string} primaryId
 * @return {OntologyPropertyByPrimaryIdResponse}
*/
export const getOntologyPropertyByPrimaryId = async (
    { ontologyId }: OntologyPropertyByPrimaryIdPathParams,
    queryParams: OntologyPropertyByPrimaryIdQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/properties/query`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as OntologyPropertyByPrimaryIdResponse;
}
