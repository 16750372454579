//TODO Replace with flag variable that can be toggled in the Admin UI.
const isReplaceEnabled = true;

/**
 * Converts an IRI to the name of the given IRI
 * @param {string} Iri - Single IRI to be converted.
 */
export const nameFromIri = (Iri: string) => {
    if (isReplaceEnabled && typeof Iri !== 'undefined') {
        let name = Iri;
        const lastSlashIndex = name.lastIndexOf('/');
        lastSlashIndex && (name = name.substring(lastSlashIndex + 1));

        const lastHashIndex = name.lastIndexOf('#');
        lastHashIndex && (name = name.substring(lastHashIndex + 1));

        return name;
    }
    return Iri;
};
