import VueCompositionAPI, { computed, ref, Ref } from '@vue/composition-api';
import Vue from 'vue';
import { ClassTree } from '@/ts';

Vue.use(VueCompositionAPI);

const classTree: Ref<ClassTree | undefined> = ref();
const renderCount: Ref<number> = ref(0);

export const useClassTree = () => {
    /**
     * Getter for the renderCount variable.
     * @type {ComputedRef<number>}
     */
    const getRenderCount = computed(() => {
        return renderCount.value;
    });

    const setRenderCount = (count: number) => {
        renderCount.value = count;
    };

    /**
     * Setter for the renderCount variable.
     * @param {number} count
     * @returns {number}
     */
    const reSetRenderCount = () => {
        renderCount.value = 0;
    };

    const incrementRenderCount = () => {
        renderCount.value++;
    };

    const selectionMounted =
        classTree.value && classTree.value.selectionMounted;

    const getClassTree = () => {
        return classTree;
    };

    const setClassTree = (tree: unknown) => {
        const treeRef = tree as unknown as ClassTree;
        classTree.value = treeRef;
    };

    /**
     * Searches for a class already loaded in the tree.
     * @param {string} primaryId
     * @return {ClassDataExtended|boolean|undefined}
     */
    const searchClassInTree = (primaryId: string) => {
        if (!classTree.value) return;

        return classTree.value.findClass(primaryId);
    };

    return {
        getClassTree,
        setClassTree,
        selectionMounted,
        searchClassInTree,
        getRenderCount,
        incrementRenderCount,
        reSetRenderCount,
        setRenderCount,
    };
};
