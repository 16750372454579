import { components } from '@/ts/ApiSpecs';

/**
 * Extracts and returns the language tag from a property.
 * @param {definitions["PropertyValue"]} property
 * @return {string | undefined}
 */
export const extractPropertyLanguageTag = (
    property: components['schemas']['PropertyValue']
): string | undefined => {
    if (!property.tags) return;

    for (const tag of property.tags) {
        if (tag.type !== 'LANG') continue;

        return tag.value;
    }
};
