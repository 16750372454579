import { union, find } from 'lodash';

import * as MutationTypes from './mutation-types';

const state = {
    copyList: [],
    lastCopied: null,
};

const mutations = {
    [MutationTypes.CLIPBOARD_ADD](state, { copiedData }) {
        state.copyList = union(state.copyList, [copiedData]);
        state.lastCopied = find(state.copyList, (item) => item === copiedData);
    },

    [MutationTypes.CLIPBOARD_CLEAR](state) {
        state.copyList = [];
        state.lastCopied = null;
    },
};

const getters = {
    copyList: (state) => state.copyList,
    lastCopied: (state) => state.lastCopied,
    isLastCopied: (state) => (copied) => {
        if (state.lastCopied && copied) {
            return state.lastCopied.trim() === copied.trim();
        } else {
            return false;
        }
    },
};

const actions = {
    clipboardAdd({ commit }, copiedData) {
        commit(MutationTypes.CLIPBOARD_ADD, { copiedData });
    },

    clipboardClear({ commit }) {
        commit(MutationTypes.CLIPBOARD_CLEAR);
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
