import ApiAuth from '@/api/auth';
import { logout } from '@/api-v2';

/**
 * Dispatches the logout action before entering the logout route
 * and assigns the redirection params.
 *
 * @param {Object} from
 * @param {Object} to
 * @param {Object} store
 * @param {Function} next
 */

const performLogout = async ({ from, to, store, next }) => {
    const logoutDetails = await ApiAuth.getLogoutDetails();
    const oauthDetails = logoutDetails.data;

    store.dispatch('logout');
    await logout();

    if (
        oauthDetails.oauthRequired &&
        oauthDetails.oauthLogoutUrl != null &&
        oauthDetails.oauthLogoutUrl !== ''
    ) {
        window.location.href = oauthDetails.oauthLogoutUrl;
        return;
    }

    if (
        typeof to.params.redirect === 'string' &&
        from.fullPath !== to.params.redirect
    ) {
        next(to.params.redirect);
    } else {
        next({
            name: 'login',
            params: { redirect: to.params.redirect },
        });
    }
};

export default performLogout;
