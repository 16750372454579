/**
 * Guards the routes where Authentication is required.
 * If a code is provided as query parameter an oAuth authentication is in progress we just proceed.
 * If the user has a token a login will be attempted, we proceed and leave the job to axios interceptors.
 * We we do not have a token or a code then the user is not authenticated and we redirect to the login page.
 *
 * @param {Object} to
 * @param {Function} next
 * @param {Object} store
 */
const requiresAuth = ({ to, next, store }) => {
    switch (true) {
        case !!to.query.code: // oAuth authentication
        case store.getters.hasToken: // the user is logged in or should be
            next();
            break;

        default: // Token absent => redirects to login
            next({
                name: 'login',
                params: { redirect: to },
            });
            break;
    }
};

export default requiresAuth;
