import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type SiblingsForInstancePathParams = operations["getSiblingsForInstanceUsingGET"]["parameters"]["path"];
type SiblingsForInstanceQueryParams = operations["getSiblingsForInstanceUsingGET"]["parameters"]["query"];
type SiblingsForInstanceResponse = operations["getSiblingsForInstanceUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * This endpoint takes in a classId and returns a list of all instances that are of that class type.
 * @param {string} ontologyId
 * @param {string} instanceId
 * @param {integer} from
 * @param {integer} size
 * @return {SiblingsForInstanceResponse}
*/
export const getSiblingsForInstance = async (
    { ontologyId, instanceId }: SiblingsForInstancePathParams,
    queryParams?: SiblingsForInstanceQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/instances/${instanceId}/siblings`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as SiblingsForInstanceResponse;
}
