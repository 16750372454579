var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ 'cover-search': _vm.isCoverSearch() },attrs:{"id":"header-region"}},[_c('cookie-law',{attrs:{"position":'top',"transitionName":'slideFromTop',"buttonText":'I agree',"buttonClass":'btn btn-primary mt-3 mb-3 cookie-agree-btn'}},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" This web app may require the limited processing of your personal data through the use of cookies. By using the site you are agreeing to this as outlined in our "),_c('b-link',{staticClass:"cookie-terms-link",attrs:{"href":_vm.termsUrl}},[_vm._v("Terms of Use")]),_vm._v(". ")],1)]),_c('vue-snotify'),(_vm.isLoggedIn)?_c('b-navbar',{ref:"headerNavbar",attrs:{"toggleable":_vm.isCoverSearch() ? 'sm' : 'md',"variant":"light","fixed":'top'}},[_c('b-navbar-brand',{staticClass:"home-link",attrs:{"to":"/","id":_vm.buildTestClass('link--centree')}},[_c('svg',{staticClass:"logo app-logo",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 100 100"}},[_c('path',{attrs:{"d":"M50,0A50,50,0,0,0,2.66,66.12l.19.56.59,0A31.5,31.5,0,0,1,33.29,96.56l0,.59.56.19A50,50,0,1,0,50,0Zm0,98.25a48.13,48.13,0,0,1-15-2.38A33.23,33.23,0,0,0,4.13,65,48.26,48.26,0,1,1,50,98.25Zm0-9.87L85.94,26.13H14.06Zm0-7L20.12,29.63H79.88Zm0-4.11L76.32,31.68H23.68Zm0-5L28,34.18H72Zm0-5.1L67.57,36.73H32.43Zm0-5L36.76,39.23H63.24Z"}})]),_c('span',{staticClass:"app-name"},[_vm._v(_vm._s(_vm.appName))])]),_c('b-navbar-nav',[_c('b-nav-form',{attrs:{"id":"nav-search"}},[_c('h1',{staticClass:"motto mb-3"},[_vm._v(" "+_vm._s(_vm.appMotto)+" ")]),_c('class-typeahead',{ref:"typeahead",class:{
                        'obsolete-suggestions':
                            _vm.$store.getters.filters.isObsolete,
                    },attrs:{"placeholder":_vm.isCoverSearch()
                            ? 'Search for terms'
                            : 'SEARCH TERMS',"suggestion-max-matches":_vm.isCoverSearch() ? _vm.suggestionMin : _vm.suggestionMax,"id":_vm.buildTestClass('div--nav-search-bar'),"isForceExactMatch":_vm.$store.getters.filters.isExact,"searchType":_vm.$store.getters.filters.searchType,"fetchSuggestions":_vm.fetchTypeaheadList,"performAction":_vm.searchTypeahead,"isGotoOnHit":!_vm.$store.getters.filters.isObsolete,"isOntoHitOnly":_vm.$store.getters.filters.isObsolete,"force-instance":_vm.$store.getters.searchType === 'instance'},on:{"change":_vm.onTypeaheadChange},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }if(!$event.ctrlKey){ return null; }return _vm.onOntoShortcut(_vm.currOntology)},"focusin":function($event){return _vm.onTypeaheadFocus(true)},"focusout":function($event){return _vm.onTypeaheadFocus(false)}},scopedSlots:_vm._u([{key:"inset",fn:function(typeahead){return [_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(typeahead.query.length),expression:"typeahead.query.length"}],staticClass:"clear-btn",attrs:{"variant":"link","tabindex":"-1"},on:{"mousedown":function($event){$event.preventDefault();return _vm.$refs.typeahead.clear()}}},[_vm._v(" × ")])]}}],null,false,3477755832)},[_c('template',{slot:"prepend"},[_c('b-dropdown',{ref:"ontoFilterDropdown",attrs:{"variant":"gray","no-flip":"","id":"onto-filter-toggle","menu-class":"mt-1 py-0 shadow"},on:{"show":_vm.onOntoFilterShow,"hidden":function($event){_vm.isOntoFilterShown = false}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$refs.ontoFilterDropdown.hide(true)}}},[_c('template',{slot:"button-content"},[(
                                        !_vm.$store.getters.filters.ontologies
                                            .length
                                    )?_c('span',[_vm._v(" All ontologies ")]):(
                                        _vm.$store.getters.filters.ontologies
                                            .length === 1
                                    )?_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.filteringOntoIDs[0])+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.filters.ontologies .length)+" ontologies ")])]),_c('onto-filter',{attrs:{"filterTitle":"Search in ontologies","summaryTooltipTarget":"onto-filter-toggle","isOntoFilterShown":_vm.isOntoFilterShown}},[_c('b-button-close',{staticClass:"dropdown-close-btn ml-3",attrs:{"text-variant":"light"},on:{"click":function($event){return _vm.$refs.ontoFilterDropdown.hide(true)}}})],1)],2)],1),_c('template',{slot:"append"},[_c('b-dropdown',{ref:"settingsSearchDropdown",staticClass:"filter-dropdown settings-filter",attrs:{"variant":"gray","size":"sm","no-flip":"","right":"","id":"settings-search-toggle","menu-class":"mt-1 py-0 shadow"},on:{"show":function($event){_vm.isSearchSettingsShown = true},"hidden":function($event){_vm.isSearchSettingsShown = false}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$refs.settingsSearchDropdown.hide(true)}}},[_c('template',{slot:"button-content"},[(_vm.$store.getters.filters.isObsolete)?_c('font-awesome-icon',{staticClass:"align-middle",attrs:{"icon":"exclamation-circle"}}):_c('font-awesome-icon',{staticClass:"align-middle",attrs:{"icon":"check-circle"}}),(_vm.$store.getters.filters.isExact)?_c('font-awesome-icon',{staticClass:"align-middle",attrs:{"icon":"crosshairs"}}):_c('fuzzy-icon',{staticClass:"svg-icon"})],1),_c('search-settings',{attrs:{"settingsTitle":"Search settings","summaryTooltipTarget":"settings-search-toggle","isSettingsShown":_vm.isSearchSettingsShown}},[_c('b-button-close',{staticClass:"dropdown-close-btn ml-3",attrs:{"text-variant":"light"},on:{"click":function($event){return _vm.$refs.settingsSearchDropdown.hide(
                                            true
                                        )}}})],1)],2),_c('b-button',{staticClass:"search-btn",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.typeahead.onAction(
                                    _vm.$refs.typeahead.query
                                )}}},[_c('font-awesome-icon',{attrs:{"icon":"search"}})],1)],1),_c('template',{slot:"view-all"},[_c('b-button',{staticClass:"view-all-btn rounded-0 border-0",attrs:{"variant":"outline-primary"},on:{"mousedown":function($event){$event.preventDefault();return _vm.$refs.typeahead.onAction(
                                    _vm.$refs.typeahead.query
                                )}}},[_c('small',[_vm._v("View all results")])])],1)],2),_c('b-link',{staticClass:"browse-link mt-4 mb-2",attrs:{"to":"/ontologies","id":_vm.buildTestClass('link--browse-ontologies')}},[_c('b-button',{attrs:{"variant":"primary"}},[_c('font-awesome-icon',{style:({ color: 'white' }),attrs:{"icon":"sitemap"}}),_c('span',{staticClass:"px-1 ml-1"},[_vm._v("Browse ontologies")])],1)],1)],1),_c('b-button',{staticClass:"header-btn d-none border-0 d-lg-inline mr-3",attrs:{"id":_vm.buildTestClass('btn--browse-ontologies'),"variant":"outline-primary","to":"/ontologies","active":_vm.isOntologiesScreen}},[_vm._v(" ONTOLOGIES ")])],1),_c('b-navbar-toggle',{staticClass:"header-btn border-0 px-0",class:{ 'icon-badge': _vm.alertTotal },attrs:{"target":"nav_collapse","data-count":_vm.alertTotal},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onShowMenu.apply(null, arguments)}}}),_c('b-collapse',{staticClass:"justify-content-end",attrs:{"is-nav":"","id":"nav_collapse"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: 'My suggestions',
                    delay: { show: _vm.showDelay, hide: 0 },
                }),expression:"{\n                    title: 'My suggestions',\n                    delay: { show: showDelay, hide: 0 },\n                }",modifiers:{"hover":true}}],staticClass:"header-btn border-0",class:{ 'icon-badge': _vm.$store.getters.rejectedCount },attrs:{"type":"submit","variant":"outline-primary","data-count":_vm.$store.getters.rejectedCount},on:{"click":function($event){return _vm.onShowPending('suggestions')}}},[_c('font-awesome-layers',{staticClass:"suggestions-icon",class:this.buildTestClass('suggestion-icon')},[_c('font-awesome-icon',{attrs:{"icon":"comment","transform":"grow-10"}}),_c('font-awesome-icon',{staticClass:"text-white",class:this.buildTestClass('suggestion-icon__pen'),style:({ marginTop: '-.05em' }),attrs:{"icon":"pen","transform":"shrink-5"}})],1)],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: 'Notifications',
                    delay: { show: _vm.showDelay, hide: 0 },
                }),expression:"{\n                    title: 'Notifications',\n                    delay: { show: showDelay, hide: 0 },\n                }",modifiers:{"hover":true}}],staticClass:"header-btn border-0",class:{ 'icon-badge': _vm.$store.getters.unreadCount },attrs:{"id":_vm.buildTestClass('btn--notifications-menu'),"type":"submit","variant":"outline-primary","data-count":_vm.$store.getters.unreadCount},on:{"click":function($event){return _vm.onShowPending('notifications')}}},[_c('font-awesome-icon',{staticClass:"header-icon",attrs:{"icon":"bell"}})],1),_c('b-button',{staticClass:"header-btn border-0",attrs:{"type":"submit","variant":"outline-primary","id":_vm.buildTestClass('btn--user-menu')},on:{"click":function($event){_vm.isSideMenuShown = true}}},[_c('font-awesome-icon',{staticClass:"header-icon mr-1",attrs:{"icon":"user-circle"}}),_c('span',{staticClass:"d-none d-xl-inline"},[_vm._v(_vm._s(_vm.$store.getters.userDisplayName))])],1),_c('b-button',{staticClass:"header-btn border-0",attrs:{"type":"submit","variant":"outline-primary","to":"/logout","id":this.buildTestClass('logout')}},[_c('font-awesome-icon',{staticClass:"header-icon mr-1",attrs:{"icon":"sign-out-alt"}}),_c('span',{staticClass:"d-none d-lg-inline",attrs:{"id":this.buildTestClass('logout__span')}},[_vm._v("Log out")])],1)],1),_c('vue-progress-bar',{staticClass:"progress-header"})],1):_vm._e(),(!_vm.isLoggedIn)?_c('vue-progress-bar'):_vm._e(),(_vm.isLoggedIn)?_c('side-menu',{ref:"sideMenu",attrs:{"isMenuShown":_vm.isSideMenuShown,"testId":0},on:{"update:isMenuShown":function($event){_vm.isSideMenuShown=$event},"update:is-menu-shown":function($event){_vm.isSideMenuShown=$event}}}):_vm._e(),_vm._m(0),_c('a',{attrs:{"id":"header-notification-download-anchor"}},[_vm._v("Download")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"id":"header-notification-download-form","method":"post","target":"_blank","action":"xxx"}},[_c('input',{attrs:{"id":"header-notification-download-form-input","type":"hidden","name":"Authorization","value":"xxx"}})])}]

export { render, staticRenderFns }