export const findHostedExportUrl = (
    message: string,
    serverApi: string
): string => {
    let url = '';

    if (message.indexOf('s3.') !== -1) {
        url = message;
        return url;
    }

    if (serverApi === '/') {
        serverApi = window.location.origin;
    }

    if (message.charAt(0) === '/') {
        url = message.substr(1);
    }

    url = url !== '' ? url : message;

    url = `${serverApi}/${url}`;

    return url;
};
