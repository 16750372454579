import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllSynonymsPathParams = operations["deleteAllSynonymsUsingDELETE"]["parameters"]["path"];
type AllSynonymsBodyParams = operations["deleteAllSynonymsUsingDELETE"]["requestBody"]["content"]["application/json"];
type AllSynonymsResponse = operations["deleteAllSynonymsUsingDELETE"]["responses"]["201"]['content']["*/*"];

/**
 * Remove all synonyms from the specified class
 * @param {string} ontologyId
 * @param {string} classId
 * @param {AllSynonymsPathParams} bodyParams
 * @return {AllSynonymsResponse}
*/
export const deleteAllSynonyms = async (
    { ontologyId, classId }: AllSynonymsPathParams,
    bodyParams: AllSynonymsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/synonyms`;
    const response = await Axios.delete(url, { data: bodyParams });

    return response && response.data as AllSynonymsResponse;
}
