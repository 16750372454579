import { ANNOTATION_PROPS } from '@/config';
import { lowerCase, upperFirst } from 'lodash';

/**
 * Gets a raw property name/iri and returns a formatted version if it's a default annotation prop
 * or the given property name/iri.
 * @param {string} propertyName
 * @return {string}
 */
export const formatPropertyName = (propertyName: string) => {
    if (propertyName === 'textualDefinitions') {
        return 'Definitions';
    }

    if (ANNOTATION_PROPS.includes(propertyName)) {
        const lowerCasePropertyName = lowerCase(propertyName); // converts to space-separated lower case.
        return upperFirst(lowerCasePropertyName);
    }

    return propertyName;
};
