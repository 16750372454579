/**
 * A list of tags that will be available even if not in the ontology metadata property.
 * @type Record<string, string>[]
 */
import { Tag } from '@/ts';

export const HARDCODED_TAGS: Tag[] = [
    {
        classPropertyName: 'Switch',
        iri: 'http://ontology.scibite.com/ontology/termite/switch',
        propertyLabel: 'switch',
        propertyName: 'termiteSwitch',
        propertyReadable: 'Termite Switch',
        type: 'switch',
        multipleAllowed: true,
    },
    {
        classPropertyName: 'Datatype',
        iri: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#datatype',
        propertyLabel: 'datatype',
        propertyName: 'datatype',
        propertyReadable: 'Datatype',
        type: 'datatype',
        multipleAllowed: false,
    },
    {
        classPropertyName: 'Type',
        iri: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type',
        propertyLabel: 'type',
        propertyName: 'type',
        propertyReadable: 'Type',
        type: 'type',
        multipleAllowed: false,
    },
];
