import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToVocabFilePathParams = operations["exportToVocabFileUsingGET"]["parameters"]["path"];
type ExportToVocabFileResponse = operations["exportToVocabFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * exportToVocabFile
 * @param {string} ontologyId
 * @return {ExportToVocabFileResponse}
*/
export const exportToVocabFile = async (
    { ontologyId }: ExportToVocabFilePathParams,
) => {
    const url = `/ontologies/${ontologyId}/exportAsTermiteVocabFile`;
    const response = await Axios.get(url);

    return response && response.data as ExportToVocabFileResponse;
}
