import { upperFirst } from 'lodash';
import { iriToShortText } from '@/utils';

export class Tag {
    iri: string;
    propertyReadable: string;
    type: string;
    classPropertyName?: string;
    propertyLabel?: string;
    propertyName?: string;
    multipleAllowed?: boolean;

    constructor(tagDefinition: Partial<Tag> & { iri: string }) {
        this.iri = tagDefinition.iri;

        this.type = tagDefinition.type || iriToShortText(this.iri);

        this.propertyName = tagDefinition.propertyName || this.type;

        this.propertyLabel = tagDefinition.propertyLabel || this.propertyName;

        this.propertyName = tagDefinition.propertyName || this.propertyName;

        this.propertyReadable =
            tagDefinition.propertyReadable || upperFirst(this.propertyName);

        this.classPropertyName =
            tagDefinition.classPropertyName || this.propertyReadable;

        this.multipleAllowed =
            tagDefinition.multipleAllowed !== undefined ? true : false;
    }
}
