import { interceptDuplicateClassId } from '@/api/interceptors/interceptDuplicateClassId';

const interceptors = [interceptDuplicateClassId];

/**
 * Runs all the interceptors.
 * @param response
 */
export const intercept = (response) => {
    interceptors.forEach((interceptor) => {
        interceptor(response);
    });
};
