/**
 * Dispatches authentication actions as soon as we know where we are directed to.
 * @param {Object} to
 * @param {Object} store
 * @param {Function} next
 */
import cookieHelper from '@/utils/strings/cookies';

const authenticateUser = async ({ to, store, next }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');

    /**
     * Authorise SSO cookie to localstorage
     */
    const cookieJwt = cookieHelper.getCookie('Authorization');
    if (
        cookieJwt !== '' &&
        typeof cookieJwt === 'string' &&
        cookieJwt.trim() !== ''
    ) {
        const jwt = '"' + cookieJwt.split(' ')[1];
        window.localStorage.setItem(process.env.VUE_APP_NAME_TOKEN, jwt);
        await store.dispatch('getUser');
        return next();
    }

    if (to.name === 'logout') {
        // If we are logging out and we have an auth code we do not want to try to login again,
        // because router link does not recognise the code query parameter we just hard-redirect to root.
        if (authCode) {
            window.location.href = '/';
        }

        // If we are logging out we don't need and we must not perform authentication to prevent infinite loop.
        if (to.name === 'logout') {
            return next();
        }
    }

    // The status is known, we don't need to perform authentication to avoid reloading the whole app.
    if (store.state.auth.status) {
        return next();
    }
    if (authCode && !localStorage.getItem(process.env.VUE_APP_NAME_TOKEN)) {
        // oAuth authentication
        await store.dispatch('initTokenWithCode', authCode);
    } else if (store.getters.hasToken /*&& !this.isLoggedIn*/) {
        // The user has a token but is not logged tries to grab the account info.
        await store.dispatch('getUser');
    }

    next();
};

export default authenticateUser;
