/* global sessionStorage */
/* global localStorage */

import Api from '@/api';
import ApiAuth from '@/api/auth';

import { cloneDeep, isEmpty } from 'lodash';

import * as MutationTypes from './mutation-types';
import { getUserPermissions } from '@/api-v2';

const state = {
    status: '',
    token: '',
    password: '',
    user: {},
    hasEdited: false,
    hasViewedClass: false,
    hasRemoved: false,
    hasReviewed: false,
    hasRootMax: false,
    isRemember: false,
    permissions: [],
};

const getOntologyRolesFromPermissionSet = (userPermissionSet, role) => {
    return userPermissionSet
        .filter(
            (permission) =>
                permission.permission.toUpperCase() === role.toUpperCase()
        )
        .map((permission) => permission.resource.toUpperCase());
};

const mutations = {
    [MutationTypes.AUTH_INIT](state, { token, isRemember, password }) {
        state.token = token;
        state.password = password;
        state.isRemember = isRemember;
    },

    [MutationTypes.AUTH_REQ](state) {
        state.status = 'loading';
    },

    async [MutationTypes.AUTH_IN](state, { user }) {
        state.status = 'loggedin';
        state.user = user;
        state.user.isAdmin = user.authorities.some(
            (role) => role === 'ROLE_ADMIN'
        );
        state.user.isUser = user.authorities.some(
            (role) => role === 'ROLE_USER'
        );

        const permission = await getUserPermissions({
            login: state.user.login,
        });

        const edit = getOntologyRolesFromPermissionSet(
            permission.userPermissionSet,
            'edit'
        );

        const suggest = getOntologyRolesFromPermissionSet(
            permission.userPermissionSet,
            'suggest'
        );

        state.user = cloneDeep({ ...user, suggest, edit });
    },

    [MutationTypes.AUTH_OUT](state) {
        state.status = '';
        state.token = '';
        state.user = {};
        state.isRemember = false;
    },

    // Signals the end of the authentication cycle regardless of its success.
    [MutationTypes.AUTH_ATTEMPTED](state) {},

    // TODO: too much redundancy. Separate into history store and coalesce.
    [MutationTypes.AUTH_EDITED](state) {
        state.hasEdited = true;
    },

    [MutationTypes.AUTH_VIEWEDCLASS](state) {
        state.hasViewedClass = true;
    },

    [MutationTypes.AUTH_REMOVED](state) {
        state.hasRemoved = true;
    },

    [MutationTypes.AUTH_REVIEWED](state) {
        state.hasReviewed = true;
    },

    [MutationTypes.AUTH_ROOTMAX](state) {
        state.hasRootMax = true;
    },
};

const getters = {
    token: (state) => state.token,
    hasToken: (state) => !!state.token,
    status: (state) => state.status,
    isRemember: (state) => state.isRemember,
    isAdmin: (state) => state.user.isAdmin,
    isUser: (state) => state.user.isUser,
    hasEdited: (state) => state.hasEdited,
    hasViewedClass: (sate) => state.hasViewedClass,
    hasRemoved: (state) => state.hasRemoved,
    hasReviewed: (state) => state.hasReviewed,
    hasRootMax: (state) => state.hasRootMax,
    user: (state) => state.user,
    username: (state) => state.user.login,
    password: (state) => state.password,

    // At least the first name is required. Otherwise, the email is used.
    fullname: (state) => {
        let name = state.user.firstName;
        const lastname = state.user.lastName;

        if (lastname && name !== lastname) {
            name = `${name} ${lastname}`;
        } else if (!name) {
            name = state.user.email;
        }

        return name;
    },

    // Synthesises initials if full name available. If not, reverts to first name only or email.
    userDisplayName: (state, getters) => {
        const name = getters.fullname;
        let splitName;

        if (name.indexOf('@') !== -1) {
            return name;
        } else if (name.indexOf(' ') !== -1) {
            splitName = name.split(' ');
            return `${splitName[0].charAt(0).toUpperCase()}. ${splitName[
                splitName.length - 1
            ]
                .charAt(0)
                .toUpperCase()}.`;
        } else {
            return name;
        }
    },

    hasPermission: (state) => (resource, permission) => {
        if (typeof resource !== 'string' || typeof permission !== 'string')
            return false;

        const permissionLevel = permission.toLowerCase();

        return (
            !isEmpty(state.user) &&
            (state.user.isAdmin ||
                (state.user[permissionLevel] &&
                    state.user[permissionLevel].indexOf(
                        resource.toUpperCase()
                    ) !== -1))
        );
    },

    canChange: (state, getters) => (ontologyID) => {
        return (
            getters.hasPermission(ontologyID, 'SUGGEST') ||
            getters.hasPermission(ontologyID, 'EDIT')
        );
    },

    isSuggester: (state, getters) => (ontologyID) => {
        return (
            getters.hasPermission(ontologyID, 'SUGGEST') &&
            !getters.hasPermission(ontologyID, 'EDIT')
        );
    },

    isEditor: (state, getters) => (ontologyID) => {
        return !!getters.hasPermission(ontologyID, 'EDIT');
    },
};

const actions = {
    /**
     * Sets the token, be it previously existing or not.
     * NOTE: whenever "remember me" is enabled, data is always saved to localStorage only.
     * @param {Object} context - Context object for this store.
     * @param {Function} context.dispatch - Triggers a certain store action.
     * @param {Function} context.commit - Commits store to a specific state.
     */
    initToken({ dispatch, commit }) {
        const tokenName = process.env.VUE_APP_NAME_TOKEN;
        const sessionToken = sessionStorage.getItem(tokenName);
        const localToken = localStorage.getItem(tokenName);
        let token;
        try {
            token = JSON.parse(sessionToken || localToken);
        } catch (e) {
            console.log('user token invalid: ', e);
        }

        commit(MutationTypes.AUTH_INIT, { token, isRemember: !!localToken });

        if (token) {
            Api.setBearer(token);
        }
    },

    /**
     * Sets the token using an authorization code.
     * NOTE: whenever "remember me" is enabled, data is always saved to localStorage only.
     * @param {Object} context - Context object for this store.
     * @param {Function} context.commit - Commits store to a specific state.
     * @param {String} authCode - Authorization code to be able to retrieve an authentication token
     */
    initTokenWithCode({ dispatch, commit, rootState }, authCode) {
        return new Promise((resolve, reject) => {
            ApiAuth.requestToken({
                authCode: authCode,
                oauthClient: 'oauth',
                rememberMe: true,
            })
                .then((response) => {
                    const token = response.data.id_token;
                    localStorage.setItem(
                        process.env.VUE_APP_NAME_TOKEN,
                        JSON.stringify(token)
                    );
                    commit(MutationTypes.AUTH_INIT, {
                        token,
                        isRemember: true,
                    });
                    Api.setBearer(token);
                    dispatch('getUser', false)
                        .then(resolve)
                        .catch((error) => {
                            Api.onUnauthorised(error);
                            reject(error);
                        });
                })
                .catch((error) => {
                    Api.onUnauthorised(error);
                    reject(error);
                });
        });
    },

    login({ dispatch, commit, rootState }, data) {
        return new Promise((resolve, reject) => {
            // Avoids user status change (and any re-renders) when current user profile being reloaded
            if (!data.isUserRefresh) {
                commit(MutationTypes.AUTH_REQ);
            }

            // Saves the request token for future use
            ApiAuth.login(data)
                .then((response) => {
                    const token = response.data.id_token;
                    const storage = rootState.storageMap[data.isRemember];

                    storage.setItem(
                        process.env.VUE_APP_NAME_TOKEN,
                        JSON.stringify(token)
                    );
                    commit(MutationTypes.AUTH_INIT, {
                        token,
                        isRemember: data.isRemember,
                        password: data.password,
                    });
                    Api.setBearer(token);

                    dispatch('getUser', data.isUserRefresh)
                        .then(resolve)
                        .catch((error) => {
                            Api.onUnauthorised(error);
                            reject(error);
                        });

                    // Deletes the token if login unsuccessful
                })
                .catch((error) => {
                    Api.onUnauthorised(error);
                    reject(error);
                });
        });
    },

    /**
     * Retrieves the account information for a given user whose token is already set. This also applies to cases where the
     * user has refreshed or come back to the app after some time, serving as a check for expired tokens.
     * @param {Object} context - Context object for this store.
     * @param {Function} context.dispatch - Triggers a certain store action.
     * @param {Function} context.commit - Commits store to a specific state.
     * @see {@link mutation-types.js}
     */
    getUser({ dispatch, commit }, isUserRefresh = false) {
        return new Promise((resolve, reject) => {
            // Guarantees that the ontology index is available before any authenticated screen that requires it for rendering.
            dispatch('indexOntos')
                .then(() => {
                    // Avoids user status change (and any re-renders) when current user profile being reloaded
                    if (!isUserRefresh) {
                        commit(MutationTypes.AUTH_REQ);
                    }

                    ApiAuth.user()
                        .then((response) => {
                            const user = response.data;

                            commit(MutationTypes.AUTH_IN, { user });
                            resolve(response);
                        })
                        .catch(reject);
                })
                .catch(reject);
        }).finally(() => {
            commit(MutationTypes.AUTH_ATTEMPTED);
        });
    },

    /**
     * Destroys the index of ontologies (see 'search' store) to prevent stale data and removes the token from the system.
     * @param {Object} context - Context object for this store.
     * @param {Function} context.dispatch - Triggers a certain store action.
     * @param {Function} context.commit - Commits store to a specific state.
     * @param {Function} context.state - Source of truth for this store module.
     * @param {Function} context.rootState - Source of truth for the whole store.
     */
    logout({ dispatch, commit, state, rootState }) {
        dispatch('flushOntos');
        dispatch('clipboardClear');
        document.cookie =
            'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        localStorage.removeItem(process.env.VUE_APP_NAME_TOKEN);
        sessionStorage.removeItem(process.env.VUE_APP_NAME_TOKEN);
        commit(MutationTypes.AUTH_OUT);
        Api.delBearer();
    },

    /**
     * Remembers if the user has performed a certain action before.
     * @param {Object} context - Context object for this store.
     * @param {Function} context.commit - Commits store to a specific state.
     * @param {string} actionName - Name of the action performed. Effectively, the suffix of an existing mutation type.
     * @see {@link mutation-types.js}
     */
    remember({ commit }, actionName) {
        commit(MutationTypes['AUTH_' + actionName.toUpperCase()]);
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
