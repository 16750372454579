import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportSnapshotToFilePathParams = operations["exportSnapshotToFileUsingGET"]["parameters"]["path"];
type ExportSnapshotToFileQueryParams = operations["exportSnapshotToFileUsingGET"]["parameters"]["query"];
type ExportSnapshotToFileResponse = operations["exportSnapshotToFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * use {ontologyId}/tags/{tagName}/export from OntologyTagResource instead if file_url is present
 * @param {string} ontologyId
 * @param {string} tagName
 * @param {string} owlFormat
 * @return {ExportSnapshotToFileResponse}
*/
export const exportSnapshotToFile = async (
    { ontologyId, tagName }: ExportSnapshotToFilePathParams,
    queryParams?: ExportSnapshotToFileQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/export/snapshot/${tagName}`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as ExportSnapshotToFileResponse;
}
