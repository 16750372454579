import { startCase } from 'lodash';

//TODO Replace with flag variable that can be toggled in the Admin UI.
const isReplaceEnabled = true;

/**
 * Converts Camel casing to space
 * @param {string} acronym - Single string to be converted.
 */
export const replaceCamelCasing = (inputString: string) => {
    if (isReplaceEnabled) {
        return startCase(inputString);
    }
    return inputString;
};
