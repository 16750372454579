import Axios from 'axios';
import { capitalize, startCase, toLower, uniq } from 'lodash';
import { ANNOTATION_PROPS, RELATIONSHIP_PROPS } from '@/config';
import { useEventBus, useOntology, useValidation } from '@/compositions';

const ENDPOINT = '/ontologies';
const env = process.env;

export default {
    new({ newValue, ontologyID, parentID, transactionID = '' }) {
        const newBody = {
            label: newValue,
            transactionId: transactionID,
        };

        if (parentID) {
            newBody.subClassOf = parentID;
        }
        return Axios.put(`${ENDPOINT}/${ontologyID}/classes`, newBody);
    },

    bulkNewClasses(bulkNodes, ontologyId) {
        return Axios.put(`${ENDPOINT}/${ontologyId}/classes/bulk`, bulkNodes);
    },

    /**
     * Handles a new class edit, it is triggered when a new property is added to a class
     * and saves the edit information using the `ontology-edit-post-resource` post endpoint.
     *
     * @param {*} newValue
     * @param {string} propName The property name we are editing, eg. "textualDefinitions"
     * @param {string} ontologyID The ontology id
     * @param {string} classID The class id
     * @param {string} transactionID The transaction id
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    edit({ newValue, propName, ontologyID, classID, transactionID = '' }) {
        const editBody = { newValue };
        const isCustomAnnotationProp =
            useOntology().isCustomAnnotationProperty(propName);
        const isCustomRelationalProp =
            useOntology().isCustomRelationalProperty(propName);

        let apiProp;

        switch (true) {
            case propName === 'primaryLabel':
                apiProp = 'label';
                break;
            case propName === 'superClasses':
                apiProp = 'subClassesOf';
                break;
            case propName === 'textualDefinitions':
                apiProp = 'textDefinition';
                break;
            case propName === 'labels':
                apiProp = `annotationProperties/label`;
                break;
            case isCustomAnnotationProp:
                apiProp = `annotationProperties/${propName}`;
                break;
            case isCustomRelationalProp:
                apiProp = `relationalProperties/${propName}`;
                break;
            default:
                if (propName.indexOf('.') === -1) {
                    apiProp = propName;
                } else {
                    apiProp = propName.replace('.', '/');
                }
        }

        if (transactionID) {
            editBody.transactionId = transactionID;
        }

        // Warnings for clashing labels are turned into full request exceptions.
        return Axios.post(
            `${ENDPOINT}/${ontologyID}/classes/${classID}/${apiProp}`,
            editBody
        ).then((response) => {
            let error;
            if (
                response.headers['warning'] &&
                response.headers['warning'].includes('already exists')
            ) {
                useEventBus().getEventBus.value.$emit(
                    'notification:show',
                    'info',
                    response.headers['warning'] + '\n Do you wish to continue?',
                    'Recent changes',
                    [
                        {
                            text: 'I understand.',
                            action: (toast) => {
                                useEventBus().getEventBus.value.$emit(
                                    'notification:close',
                                    toast
                                );
                            },
                        },
                    ],
                    'centerTop',
                    {
                        timeout: 0,
                    }
                );

                return response;
            }

            if (apiProp === 'label' && response.headers['warning']) {
                /* This will stop a error being generated from duplicate primary ID's across ontologies.
                 *  A warning will be created instead. May be useful later to create a warning system.
                 */

                if (
                    response.headers['warning']
                        .toLowerCase()
                        .includes('the primaryid')
                )
                    return response;
                if (
                    response.headers['warning']
                        .toLowerCase()
                        .includes('primary')
                )
                    return response;

                error = new Error(response.headers['warning']);
                error.errorMessage = response.headers['warning'];
                throw error;
            } else {
                return response;
            }
        });
    },

    /**
     * editLabels
     * @param {string} classId
     * @param {string} ontologyId
     * @param {{newValue: string, transactionId: string}} bodyParams
     * @since 2.0
     * @return {Promise<EditLabelsResponse>}
     */
    editLabelV2({ classId, ontologyId }, bodyParams) {
        const url = `/ontologies/${ontologyId}/classes/${classId}/label`;
        return Axios.post(url, bodyParams);
    },

    /**
     * Edit a property with iri = propertyIri and value = propertyValueIf the property does not exist, it will be created. If the property contains tags, they will be kept
     * @param {string} classId
     * @param {string} ontologyId
     * @param {EditPropertyValuePathParams} bodyParams
     * @return {EditPropertyValueResponse}
     */
    async editPropertyValue({ classId, ontologyId }, bodyParams) {
        const url = `/ontologies/${ontologyId}/classes/${classId}/properties`;
        const response = await Axios.put(url, bodyParams);

        if (
            response.headers['warning'] &&
            response.headers['warning'].includes('already exists')
        ) {
            useEventBus().getEventBus.value.$emit(
                'notification:show',
                'info',
                response.headers['warning'] + '\n Do you wish to continue?',
                'Recent changes',
                [
                    {
                        text: 'I understand.',
                        action: (toast) => {
                            useEventBus().getEventBus.value.$emit(
                                'notification:close',
                                toast
                            );
                        },
                    },
                ],
                'centerTop',
                {
                    timeout: 0,
                }
            );
        }

        return response;
    },

    /**
     * Create a property with iri = propertyIri and value = propertyValue and no tags.If the property already exists, then ALREADY_REPORTED is returned
     * @param {string} classId
     * @param {string} ontologyId
     * @param {CreatePropertyValuePathParams} bodyParams
     * @return {CreatePropertyValueResponse}
     */
    async createPropertyValue({ classId, ontologyId }, bodyParams) {
        const url = `/ontologies/${ontologyId}/classes/${classId}/properties`;
        const response = await Axios.post(url, bodyParams);

        return response;
    },

    /**
     * Delete a property with iri = propertyIri and value = propertyValue
     * @param {string} classId
     * @param {string} ontologyId
     * @param {{propertyIri: string, propertyValue: string, transactionId: string}} bodyParams
     * @since 2.0
     * @return {Promise<PropertyValueResponse>}
     */
    deleteV2({ classId, ontologyId }, bodyParams) {
        const url = `/ontologies/${ontologyId}/classes/${classId}/properties`;
        return Axios.delete(url, { data: bodyParams });
    },

    editAnnotation({
        newValue,
        propName,
        ontologyID,
        classID,
        transactionID = '',
    }) {
        const editBody = { newValue };

        if (transactionID) {
            editBody.transactionId = transactionID;
        }

        return Axios.post(
            `${ENDPOINT}/${ontologyID}/classes/${classID}/annotationProperties/${propName}`,
            editBody
        );
    },

    obsolete({ ontologyID, classID, transactionID = '' }) {
        return Axios.post(
            `${ENDPOINT}/${ontologyID}/classes/${classID}/obsolete`,
            { transactionId: transactionID }
        );
    },

    suggester({ type, ontologyID, primaryLabel, cancelToken }) {
        let apiType;

        switch (type) {
            case 'superClasses':
                apiType = 'subclassof';
                break;
            default:
                apiType = type.toLowerCase();
        }

        return Axios.get(`/suggest/${apiType}`, {
            params: {
                // the endopoint expects everything in lower case
                ontology: ontologyID.toLowerCase(),

                // the endpoint does not accept '+' as the encoding of the space character in queries
                q: primaryLabel.toLowerCase().replace(/\s/g, '_'),
            },

            // prevents race conditions from previous, more sluggish calls
            cancelToken,

            // Normalises each response item into class data, even if not a class in the first place.
        }).then((response) => {
            response.data = response.data.map((suggestItem, index) => {
                if (typeof suggestItem === 'string') {
                    return {
                        id: 'normalised-' + index,
                        primaryLabel: suggestItem,
                        primaryID: suggestItem,
                        shortFormIDs: [],
                        synonyms: [],
                        textualDefinitions: [],
                    };
                } else {
                    Object.assign(suggestItem, suggestItem.suggestion);
                    delete suggestItem.suggestion;
                    return suggestItem;
                }
            });

            return response;
        });
    },

    wikipedia({ text }) {
        const apiUrl = 'https://en.wikipedia.org/w/api.php';
        const params = {
            origin: '*',
            action: 'query',
            prop: 'extracts',
            format: 'json',
            exlimit: 1,
            titles: text,
            redirects: '',
        };

        // Disables CORS for this request by making it anonymous (without a bearer)
        return Axios.get(apiUrl, { params, isAnonymous: true }).then(
            (response) => {
                const pages = response.data.query.pages;
                const key = Object.keys(pages)[0];

                // If no entry was found, it could be that there is no case-sensitive redirect => re-issue search with title case.
                if (key === '-1') {
                    params.titles = startCase(toLower(text));

                    return Axios.get(apiUrl, {
                        params,
                        isAnonymous: true,
                    }).then((response) => {
                        const pages = response.data.query.pages;
                        return pages[Object.keys(pages)[0]].extract;
                    });
                } else {
                    return pages[Object.keys(pages)[0]].extract;
                }
            }
        );
    },

    /**
     * Posts or get a transaction to/from the database.
     * If the `verb` parameter is provided it will edit an existing transaction, otherwise it will return
     * the transaction data associated to the specified `transactionID`.
     *
     * @param {string} ontologyID
     * @param {string} transactionID The transaction id
     * @param {string} comment The text used to populate the transaction message field
     * @param {string} verb The action to perform ('cancel', 'commit', 'reject', 'suggest')
     * @returns {AxiosPromise<any>}
     */ async transaction({
        ontologyID,
        transactionID,
        comment = '',
        verb = '',
        createReverseMappings = false,
    }) {
        if (verb) {
            let url = `${ENDPOINT}/${ontologyID}/edits/transactions/${transactionID}/${verb}?createReverseMappings=${createReverseMappings}`;
            if (useValidation().getChecksum()) {
                url += `&validationChecksum=${useValidation().getChecksum()}`;
            }
            return Axios.post(url, { message: comment }).then(function (
                response
            ) {
                if (response.headers['warning']) {
                    let mappings = JSON.stringify(response.headers['warning'])
                        .replaceAll('"', '')
                        .replaceAll(',', ':')
                        .replaceAll(']:', ']');

                    setTimeout(() => {
                        useEventBus().getEventBus.value.$emit(
                            'notification:show',
                            'info',
                            mappings,
                            '',
                            [],
                            'rightTop'
                        );
                    }, 1000);
                }
            });
        } else {
            return Axios.get(`${ENDPOINT}/edits/transactions/${transactionID}`);
        }
    },

    transaction_backup({ ontologyID, transactionID, comment = '', verb = '' }) {
        if (verb) {
            return Axios.post(
                `${ENDPOINT}/${ontologyID}/edits/transactions/${transactionID}/${verb}`,
                { message: comment }
            );
        } else {
            return Axios.get(`${ENDPOINT}/edits/transactions/${transactionID}`);
        }
    },

    compare({ ontologyID, editID, target = 'current' }) {
        return Axios.get(
            `${ENDPOINT}/${ontologyID}/edits/${editID}/compareWith${capitalize(
                target
            )}`
        ).then((comparison) => {
            // Discards any entries for internal properties or that have not changed.
            const changeableProps = ANNOTATION_PROPS.concat(RELATIONSHIP_PROPS)
                .concat(env.VUE_ANNOTATION_WRAPPER)
                .concat(env.RELATIONAL_WRAPPER);
            const changes = comparison.data.changesArray.filter(
                (changeData) => {
                    const hasChanged =
                        changeData.oldValue !== changeData.value ||
                        changeData.operation === 'REMOVE';
                    const isInteractive =
                        changeableProps.indexOf(changeData.fieldName) !== -1;

                    return hasChanged && isInteractive;
                }
            );

            // Converts property paths to dot-notated names, removing the leading root path and the trailing position indicator if present.
            comparison.data.propNames = uniq(
                changes.map((changeData) => {
                    return changeData['fieldName'];
                })
            );

            return comparison;
        });
    },

    suggestions({
        username,
        isActiveOnly = true,
        pageSize = parseInt(env.VUE_APP_SEARCH_SIZE),
        sort = 'desc',
    }) {
        const params = { size: pageSize, sort };

        return Axios.get(`${ENDPOINT}/suggestions/${username}`, {
            params,
        }).then((response) => {
            if (isActiveOnly) {
                response.data = response.data.content.filter((suggestion) => {
                    return suggestion.status !== 'CANCELLED';
                });
            }

            return response;
        });
    },

    suggestion({ transactionID }) {
        return Axios.get(
            `${ENDPOINT}/suggestions/transactions/${transactionID}`
        );
    },
};
