import * as MutationTypes from './mutation-types';

const state = {
    managementData: {},
};

const mutations = {
    [MutationTypes.MANAGEMENT_SET_DATA](state, managementData) {
        state.managementData = managementData;
    },
};

const getters = {
    managementData: (state) => state.managementData,
    experimental: (state) => {
        const activeProfiles = state.managementData.data.activeProfiles || [];
        return (
            Array.isArray(activeProfiles) &&
            activeProfiles.includes('experimental')
        );
    },
};

const actions = {
    setManagementData({ commit }, managementData) {
        commit(MutationTypes.MANAGEMENT_SET_DATA, managementData);
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
