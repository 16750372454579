import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToVocabFlatFilePathParams = operations["exportToVocabFlatFileUsingGET"]["parameters"]["path"];
type ExportToVocabFlatFileQueryParams = operations["exportToVocabFlatFileUsingGET"]["parameters"]["query"];
type ExportToVocabFlatFileResponse = operations["exportToVocabFlatFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * Export flat vocab file. Supply fileType of 'CSV' or 'TSV', or instead supply customer delimiter, e.g. a comma character. If both are supplied, the fileType will take precedence. If neither are supplied, CSV is used.
 * @param {string} ontologyId
 * @param {string} delimiter
 * @param {string} fileType
 * @return {ExportToVocabFlatFileResponse}
*/
export const exportToVocabFlatFile = async (
    { ontologyId }: ExportToVocabFlatFilePathParams,
    queryParams?: ExportToVocabFlatFileQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/exportAsVocabFlatFile`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as ExportToVocabFlatFileResponse;
}
