import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyDesignPatternsWithLabelsPathParams = operations["getOntologyDesignPatternsWithLabelsUsingGET"]["parameters"]["path"];
type OntologyDesignPatternsWithLabelsResponse = operations["getOntologyDesignPatternsWithLabelsUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get the design patterns of a given ontology with labels
 * @param {string} ontologyId
 * @return {OntologyDesignPatternsWithLabelsResponse}
*/
export const getOntologyDesignPatternsWithLabels = async (
    { ontologyId }: OntologyDesignPatternsWithLabelsPathParams,
) => {
    const url = `/ontology/${ontologyId}/designPatternsWithLabels`;
    const response = await Axios.get(url);

    return response && response.data as OntologyDesignPatternsWithLabelsResponse;
}
