import { computed, ref, Ref } from '@vue/composition-api';
import { DialogueProps } from '@/ts';

type AppDialogConfirmAction = (
    args: unknown | undefined,
    event: Event | undefined
) => void;
type AppDialogCancelAction = (
    args: unknown | undefined,
    event: Event | undefined
) => void;

const showDialog: Ref<boolean> = ref(false);
const dialogTitle: Ref<string> = ref('');
const displayCancelButton: Ref<boolean> = ref(false);
const confirmButtonDisabled: Ref<boolean> = ref(false);
const confirmButtonText: Ref<string> = ref('OK');
const dialogConfirm: Ref<AppDialogConfirmAction | undefined> = ref();
const dialogCancel: Ref<AppDialogCancelAction | undefined> = ref();
const dialogProps: Ref<DialogueProps | undefined> = ref();

export const useAppDialog = () => {
    const getShowDialog = () => {
        return showDialog;
    };

    const getDisplayCancelButton = () => {
        return displayCancelButton;
    };

    const getConfirmButtonDisabled = () => {
        return confirmButtonDisabled;
    };

    const getConfirmButtonText = () => {
        return confirmButtonText;
    };

    const getTitle = computed(() => {
        return dialogTitle.value;
    });

    const getCurrentDialog = () => {
        return {
            dialogTitle: dialogTitle,
            confirmAction: dialogConfirm,
            cancelAction: dialogCancel,
            dialogProps: dialogProps,
        };
    };

    const getConfirmProps = computed(() => {
        return dialogProps.value;
    });

    const confirm = (event: Event, args: unknown) => {
        dialogConfirm.value && dialogConfirm.value(args, event);
        unsetDialog();
    };

    const cancel = (event: Event, args: unknown) => {
        dialogCancel.value && dialogCancel.value(args, event);
        unsetDialog();
    };

    const setProps = (args: DialogueProps | undefined = undefined) => {
        dialogProps.value = args;
    };

    const setConfirmButtonText = (text: string) => {
        confirmButtonText.value = text;
    };

    const setConfirmAction = (action: AppDialogConfirmAction) => {
        dialogConfirm.value = action;
    };

    const setCancelAction = (action: AppDialogCancelAction) => {
        dialogCancel.value = action;
    };

    const setConfirmButtonDisabled = (isDisabled: boolean) => {
        confirmButtonDisabled.value = isDisabled;
    };

    const setDialog = (
        show: boolean,
        title: string,
        options:
            | {
                  confirmAction?: AppDialogConfirmAction | undefined;
                  confirmButtonDisabled?: boolean;
                  confirmButtonText?: string;
                  cancelAction?: AppDialogCancelAction | undefined;
                  showCancelButton?: boolean;
                  props?: DialogueProps | undefined;
              }
            | undefined = undefined
    ) => {
        showDialog.value = show ? show : showDialog.value;
        dialogTitle.value = title ? title : dialogTitle.value;

        if (!options) return;
        (displayCancelButton.value =
            options.showCancelButton !== undefined
                ? options.showCancelButton
                : false),
            (dialogConfirm.value = options.confirmAction
                ? options.confirmAction
                : dialogConfirm.value);
        dialogCancel.value = options.cancelAction
            ? options.cancelAction
            : dialogCancel.value;
        dialogProps.value = options.props ? options.props : dialogProps.value;
        confirmButtonDisabled.value =
            options.confirmButtonDisabled !== undefined
                ? options.confirmButtonDisabled
                : false;
        confirmButtonText.value = options.confirmButtonText || 'OK';
    };

    const unsetDialog = () => {
        showDialog.value = false;
        dialogTitle.value = '';
        dialogConfirm.value = undefined;
        dialogCancel.value = undefined;
        dialogProps.value = undefined;
        confirmButtonText.value = 'OK';
        confirmButtonDisabled.value = false;
        displayCancelButton.value = false;
    };

    const setShowDialog = (show: boolean) => {
        showDialog.value = show;
    };

    const enableConfirmButton = () => {
        setConfirmButtonDisabled(false);
    };

    const disableConfirmButton = () => {
        setConfirmButtonDisabled(true);
    };

    return {
        getTitle,
        getConfirmProps,
        enableConfirmButton,
        disableConfirmButton,
        setConfirmAction,
        setCancelAction,
        setConfirmButtonDisabled,
        getShowDialog,
        getDisplayCancelButton,
        getConfirmButtonDisabled,
        getConfirmButtonText,
        getCurrentDialog,
        setDialog,
        setShowDialog,
        setConfirmButtonText,
        unsetDialog,
        confirm,
        cancel,
        setProps,
    };
};
