<template>
    <b-popover
        :placement="direction"
        triggers="hover"
        boundary="viewport"
        :title="primaryID"
        :target="target"
        :delay="{ show: 0.5, hide: 100 }">
        <h4>
            <b>{{ label }}</b>
        </h4>
        <p>
            <vue-truncate
                ref="vueTruncate"
                class="popover-truncate"
                action-class="toggle-text popover-text"
                :text="description"
                :length="90"
                clamp="..."
                less="..."
                collapsed-text-class="collapsed" />
        </p>

        <span class="synonyms"><b>Synonyms:</b> {{ validSynonyms }}</span>
    </b-popover>
</template>

<script>
import { computed } from '@vue/composition-api/dist/vue-composition-api';
import VueTruncate from 'vue-truncate-collapsed';

export default {
    name: 'PropertyValuePopup',
    components: {
        VueTruncate,
    },
    props: {
        primaryID: {
            type: String,
            required: true,
        },
        primaryLabel: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
            default: 'No Definitions found',
        },
        synonyms: {
            type: [],
            required: false,
            default: 'No synonyms found',
        },
        target: {
            type: String,
            required: true,
        },
        direction: {
            type: String,
            default: 'bottom',
        },
    },
    setup(props) {
        const label = computed(() => {
            return (
                props.primaryLabel[0].toUpperCase() +
                props.primaryLabel.slice(1).toLowerCase()
            );
        });

        const validSynonyms = computed(() => {
            return props.synonyms
                ? props.synonyms.toString().replace(',', ' \u2022 ')
                : 'No Synonyms found';
        });

        return {
            label,
            validSynonyms,
        };
    },
};
</script>
<style scoped lang="scss">
@import 'src/scss/variables.scss';
.description {
    font-style: normal;
    font-size: 95%;
}
.synonyms {
    color: #6c757d;
    font-size: 90%;
}

.popover {
    max-width: 25em; /* Max Width of the popover (depending on the container!) */
}

::v-deep .toggle-text {
    display: inline-block;
    color: lighten($secondary, 8%) !important;
    font-size: 0.9em;
    font-weight: 500;
    user-select: none;
    transition: $btn-transition;

    &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 0.2rem;
        vertical-align: middle;
        border-style: solid;
        border-width: 0.4em 0.4em 0 0.4em;
        border-color: lighten($secondary, 8%) transparent transparent
            transparent;
        transform: rotate(180deg);
        transition: $btn-transition;
    }

    &:hover {
        color: $secondary !important;

        &:after {
            border-color: $secondary transparent transparent transparent;
        }
    }
}

::v-deep .edit-focus .toggle-text {
    pointer-events: none;
    opacity: 0.5;
}

// The plugin shows the toggle even when the text is equal to the max
.max-size ::v-deep .toggle-text {
    display: none;
}

::v-deep .collapsed + .toggle-text:after {
    transform: none;
}

::v-deep .popover.popover-truncate .toggle-text {
    display: inline-block !important;
}
</style>
