import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type MergeClassesPathParams = operations["mergeClassesUsingPOST"]["parameters"]["path"];
type MergeClassesBodyParams = operations["mergeClassesUsingPOST"]["requestBody"]["content"]["application/json"];
type MergeClassesResponse = operations["mergeClassesUsingPOST"]["responses"]["200"]['content']["*/*"];

/**
 * Merge the classes depending on the merge strategy OBSOLETE, DELETE and CREATETERM. New label is mandatory only if merge strategy is CREATETERM
 * @param {string} classId
 * @param {string} ontologyId
 * @param {MergeClassesPathParams} bodyParams
 * @return {MergeClassesResponse}
*/
export const mergeClasses = async (
    { classId, ontologyId }: MergeClassesPathParams,
    bodyParams: MergeClassesBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/merge`;
    const response = await Axios.post(url, bodyParams);

    return response && response.data as MergeClassesResponse;
}
