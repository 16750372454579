import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

Vue.use(VueCompositionAPI);

import { camelCase } from 'lodash';
import { v4 as generateUuid } from 'uuid';
import { UnwrapRef, reactive, computed } from '@vue/composition-api';
import * as importedActions from '@/observers';
import { ObserverParams, Observer } from '@/ts';

const observerActions = importedActions as unknown as Record<string, Observer>;

interface Observers extends ObserverParams {
    id: string;
    priority: number;
}

/**
 * Collection of observers by subject name.
 * @type {UnwrapRef<{}>}
 */
const observers: UnwrapRef<Record<string, Observers[]>> = reactive({});

/**
 * Observers system, use to subscribe/unsubscribe actions against events.
 * @returns Object
 */
export const useObservers = () => {
    const getSubjects = computed(() => observers);

    /**
     * Removes an entire action from the actions reactive object.
     * @param {string} name
     */
    const unregisterSubject = (name: string) => {
        observers[name] && delete observers[name];
    };

    /**
     * Removes a record by observerId from the observers[subjectName] array.
     * @param {string} subjectName
     * @param {string} observerId
     */
    const unregisterObserver = (subjectName: string, observerId: string) => {
        if (!observers[subjectName]) return;

        observers[subjectName] = observers[subjectName].filter((observer) => {
            return observer.id !== observerId;
        });
    };

    /**
     * Adds an observer against a subject name in the observers reactive object.
     * @param {string} name The action name
     * @param {ObserverName} subjectName
     * @param {Record<string, unknown> | undefined} args The arguments to pass to the hook
     * @param {number | undefined} priority The order to execute the hook
     * @return {() => void}
     */
    const registerObserver = (
        subjectName: string,
        { observer, args, priority = 10 }: ObserverParams
    ) => {
        if (!observers[subjectName]) observers[subjectName] = [];

        const id = generateUuid();

        /**
         * Removes the registered hook from the actions reactive object.
         */
        const removeSelf = () => {
            unregisterObserver(subjectName, id);
        };

        args = { ...args, removeSelf };

        observers[subjectName].push({ observer: observer, args, priority, id });

        // Sorts again the array by priority
        observers[subjectName].sort((a, b) => a.priority - b.priority);

        return removeSelf;
    };

    /**
     * Adds an observer against multiple subjects.
     * @param {string[]} subjectNames
     * @param {ObserverName} observer
     * @param {Record<string, unknown> | undefined} args
     * @param {number | undefined} priority
     * @return {Record<string, Function>} An object having as a key the subject name and as a value the remove function.
     */
    const registerObserverMultiple = (
        subjectNames: string[],
        { observer, args, priority = 10 }: ObserverParams,
        priorities: number[] = []
    ) => {
        const unregisterObservers: Record<string, Function> = {};

        subjectNames.forEach((subjectName, index) => {
            priority = (priorities.length && priorities[index]) || priority;
            unregisterObservers[subjectName] = registerObserver(subjectName, {
                observer,
                args,
                priority,
            });
        });

        return unregisterObservers;
    };

    /**
     * Performs the observer actions contained in the observers[name] array at the specified index.
     * If the action returns true it will execute the next action in the observers[name] array.
     * @param {string} subjectName
     * @param {Record<string, unknown>} args
     * @param {number} index
     * @return {Promise<void>}
     */
    const notifyObservers = async (
        subjectName: string,
        args: Record<string, unknown> = {},
        index = 0
    ) => {
        if (!observers[subjectName]) return;

        args.subjectName = subjectName;

        const subjectObservers = observers[subjectName];
        const observer = subjectObservers[index];
        const observerName = camelCase(observer.observer);
        const observerAction: Observer = observerActions[observerName];
        const nextAction = await observerAction({ ...args, ...observer.args });
        const nextIndex = index + 1;

        if (args.oneOff === true) unregisterObserver(subjectName, observer.id);

        if (nextAction && subjectObservers[nextIndex] !== undefined)
            await notifyObservers(subjectName, args, nextIndex);
    };

    return {
        getSubjects,
        unregisterSubject,
        unsubscribeObserver: unregisterObserver,
        registerObserver,
        registerObserverMultiple,
        notifyObservers,
    };
};

export default useObservers;
