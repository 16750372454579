import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type RootPropertiesPathParams = operations["getRootPropertiesUsingGET"]["parameters"]["path"];
type RootPropertiesQueryParams = operations["getRootPropertiesUsingGET"]["parameters"]["query"];
type RootPropertiesResponse = operations["getRootPropertiesUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get root properties (properties with no parents) for a given ontology
 * @param {string} ontologyId
 * @param {integer} from
 * @param {integer} size
 * @return {RootPropertiesResponse}
*/
export const getRootProperties = async (
    { ontologyId }: RootPropertiesPathParams,
    queryParams?: RootPropertiesQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/properties/rootPropertiesPage`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as RootPropertiesResponse;
}
