import { searchClassesByIdentifiersInAllOntologiesOneQuery } from '@/api-v2';
import { components } from '@/ts/ApiSpecs';
import { computed, ref, Ref } from '@vue/composition-api';

let propertyBatch: string[] = [];
let propertyBatches: string[][] = [];
const maxBatchSize = 100;
const cachedResults: Ref<
    Record<string, components['schemas']['OntologySummaryVM'][]>
> = ref({});
const isSearchIdle = ref(true);

export const usePropertyBatching = () => {
    const getIsSearchFinished = computed(() => {
        if (Object.keys(cachedResults.value).length) return true;
        return isSearchIdle.value;
    });

    const addStringToSearch = (value: string) => {
        if (propertyBatch.length >= maxBatchSize) pushBatch();
        propertyBatch.push(value);
    };

    const pushBatch = () => {
        propertyBatches.push([...propertyBatch]);
        propertyBatch = [];
    };

    const clearCache = () => {
        propertyBatch = [];
        propertyBatches = [];
        cachedResults.value = {};
    };

    const searchPropertyBatches = async () => {
        isSearchIdle.value = false;
        if (propertyBatch.length > 0) pushBatch(); //Pushes the current batch, as if it has less than 100 results, it will not have been pushed yet.

        const promises = [];
        for (const batch of propertyBatches) {
            const promise =
                searchClassesByIdentifiersInAllOntologiesOneQuery(batch);
            promises.push(promise);
        }
        const responses = await Promise.allSettled(promises);

        if (responses.length === 0) {
            return;
        }

        for (const response of responses) {
            if (response.status === 'rejected') continue;

            if (typeof response.value.hits === 'undefined') continue;

            const hits = response.value.hits;

            for (const key in hits) {
                if (hits[key].length === 0) continue;
                cachedResults.value[key] = hits[key];
            }
        }

        propertyBatch = [];
        propertyBatches = [];
        isSearchIdle.value = true;
        return cachedResults.value;
    };

    return {
        addStringToSearch,
        searchPropertyBatches,
        isSearchIdle,
        cachedResults,
        getIsSearchFinished,
        clearCache,
    };
};
