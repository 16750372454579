import * as MutationTypes from '@/store/mutation-types';
import Api from '@/api';
import store, { state as rootState } from '@/store';

const setTokenToState = (token, isRemember) => {
    const storage = rootState.storageMap[isRemember];
    if (!storage) {
        Api.setBearer(token);
        localStorage.setItem(
            process.env.VUE_APP_NAME_TOKEN,
            JSON.stringify(token)
        );
        return;
    }

    storage.setItem(process.env.VUE_APP_NAME_TOKEN, JSON.stringify(token));
    store.commit(MutationTypes.AUTH_INIT, {
        token,
        isRemember: isRemember,
    });
    Api.setBearer(token);
};

export default setTokenToState;
