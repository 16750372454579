import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type LogoutResponse = unknown;

/**
 * logout
 * @return {LogoutResponse}
*/
export const logout = async () => {
    const url = `/logout`;
    const response = await Axios.post(url);

    return response && response.data as LogoutResponse;
}
