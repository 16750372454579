import { computed, ref, Ref } from '@vue/composition-api';
import { components } from '@/ts/ApiSpecs';
import { pick, upperFirst } from 'lodash';
import { formatPropertyName, humanReadable } from '@/utils';

import {
    ANNOTATION_PROPERTY_LOOKUP,
    ANNOTATION_PROPERTY_NAMES,
    METADATA_RELATIONAL_PROP_NAMES,
} from '@/config';
import {
    AnnotationProperty,
    AnnotationPropertyAlias,
    DefaultAnnotationPropertyName,
} from '@/ts';
import { Tag } from '@/ts/classes/Tag';
import { Keys } from '@/ts/Utilities';
import { usePropertyValues } from '@/compositions/usePropertyValues';

type CustomProperty = components['schemas']['CustomProperty'];
interface CustomPropertyAlias extends CustomProperty {
    isAlias?: boolean;
}
type OntologyMetadataJSONBean =
    components['schemas']['OntologyMetadataJSONBean'];
interface OntologyMetaDataJSONBeanExtended extends OntologyMetadataJSONBean {
    customAnnotationProperties: components['schemas']['CustomProperty'][];
}

const ontologyId: Ref<string> = ref('');

const currentOntology: Ref<OntologyMetaDataJSONBeanExtended | undefined> =
    ref();
const currentDefaultAnnotationProperties: Ref<
    components['schemas']['CustomProperty'][]
> = ref([]);
const currentCustomAnnotationProperties: Ref<CustomPropertyAlias[]> = ref([]);
const currentCustomRelationalProperties: Ref<
    components['schemas']['CustomProperty'][]
> = ref([]);
const currentAnnotationProperties: Ref<
    components['schemas']['CustomProperty'][]
> = ref([]);
const currentAnnotationPropertyNames: Ref<string[]> = ref([]);

export const useOntology = () => {
    /**
     * This gets set from the params rather than a call
     * @param _ontologyId
     */
    const setOntologyId = (_ontologyId: string) => {
        ontologyId.value = _ontologyId;
        return ontologyId.value;
    };

    /**
     * This gets set from the params rather than a call
     * @param _ontologyId
     */
    const getOntologyId = computed(() => {
        return ontologyId.value;
    });

    const getCurrentOntology = computed(() => {
        return currentOntology.value;
    });

    const getCurrentOntologyId = computed(() => {
        return currentOntology.value && currentOntology.value.ontologyUniqueID;
    });

    //Computes and returns a list of default annotations with their aliases/labels returned in their data.
    //This pulls from the ontology annotations properties.
    //This function is needed to calculate the annotation property list, edit this at your own risk.
    const getAllDefaultAnnotationPropertiesWithAliases = computed(() => {
        //Get the default properties so we can loop through the defaults
        const defaultProperties = pick(
            currentOntology.value,
            ANNOTATION_PROPERTY_NAMES
        ) as Record<string, string[]>;

        //Find the annotations as they contain the aliases
        const annotationProperties =
            currentOntology.value?.annotationProperties || [];

        //Create an object to fill out the new annotation properties with aliases
        const annotationPropertiesWithAliases: Record<
            string,
            AnnotationPropertyAlias[]
        > = {};

        //Loop through the default properties and find the matching ones in the annotationProperties
        for (const defaultPropertyKey in defaultProperties) {
            defaultProperties[defaultPropertyKey].forEach((iri) => {
                const annotationPropertyWithAlias = annotationProperties.find(
                    (propertyIri) => propertyIri.iri === iri
                );

                const annotationPropertySectionName =
                    lookupAnnotationOntologyName(
                        defaultPropertyKey as DefaultAnnotationPropertyName
                    );

                if (
                    !annotationPropertiesWithAliases[
                        annotationPropertySectionName
                    ]
                ) {
                    annotationPropertiesWithAliases[
                        annotationPropertySectionName
                    ] = [];
                }

                if (annotationPropertyWithAlias) {
                    annotationPropertiesWithAliases[
                        annotationPropertySectionName
                    ].push({
                        iri: annotationPropertyWithAlias.iri || '',
                        alias: annotationPropertyWithAlias.name,
                        name: annotationPropertySectionName,
                        searchable: annotationPropertyWithAlias.searchable,
                        sectionName: annotationPropertySectionName,
                    });
                    return;
                }

                annotationPropertiesWithAliases[
                    annotationPropertySectionName
                ].push({
                    iri: iri,
                    alias: '',
                    name: annotationPropertySectionName,
                    sectionName: annotationPropertySectionName,
                });
            });
        }

        return annotationPropertiesWithAliases;
    });

    /**
     * Used to find custom annotation property Iris name property
     */
    const lookUpIriToName = computed(() => {
        const customAnnotationLookUp: Record<string, string> = {};
        currentOntology.value?.customAnnotationProperties.forEach(
            (customProperty) => {
                if (!customProperty.iri || !customProperty.name) return;
                if (customAnnotationLookUp[customProperty.iri]) return;

                customAnnotationLookUp[customProperty.iri] =
                    customProperty.name;
            }
        );
        return customAnnotationLookUp;
    });

    /**
     * Transform a given annotation property name from the OntologyMetadataVM object (Ontology)
     * to a property name from the OntologySummaryVM (Class).
     * @param {DefaultAnnotationPropertyName} annotationName
     * @return {string}
     */
    const lookupAnnotationOntologyName = (
        annotationName: DefaultAnnotationPropertyName
    ) => {
        return ANNOTATION_PROPERTY_LOOKUP[annotationName].name;
    };

    /**
     * Gets default relational properties.
     * @type {ComputedRef<undefined | {iri: string | undefined, name: string}[]>}
     */
    const getDefaultRelationalProperties = computed(() => {
        if (!currentOntology.value) return;

        const relationalPropertiesNames = Object.keys(
            METADATA_RELATIONAL_PROP_NAMES
        );
        const propertiesObjects: Record<string, unknown> = pick(
            currentOntology.value,
            relationalPropertiesNames
        );

        if (!propertiesObjects) return;

        return Object.entries(METADATA_RELATIONAL_PROP_NAMES).map(
            ([metadataName, name]) => {
                const prop = propertiesObjects[
                    metadataName
                ] as components['schemas']['CustomProperty'];
                const iri = prop ? prop.iri : '';

                return { name, iri };
            }
        );
    });

    /**
     * Gets the custom defined relational properties for the current ontology.
     * @type {ComputedRef<definitions["CustomProperty"][]|undefined>}
     */
    const getCustomRelationalProperties = computed(
        (): components['schemas']['CustomProperty'][] | undefined => {
            return currentCustomRelationalProperties.value;
        }
    );

    const getDefaultRelationalPropertyNames = computed(() => {
        return (
            (getDefaultRelationalProperties.value &&
                getDefaultRelationalProperties.value.map((property) => {
                    return property.name;
                })) ||
            []
        );
    });

    const getCustomRelationalPropertyNames = computed(() => {
        return (
            (getCustomRelationalProperties.value &&
                getCustomRelationalProperties.value.map((property) => {
                    return property.name;
                })) ||
            []
        );
    });

    const getAllRelationalPropertyNames = computed(() => {
        const defaultRelationalPropertyNames =
            getDefaultRelationalPropertyNames.value;
        const customRelationalPropertyNames =
            getCustomRelationalPropertyNames.value;

        return [
            ...defaultRelationalPropertyNames,
            ...customRelationalPropertyNames,
        ];
    });

    const getAllRelationalProperties = computed(() => {
        const defaultRelationalProperties =
            getDefaultRelationalProperties.value || [];
        const customRelationalProperties =
            getCustomRelationalProperties.value || [];

        return [...defaultRelationalProperties, ...customRelationalProperties];
    });

    /**
     * Gets the default annotation properties for the current ontology.
     * Defaults annotation properties are those contained in the AnnotationPropertyNames types.
     * @type {ComputedRef<undefined|MappedDefaultAnnotationProperty[]>}
     */
    const getDefaultAnnotationProperties = computed(
        (): undefined | components['schemas']['CustomProperty'][] => {
            return currentDefaultAnnotationProperties.value;
        }
    );

    /**
     * Gets the custom defined annotation properties for the current ontology.
     */
    const getCustomAnnotationProperties = computed(() => {
        return currentCustomAnnotationProperties.value;
        // if (!currentOntology.value) return;
        // return currentOntology.value && currentOntology.value.annotationProperties;
    });

    /**
     * Gets all the annotation properties of the current ontology.
     * @type {ComputedRef<AnnotationProperty[]>}
     */
    const getCurrentAnnotationProperties = computed(
        (): AnnotationProperty[] => {
            return currentAnnotationProperties.value;
            // const defaultAnnotationProperties = getDefaultAnnotationProperties.value || [];
            // const annotationProperties = getCustomAnnotationProperties.value || [];
            // const allAnnotationProperties = [...defaultAnnotationProperties, ...annotationProperties];
            //
            // return allAnnotationProperties;
        }
    );

    const updateCurrentDefaultAnnotationProperties = () => {
        if (!currentOntology.value) return;

        const propertiesObjects = pick(
            currentOntology.value,
            ANNOTATION_PROPERTY_NAMES
        );

        if (!propertiesObjects) return;

        currentDefaultAnnotationProperties.value = Object.entries(
            propertiesObjects
        ).map(([name, iri]) => {
            const propName = name as DefaultAnnotationPropertyName;
            if (!name || !iri) return { name: '', iri: '' };

            return {
                name: lookupAnnotationOntologyName(propName),
                iri: Array.isArray(iri) ? [iri[0]] : [iri],
            } as unknown as components['schemas']['CustomProperty'];
        });
    };

    const updateCurrentCustomAnnotationProperties = () => {
        if (!currentOntology.value) return;
        currentCustomAnnotationProperties.value = currentOntology.value
            ? currentOntology.value.annotationProperties || []
            : [];
    };

    const updateCurrentCustomRelationalProperties = () => {
        if (!currentOntology.value) return;
        currentCustomRelationalProperties.value = currentOntology.value
            ? currentOntology.value?.relationalProperties || []
            : [];
    };

    const updateCurrentAnnotationPropertyNames = () => {
        const defaultAnnotationPropertiesNames =
            currentDefaultAnnotationProperties.value.map((property) => {
                return property.name || '';
            }) || [];

        const customAnnotationPropertiesNames =
            currentCustomAnnotationProperties.value.map((property) => {
                return property.name || '';
            }) || [];

        currentAnnotationPropertyNames.value = [
            ...defaultAnnotationPropertiesNames,
            ...customAnnotationPropertiesNames,
        ];
    };

    const getAllAnnotationProperties = () => {
        const propertiesObjects = pick(
            currentOntology.value,
            ANNOTATION_PROPERTY_NAMES
        );

        if (!propertiesObjects) return;

        return Object.entries(propertiesObjects).map(([name, iri]) => {
            const propName = name as DefaultAnnotationPropertyName;
            if (!name || !iri) return { name: '', iri: '' };
            return {
                name: lookupAnnotationOntologyName(propName),
                iri,
            } as components['schemas']['CustomProperty'];
        });
    };

    const updateCurrentAnnotationProperties = () => {
        updateCurrentDefaultAnnotationProperties();
        updateCurrentCustomAnnotationProperties();
        updateCurrentAnnotationPropertyNames();

        const annotationProperties = getCustomAnnotationProperties.value || [];
        const allAnnotationProperties = getAllAnnotationProperties() || [];

        currentAnnotationProperties.value = [
            ...allAnnotationProperties,
            ...annotationProperties,
        ];
    };

    const getDefaultAnnotationPropertyNames = computed(() => {
        return (
            (getDefaultAnnotationProperties.value &&
                getDefaultAnnotationProperties.value.map((property) => {
                    return property.name;
                })) ||
            []
        );
    });

    /**
     * Extracts iris from an array of annotation properties.
     * @type {ComputedRef<any[] | string[]>}
     */
    const getPropertiesIris = (
        properties: components['schemas']['CustomProperty'][]
    ) => {
        const iris: string[] = [];

        properties.forEach((property) => {
            if (!property.iri) return;

            if (Array.isArray(property.iri)) {
                const iri = property.iri as string[];
                iris.push(...iri);
            } else iris.push(property.iri);
        });

        return iris;
    };

    /**
     * Retrieves all label properties iris.
     * @type {ComputedRef<string[]|undefined>}
     */
    const getLabelIris = computed((): string[] | undefined => {
        if (!currentOntology.value) return;

        return currentOntology.value.allLabelProperties;
    });

    /**
     * Gets the irs of custom default annotation properties of the current class.
     * @type {ComputedRef<any[] | string[]>}
     */
    const getDefaultAnnotationPropertyIris = computed(() => {
        if (!getDefaultAnnotationProperties.value) return [];

        return getPropertiesIris(getDefaultAnnotationProperties.value);
    });

    /**
     * Gets the names of custom default annotation properties of the current class.
     * @type {ComputedRef<any>}
     */
    const getCustomAnnotationPropertyNames = computed(() => {
        return (
            (getCustomAnnotationProperties.value &&
                getCustomAnnotationProperties.value.map((property) => {
                    return property.name;
                })) ||
            []
        );
    });

    /**
     * Gets the irs of custom custom annotation properties of the current class.
     * @type {ComputedRef<undefined | string[]>}
     */
    const getCustomAnnotationPropertyIris = computed(() => {
        if (!getCustomAnnotationProperties.value) return [];

        return getPropertiesIris(getCustomAnnotationProperties.value);
    });

    /**
     * Determines if a property, specified by name, is a custom annotation property.
     * @param {string} propertyName
     * @return {boolean}
     */
    const isCustomAnnotationProperty = (propertyName: string): boolean => {
        return getCustomAnnotationPropertyNames.value.includes(propertyName);
    };

    /**
     * Determines if a property, specified by name, is a relational property.
     * @param {string} propertyName
     * @return {boolean}
     */
    const isCustomRelationalProperty = (propertyName: string): boolean => {
        return getCustomRelationalPropertyNames.value.includes(propertyName);
    };

    /**
     * Gets a collection of the the current class annotation properties names.
     * @type {ComputedRef<string[]>}
     */
    const getCurrentAnnotationPropertiesNames = computed(() => {
        const defaultAnnotationPropertiesNames =
            getDefaultAnnotationPropertyNames.value;

        const customAnnotationPropertiesNames =
            getCustomAnnotationPropertyNames.value;

        return [
            ...defaultAnnotationPropertiesNames,
            ...customAnnotationPropertiesNames,
        ];
    });

    /**
     * Gets a collection of the the current class annotation properties iris.
     * @type {ComputedRef<string[]>}
     */
    const getCurrentAnnotationPropertiesIris = computed(() => {
        const defaultAnnotationPropertiesIris =
            getDefaultAnnotationPropertyIris.value;
        const customAnnotationPropertiesIris =
            getCustomAnnotationPropertyIris.value;

        return [
            ...defaultAnnotationPropertiesIris,
            ...customAnnotationPropertiesIris,
        ];
    });

    const setCurrentOntology = (ontology: OntologyMetaDataJSONBeanExtended) => {
        currentOntology.value = ontology;

        updateCurrentAnnotationProperties();
        updateCurrentCustomRelationalProperties();
    };

    const propMetadataNameToClassName = (
        metadataPropertyName: DefaultAnnotationPropertyName
    ) => {
        const lookupObject = ANNOTATION_PROPERTY_LOOKUP[metadataPropertyName];

        if (!lookupObject) return;

        return lookupObject.name;
    };

    /**
     * Retrieves a list of property objects for the current class.
     * @type {ComputedRef<undefined | Record<string, string>[]>}
     */
    const getAvailableTags = computed(() => {
        if (!currentOntology.value) return;

        const availableTags: Tag[] = [];

        Object.entries(currentOntology.value).forEach((entry) => {
            const propertyName = entry[0] as Keys<
                components['schemas']['OntologyMetadataJSONBean']
            >;
            const classPropertyName = propMetadataNameToClassName(
                propertyName as DefaultAnnotationPropertyName
            );
            const value = entry[1] as
                | { iri: string; name: string; searchable: boolean }
                | string;
            const relationalPropertyNames = Object.keys(
                METADATA_RELATIONAL_PROP_NAMES
            );
            const ignoreProperties = [
                ...relationalPropertyNames,
                'hierarchicalProperties',
            ];

            if (!Array.isArray(value)) return;
            if (!value.length) return;
            if (!propertyName.match(/Properties$/)) return;
            if (ignoreProperties.includes(propertyName)) return;
            if (propertyName === 'relationalProperties') return;

            let shortPropName = propertyName.replace('Properties', '');

            if (relationalPropertyNames.includes(shortPropName)) return;

            if (propertyName === 'allLabelProperties') shortPropName = 'label';

            const propertyReadable = upperFirst(humanReadable(shortPropName));

            value.forEach((metadata) => {
                let iri = '';
                let type = '';

                if (typeof metadata === 'object') {
                    iri = metadata.iri as string;
                    type = (metadata.name as unknown as string) || '';
                } else {
                    iri = metadata as string;
                }

                if (!type) {
                    const iriLabelIndex = iri.search(/[^/]*$/);
                    const iriLabel = iri.slice(iriLabelIndex, iri.length);

                    const iriHashIndex = iri.search(/[^#]*$/);
                    const iriHash =
                        iriHashIndex > 0
                            ? iri.slice(iriHashIndex, iri.length)
                            : '';

                    type = iriHash ? formatPropertyName(iriHash) : iriLabel;
                }

                if (type === 'license') {
                    return;
                }

                availableTags.push(
                    new Tag({
                        iri,
                        type,
                        propertyName,
                        classPropertyName,
                        propertyReadable,
                    })
                );
            });
        });

        availableTags.push({
            iri: '',
            propertyReadable: 'Language',
            type: 'LANG',
        });

        return availableTags;
    });

    /**
     * Gets an IRI by property name.
     * @param {string} propertyName
     * @return {string}
     */
    const getIriByPropName = (propertyName: string): string | undefined => {
        if (!getDefaultAnnotationProperties.value) return;

        for (const annotationProperty of getDefaultAnnotationProperties.value) {
            if (propertyName === annotationProperty.name) {
                const iri = annotationProperty.iri;

                return Array.isArray(iri) ? iri[0] : iri;
            }
            continue;
        }
    };

    const getIrisByPropName = (propertyName: string): string[] | undefined => {
        if (!getDefaultAnnotationProperties.value) return;

        const valuesByPropertyName =
            usePropertyValues().propertyValuesByPropGroupName.value[
                propertyName
            ];

        return valuesByPropertyName ? Object.keys(valuesByPropertyName) : [];
    };

    const getFirstIriByPropName = (propertyName: string) => {
        if (!getDefaultAnnotationProperties.value) return;

        for (const annotationProperty of getDefaultAnnotationProperties.value) {
            if (propertyName === annotationProperty.name)
                return annotationProperty.iri && annotationProperty.iri[0];
            continue;
        }
    };

    return {
        getCurrentOntology,
        getCurrentOntologyId,
        getDefaultRelationalProperties,
        getCustomRelationalProperties,
        getAllRelationalProperties,
        getDefaultRelationalPropertyNames,
        getCustomRelationalPropertyNames,
        getAllRelationalPropertyNames,
        getCustomAnnotationProperties,
        getDefaultAnnotationProperties,
        getCurrentAnnotationProperties,
        getDefaultAnnotationPropertyNames,
        getCustomAnnotationPropertyNames,
        getCurrentAnnotationPropertiesNames,
        getCurrentAnnotationPropertiesIris,
        getDefaultAnnotationPropertyIris,
        getAvailableTags,
        getLabelIris,
        isCustomAnnotationProperty,
        isCustomRelationalProperty,
        setCurrentOntology,
        getIriByPropName,
        getIrisByPropName,
        getFirstIriByPropName,
        currentAnnotationProperties,
        currentAnnotationPropertyNames,
        currentCustomAnnotationProperties,
        getAllDefaultAnnotationPropertiesWithAliases,
        lookUpIriToName,
        getOntologyId,
        setOntologyId,
    };
};
