import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllOntologyTagsPathParams = operations["getAllOntologyTagsUsingGET"]["parameters"]["path"];
type AllOntologyTagsResponse = operations["getAllOntologyTagsUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get all ontology snapshots
 * @param {string} ontologyId
 * @return {AllOntologyTagsResponse}
*/
export const getAllOntologyTags = async (
    { ontologyId }: AllOntologyTagsPathParams,
) => {
    const url = `/ontology/${ontologyId}/tags`;
    const response = await Axios.get(url);

    return response && response.data as AllOntologyTagsResponse;
}
