import { kebabCase } from 'lodash';
import { useTestSelectors } from '@/compositions';

/**
 * Handles logic behind class for testing selectors.
 */
export default {
    props: {
        /**
         * @var {string|number} testId
         * @description The final partial identifier to be used in the class name.
         *              This could be an iteration index or any other definition to identify the current selector.
         */
        testId: {
            type: [String | Number],
        },
    },
    updated() {
        this.addTestClass();
    },
    mounted() {
        this.addTestClass();
    },
    methods: {
        /**
         * Given a testId and a namespace computes the test class name.
         * @param {string} namespace A string to be used between the component name and the testId prop.
         * @returns {string} The computed test class.
         */
        buildTestClass: function (namespace = '') {
            const componentName = this.$options.name;
            const testId =
                this.testId?.replace && this.testId.replace(' ', '__');

            return useTestSelectors({ testId }).buildTestClass(
                componentName,
                namespace
            );
        },
        addTestClass: function () {
            if (this.testId !== undefined) {
                this.$el.classList.add(this.buildTestClass());
            }
        },
    },
};
