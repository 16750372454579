import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type EditTagsPathParams = operations["editTagsUsingPOST"]["parameters"]["path"];
type EditTagsBodyParams = operations["editTagsUsingPOST"]["requestBody"]["content"]["application/json"];
type EditTagsResponse = operations["editTagsUsingPOST"]["responses"]["201"]['content']["*/*"];

/**
 * Edit tags of a property with iri = propertyIri, value = propertyValue and optional lang = the given language in standard format Tags will be overwritten for that specific property with the new value. If the propery value has a language tag, make sure to include it on the tags list, otherwise it will be removed.
 * @param {string} ontologyId
 * @param {string} classId
 * @param {EditTagsPathParams} bodyParams
 * @return {EditTagsResponse}
*/
export const editTags = async (
    { ontologyId, classId }: EditTagsPathParams,
    bodyParams: EditTagsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/tags`;
    const response = await Axios.post(url, bodyParams);

    return response && response.data as EditTagsResponse;
}
