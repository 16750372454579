import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToTermiteSkosFilePathParams = operations["exportToTermiteSkosFileUsingGET"]["parameters"]["path"];
type ExportToTermiteSkosFileQueryParams = operations["exportToTermiteSkosFileUsingGET"]["parameters"]["query"];
type ExportToTermiteSkosFileResponse = operations["exportToTermiteSkosFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * Use termiteSkosFormat.TERMITE_SKOS_AUG if you want to export AUG. Use TERMITE_SKOS or no parameter at all if you want to export a full TERMITE SKOS file
 * @param {string} ontologyId
 * @param {array} includePropertiesForTermite
 * @param {string} skosFormat
 * @param {string} termiteSkosFormat
 * @return {ExportToTermiteSkosFileResponse}
*/
export const exportToTermiteSkosFile = async (
    { ontologyId }: ExportToTermiteSkosFilePathParams,
    queryParams?: ExportToTermiteSkosFileQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/exportAsTermiteSkos`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as ExportToTermiteSkosFileResponse;
}
