import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type SuggestionsForTransactionPathParams = operations["getSuggestionsForTransactionUsingGET"]["parameters"]["path"];
type SuggestionsForTransactionResponse = operations["getSuggestionsForTransactionUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * getSuggestionsForTransaction
 * @param {string} transactionId
 * @return {SuggestionsForTransactionResponse}
*/
export const getSuggestionsForTransaction = async (
    { transactionId }: SuggestionsForTransactionPathParams,
) => {
    const url = `/ontologies/edits/transactions/${transactionId}`;
    const response = await Axios.get(url);

    return response && response.data as SuggestionsForTransactionResponse;
}
