import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ValidateTransactionPathParams = operations["validateTransactionUsingPUT"]["parameters"]["path"];
type ValidateTransactionResponse = operations["validateTransactionUsingPUT"]["responses"]["200"]['content']["*/*"];

/**
 * Validate one transaction. Only valid transactions return a checksum
 * @param {string} ontologyId
 * @param {string} transactionId
 * @return {ValidateTransactionResponse}
*/
export const validateTransaction = async (
    { ontologyId, transactionId }: ValidateTransactionPathParams,
) => {
    const url = `/ontology/${ontologyId}/transactions/${transactionId}/validate`;
    const response = await Axios.put(url);

    return response && response.data as ValidateTransactionResponse;
}
