export default {
    data() {
        return {
            localStorageKey: 'uncommittedTransaction',
            // Properties of any suggestion being edited.
            session: {},
        };
    },

    computed: {
        isRecoveryMode() {
            return this.$store.getters.isRecoveryMode;
        },
    },

    methods: {
        $_recovery_reset() {
            localStorage.removeItem(this.localStorageKey);
            this.session = {};
        },

        /**
         * Saves the transaction ID when an edit session starts.
         * @param ontologyID
         * @param transactionID
         */
        $_recovery_save_current_transaction(ontologyID, transactionID) {
            const username = this.$store.getters.username;
            this.session = { ontologyID, transactionID, username };

            localStorage.setItem(
                this.localStorageKey,
                JSON.stringify(this.session)
            );
        },
    },
};
