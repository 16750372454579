import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyInstancesPagePathParams = operations["getOntologyInstancesPageUsingGET"]["parameters"]["path"];
type OntologyInstancesPageQueryParams = operations["getOntologyInstancesPageUsingGET"]["parameters"]["query"];
type OntologyInstancesPageResponse = operations["getOntologyInstancesPageUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get all instances using pages. If you want to retrieve more than 20000 instances, please use the {ontologyId}/instances endpoint
 * @param {string} ontologyId
 * @param {integer} from
 * @param {integer} size
 * @return {OntologyInstancesPageResponse}
*/
export const getOntologyInstancesPage = async (
    { ontologyId }: OntologyInstancesPagePathParams,
    queryParams?: OntologyInstancesPageQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/instancesPage`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as OntologyInstancesPageResponse;
}
