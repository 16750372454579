import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllOntologyMetadataSummaryQueryParams = operations["getAllOntologyMetadataSummaryUsingGET"]["parameters"]["query"];
type AllOntologyMetadataSummaryResponse = operations["getAllOntologyMetadataSummaryUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * This endpoint returns all metadata about ontologies loaded in CENtree. The parameter loadStatusToFilterBy, used to filter by ontology load status, can be set to either 'loaded', 'unloaded' or 'none'. E.g. loadStatusToFilterBy = 'loaded' returns metadata for loaded ontologies only. Note: 'none' returns metadata for all ontologies, without filtering.Additionally, an ontologiIdFilter can be supplied to filter the results based on a partial ontologyId 
 * @param {string} loadStatusToFilterBy
 * @param {string} ontologyIdFilter
 * @return {AllOntologyMetadataSummaryResponse}
*/
export const getAllOntologyMetadataSummary = async (
    queryParams?: AllOntologyMetadataSummaryQueryParams,) => {
    const url = `/ontologies/ontologyMetadataSummary`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as AllOntologyMetadataSummaryResponse;
}
