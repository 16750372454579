var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"class-name-container",class:{
        resolving: _vm.isLoading,
        'new-class': _vm.isNew,
        'obsolete-class': _vm.isObsolete,
        'multi-class': _vm.classList.length > 1,
    }},[_vm._l((_vm.classList),function(classItem,index){return [(classItem.primaryLabel)?[(!_vm.isGlobalScope && _vm.isOntoBadge)?[(
                        _vm.$store.getters.ontoData(classItem.sourceUniqueID)
                            .ontologyShortDisplayName
                    )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                        title: _vm.$store.getters.ontoData(
                            classItem.sourceUniqueID
                        ).ontologyLongDisplayName,
                        delay: { show: _vm.showDelay, hide: 0 },
                    }),expression:"{\n                        title: $store.getters.ontoData(\n                            classItem.sourceUniqueID\n                        ).ontologyLongDisplayName,\n                        delay: { show: showDelay, hide: 0 },\n                    }",modifiers:{"hover":true}}],key:("onto-" + (classItem.id)),staticClass:"align-text-bottom",class:{
                        'chip-property': !_vm.type === 'property',
                    },attrs:{"variant":"primary","to":("/ontologies/" + (classItem.sourceUniqueID))}},[_vm._v(" "+_vm._s(classItem.shortDisplayName)+" ")]):_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                        title: 'The ontology is no longer loaded',
                        delay: { show: _vm.showDelay, hide: 0 },
                    }),expression:"{\n                        title: 'The ontology is no longer loaded',\n                        delay: { show: showDelay, hide: 0 },\n                    }",modifiers:{"hover":true}}],key:("onto-" + (classItem.id)),staticClass:"text-muted",attrs:{"icon":"minus-circle"}})]:_vm._e(),_c('span',{key:("name-" + (classItem.id)),staticClass:"class-name"},[_c(_vm.isLink ? 'b-link' : 'span',{tag:"component",attrs:{"id":(_vm.global_randomID + "-" + (classItem.id)),"to":{
                        name: _vm.type,
                        params: {
                            ontologyID: classItem.sourceUniqueID,
                            primaryID: classItem.primaryID,
                            initialData: classItem,
                        },
                    }}},[(_vm.isGlobalScope)?_c('b-badge',{staticClass:"global-badge mr-1",attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(classItem.shortDisplayName)+" ")]):_vm._e(),(
                            _vm.isSelf ||
                            classItem.primaryLabel !==
                                _vm.initClass.primaryLabel
                        )?_c('span',{staticClass:"name-wrapper"},[(
                                index === _vm.classList.length - 1 ||
                                classItem.primaryLabel !==
                                    _vm.classList[index + 1].primaryLabel
                            )?_c('span',{ref:"termName",refInFor:true,staticClass:"name-label px-1",class:_vm.buildTestClass('name-label')},[_vm._t("default",function(){return [_vm._v(_vm._s(classItem.primaryLabel))]})],2):_vm._e(),(index === _vm.classList.length - 1)?_c('span',{staticClass:"d-inline-block"},[(_vm.isShortID)?_c('span',{ref:"shortID",refInFor:true,staticClass:"name-termid",class:{
                                    'name-termid-property': _vm.type,
                                },attrs:{"id":_vm.buildTestClass('term-id')}},[_vm._v(_vm._s(_vm.termID(classItem)))]):_vm._e(),_vm._t("actions")],2):_vm._e()]):_vm._e(),(_vm.isSummary)?_c('b-popover',{attrs:{"placement":"bottom","triggers":"hover","boundary":"viewport","title":classItem.primaryID,"target":(_vm.global_randomID + "-" + (classItem.id)),"delay":{ show: _vm.showDelay, hide: 0 }}},[(_vm.ontologyID && _vm.primaryID)?_c('class-summary',_vm._b({attrs:{"isNew":_vm.isNew,"isObsolete":_vm.isObsolete}},'class-summary',classItem,false)):_vm._e()],1):_vm._e()],1)],1)]:_c('span',{key:("placeholder-" + (classItem.id)),staticClass:"name-primaryid"},[_vm._v("#"+_vm._s(_vm.primaryID))])]}),(!_vm.classList.length)?_c('span',{staticClass:"name-primaryid"},[_vm._v(_vm._s(_vm.primaryID))]):_vm._e(),(_vm.isGlobalScope && !_vm.isLastClassesPage && !_vm.isTruncate)?_c('b-button',{staticClass:"small ml-1 py-0 px-1 align-baseline",class:{ pulsating: _vm.isLoading },attrs:{"variant":"outline-secondary","size":"sm","disabled":_vm.isLoading},on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMoreClasses.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isLoading ? 'Loading classes...' : 'Load more')+" ")]):(_vm.isGlobalScope && !_vm.isLastClassesPage)?_c('span',[_vm._v("...")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }