<template>
    <div class="position-relative onto-typeahead">
        <b-form-input
            class="onto-field w-100"
            placeholder="Find ontology..."
            autocomplete="off"
            spellcheck="false"
            :class="{ secondary: variant === 'secondary' }"
            :size="size"
            v-model="ontoQuery" />
        <b-button
            class="clear-btn position-absolute"
            variant="link"
            tabindex="-1"
            v-show="ontoQuery.length"
            @mousedown.prevent="ontoQuery = ''">
            &times;
        </b-button>
    </div>
</template>

<script>
import { debounce, map } from 'lodash';
import ApiOntology from '@/api/ontology';

export default {
    name: 'OntoTypeahead',
    props: {
        variant: {
            type: String,
            default: 'primary',
        },

        size: {
            type: String,
            default: 'sm',
        },

        typeDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_TYPE_DEBOUNCE),
        },
    },

    data() {
        return {
            ontoQuery: '',
        };
    },

    watch: {
        ontoQuery(query) {
            this.$el.classList.toggle('query-present', query.length);
            this.onOntoQuery(query);
        },
    },

    created() {
        this.onOntoQuery = debounce(this.onOntoQuery, this.typeDelay);
    },

    methods: {
        onOntoQuery(query) {
            const trimmed = query.trim();

            if (trimmed.length) {
                ApiOntology.filter({ query: trimmed }).then((response) => {
                    const ontoIDs = map(response.data, 'ontologyId');
                    this.$emit('onto:query', trimmed, ontoIDs);
                });
            } else {
                this.$emit('onto:query', '');
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.onto-field {
    box-shadow: none !important;
    padding-right: 1.6rem;

    &.secondary {
        &:hover,
        &:focus {
            border-color: rgba($secondary, 0.3) !important;
        }
    }
}

.clear-btn {
    top: 0.05rem;
    right: 0;
    padding: 0 0.6rem 0 0.4rem !important;
    text-decoration: none !important;
    opacity: 0.6;

    &:not(:hover) {
        color: $text-muted;
    }
}
</style>
