var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onto-filter"},[_c('b-dropdown-header',{staticClass:"onto-filter-title d-flex",attrs:{"id":_vm.titleId}},[_c('span',{staticClass:"flex-grow-1 text-light filter-title"},[_vm._v(_vm._s(_vm.filterTitle))]),_vm._t("default",function(){return [_c('b-button',{staticClass:"collapse-btn ml-3 p-0 border-0 bg-transparent",on:{"click":function($event){return _vm.$emit(
                        _vm.isOntoFilterShown ? 'filter:close' : 'filter:open'
                    )}}},[_c('font-awesome-icon',{staticClass:"align-middle",attrs:{"icon":_vm.isOntoFilterShown ? 'minus' : 'plus'}})],1)]})],2),(!_vm.isOntoFilterShown)?_c('b-tooltip',{attrs:{"triggers":"hover","target":_vm.titleId,"delay":{ show: _vm.showDelay, hide: 0 }}},[(_vm.$store.getters.filters.ontologies.length)?_c('span',[_c('strong',[_vm._v("Search in")]),_vm._v(": "+_vm._s(_vm.$store.getters.filteringOntoIDs.join(', '))+" ")]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOntoFilterShown),expression:"isOntoFilterShown"}],staticClass:"onto-filter-controls"},[(!_vm.$store.getters.hasOntoIndex)?_c('b-dropdown-header',{staticClass:"text-center"},[_c('p',{staticClass:"text-wrap"},[_vm._v("No ontologies have been loaded yet")]),_c('b-button',{attrs:{"variant":"primary","to":"/ontologies","size":"sm"}},[_vm._v(" Load new ")])],1):_vm._e(),(_vm.$store.getters.hasOntoIndex)?[_c('onto-typeahead',{staticClass:"mx-2 my-2",attrs:{"variant":"secondary"},on:{"onto:query":_vm.onOntoQuery}}),_c('b-form-checkbox-group',{ref:"ontoFilterList",staticClass:"px-3 pt-2 pb-1 onto-filter-list",style:({ maxHeight: _vm.ontoEntriesMax * 1.56 + 'rem' }),attrs:{"stacked":""},model:{value:(_vm.$store.getters.filters.ontologies),callback:function ($$v) {_vm.$set(_vm.$store.getters.filters, "ontologies", $$v)},expression:"$store.getters.filters.ontologies"}},_vm._l((_vm.ontoFilterList),function(ontoID,index){return (
                        _vm.$store.getters.hasOnto(ontoID) &&
                        _vm.ontoQueryIDs.indexOf(ontoID) !== -1
                    )?_c('b-form-checkbox',{key:ontoID,class:{
                        'border-top pt-1 mt-1':
                            _vm.ontoListSplitIndex === index,
                    },attrs:{"value":ontoID,"id":_vm.buildTestClass('filter--onto-' + ontoID)}},[_c('b-link',{staticClass:"info-onto-link text-secondary float-right ml-1",attrs:{"to":{
                            name: 'ontology',
                            params: { ontologyID: ontoID },
                        }}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-right","size":"sm"},on:{"mouseenter":function($event){$event.stopPropagation();return _vm.cancelParentHover($event, true, 2)},"mouseleave":function($event){$event.stopPropagation();return _vm.cancelParentHover($event, false, 2)}}})],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                            boundary: 'window',
                            title: _vm.$store.getters.ontoData(ontoID)
                                .ontologyLongDisplayName,
                            delay: { show: _vm.showDelay, hide: 0 },
                        }),expression:"{\n                            boundary: 'window',\n                            title: $store.getters.ontoData(ontoID)\n                                .ontologyLongDisplayName,\n                            delay: { show: showDelay, hide: 0 },\n                        }",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.$store.getters.ontoData(ontoID) .ontologyShortDisplayName)+" ")])],1):_vm._e()}),1),(_vm.$store.getters.hasOntoIndex)?_c('div',{staticClass:"px-2 py-1 mt-1 mb-1"},[_c('b-button',{staticClass:"onto-filter-reset border-0",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.onOntoReset}},[_vm._v(" Clear all ")]),(_vm.enableViewAll)?_c('b-link',{staticClass:"onto-filter-more float-right font-weight-normal",attrs:{"size":"sm"},on:{"click":_vm.onOntoSeeAll}},[_vm._v(" View all ")]):_vm._e()],1):_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }