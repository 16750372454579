import Axios from 'axios';

import globalMixin from '@/GlobalMixin';
import { throttle } from 'lodash';
import { intercept } from '@/api/interceptors/intercept';
import { interceptWhitelist } from '@/api/interceptors/interceptWhitelist';

const api = {
    utils: globalMixin.methods,
    source: Axios.CancelToken.source,
    isCancel: Axios.isCancel,
    globalCancel: Axios.CancelToken.source(),
    bearer: '',
    header: {},

    setBearer: function (token) {
        this.bearer = `Bearer ${token}`;
    },

    delBearer: function () {
        this.bearer = '';
    },

    restoreBearer: function () {
        this.setBearer(this.bearer);
    },

    cancelAll: function (error) {
        this.globalCancel.cancel(error);
        this.globalCancel = this.source();
    },

    errorStatus: function (error) {
        return error.status || (error.response && error.response.status);
    },

    onRequest: function () {
        this.header._progressStart();
    },
    onResponse: function () {
        this.header._progressFinish();
    },
    onError: function () {
        throttle(
            (error) => {
                this.header.$Progress.fail();

                // Normally, the user has already been made aware if something was not found.
                if (error.response && error.response.status !== 404) {
                    this.header.notifyError(error, 'Server error');

                    // If there was no response, something must be iffy with the network or the app.
                } else if (!error.response) {
                    this.header.notifyError(error, 'Request error');
                }
            },
            500,
            { leading: true, trailing: false }
        );
    },
    onUnauthorised: function (error) {
        if (this.header.$store.getters.hasToken) {
            error.isTokenExpired = true;
            this.header.$router.push({
                name: 'logout',
                params: {
                    redirect:
                        location.hash.replace(/^#/, '') || location.pathname,
                },
            });
        }
    },
};

// Sends the jwt token with every request.
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Allows non-CORS requests and global cancellation.
Axios.interceptors.request.use((config) => {
    if (!config.isAnonymous) {
        config.headers['Authorization'] = api.bearer;
    }

    api.onRequest(config);
    return config;
});

// Distinguishes between an unauthorised and errored request.
Axios.interceptors.response.use(
    (response) => {
        intercept(response);
        api.onResponse(response);
        return response;
    },
    (error) => {
        const status = api.errorStatus(error);
        const path = error.response.config.url;

        for (const item of interceptWhitelist) {
            if (item.wildcard) {
                const wildcardRegex = new RegExp( //Regex pattern to match wildcard API endpoints, with the hard coded path from the error.
                    '^' + item.path.replace(/\*/g, '.*') + '$'
                );

                //
                if (!wildcardRegex.test(path)) {
                    //If the wildcarded path is not a match, move to the next value in the whitelist
                    continue;
                }
            } else if (item.path !== path) {
                //If the set path is not a match, move to the next value in the whitelist
                continue;
            }

            //If the path matches, then check if the status matches one of the whitelisted values.
            if (item.statuses.includes(status)) {
                return; //If the status matches, then return and do not continue to the standard interceptor for errors.
            }
        }

        let is401;
        if (!api.isCancel(error)) {
            is401 =
                (status && status === 401) ||
                (error.message || []).indexOf('401') !== -1;

            // Acts on an expired token.
            if (is401 && error.config && !error.config.__isRetryRequest) {
                api.onUnauthorised(error);
                api.cancelAll(error);
            }

            // There was a response at least => formats and irons out differences in error object structure
            if (error.response) {
                error.errorMessage =
                    (error.response.data && error.response.data.detail) ||
                    error.message;
                error.errorMessage = api.utils.addFullStop(
                    api.utils.truncate(error.errorMessage, 135)
                );
            } else if (!error.response) {
                error.errorMessage = error.message || 'Request error.';
            }

            // Signals any error (regardless of its type) globally unless it's down to a cancelled request.
            api.onError(error);
        }

        return Promise.reject(error);
    }
);

Axios.api = api;
export default api;
