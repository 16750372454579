import asyncPool from 'tiny-async-pool';
import Axios from 'axios';

const ENDPOINT = '/ontologies';
const env = process.env;

export default {
    // Gives all the ontologies available and their meta-data.
    list({ pageStart = 0, pageSize = parseInt(env.VUE_APP_SEARCH_SIZE) }) {
        return Axios.get(ENDPOINT, {
            params: {
                from: pageStart,
                size: pageSize,
            },
        }).then((response) => {
            response.data.elements = response.data.elements.map((element) => {
                element.ontologyMetadataJSONBean.loaded =
                    element.loaded || false;
                return element.ontologyMetadataJSONBean;
            });
            return response;
        });
    },

    // Lists the names (short and long) for each ontology.
    names(isHideNotloaded = true) {
        return Axios.get(`${ENDPOINT}/names`, {
            params: { hideNotLoaded: isHideNotloaded },
        });
    },

    // Lists those ontologies with a name matching the query.
    filter({ query }) {
        return Axios.get(`${ENDPOINT}/filter/${encodeURIComponent(query)}`);
    },

    /**
     * @param {String} ontologyID
     * @returns {Promise<AxiosResponse<definitions["OntologyMetadataEntry]>>}
     */
    get({ ontologyID }) {
        return Axios.get(`${ENDPOINT}/${ontologyID}`).then((response) => {
            if (response.data) {
                response.data = response.data.ontologyMetadataJSONBean;
            } else {
                response.data = {};
            }
            return response;
        });
    },

    // Gets all of the ontologies from the endpoint
    getAll(loadStatus) {
        return Axios.get(
            `${ENDPOINT}/ontologyMetadataSummary?loadStatusToFilterBy=` +
                loadStatus
        );
    },

    query({ ontologyID, primaryID }) {
        return Axios.get(`${ENDPOINT}/${ontologyID}/classes/query`, {
            params: {
                primaryId: primaryID,
            },

            // Parses response to normalise this endpoint's sourceUniqueID to the API's entityUniqueID
            // TODO: entityUniqueID will disappear in the future and replaced with sourceUniqueID
        }).then((response) => {
            response.data.entityUniqueID = response.data.sourceUniqueID;
            return response;
        });
    },

    // Uses a dummy collection call to grab its total length.
    ontologyCounts({ ontologyID }) {
        return Axios.get(`${ENDPOINT}/${ontologyID}/stats`).then((response) => {
            return response.data.totals;
        });
    },

    pathsFromRoot({ ontologyID, classID, transactionID = '' }) {
        let baseUrl = `${ENDPOINT}/${ontologyID}/classes/`;
        let classIDs = [];

        // Retrieving multiple trees for edited classes.
        if (transactionID) {
            if (typeof classID === 'string') {
                classIDs.push(classID);
            } else {
                classIDs = classID;
            }

            // Gets the path for each edited class.
            return asyncPool(
                env.VUE_APP_CONCURRENCY_MAX,
                classIDs,
                (classID) => {
                    return Axios.get(
                        baseUrl + `${classID}/paths-from-root/${transactionID}`
                    ).then((response) => {
                        return response.data.leaves[0];
                    });

                    // Converts all the paths into a single tree.
                }
            ).then((leaves) => {
                return { data: this.toRootClasses({ classes: leaves }) };
            });

            // Retrieving just one path for live classes
        } else {
            return Axios.get(baseUrl + `${classID}/paths-from-root`);
        }
    },

    // Path for all root classes of a given ontology
    rootClasses({
        ontologyID,
        pageStart = 0,
        pageSize = env.VUE_APP_ROOT_SIZE,
    }) {
        return Axios.get(`${ENDPOINT}/${ontologyID}/rootClassesPage`, {
            params: {
                from: pageStart,
                size: pageSize,
            },
        }).then((response) => {
            const path = this.toRootClasses({
                classes: response.data.elements,
            });

            path.total = response.data.total;
            return { data: path };
        });
    },

    toRootClasses({ classes }) {
        // Only converts the class object if its format doesn't resemble a path node.
        classes = classes.map((classItem) => {
            if (!classItem.hasOwnProperty('value')) {
                classItem.typeOfNode = 'subClassOf';
                classItem.entityUniqueID = classItem.sourceUniqueID;
                classItem = { value: classItem, leaves: [] };
            }
            return classItem;
        });

        // Prefixes an array of class objects with the universal root to make it appear as a path.
        return {
            leaves: classes,
            value: { primaryID: 'THING' },
        };
    },

    children({
        ontologyID,
        classID,
        pageStart = 0,
        pageSize = env.VUE_APP_CHILDREN_SIZE,
    }) {
        return Axios.get(
            `${ENDPOINT}/${ontologyID}/classes/${classID}/children`,
            {
                params: {
                    from: pageStart,
                    size: pageSize,
                },
            }
        );
    },

    // TODO: the endpoint's root path should be 'ontologies'. Potential API bug.
    load({ ontologyID, version = 'latest' }) {
        return Axios.post(`ontology/${ontologyID}/load`, null, {
            params: { version },
        });
    },

    /**
     * Fetches a class data by providing ontology ID and class short ID.
     * @param {String} ontologyID
     * @param {String} shortID
     * @returns {AxiosPromise<Object>}
     */
    classByShortId({ ontologyID, shortID }) {
        const classApi = Axios.create({
            baseURL: process.env.VUE_APP_BASE_URL,
        });
        return classApi.get(`/ontology/${ontologyID}/${shortID}`);
    },

    // Returns the history of a class without diffs
    getHistory(primaryId, ontologyId, withOriginalValue = false) {
        return Axios.get(
            `${ENDPOINT}/${ontologyId}/classes/${primaryId}/edits`,
            {
                params: {
                    withOriginalValue: withOriginalValue,
                },
            }
        );
    },

    // Returns the edit changes with a default of compare with previous
    compareClassHistory(
        ontologyId,
        editId,
        compareWith = 'compareWithPrevious'
    ) {
        return Axios.get(
            `${ENDPOINT}/${ontologyId}/edits/${editId}/${compareWith}`
        );
    },

    /**
     * Requests a class by ontologyID and classID.
     * @param {String} ontologyID The ontology ID.
     * @param {String} classID The class ID.
     * @returns {Promise<AxiosResponse<any>>} An axios response representing the requested class or empty data if not found.
     */
    getClass(ontologyID, classID) {
        return Axios.get(`${ENDPOINT}/${ontologyID}/classes/${classID}`).then(
            (response) => {
                response.data = response.data || {};

                return response;
            }
        );
    },

    /**
     * Retrieves all keywords for all ontologies.
     * @returns {AxiosPromise<any>}
     */
    getAllKeywords() {
        return Axios.get(`${ENDPOINT}/keywords`);
    },

    /**
     * Retrieves ontologies by keywords.
     * @param {Array} keywords
     * @returns {AxiosPromise<any>}
     */
    filterByKeywords(keywords) {
        return Axios.get(`${ENDPOINT}/filterByKeywords`, {
            params: { keywords },
            paramsSerializer: () => {
                return 'keywords=' + keywords.join('&keywords=');
            },
        });
    },

    /**
     * Retrieves keywords filtered by a specified string.
     * @param value
     * @returns {AxiosPromise<any>}
     */
    getKeywordsFiltered(value) {
        return Axios.get(`${ENDPOINT}/keywords/filter/${value}`);
    },
};
