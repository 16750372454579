var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"typeahead-wrapper",class:{ disabled: _vm.disabled }},[_c('vue-bootstrap-typeahead',{ref:"typeahead",attrs:{"inputClass":"search-field","placeholder":_vm.placeholder + '...',"data":_vm.results,"serializer":_vm.serializer,"min-matching-chars":_vm.suggestionMinChars,"max-matches":_vm.suggestionMaxMatches},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.onArrowDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.onArrowUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return _vm.onEsc.apply(null, arguments)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.preventDefault();return _vm.onArrowRight.apply(null, arguments)}},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
var htmlText = ref.htmlText;
return [_c('div',{staticClass:"suggestion-wrapper d-flex align-items-center",on:{"mouseover":function($event){return _vm.onSuggestionHover($event, data)},"mouseout":function($event){return _vm.offSuggestionHover($event)}}},[(_vm.isSuggestionScore && data.score)?_c('b-badge',{staticClass:"score-badge",attrs:{"variant":"light","pill":""}},[_vm._v(" "+_vm._s(data.score)+" ")]):(_vm.getShortDisplayName(data))?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                        title: _vm.getLongDisplayName(data),
                        delay: { show: _vm.showDelay, hide: 0 },
                        boundary: 'window',
                    }),expression:"{\n                        title: getLongDisplayName(data),\n                        delay: { show: showDelay, hide: 0 },\n                        boundary: 'window',\n                    }",modifiers:{"hover":true}}],staticClass:"ontology-btn inner-btn btn-primary",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$refs.typeahead.handleHit({ data: data }, false)}}},[_vm._v(" "+_vm._s(_vm.getShortDisplayName(data))+" ")]):_c('font-awesome-icon',{staticClass:"text-muted",attrs:{"icon":"minus-circle"}}),_c('div',{staticClass:"suggestion-entry",attrs:{"id":(_vm.global_randomID + "-" + (data.id))}},[_c('span',{staticClass:"suggestion-label",class:{ 'new-class': _vm.isNewClass(data) }},[(_vm.containsHtml(data.primaryLabel, htmlText))?_c('span',{staticClass:"term-label",domProps:{"innerHTML":_vm._s(_vm.getMatchedHtml(htmlText))}}):_c('span',{staticClass:"term-label"},[_vm._v(_vm._s(data.primaryLabel))]),(_vm.containsHtml(_vm.termID(data), htmlText))?_c('span',{staticClass:"entity-link pl-1",domProps:{"innerHTML":_vm._s(_vm.getMatchedHtml(htmlText))}}):_c('span',{staticClass:"entity-link pl-1"},[_vm._v(_vm._s(_vm.termID(data)))])]),(
                            _vm.containsHtml(data.synonyms, htmlText) &&
                            !_vm.containsHtml(data.primaryLabel, htmlText)
                        )?_c('span',{staticClass:"synonym-label d-block text-truncate",domProps:{"innerHTML":_vm._s(_vm.getMatchedHtml(htmlText))}}):_vm._e()]),_c('b-popover',{attrs:{"placement":"bottom","triggers":"hover","boundary":"viewport","title":data.primaryID,"target":(_vm.global_randomID + "-" + (data.id)),"delay":{ show: _vm.showDelay, hide: 0 }}},[_c('class-summary',_vm._b({attrs:{"isNew":_vm.isNewClass(data)}},'class-summary',data,false))],1),_c('font-awesome-icon',{staticClass:"autocomplete-btn inner-btn text-secondary",attrs:{"icon":"external-link-square-alt","flip":"horizontal","size":"lg"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.onAutocomplete(_vm.getMatchedText(htmlText), true)}}})],1)]}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('template',{slot:"prepend"},[_vm._t("prepend")],2),_c('template',{slot:"append"},[_vm._t("append",null,{"query":_vm.query,"results":_vm.results})],2)],2),_vm._t("inset",null,{"query":_vm.query,"results":_vm.results}),_c('div',{staticClass:"d-none"},[_vm._t("view-all")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }