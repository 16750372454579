var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"full-text",class:{
        'internal-url': _vm.isInternalLink && !_vm.isEditMode,
    }},[(_vm.label && !_vm.isEditMode)?_c('span',{staticClass:"prefix"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('vue-truncate',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
            placement: 'top',
            html: true,
            content: _vm.errorToHtml,
            delay: { show: _vm.showDelay, hide: 0 },
            disabled: !_vm.errorToHtml,
        }),expression:"{\n            placement: 'top',\n            html: true,\n            content: errorToHtml,\n            delay: { show: showDelay, hide: 0 },\n            disabled: !errorToHtml,\n        }",modifiers:{"hover":true}}],key:_vm.renderCount.value,ref:"vueTruncate",staticClass:"editable-text",class:{
            'edit-saving': _vm.isSaving,
            'edit-success': _vm.isSuccess,
            'edit-error': _vm.isError,
            'max-size': _vm.text.length === _vm.maxLength,
            'external-url': _vm.isLink,
            'internal-url': _vm.isInternalLink && !_vm.isEditMode,
        },attrs:{"collapsed-text-class":"collapsed","action-class":"toggle-text","clamp":"More","less":"Less","text":_vm.text,"length":_vm.maxLength},nativeOn:{"click":function($event){return _vm.onClickEditableText($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.forceBlur($event)},"dblclick":function($event){return _vm.forceFocus($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }