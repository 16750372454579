import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyMetadataNamesQueryParams = operations["getOntologyMetadataNamesUsingGET"]["parameters"]["query"];
type OntologyMetadataNamesResponse = operations["getOntologyMetadataNamesUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * getOntologyMetadataNames
 * @param {boolean} hideNotLoaded
 * @return {OntologyMetadataNamesResponse}
*/
export const getOntologyMetadataNames = async (
    queryParams?: OntologyMetadataNamesQueryParams,) => {
    const url = `/ontologies/names`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as OntologyMetadataNamesResponse;
}
