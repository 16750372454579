import Axios from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { uniqWith } from 'lodash';

const ENDPOINT = '/notification';

export default {
    connect(token, isCORS = false) {
        const url = process.env.VUE_APP_API_URL + ENDPOINT;
        const eventSourceInit = {
            headers: { Authorization: 'Bearer ' + token },
            withCredentials: isCORS,
        };

        const source = new EventSourcePolyfill(url, eventSourceInit);

        source.onError = function (event) {
            Axios.api.onError({ message: 'Server-sent event failed.' });
        };
        return source;
    },

    unread() {
        return Axios.get(`${ENDPOINT}/unread`).then((response) => {
            response.data = uniqWith(
                response.data.reverse(),
                (arrVal, othVal) => {
                    const equalId = arrVal.id === othVal.id;
                    const equalType = arrVal.type === othVal.type;

                    return equalId && equalType;
                }
            );
            return response;
        });
    },

    clear() {
        return Axios.delete(`${ENDPOINT}`);
    },

    delete({ notificationID }) {
        return Axios.delete(`${ENDPOINT}/${notificationID}`);
    },
};
