/**
 * Annotation property names as listed in ontologyMetadataJSONBean
 * @type {DefaultAnnotationPropertyName[]}
 */
import { DefaultAnnotationPropertyName } from '@/ts';

export const ANNOTATION_PROPERTY_NAMES: DefaultAnnotationPropertyName[] = [
    'textualDefinitionProperties',
    'synonymProperties',
    'mappingProperties',
    'allLabelProperties',
];
