/* eslint-disable no-new */
// @ts-nocheck
// PLUGINS
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import cookieHelper from '@/utils/strings/cookies';
import VueCompositionAPI from '@vue/composition-api';
import Vue from 'vue';
import vSelect from 'vue-select';
import UUID from 'vue-uuid';
import VueMoment from 'vue-moment';
import VuePluralize from 'vue-pluralize';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';
import VueClipboard from 'vue-clipboard2';

// APP MODULES
import router from '@/router';
import store from '@/store';
import GlobalMixin from '@/GlobalMixin';
import testSelectors from '@/mixins/testSelectors';
import Api from '@/api';
import App from '@/App';
import AppFooter from '@/components/layout/AppFooter';
import Header from '@/components/layout/AppHeader';

// PLUGIN AND APP SETUP
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDoubleDown,
    faArrowRight,
    faBan,
    faBell,
    faBolt,
    faBorderAll,
    faCamera,
    faCheck,
    faCheckCircle,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle as fasCircle,
    faClipboard,
    faClipboardCheck,
    faCode,
    faCodeBranch,
    faCog,
    faComment,
    faCommentDots,
    faCrosshairs,
    faDownload,
    faEllipsisV,
    faEnvelopeOpenText,
    faEquals,
    faExclamationCircle,
    faExpand,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFileExport,
    faFileImport,
    faFilter,
    faHighlighter,
    faInfo,
    faInfoCircle,
    faLightbulb as fasLightbulb,
    faLink,
    faList,
    faListAlt,
    faMinus,
    faMinusCircle,
    faPen,
    faPlay,
    faPlus,
    faPlusCircle,
    faPuzzlePiece,
    faRemoveFormat,
    faSearch,
    faSignOutAlt,
    faSitemap,
    faSlidersH,
    faSortAlphaDown,
    faSortAlphaUp,
    faStop,
    faTag,
    faTimesCircle,
    faToggleOff,
    faToggleOn,
    faTrashAlt,
    faUndoAlt,
    faUserCircle,
    faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircle as farCircle,
    faClock as farClock,
    faLightbulb as farLightbulb,
    faPaperPlane as farPaperPlane,
    faUserCircle as farUserCircle,
} from '@fortawesome/free-regular-svg-icons';

Vue.use(VueCompositionAPI);

library.add(
    faEquals,
    faLink,
    faCode,
    fasLightbulb,
    farLightbulb,
    farPaperPlane,
    faEye,
    faEyeSlash,
    faCamera,
    faExpand,
    faBan,
    faPlay,
    faStop,
    faToggleOn,
    faToggleOff,
    faSearch,
    faFileImport,
    faFileExport,
    faEllipsisV,
    faFilter,
    faCog,
    faPuzzlePiece,
    faBell,
    faSlidersH,
    faTag,
    farClock,
    fasCircle,
    farCircle,
    faInfo,
    faInfoCircle,
    faUserCircle,
    farUserCircle,
    faUserShield,
    faEnvelopeOpenText,
    faTimesCircle,
    faExclamationCircle,
    faCheckCircle,
    faComment,
    faCommentDots,
    faCrosshairs,
    faSignOutAlt,
    faExternalLinkSquareAlt,
    faExternalLinkAlt,
    faSitemap,
    faList,
    faHighlighter,
    faClipboard,
    faClipboardCheck,
    faCheck,
    faPen,
    faPlus,
    faPlusCircle,
    faMinus,
    faMinusCircle,
    faTrashAlt,
    faChevronLeft,
    faChevronRight,
    faChevronCircleLeft,
    faChevronCircleRight,
    faArrowRight,
    faAngleDoubleDown,
    faDownload,
    faUndoAlt,
    faChevronDown,
    faChevronUp,
    faListAlt,
    faBorderAll,
    faList,
    faRemoveFormat,
    faCodeBranch,
    faSortAlphaDown,
    faSortAlphaUp,
    faBolt,
    faClipboardCheck
);

// Used for Oauth login
const cookieJwt = cookieHelper.getCookie('Authorization');
if (
    cookieJwt !== '' &&
    typeof cookieJwt === 'string' &&
    cookieJwt.trim() !== ''
) {
    const jwt = '"' + cookieJwt.split(' ')[1];
    window.localStorage.setItem(process.env.VUE_APP_NAME_TOKEN, jwt);
}

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('v-select', vSelect);

Vue.use(BootstrapVue);

Vue.use(UUID);
Vue.use(VueMoment);
Vue.use(VuePluralize);
Vue.use(VueClipboard);
Vue.config.productionTip = false;

// GLOBAL CONVENIENCE PROPERTIES
Vue.prototype.$api = Api;
Vue.prototype.$eventBus = new Vue();
store.$eventBus = Vue.prototype.$eventBus;
Vue.mixin(GlobalMixin);

// APP ENTRY POINT
store.dispatch('appInit');

// Only boots up the app when all static images are fully loaded.
const cachedImages = [];
function loadImage(url) {
    return new Promise((resolve, reject) => {
        let imageObj = new Image();

        imageObj.src = url;
        cachedImages.push(imageObj);
        imageObj.onload = () => resolve(imageObj);
    });
}

Promise.all([
    loadImage(require('./assets/hero.jpg')),
    loadImage(require('./assets/error.jpg')),
]).then(function () {
    new Vue({
        router,
        store,

        // Passes the Vue instance in as prop for global app configuration.
        render: function (createElement) {
            return createElement(Header, {
                props: {
                    $vue: Vue,
                    testId: 0,
                },
                mixins: [testSelectors],
            });
        },

        created: async function () {
            await this.instantiateAppRegion();
        },

        // Mounts the footer after the header.
        mounted: function () {
            this.instantiateFooterRegion();
        },

        methods: {
            /**
             * Creates the region Vue instance only after the auth info are fetched.
             * @return {Promise<void>}
             */
            async instantiateAppRegion() {
                await this.$store.dispatch('appStarted');

                new Vue({
                    router,
                    store,
                    render: (h) => h(App),
                }).$mount('#app-region');
            },

            /**
             * Creates the footer region Vue instance.
             */
            instantiateFooterRegion() {
                new Vue({
                    router,
                    store,
                    render: (h) => h(AppFooter),
                }).$mount('#footer-region');
            },
        },
    }).$mount('#header-region');
});
