import { validateTransaction } from '@/api-v2';
import { useOntology } from '@/compositions/useOntology';
import { useEdits } from '@/compositions/useEdits';
import { computed, Ref, ref } from '@vue/composition-api';

const currentReport: Ref = ref();
const displayValidationModal = ref(false);
const pageSize = ref(10);
const checkSum: Ref<string> = ref('');

export const useValidation = () => {
    const getChecksum = () => {
        return checkSum.value.length ? checkSum.value : false;
    };

    const getPageSize = computed(() => {
        return pageSize.value;
    });

    const setPageSize = (size: number) => {
        pageSize.value = size;
    };

    const getReportByPage = (page: number, pageSize: number) => {
        const pageStart = page * pageSize - pageSize;

        return currentReport.value.ontologyValidationReportRowVMList.slice(
            pageStart,
            pageStart + pageSize
        );
    };

    const getNumPages = (pageSize: number) => {
        if (
            currentReport.value &&
            currentReport.value.ontologyValidationReportRowVMList?.length
        ) {
            return Math.ceil(
                currentReport.value.ontologyValidationReportRowVMList?.length /
                    pageSize
            );
        }
    };

    const getDisplayModal = computed(() => {
        return displayValidationModal.value;
    });

    const getNumberOfErrors = computed(() => {
        return (currentReport.value && currentReport.value.numberOfErrors) || 0;
    });

    const getNumberOfWarnings = computed(() => {
        return currentReport.value.numberOfWarnings;
    });

    const setDisplayModal = (value: boolean) => {
        displayValidationModal.value = value;
    };

    const validateCurrentTransaction = async () => {
        const ontologyId = useOntology().getCurrentOntologyId.value;
        const transactionId = useEdits().getTransactionId.value;

        if (
            typeof ontologyId === 'undefined' ||
            typeof transactionId === 'undefined'
        )
            return;

        const validationCheck = await validateTransaction({
            ontologyId,
            transactionId,
        });

        if (!validationCheck.ontologyValidationReportRowVMList?.length) {
            checkSum.value = validationCheck.successChecksum || '';
            return true; //Return true as there are no rule violations found
        }

        currentReport.value = validationCheck;
        return getReportByPage(1, pageSize.value);
    };

    return {
        validateCurrentTransaction,
        getDisplayModal,
        setDisplayModal,
        getReportByPage,
        getNumPages,
        getNumberOfErrors,
        getNumberOfWarnings,
        getPageSize,
        setPageSize,
        getChecksum,
    };
};
