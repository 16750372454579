import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToFilePathParams = operations["exportToFileUsingGET"]["parameters"]["path"];
type ExportToFileQueryParams = operations["exportToFileUsingGET"]["parameters"]["query"];
type ExportToFileResponse = operations["exportToFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * exportToFile
 * @param {string} ontologyId
 * @param {string} owlFormat
 * @return {ExportToFileResponse}
*/
export const exportToFile = async (
    { ontologyId }: ExportToFilePathParams,
    queryParams?: ExportToFileQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/export`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as ExportToFileResponse;
}
