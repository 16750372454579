<template>
    <header id="header-region" :class="{ 'cover-search': isCoverSearch() }">
        <cookie-law
            :position="'top'"
            :transitionName="'slideFromTop'"
            :buttonText="'I agree'"
            :buttonClass="'btn btn-primary mt-3 mb-3 cookie-agree-btn'">
            <div slot="message">
                This web app may require the limited processing of your personal
                data through the use of cookies. By using the site you are
                agreeing to this as outlined in our
                <b-link :href="termsUrl" class="cookie-terms-link"
                    >Terms of Use</b-link
                >.
            </div>
        </cookie-law>

        <vue-snotify></vue-snotify>

        <b-navbar
            ref="headerNavbar"
            v-if="isLoggedIn"
            :toggleable="isCoverSearch() ? 'sm' : 'md'"
            variant="light"
            :fixed="'top'">
            <b-navbar-brand
                class="home-link"
                to="/"
                :id="buildTestClass('link--centree')">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    class="logo app-logo">
                    <path
                        d="M50,0A50,50,0,0,0,2.66,66.12l.19.56.59,0A31.5,31.5,0,0,1,33.29,96.56l0,.59.56.19A50,50,0,1,0,50,0Zm0,98.25a48.13,48.13,0,0,1-15-2.38A33.23,33.23,0,0,0,4.13,65,48.26,48.26,0,1,1,50,98.25Zm0-9.87L85.94,26.13H14.06Zm0-7L20.12,29.63H79.88Zm0-4.11L76.32,31.68H23.68Zm0-5L28,34.18H72Zm0-5.1L67.57,36.73H32.43Zm0-5L36.76,39.23H63.24Z" />
                </svg>
                <span class="app-name">{{ appName }}</span>
            </b-navbar-brand>

            <b-navbar-nav>
                <b-nav-form id="nav-search">
                    <h1 class="motto mb-3">
                        {{ appMotto }}
                    </h1>

                    <!-- Search field -->
                    <class-typeahead
                        ref="typeahead"
                        :class="{
                            'obsolete-suggestions':
                                $store.getters.filters.isObsolete,
                        }"
                        :placeholder="
                            isCoverSearch()
                                ? 'Search for terms'
                                : 'SEARCH TERMS'
                        "
                        :suggestion-max-matches="
                            isCoverSearch() ? suggestionMin : suggestionMax
                        "
                        :id="buildTestClass('div--nav-search-bar')"
                        :isForceExactMatch="$store.getters.filters.isExact"
                        :searchType="$store.getters.filters.searchType"
                        :fetchSuggestions="fetchTypeaheadList"
                        :performAction="searchTypeahead"
                        :isGotoOnHit="!$store.getters.filters.isObsolete"
                        :isOntoHitOnly="$store.getters.filters.isObsolete"
                        :force-instance="
                            $store.getters.searchType === 'instance'
                        "
                        @keyup.native.ctrl.space="onOntoShortcut(currOntology)"
                        @focusin.native="onTypeaheadFocus(true)"
                        @focusout.native="onTypeaheadFocus(false)"
                        @change="onTypeaheadChange">
                        <!-- Ontology filter -->
                        <template slot="prepend">
                            <b-dropdown
                                ref="ontoFilterDropdown"
                                variant="gray"
                                no-flip
                                id="onto-filter-toggle"
                                menu-class="mt-1 py-0 shadow"
                                @show="onOntoFilterShow"
                                @hidden="isOntoFilterShown = false"
                                @keydown.esc.native="
                                    $refs.ontoFilterDropdown.hide(true)
                                ">
                                <template slot="button-content">
                                    <span
                                        v-if="
                                            !$store.getters.filters.ontologies
                                                .length
                                        ">
                                        All ontologies
                                    </span>
                                    <span
                                        v-else-if="
                                            $store.getters.filters.ontologies
                                                .length === 1
                                        ">
                                        {{ $store.getters.filteringOntoIDs[0] }}
                                    </span>
                                    <span v-else>
                                        {{
                                            $store.getters.filters.ontologies
                                                .length
                                        }}
                                        ontologies
                                    </span>
                                </template>
                                <onto-filter
                                    filterTitle="Search in ontologies"
                                    summaryTooltipTarget="onto-filter-toggle"
                                    :isOntoFilterShown="isOntoFilterShown">
                                    <b-button-close
                                        class="dropdown-close-btn ml-3"
                                        text-variant="light"
                                        @click="
                                            $refs.ontoFilterDropdown.hide(true)
                                        " />
                                </onto-filter>
                            </b-dropdown>
                        </template>

                        <!-- Search settings -->
                        <template slot="append">
                            <b-dropdown
                                ref="settingsSearchDropdown"
                                variant="gray"
                                size="sm"
                                no-flip
                                right
                                id="settings-search-toggle"
                                class="filter-dropdown settings-filter"
                                menu-class="mt-1 py-0 shadow"
                                @show="isSearchSettingsShown = true"
                                @hidden="isSearchSettingsShown = false"
                                @keydown.esc.native="
                                    $refs.settingsSearchDropdown.hide(true)
                                ">
                                <template slot="button-content">
                                    <font-awesome-icon
                                        v-if="$store.getters.filters.isObsolete"
                                        icon="exclamation-circle"
                                        class="align-middle" />
                                    <font-awesome-icon
                                        v-else
                                        icon="check-circle"
                                        class="align-middle" />
                                    <font-awesome-icon
                                        v-if="$store.getters.filters.isExact"
                                        icon="crosshairs"
                                        class="align-middle" />
                                    <fuzzy-icon v-else class="svg-icon" />
                                </template>
                                <search-settings
                                    settingsTitle="Search settings"
                                    summaryTooltipTarget="settings-search-toggle"
                                    :isSettingsShown="isSearchSettingsShown">
                                    <b-button-close
                                        class="dropdown-close-btn ml-3"
                                        text-variant="light"
                                        @click="
                                            $refs.settingsSearchDropdown.hide(
                                                true
                                            )
                                        " />
                                </search-settings>
                            </b-dropdown>
                            <b-button
                                class="search-btn"
                                variant="primary"
                                @click="
                                    $refs.typeahead.onAction(
                                        $refs.typeahead.query
                                    )
                                ">
                                <font-awesome-icon icon="search" />
                            </b-button>
                        </template>

                        <!-- Clear button -->
                        <template slot="inset" slot-scope="typeahead">
                            <b-button
                                class="clear-btn"
                                variant="link"
                                tabindex="-1"
                                v-show="typeahead.query.length"
                                @mousedown.prevent="$refs.typeahead.clear()">
                                &times;
                            </b-button>
                        </template>

                        <!-- View all search results -->
                        <template slot="view-all">
                            <b-button
                                class="view-all-btn rounded-0 border-0"
                                variant="outline-primary"
                                @mousedown.prevent="
                                    $refs.typeahead.onAction(
                                        $refs.typeahead.query
                                    )
                                ">
                                <small>View all results</small>
                            </b-button>
                        </template>
                    </class-typeahead>

                    <b-link
                        class="browse-link mt-4 mb-2"
                        to="/ontologies"
                        :id="buildTestClass('link--browse-ontologies')">
                        <b-button variant="primary">
                            <font-awesome-icon
                                icon="sitemap"
                                :style="{ color: 'white' }" />
                            <span class="px-1 ml-1">Browse ontologies</span>
                        </b-button>
                    </b-link>
                </b-nav-form>

                <b-button
                    class="header-btn d-none border-0 d-lg-inline mr-3"
                    :id="buildTestClass('btn--browse-ontologies')"
                    variant="outline-primary"
                    to="/ontologies"
                    :active="isOntologiesScreen">
                    ONTOLOGIES
                </b-button>
            </b-navbar-nav>

            <b-navbar-toggle
                target="nav_collapse"
                class="header-btn border-0 px-0"
                :class="{ 'icon-badge': alertTotal }"
                :data-count="alertTotal"
                @click.prevent.stop="onShowMenu" />
            <b-collapse is-nav id="nav_collapse" class="justify-content-end">
                <b-button
                    class="header-btn border-0"
                    type="submit"
                    variant="outline-primary"
                    :class="{ 'icon-badge': $store.getters.rejectedCount }"
                    :data-count="$store.getters.rejectedCount"
                    v-b-tooltip.hover="{
                        title: 'My suggestions',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click="onShowPending('suggestions')">
                    <font-awesome-layers
                        class="suggestions-icon"
                        :class="this.buildTestClass('suggestion-icon')">
                        <font-awesome-icon icon="comment" transform="grow-10" />
                        <font-awesome-icon
                            icon="pen"
                            transform="shrink-5"
                            class="text-white"
                            :class="this.buildTestClass('suggestion-icon__pen')"
                            :style="{ marginTop: '-.05em' }" />
                    </font-awesome-layers>
                </b-button>
                <b-button
                    class="header-btn border-0"
                    :id="buildTestClass('btn--notifications-menu')"
                    type="submit"
                    variant="outline-primary"
                    :class="{ 'icon-badge': $store.getters.unreadCount }"
                    :data-count="$store.getters.unreadCount"
                    v-b-tooltip.hover="{
                        title: 'Notifications',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click="onShowPending('notifications')">
                    <font-awesome-icon class="header-icon" icon="bell" />
                </b-button>
                <b-button
                    class="header-btn border-0"
                    type="submit"
                    variant="outline-primary"
                    :id="buildTestClass('btn--user-menu')"
                    @click="isSideMenuShown = true">
                    <font-awesome-icon
                        class="header-icon mr-1"
                        icon="user-circle" />
                    <span class="d-none d-xl-inline">{{
                        $store.getters.userDisplayName
                    }}</span>
                </b-button>
                <b-button
                    class="header-btn border-0"
                    type="submit"
                    variant="outline-primary"
                    to="/logout"
                    :id="this.buildTestClass('logout')">
                    <font-awesome-icon
                        class="header-icon mr-1"
                        icon="sign-out-alt" />
                    <span
                        class="d-none d-lg-inline"
                        :id="this.buildTestClass('logout__span')"
                        >Log out</span
                    >
                </b-button>
            </b-collapse>

            <vue-progress-bar class="progress-header"></vue-progress-bar>
        </b-navbar>

        <vue-progress-bar v-if="!isLoggedIn" />

        <side-menu
            v-if="isLoggedIn"
            ref="sideMenu"
            :isMenuShown.sync="isSideMenuShown"
            :testId="0" />

        <form
            id="header-notification-download-form"
            method="post"
            target="_blank"
            action="xxx">
            <input
                id="header-notification-download-form-input"
                type="hidden"
                name="Authorization"
                value="xxx" />
        </form>
        <a id="header-notification-download-anchor">Download</a>
    </header>
</template>

<script>
import { extend, pick, throttle, union } from 'lodash';
import OntoFilter from '@/components/ui/OntoFilter';
import SearchSettings from '@/components/ui/SearchSettings';
import FuzzyIcon from '@/components/ui/FuzzyIcon';
import CookieLaw from 'vue-cookie-law';
import VueProgressBar from 'vue-progressbar';
import VueSnotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';

import ApiSearch from '@/api/search';
import ClassTypeahead from '@/components/ui/ClassTypeahead';
import SideMenu from './SideMenu';
import { findHostedExportUrl } from '@/utils/ontoexport/findHostedExportUrl';

import RecoveryMixin from '@/components/ClassMain/RecoveryMixin';

export default {
    name: 'Header',
    components: {
        OntoFilter,
        SearchSettings,
        FuzzyIcon,
        CookieLaw,
        ClassTypeahead,
        SideMenu,
    },
    props: {
        termsUrl: {
            type: String,
            default: process.env.VUE_APP_TERMS_URL,
        },

        appName: {
            type: String,
            default: process.env.VUE_APP_NAME,
        },

        appMotto: {
            type: String,
            default: process.env.VUE_APP_MOTTO,
        },

        suggestionMin: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MIN),
        },

        suggestionMax: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MAX),
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        $vue: {
            type: Function,
            required: true,
        },
    },

    data() {
        let isS3 = false;

        return {
            currOntology: '',
            isSearchSettingsShown: false,
            isOntoFilterShown: false,
            isOntologiesScreen: false,
            isSideMenuShown: false,
            pristineOntoIDs: [],
            ontoFilterList: [],
            ontoListSplitIndex: -1,
            isS3,
        };
    },

    computed: {
        alertTotal: function () {
            return (
                this.$store.getters.rejectedCount +
                this.$store.getters.unreadCount
            );
        },
    },

    watch: {
        $route({ params }) {
            this.trackOntoRoute(params);
        },

        /**
         * Updates the typeahead's suggestions whenever the match mode is updated.
         * @param {string} searchType -
         */
        '$store.getters.filters.searchType'(searchType) {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            this.$store.dispatch('searchType', searchType);
        },

        /**
         * Updates the typeahead's suggestions whenever the match mode is updated.
         * @param {boolean} isExact - True if results are limited to exact matches.
         */
        '$store.getters.filters.isExact'(isExact) {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            this.$store.dispatch('exact', isExact);
        },

        /**
         * Updates the typeahead's suggestions whenever the type of class is changed.
         * @param {boolean} isObsolete - True if results are limited to obsolete classes.
         */
        '$store.getters.filters.isObsolete'(isObsolete) {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            this.$store.dispatch('obsolete', isObsolete);
        },

        /**
         * Updates the typeahead's suggestions on ontology filter selection. It also adds any newly selected ontologies
         * to the list of recent ones whithout duplication.
         * @param {string[]} selected - List of selected ontology filters.
         */
        '$store.getters.filters.ontologies'(selected) {
            this.$refs.typeahead && this.$refs.typeahead.checkFetch();
            this.$store.dispatch(
                'ontosTouched',
                union(this.$store.getters.pastOntoIDs, selected)
            );
        },

        /**
         * Makes the document title reflect the number of total alerts.
         */
        alertTotal(count) {
            if (count) {
                document.title = `(${count}) ${this.$store.currTitle}`;
                this.$store.dispatch('setBadgedFavicon');
            } else {
                document.title = this.$store.currTitle;
                this.$store.dispatch('setNormalFavicon');
            }
        },

        /**
         * Actions to perform once we know if we are in a logged-in session.
         */
        isLoggedIn() {
            // Updates the typeahead's field only once it's been rendered.
            const query = this.$route.params.query || this.$store.getters.query;

            this.$nextTick(() => {
                this.$refs.typeahead &&
                    this.$refs.typeahead.onAutocomplete(
                        query,
                        this.isCoverSearch()
                    );
            });
        },

        isSideMenuShown: 'grayout',
    },

    created() {
        // Keeps track of the app's loading state by means of a global loading bar, both request and rendering progress.
        // NOTE: When tracking router changes, no micro-state change (even if paired with a different route) is tracked.
        this.$vue.use(VueProgressBar, { autoFinish: false });
        this.$api.header = this;

        this.$router.beforeEach((to, from, next) => {
            if (from.name !== to.name) {
                this._progressStart();
            }
            next();
        });
        this.$router.afterEach((to, from) => {
            if (from.name !== to.name) {
                this._progressFinish();
            }
        });

        // Notifies of any application/api exception
        // NOTE: only the last API error is shown. Hence the throttling.
        this.$vue.use(VueSnotify, {
            toast: {
                timeout: parseInt(process.env.VUE_APP_NOTIFICATION_TIMEOUT),
                titleMaxLength: 35,
                bodyMaxLength: 200,
            },
        });
        this.$vue.config.errorHandler = (error, vm, info) => {
            this.$vue.config.errorHandler = function () {};
            console.error(error);
            console.info(info);
            console.log(vm.$options.name || vm.$options._componentTag);

            this.notifyError(error, 'Internal error');
        };

        // Pre-populates the typahead, the list of recently visited ontologies and pristine ones shown in the filter dropdown.
        this.trackOntoRoute(this.$route.params);
    },

    mounted() {
        // Updates the typeahead's current query on demand
        this.$eventBus.$on('typeahead:set', (query) => {
            this.$refs.typeahead.onAutocomplete(query, true);
        });

        // Notification display on demand
        this.$eventBus.$on('notification:show', this.notify);
        this.$eventBus.$on('notification:close', this.notifyDel);
        this.$eventBus.$on('notification:progress', this.notifyOverlay);

        // Makes sure the progress bar is seen even between very quick requests.
        this._progressStart = throttle(
            this.$Progress.start.bind(this.$Progress),
            500,
            { leading: true, trailing: false }
        );
        this._progressFinish = throttle(
            this.$Progress.finish.bind(this.$Progress),
            500,
            { leading: true, trailing: true }
        );

        // Show/hides the progress bar on demand
        this.$eventBus.$on('progress:start', () => {
            this._progressStart();
        });
        this.$eventBus.$on('progress:finish', () => {
            this._progressFinish();
        });

        this.$eventBus.$on('window:resize', this.trackHeaderHeight);
        this.$on('hook:updated', this.trackHeaderHeight);
    },

    methods: {
        performDownload(toast) {
            //debugger;
            const downloadForm = document.getElementById(
                'header-notification-download-form'
            );
            const authInput = document.getElementById(
                'header-notification-download-form-input'
            );

            const anchor = document.getElementById(
                'header-notification-download-anchor'
            );

            if (this.isS3) {
                //Change the submit method to GET
                downloadForm.method = 'GET';

                //Remove the Auth Input
                authInput.remove();

                anchor.click();
            } else {
                //Set the Auth Input value
                authInput.value = this.$store.getters.token;
                downloadForm.submit();
            }

            this.notifyDel(toast);
        },

        isCoverSearch() {
            return this.$route.name === 'search' && !this.$route.params.query;
        },

        /**
         * Saves the header's rendered height and makes it available app-wide.
         */
        trackHeaderHeight() {
            if (this.$refs.headerNavbar) {
                this.$store.headerHeight =
                    this.$refs.headerNavbar.$el.offsetHeight;
            }
        },

        /**
         * Updates various components depending on the current route.
         * @param {string} params - Route parameters.
         */
        trackOntoRoute(params) {
            let isOntoLoaded = false;

            // The route leads to an ontology-related view that is already loaded.
            if (params.hasOwnProperty('ontologyID')) {
                this.currOntology = params.ontologyID;
                isOntoLoaded =
                    this.$store.getters.hasOntoIndex &&
                    this.$store.getters.hasOnto(this.currOntology);

                if (isOntoLoaded) {
                    this.$store.dispatch(
                        'ontosTouched',
                        union(this.$store.getters.pastOntoIDs, [
                            params.ontologyID,
                        ])
                    );
                }

                // No ontology view here.
            } else {
                this.currOntology = '';
            }

            // The route is a search one => pre-populates typeahead accordingly.
            if (params.hasOwnProperty('query')) {
                this.$refs.typeahead &&
                    this.$refs.typeahead.onAutocomplete(
                        params.query,
                        this.isCoverSearch()
                    );
            }

            if (this.$route.name) {
                this.isOntologiesScreen =
                    this.$route.name.indexOf('ontologies') === 0;
            }
        },

        /**
         * Proxy method for the typeahead component to fetch the list of suggestions for a given query.
         * @param {string} query - Search query typed into the typeahead's field.
         */ fetchTypeaheadList(query) {
            return ApiSearch.suggestions({
                query,
                ontologies: this.$store.getters.filters.ontologies,
                isExact: this.$store.getters.filters.isExact,
                isObsolete: this.$store.getters.filters.isObsolete,
                searchType: this.$store.getters.filters.searchType,
            });
        },

        /**
         * Proxy method for the typeahead component to carry out a search for a given query.
         * @param {string} query - Search query typed into the typeahead's field.
         */
        searchTypeahead(query) {
            this.$router.push({
                name: 'search-page',
                params: { query },
            });
        },

        onTypeaheadChange(newValue) {
            this.$store.dispatch('query', newValue);
        },

        /**
         * Automatically toggles the filter state of the current ontology on pressing a pre-defined key shortcut.
         * @param {string} currOnto - Ontology ID for the current route.
         */
        onOntoShortcut(currOnto) {
            const ontologies = this.$store.getters.filters.ontologies;
            const ontoIndex = ontologies.indexOf(currOnto);

            // Some routes will lack the ontology parameter. In those cases, the action is ignored
            if (currOnto) {
                if (ontoIndex !== -1) {
                    this.$delete(ontologies, ontoIndex);
                } else {
                    ontologies.push(currOnto);
                }
            }
        },

        onOntoFilterShow() {
            this.isOntoFilterShown = true;
        },

        onTypeaheadFocus(isFocus) {
            this.$refs.typeahead.$el.classList.toggle('focus', isFocus);
        },

        onShowMenu() {
            this.isSideMenuShown = true;
        },

        onShowPending(nameList) {
            this.isSideMenuShown = true;
            this.$refs.sideMenu.reveal(nameList);
        },

        notifyOverlay(message, promise) {
            const toast = this.notify('confirm', '', '', [], 'centerCenter', {
                html: `<span class="loading" data-message="${message}"></span>`,
                closeOnClick: false,
                timeout: 0,
                animation: { time: 1 },
            });
            if (toast) {
                toast.on('mounted', () => {
                    document
                        .getElementsByClassName('snotifyToast__inner')[0]
                        .classList.add('notify-overlay');
                });
                toast.on('hidden', () => {
                    document
                        .getElementsByClassName('snotifyToast__inner')[0]
                        .classList.remove('notify-overlay');
                });
                promise.finally(() => {
                    this.notifyDel(toast);
                });
            }
        },

        notifyError(error, title = '') {
            let endpoint;
            let type = 'error';
            let body;
            let buttons = [];
            let opts = {};

            if (!this.$store.isPreventGlobalError) {
                // Session has expired
                if (error.isTokenExpired) {
                    title = 'Session expired';
                    body = 'Please log in again to continue using the app.';
                    type = 'warning';

                    // Wrong credentials
                } else if (error.response && error.response.status === 403) {
                    endpoint = error.config.url.replace(
                        error.config.baseURL,
                        ''
                    );
                    title = 'Access denied';
                    body = `The request to ${endpoint} failed. ${error.errorMessage}`;

                    // Request failed
                } else if (error.config) {
                    endpoint = error.config.url.replace(
                        error.config.baseURL,
                        ''
                    );
                    body = `The request to ${endpoint} failed. ${
                        error.errorMessage || 'Please make sure you are online.'
                    }`;

                    // Any other error
                } else {
                    body = error.message;
                }

                if (type === 'error') {
                    buttons = [
                        {
                            text: 'Close',
                            action: (toast) => {
                                this.$eventBus.$emit(
                                    'notification:close',
                                    toast
                                );
                            },
                        },
                        {
                            text: 'Show details',
                            action: (toast) => {
                                const errorObj = JSON.stringify(
                                    error,
                                    Object.getOwnPropertyNames(error)
                                );
                                const routeObj = JSON.stringify(
                                    pick(this.$route, [
                                        'fullPath',
                                        'params',
                                        'name',
                                        'redirectedFrom',
                                    ])
                                );
                                window.alert(
                                    'EXCEPTION:\n' +
                                        errorObj +
                                        '\n\nROUTE:\n' +
                                        routeObj
                                );
                            },
                        },
                    ];
                    opts = { timeout: 0 };
                }

                this.notify(type, body, title, buttons, 'centerTop', opts);
            }
        },

        arePopupsEnabled() {
            return localStorage.getItem("notification-popups-enabled") !== "false";
        },

        notify(
            type,
            message,
            title = '',
            buttons = [],
            position = 'centerTop',
            config = {}
        ) {
            let toast;

            extend(config, { position, buttons });

            if (
                type === 'success' &&
                title.toLowerCase() === 'export completed'
            ) {
                if (!this.arePopupsEnabled()) {
                    console.log("notification:", message);
                    return;
                }

                const url = findHostedExportUrl(
                    message,
                    process.env.VUE_APP_BASE_URL
                );
                if (!url) return;
                if (url.indexOf('s3.') !== -1) {
                    const anchor = document.getElementById(
                        'header-notification-download-anchor'
                    );
                    anchor.href = url;

                    this.isS3 = true;
                }

                document.getElementById(
                    'header-notification-download-form'
                ).action = url;

                config.html = `<div class="snotifyToast__title d-flex justify-content-between w-100">
                                <div>Export Completed</div>
                                  <div> <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-times-circle fa-w-16">
                                  <path data-v-6981495b="" fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" class=""></path></svg></div></div>
                                  <div class="snotifyToast__body">The ontology you exported is now ready for download.<div>
                                  </div>
                                </div> `;
                config.title = 'Export Completed';
                config.timeout = 0;
                config.closeOnClick = true;

                config.buttons = [
                    {
                        text: 'Download',
                        action: this.performDownload,
                        bold: true,
                    },
                ];

                // Brings the notification up and restores the app's interactivity
                return this.$snotify[type](
                    'Export Complete',
                    'Export Complete',
                    config
                );
            }

            // Modal-type confirmation
            if (type === 'confirm' || type === 'critical') {
                this.grayout();

                // Applies defautl styles to confirm buttons. Critical confirmation boxes are given a different style.
                if (buttons.length) {
                    buttons[0].className = 'cancel-btn';
                    buttons[1].className = 'ok-btn ' + type;
                }
                if (type === 'critical') {
                    type = 'confirm';
                }

                // Brings the notification up and restores the app's interactivity
                toast = this.$snotify[type](message, title, config).on(
                    'destroyed',
                    () => {
                        this.grayout(false);
                    }
                );

                // Other notifications
            } else {
                if (!this.arePopupsEnabled()) {
                    console.log("notification:", message);
                    return;
                }
                toast = this.$snotify[type](message, title, config);
            }

            return toast;
        },

        /**
         * Removes a given toast or all the existing ones if none is specified.
         * @param {Object} toast - Object descriptive of the notification to be closed.
         */
        notifyDel(toast) {
            const notifications = this.$snotify.notifications;

            if (!toast && notifications.length) {
                notifications.forEach((notification) => {
                    this.$snotify.remove(notification.id);
                });
            } else if (toast) {
                this.$snotify.remove(toast.id);
            }
        },

        /**
         * Blocks everything out except the header with a gray overlay.
         * @param {boolean} [isGrayout = true] - True if the overlay is to be applied.
         */
        grayout(isGrayout = true) {
            const appEl = document.getElementById('app-region');
            const headerEl = document.getElementById('nav-search');

            appEl && appEl.classList.toggle('grayed-out', isGrayout);

            if (isGrayout) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }

            if (headerEl && this.isCoverSearch) {
                headerEl.classList.toggle('grayed-out', isGrayout);
            }
        },
    },

    mixins: [RecoveryMixin],
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

#header-notification-download-anchor {
    display: none;
}
.header-btn {
    color: $text-muted;

    .header-icon {
        font-size: 1.1em;
        vertical-align: text-bottom !important;
    }

    &.icon-badge {
        position: relative;

        &:before {
            content: attr(data-count);
            position: absolute;
            top: 4px;
            right: 3px;
            z-index: 2;
            display: block;
            height: 1rem;
            width: 1rem;
            font-size: 0.7rem;
            color: $white;
            font-weight: bold;
            border-radius: 50%;
            background: $danger;
        }

        &.navbar-toggler:before {
            top: 0;
            right: 0;
            padding-top: 0.12rem;
        }
    }

    .suggestions-icon {
        font-size: 0.86rem;
    }
}

.navbar {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);

    .navbar-brand {
        color: $secondary !important;
        margin-right: 0;

        @media (min-width: 576px) {
            margin-right: 1rem;
        }

        .app-name {
            display: none;
            font-size: 1.4rem;
            font-weight: 500;
            vertical-align: middle;

            .cover-search & {
                display: inline;
            }

            @media (min-width: 576px) {
                display: inline !important;
            }
        }
    }

    .navbar-nav {
        width: 65%;

        .form-inline {
            flex-grow: 1;
        }
        .cover-search & {
            width: auto;
        }

        @media (min-width: 1024px) {
            width: 55%;
        }

        #nav-search {
            flex-grow: 1;
            flex-direction: column;

            .cover-search & {
                position: fixed;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -40%);
                min-width: 768px;
                width: 100%;
                max-width: 1200px;
                margin: 0 !important;
                padding: 0.75rem 1rem;
                color: $primary;

                ::v-deep .typeahead-wrapper {
                    box-shadow: 0 1rem 1rem rgb(0 0 0 / 30%);
                }

                ::v-deep .search-field {
                    font-size: 1.15rem !important;
                    @media (min-width: 1379px) {
                        font-size: 1.5rem !important;
                    }
                }

                ::v-deep .list-group {
                    width: 100% !important;
                }
            }

            .motto {
                display: none;
                text-align: center;
                font-size: 1.5rem;
                font-weight: 600;

                .cover-search & {
                    display: block;
                }

                @media (min-width: 768px) {
                    font-size: 2rem;
                }
            }

            @media (min-width: 576px) {
                margin: 0 1rem;
            }
        }
    }

    .navbar-collapse {
        text-align: right;

        &.collapsing,
        &.show {
            display: none !important;
        }
    }
}

.browse-link {
    display: none;
    font-size: 1.1rem;
    text-decoration: none !important;
    transition: $btn-transition;
    box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 20%);
    .cover-search & {
        display: inline;
    }

    .fa-layers {
        vertical-align: text-top;
    }
}

// Hover and focus states for the typeahead
::v-deep .typeahead-wrapper {
    width: 100%;
    transition: box-shadow 200ms ease-in;

    &.obsolete-suggestions .list-group-item {
        background: $white;
        cursor: text;

        .suggestion-label * {
            color: $gray-600 !important;
        }

        .entity-link {
            text-decoration: line-through;
        }

        .inner-btn {
            cursor: pointer !important;
        }
    }

    .search-field {
        padding-right: 2rem;
        border: 1px solid $gray-200 !important;
        box-shadow: none !important;
    }

    .search-btn:focus {
        background: $link-hover-color;
    }

    &.focus {
        box-shadow: rgba($primary, 0.1) 0 0.1rem 0.25rem,
            rgba($primary, 0.08) 0 0.25rem 0.75rem !important;
    }

    &:hover,
    &.focus {
        .search-field {
            border-color: lighten($primary, 25%) !important;
        }

        .dropdown-toggle {
            border-top-color: lighten($primary, 25%) !important;
            border-bottom-color: lighten($primary, 25%) !important;
        }
    }
}

::v-deep .list-group {
    z-index: $zindex-fixed !important;

    .view-all-btn:not(:hover) {
        color: $link-hover-color;
        background: $white;
    }
}

// Factors in the right addon's width in the typeahead
.clear-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6.5rem !important;
    padding: 0 0.6rem 0.17rem 0.4rem !important;
    font-size: 1.4rem !important;
    text-decoration: none !important;
    opacity: 0.6;

    &:not(:hover) {
        color: $text-muted;
    }
}

::v-deep #onto-filter-toggle {
    &.dropdown .btn:first-child {
        border-top-left-radius: $border-radius !important;
        border-bottom-left-radius: $border-radius !important;
    }

    .dropdown-menu {
        z-index: $zindex-fixed;
        min-width: 20rem;
        .onto-filter-title {
            color: #fff;
            background: $secondary;
        }

        .onto-filter-list {
            overflow: auto;
        }

        .onto-filter-reset,
        .onto-filter-more {
            line-height: 1;
        }

        .onto-filter-more {
            margin: 0.25rem 0;
            color: $link-hover-color;
            text-decoration: none;

            &:hover,
            &:focus {
                color: darken($link-hover-color, 6%);
            }
        }

        .custom-control-label {
            display: inline-block;
        }
    }
}

.settings-filter {
    width: 4rem; // guarantees by how much the cross has to be displaced

    ::v-deep .svg-icon {
        margin-right: 0.25rem;
    }

    ::v-deep .dropdown-menu {
        z-index: $zindex-fixed;
    }
}

::v-deep .custom-control-label .info-onto-link {
    opacity: 0;

    &:hover {
        opacity: 1 !important;
        color: $link-hover-color !important;

        & + span {
            color: $link-hover-color !important;
        }
    }
}

::v-deep .custom-checkbox:hover .info-onto-link {
    opacity: 0.25;
}

::v-deep .Cookie > * {
    margin: 0;
}

::v-deep .Cookie--base {
    padding-bottom: 0;

    .cookie-agree-btn {
        cursor: pointer;
    }

    @media (min-width: 768px) {
        padding: 0 1rem;
    }
}

::v-deep .snotify {
    width: auto;
    max-width: 400px;

    @media (max-width: 450px) {
        max-width: 300px;
    }

    &.snotify-centerTop,
    &.snotify-centerCenter,
    &.snotify-centerBottom {
        left: 50%;
        transform: translateX(-50%);
    }

    .snotifyToast {
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);

        &.snotify-error {
            background-color: $danger;

            .snotifyToast__buttons {
                background: $light;

                * {
                    text-transform: none;
                    color: $body-color;
                }
            }
        }

        &.snotify-warning {
            .snotifyToast__title,
            .snotifyToast__body {
                color: $body-color;
            }
        }

        &.snotify-confirm {
            .snotifyToast__inner {
                border: solid $gray-300 1px;
                background-color: $light;

                &.notify-overlay {
                    padding-left: 2rem;
                }

                .loading {
                    position: relative;
                    display: inline-block;

                    &:after {
                        content: attr(data-message);
                        color: $gray-500;
                    }
                }
            }

            .snotifyToast__title,
            .snotifyToast__body {
                color: $body-color;
            }

            .snotifyToast__buttons button {
                text-transform: none;
                cursor: pointer;

                &.ok-btn {
                    text-transform: uppercase;

                    &.critical {
                        background-color: $danger;
                    }
                }

                &.cancel-btn {
                    background-color: $dark;
                    color: #fff;
                }
            }
        }
    }

    .snotifyToast__title {
        margin-bottom: 0;
        line-height: 1.5em;
        font-size: 0.9rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .snotifyToast__body {
        width: 100%;
        font-size: 0.9rem;
        color: #fff;
        word-break: break-word;
    }
}

.__cov-progress {
    z-index: $zindex-sticky !important;
    position: absolute !important;
    background: $primary !important;
    opacity: 1 !important;

    &.progress-header {
        top: auto !important;
        bottom: 0;
    }
}
</style>
