import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type UserPermissionsPathParams = operations["getUserPermissionsUsingGET"]["parameters"]["path"];
type UserPermissionsResponse = operations["getUserPermissionsUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * getUserPermissions
 * @param {string} login
 * @return {UserPermissionsResponse}
*/
export const getUserPermissions = async (
    { login }: UserPermissionsPathParams,
) => {
    const url = `/users/${login}/permissions`;
    const response = await Axios.get(url);

    return response && response.data as UserPermissionsResponse;
}
