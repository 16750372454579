export const NAME_MAPPINGS = {
    numberOfChildren: 'children',
    textualDefinitions: 'definitions',
    shortFormIDs: 'short-form ID',
    primaryLabel: 'class label',
    superClasses: 'superclasses',
    equivalences: 'equivalent to',
    relationalProperties: 'custom relationships',
    obsoleteSubClassProperties: 'obsolete subclass properties',
    obsoleteAnnotationPropertyKeyValue:
        'obsolete annotation property key-value',
    ontologyFileWebLocation: 'original ontology file',
    propertiesToHide: 'properties to hide',
};
