import { getOntologyClasses } from '@/api-v2';
import { computed, Ref, ref } from '@vue/composition-api';
import { components } from '@/ts/ApiSpecs';

type ontologyClassesType =
    components['schemas']['ScrollOntologySummaryVM']['elements'];

const displayIndexModal = ref(false);

const queryLimit = ref(100);
const ontologyClasses: Ref<null | ontologyClassesType> = ref(null);
const ontoStatus = ref('');
const awaitingLoad = ref(['']);
const awaitingIndex = ref(['']);
const interceptIri = ref('');

//Indexed & Loaded Arrays
const unloadedOntologies: Ref<
    null | components['schemas']['OntologyMetadataSummary'][]
> = ref([]);
const loadedOntologies: Ref<
    null | components['schemas']['OntologyMetadataSummary'][]
> = ref([]);
const indexedOntologies: Ref<
    null | components['schemas']['SparqlIndexMetadata'][]
> = ref([]);

//Selected Ontology
const selectedOntologyId: Ref<string> = ref('');
const selectedOntology: Ref<null | ISelectedOntologyData> = ref(null);

export const useSparql = () => {
    const getSelectedOntologyId = () => {
        return selectedOntologyId.value;
    };
    const setSelectedOntologyId = (id: string) => {
        selectedOntologyId.value = id;
    };

    const getLoadedOntologies = () => {
        return loadedOntologies.value;
    };

    const setLoadedOntologies = (
        updatedArray: null | components['schemas']['OntologyMetadataSummary'][]
    ) => {
        loadedOntologies.value = updatedArray;
    };

    const getUnloadedOntologies = () => {
        return unloadedOntologies.value;
    };

    const setUnloadedOntologies = (
        updatedArray: null | components['schemas']['OntologyMetadataSummary'][]
    ) => {
        unloadedOntologies.value = updatedArray;
    };

    const getIndexedOntologies = () => {
        return indexedOntologies.value;
    };

    const setIndexedOntologies = (
        updatedArray: null | components['schemas']['SparqlIndexMetadata'][]
    ) => {
        indexedOntologies.value = updatedArray;
    };

    const getInterceptIri = () => {
        return interceptIri.value;
    };

    const setInterceptIri = (iri: string) => {
        interceptIri.value = iri;
    };

    const setOntologyIndexed = (ontologyId: string) => {
        awaitingIndex.value = awaitingIndex.value.filter(
            (ontology) => ontology !== ontologyId
        );
    };
    const addAwaitingIndex = (ontologyId: string) => {
        awaitingIndex.value.push(ontologyId);
    };
    const getAwaitingIndex = () => {
        return awaitingIndex.value;
    };

    const setOntologyLoaded = (ontologyId: string) => {
        awaitingLoad.value = awaitingLoad.value.filter(
            (ontology) => ontology !== ontologyId
        );
    };
    const addAwaitingLoad = (ontologyId: string) => {
        awaitingLoad.value.push(ontologyId);
    };
    const getAwaitingLoad = () => {
        return awaitingLoad.value;
    };

    const setOntoStatus = (status: string) => {
        ontoStatus.value = status;
    };

    const getOntoStatus = () => {
        return ontoStatus.value;
    };

    const setSelectedOntology = (ontology: ISelectedOntologyData) => {
        if (
            selectedOntology.value?.ontologyId == ontology.ontologyId ||
            ontology.ontologyId == ''
        )
            return;
        selectedOntology.value = ontology;
        if (ontology.status !== 'Ready' || ontology.indexOutdated === true)
            setDisplayIndexModal(true);
    };

    const getSelectedOntology = () => {
        return selectedOntology.value;
    };

    const loadOntologyClasses = async (selectedOntology: string) => {
        const classes = await getOntologyClasses(
            { ontologyId: selectedOntology },
            { size: 10000 }
        );
        ontologyClasses.value = classes.elements;
        return ontologyClasses.value;
    };

    const getDisplayIndexModal = computed(() => {
        return displayIndexModal.value;
    });

    const setDisplayIndexModal = (value: boolean) => {
        displayIndexModal.value = value;
    };

    const getQueryLimit = () => {
        return queryLimit.value;
    };

    const setQueryLimit = (value: number) => {
        queryLimit.value = value;
    };

    const isOntologyUnloaded = (newOntology: string) => {
        //Check if exists
        if (!unloadedOntologies.value) return false;
        const isUnloaded = (
            ontology: components['schemas']['OntologyMetadataSummary']
        ) => ontology.ontologyId === newOntology;

        return unloadedOntologies.value?.some(isUnloaded);
    };

    const isOntologyLoaded = (newOntology: string) => {
        //Check if loaded
        if (!loadedOntologies.value) return false;
        const isLoaded = (
            ontology: components['schemas']['OntologyMetadataSummary']
        ) => ontology.ontologyId === newOntology;

        return loadedOntologies.value?.some(isLoaded);
    };

    const isOntologyIndexed = (newOntology: string) => {
        //Check if indexed
        if (!indexedOntologies.value) return;

        for (const ontology of indexedOntologies.value) {
            if (ontology.ontologyId === newOntology) {
                const indexedAt = new Date(
                    ontology.lastIndexedDate || '01/01/1970'
                );
                const editedAt = new Date(
                    ontology.lastEditDate || '01/01/1970'
                );
                return { indexedAt, editedAt };
            }
        }
    };

    return {
        getDisplayIndexModal,
        setDisplayIndexModal,
        getQueryLimit,
        setQueryLimit,
        loadOntologyClasses,
        getSelectedOntology,
        setSelectedOntology,
        getOntoStatus,
        setOntoStatus,
        addAwaitingLoad,
        getAwaitingLoad,
        setOntologyLoaded,
        addAwaitingIndex,
        getAwaitingIndex,
        setOntologyIndexed,
        getInterceptIri,
        setInterceptIri,
        getLoadedOntologies,
        setLoadedOntologies,
        getUnloadedOntologies,
        setUnloadedOntologies,
        getIndexedOntologies,
        setIndexedOntologies,
        getSelectedOntologyId,
        setSelectedOntologyId,
        isOntologyIndexed,
        isOntologyLoaded,
        isOntologyUnloaded,
    };
};

export interface ISelectedOntologyData {
    ontologyId: string;
    status: string;
    indexOutdated?: boolean;
    lastIndexDate?: Date;
    lastTransactionDate?: Date;
}
