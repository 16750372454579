import { upperFirst } from 'lodash';
import { humanReadable, iriToShortText } from '@/utils';

/**
 * Tries to extract readable text form an IRI.
 * @param {string} iri
 * @return {string}
 */
export const iriToReadableText = (iri: string) => {
    const shortText = iriToShortText(iri);

    return upperFirst(humanReadable(shortText));
};
