import Vue from 'vue';
import { computed, ref, Ref, set } from '@vue/composition-api';
import {
    ClassDataExtended,
    ClassEdit,
    ClassSnapshot,
    PropertyListEditState,
    TagChangeByIri,
    TagEdit,
} from '@/ts';
import { cloneDeep, filter, forOwn, intersection, isEmpty, map } from 'lodash';
import { useClassView } from '@/compositions/useClassView';
import { components } from '@/ts/ApiSpecs';
import {
    commitSuggestion,
    compareEditWithCurrent,
    editLabels,
    getSuggestionsForTransaction,
} from '@/api-v2';
import { useOntology } from '@/compositions/useOntology';
import { base64Encode, propValuesDifference } from '@/utils';
import { Keys } from '@/ts/Utilities';
import { usePropertyList } from '@/compositions/usePropertyList';
import useObservers from '@/compositions/useObservers';
import { useEventBus } from '@/compositions/useEventBus';
import { useValidation } from '@/compositions/useValidation';

type TagsChanges = Record<string, Record<string, TagChangeByIri>>;

const classesWithTagEdits: Ref<Record<string, ClassDataExtended>> = ref({});
const classesWithEdits: Ref<string[]> = ref([]);
const currentTransaction: components['schemas']['ClassSuggestionSummary'][] =
    [];
const tagsChanges: Ref<TagsChanges> = ref({});
const editIsActive: Ref<boolean> = ref(false);
const block: Ref<boolean> = ref(false);
const editChanges: Ref<Record<string, ClassEdit | ClassSnapshot> | undefined> =
    ref();
const editErrors: Ref<Record<string, ClassEdit | ClassSnapshot> | undefined> =
    ref();
const transactionId: Ref<string> = ref('');

const mergeEditChanges: Ref<components['schemas']['OntologyEdit'][]> = ref([]);
const refreshEditCount: Ref<boolean> = ref(false);

/**
 * Flags if the changes ultimately involve class relationships.
 * @type {Ref<boolean>}
 */
const relationsHaveChanged: Ref<boolean> = ref(false);
const listOfClassChanges: Ref<components['schemas']['EditDiffResult'][]> = ref(
    []
);
const isSuggestionMode: Ref<boolean> = ref(false);
const isRecoveryMode: Ref<boolean> = ref(false);
const changesMade: Ref<number> = ref(0);
const isSlowModeEnabled: Ref<boolean> = ref(false);
const activeEdits: Ref<
    Record<string, components['schemas']['OntologySummaryVM']>
> = ref({});

export const useEdits = () => {
    const getIsSlowModeEnabled = computed(() => {
        return isSlowModeEnabled.value;
    });

    const setIsSlowModeEnabled = (_isSlowModeEnabled: boolean) => {
        const eventBus = useEventBus().getEventBus.value;
        //Notifies the user that slow mode has been enabled
        if (_isSlowModeEnabled !== isSlowModeEnabled.value) {
            if (_isSlowModeEnabled) {
                eventBus.$emit(
                    'notification:show',
                    'info',
                    'Due to the large metadata in this class we have enabled slow mode to prevent crashing. We have deactivated our smart features and limited editing capabilities to only adding values.',
                    'Slow mode enabled',
                    [],
                    'centerTop',
                    { timeout: 0 }
                );
            } else {
                eventBus.$emit(
                    'notification:show',
                    'info',
                    'Slow mode as been disabled, you are now free to do normal operations.',
                    'Slow mode disabled',
                    [],
                    'centerTop',
                    { timeout: 0 }
                );
            }
        }

        isSlowModeEnabled.value = _isSlowModeEnabled;
    };

    const getMergeEditChanges = computed(() => {
        return mergeEditChanges.value;
    });

    const setMergeEditChanges = (
        mergeEdits: components['schemas']['OntologyEdit'][]
    ) => {
        mergeEditChanges.value = mergeEdits;
    };

    const pushMergeEditChange = (
        mergeEdit: components['schemas']['OntologyEdit']
    ) => {
        mergeEditChanges.value.push(mergeEdit);
        return mergeEditChanges.value;
    };

    const hasMergeEditChanges = computed(() => {
        return !!mergeEditChanges.value.length;
    });

    /**
     * Getter for the classesWithEdits variable.
     * @type {ComputedRef<string[]>}
     */
    const getClassesWithEdits = computed(() => {
        return classesWithEdits.value;
    });

    /**
     * Getter for the currentTransaction variable.
     * @type {ComputedRef<definitions['ClassSuggestionSummary']>}
     */
    const getCurrentTransaction = computed(() => {
        return currentTransaction;
    });

    /**
     * Setter for the currentTransaction variable.
     * @param {definitions['ClassSuggestionSummary'][]} transaction
     * @returns {definitions['ClassSuggestionSummary'][]}
     */
    const setCurrentTransaction = (
        transaction: components['schemas']['ClassSuggestionSummary'][]
    ) => {
        currentTransaction.length = 0;
        currentTransaction.push(...transaction);
    };

    /**
     * Retrieves the current transaction and set the currentTransaction object.
     * @return {Promise<void>}
     */
    const refreshCurrentTransaction = async () => {
        const currentTransactionId = transactionId.value;

        if (!currentTransactionId) return;

        const transaction = await getSuggestionsForTransaction({
            transactionId: currentTransactionId,
        });
        const classesWithEditIds = getClassesWithEditsIds();

        setClassesWithEdits(classesWithEditIds);
        setCurrentTransaction(transaction);
    };

    /**
     * Loops through the current transaction and returns the current version of the requested class.
     * If the requested class is not being edited, it will return undefined.
     * @param {string} classId
     * @return {definitions["OntologyJSONBean"]|undefined}
     */
    const getCurrentClassState = (classId: string) => {
        if (!currentTransaction.length) return;

        for (const classEdit of currentTransaction) {
            if (classId !== classEdit.lastOntologyEdit?.ontologyClassId)
                continue;

            return classEdit.lastOntologyEdit.ontologyJSONBean;
        }
    };

    const getClassesWithEditsIds = () => {
        if (!currentTransaction.length) return [];

        return currentTransaction
            .map((classEdits) => {
                return classEdits.lastOntologyEdit?.ontologyJSONBean?.id;
            })
            .filter((id) => id) as string[];
    };

    /**
     * Setter for the classesWithEdits variable.
     * @param {string[]} classesIds
     * @returns {string[]}
     */
    const setClassesWithEdits = (classesIds: string[]) => {
        classesWithEdits.value = classesIds;
    };

    const getListOfClassChanges = () => {
        return listOfClassChanges;
    };

    const setListOfClassChanges = (
        newListOfClassChanges: components['schemas']['EditDiffResult'][]
    ) => {
        listOfClassChanges.value = newListOfClassChanges;
    };

    const pushListOfClassChanges = (
        classChange: components['schemas']['EditDiffResult']
    ) => {
        listOfClassChanges.value.push(classChange);
    };

    const getIsSuggestionMode = () => {
        return isSuggestionMode.value;
    };

    const getIsRecoveryMode = () => {
        return isRecoveryMode.value;
    };

    const setIsSuggestionMode = (newSuggestionMode: boolean) => {
        isSuggestionMode.value = newSuggestionMode;
    };

    const setIsRecoveryMode = (newRecoveryMode: boolean) => {
        isRecoveryMode.value = newRecoveryMode;
    };

    const getEditIsActive = computed(() => {
        return editIsActive.value;
    });

    const getEditChanges = computed(() => {
        return editChanges.value;
    });

    const getEditErrors = computed(() => {
        return editErrors.value;
    });

    const getTagChanges = computed(() => {
        return tagsChanges.value;
    });

    const getClassChanges = computed(() => {
        const classPrimaryId = useClassView().getClassElasticSearchId.value;

        return (
            classPrimaryId &&
            editChanges.value &&
            editChanges.value[classPrimaryId]
        );
    });

    const getTransactionId = computed(() => {
        return transactionId.value;
    });

    const getRelationsHaveChanged = computed(() => {
        return relationsHaveChanged.value;
    });

    const countOfClassChanges = computed(() => {
        return listOfClassChanges.value.length;
    });

    /**
     * Gets the primary ids of the classes that have been edited.
     * @return {string[]}
     */
    const getEditedClassesIds = () => {
        const changedClassesIds: string[] = [];

        listOfClassChanges.value.forEach((edit) => {
            edit.changesArray?.forEach((change) => {
                if (change.fieldName === 'primaryID') {
                    const classPrimaryId = change.value as string;
                    changedClassesIds.push(classPrimaryId);
                }
            });

            if (edit.comparedWith?.primaryID)
                changedClassesIds.push(edit.comparedWith.primaryID);
        });

        return changedClassesIds;
    };

    /**
     * Computes the newly added classes.
     * @type {ComputedRef<string[]>}
     */
    const getAddedClassesIds = computed(() => {
        const addedClassesIds: string[] = [];

        listOfClassChanges.value.forEach((edit) => {
            edit.changesArray?.forEach((change) => {
                if (
                    change.fieldName === 'primaryID' &&
                    change.operation === 'ADD'
                ) {
                    const classPrimaryId = change.value as string;
                    addedClassesIds.push(classPrimaryId);
                }
            });
        });

        return addedClassesIds;
    });

    /**
     * Computes classes that have been made obsolete.
     * @type {ComputedRef<string[]>}
     */
    const getRemovedClassesIds = computed(() => {
        const addedClassesIds: string[] = [];

        listOfClassChanges.value.forEach((edit) => {
            edit.changesArray?.forEach((change) => {
                if (
                    change.fieldName === 'primaryID' &&
                    change.operation === 'REMOVE'
                ) {
                    const classPrimaryId = change.oldValue as string;
                    addedClassesIds.push(classPrimaryId);
                }
            });
        });

        return addedClassesIds;
    });

    const isNewClass = (classId: string) => {
        return getAddedClassesIds.value.includes(classId);
    };

    const isObsoleteClass = (classId: string) => {
        return getRemovedClassesIds.value.includes(classId);
    };

    /**
     * Gets only non-cancelling class edit actions (ie: those that have a net effect).
     * Formerly $_edit_netEdits (EditMixin.js)
     * @type {ComputedRef<ClassEdit[]>}
     */
    const getNonCancellingEdits = computed(
        (): (ClassEdit | ClassSnapshot)[] => {
            if (!editChanges.value) return [];

            const filteredEdits = filter(editChanges.value, (entry) => {
                return !isEmpty(entry.history);
            });

            return filteredEdits;
        }
    );

    /**
     * Gets edit log entries for only brand new classes.
     * @type {ComputedRef<ClassEdit[]>}
     */
    const getNewClasses = computed(() => {
        const newClassEntries = getNonCancellingEdits.value.filter(
            (entry) => entry.isNew
        );
        const newClasses = map(newClassEntries, 'live');

        return newClasses;
    });

    /**
     * Determines if a given class is a new class in the current edit.
     * @param {string} classId
     * @return {boolean}
     */
    const isClassNew = (classId: string) => {
        return listOfClassChanges.value.some((edit) => {
            if (edit.ontologyClassId !== classId) return false;

            return edit.comparedWith?.primaryLabel === null;
        });
    };

    /**
     * Gets edit log entries for only already existing classes that are not obsolete and have been changed.
     * Formerly $_edit_changedClasses (EditMixin.js)
     * @type {ComputedRef<ClassEdit[]>}
     */
    const getChangedClasses = computed(() => {
        const changedClassEntries = getNonCancellingEdits.value.filter(
            (entry) => !entry.isNew && !entry.isObsolete
        );
        return map(changedClassEntries, 'live');
    });

    /**
     * Counts the number of classes that have been edited.
     * @type {ComputedRef<number>}
     */
    const countEditedClasses = computed(() => {
        return changesMade.value;
    });

    /**
     * Gets edit log entries for only obsolete classes.
     * @type {ComputedRef<ClassEdit[]>}
     */
    const getObsoleteClasses = computed(() => {
        const obsoleteClasses = getNonCancellingEdits.value.filter(
            (entry) => entry.isObsolete
        );
        return map(obsoleteClasses, 'live');
    });

    /**
     * Checks if any tag has been changed.
     * @type {ComputedRef<boolean>}
     */
    const hasTagChanges = computed(() => {
        if (useClassView().isSchemaVersion1.value) return false;
        if (!Object.values(tagsChanges.value).length) return false;

        return Object.values(tagsChanges.value).some((classChanges) => {
            if (!Object.values(classChanges).length) return false;

            return Object.values(classChanges).some((propertyChanges) => {
                const hasDeletedProperties =
                    propertyChanges.removedTags &&
                    propertyChanges.removedTags.length > 0;
                const hasAddedProperties =
                    propertyChanges.addedTags &&
                    propertyChanges.addedTags.length > 0;

                return hasDeletedProperties || hasAddedProperties;
            });
        });
    });

    /**
     * Gets the classes that have tags edits.
     * @type {ComputedRef<Record<string, ClassDataExtended>>}
     */
    const getClassesWithTagEdits = computed(() => {
        return classesWithTagEdits.value;
    });

    /**
     * Gets ids of classes with tags edits.
     * @type {ComputedRef<any[] | string[]>}
     */
    const getClassesWithTagsEditsIds = computed(() => {
        if (!Object.values(tagsChanges.value).length) return [];

        return Object.keys(tagsChanges.value);
    });

    /**
     * Checks if a class has edits.
     * @param {number} classId
     * @return {boolean}
     */
    const classHasEdits = (classId: string) => {
        if (!classesWithEdits.value.length) return false;

        return classesWithEdits.value.includes(classId);
    };

    const setEditChanges = (
        edits: Record<string, ClassEdit | ClassSnapshot>
    ) => {
        editChanges.value = edits;
    };

    const setEditErrors = (
        errors: Record<string, ClassEdit | ClassSnapshot>
    ) => {
        editErrors.value = errors;
    };

    const setEditIsActive = (isActive: boolean) => {
        editIsActive.value = isActive;
    };

    const setTransactionId = (id: string) => {
        transactionId.value = id;
    };

    const setRelationsHaveChanged = (didChange: boolean) => {
        relationsHaveChanged.value = didChange;
    };

    const trackState = (
        propName: string,
        isNew: boolean,
        isObsolete: boolean,
        editResponse: components['schemas']['OntologyEdit']
    ) => {
        const classData = useClassView().getClassData.value;
        if (!classData) return;
        if (!editChanges.value) return;

        const classPrimaryId = classData.id as string;
        let newEditState: PropertyListEditState = { isSaving: true };

        // Only notifies state change if there is a tree to notify to.
        // NOTE: in some cases, a class may not have a path-from-root tree.
        if (classData.editState) {
            if (classData.editState[propName]) {
                newEditState = classData.editState[
                    propName
                ] as PropertyListEditState;
            } else {
                set(classData.editState, propName, newEditState);
            }
        }

        // Obsolescence takes precedence over newness and the latter over mere changes.
        const isEffectiveNew =
            !isObsolete && (isNew || editChanges.value[classPrimaryId].isNew);

        Vue.delete(newEditState, 'error');
        set(newEditState, 'isNew', isEffectiveNew);
        set(newEditState, 'isObsolete', isObsolete);

        // Declares state at the class global level
        set(editChanges.value[classPrimaryId], 'isNew', isEffectiveNew);
        set(editChanges.value[classPrimaryId], 'isObsolete', isObsolete);
        set(editChanges.value[classPrimaryId], 'last', editResponse);

        newEditState.isSaving = false;

        return newEditState;
    };

    /**
     * Updates the value in all tag edits with the same combination of classId, iri, value.
     * Note: this will be used when updating a property value.
     * @param {string} classId
     * @param {string} propertyIri
     * @param {string} previousValue
     * @param {string} newValue
     */
    const updateTagEditsPropertyValue = (
        classId: string,
        propertyIri: string,
        previousValue: string,
        newValue: string
    ) => {
        if (!classId || !tagsChanges.value[classId]) return;
        if (!tagsChanges.value[classId][propertyIri]) return;

        const removedTags = tagsChanges.value[classId][propertyIri].removedTags;
        const addedTags = tagsChanges.value[classId][propertyIri].addedTags;

        addedTags &&
            addedTags.forEach((addedTag) => {
                if (addedTag.propertyValue !== previousValue) return;

                addedTag.propertyValue = newValue;
            });

        removedTags &&
            removedTags.forEach((removedTag) => {
                if (removedTag.propertyValue !== previousValue) return;

                removedTag.propertyValue = newValue;
            });
    };

    const getEditedTagsByPropertyIriAndValue = (
        editType: 'addedTags' | 'removedTags',
        classId: string,
        propertyIri: string,
        propertyValue: string
    ) => {
        if (!tagsChanges.value[classId]) return false;
        if (!tagsChanges.value[classId][propertyIri]) return false;
        if (!tagsChanges.value[classId][propertyIri][editType]) return false;

        const editedTags = tagsChanges.value[classId][propertyIri][editType];
        const iriHasAddedTags = editedTags && editedTags.length > 0;

        if (!iriHasAddedTags) return;

        return editedTags.filter((addedTag) => {
            return addedTag.value === propertyValue;
        });
    };

    const getAddedTagsByPropertyIriAndValue = (
        classId: string,
        propertyIri: string,
        propertyValue: string
    ) => {
        return getEditedTagsByPropertyIriAndValue(
            'addedTags',
            classId,
            propertyIri,
            propertyValue
        );
    };

    const getRemovedTagsByPropertyIriAndValue = (
        classId: string,
        propertyIri: string,
        propertyValue: string
    ) => {
        return getEditedTagsByPropertyIriAndValue(
            'removedTags',
            classId,
            propertyIri,
            propertyValue
        );
    };

    const propertyHasTagEdits = (
        classId: string,
        propertyIri: string,
        propertyValue: string
    ) => {
        if (!tagsChanges.value[classId]) return false;
        if (!tagsChanges.value[classId][propertyIri]) return false;
        if (!tagsChanges.value[classId][propertyIri].addedTags) return false;

        const addedTags = tagsChanges.value[classId][propertyIri].addedTags;
        const removedTags = tagsChanges.value[classId][propertyIri].addedTags;
        const iriHasAddedTags = addedTags && addedTags.length > 0;
        const iriHasRemovedTags = removedTags && removedTags.length > 0;

        if (!iriHasAddedTags && !iriHasRemovedTags) return false;

        const hasAddedTags = addedTags.some((addedTag) => {
            if (addedTag.value !== propertyValue) return false;
            return true;
        });

        const hasRemovedTags = addedTags.some((removedTag) => {
            if (removedTag.value !== propertyValue) return false;
            return true;
        });

        return hasRemovedTags && hasAddedTags;
    };

    /**
     * Checks if a tag is in the removedTags or addedTags property array.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {"removedTags" | "addedTags"} tagsListType
     * @param {TagEdit} tag
     * @return {boolean}
     */
    const isInTagsList = (
        classId: string,
        iri: string,
        tagsListType: 'removedTags' | 'addedTags' | 'originalTags',
        tag: TagEdit
    ) => {
        if (!classId || !tagsChanges.value[classId]) return false;
        if (!tagsChanges.value[classId][iri]) return false;
        if (!tagsChanges.value[classId][iri][tagsListType]) return false;

        const tagsList = tagsChanges.value[classId][iri][tagsListType];

        for (const tagListItem of tagsList) {
            if (tagListItem.iri !== tag.iri) continue;
            if (tagListItem.propertyValue !== tag.propertyValue) continue;
            if (tagListItem.value !== tag.value) continue;

            return true;
        }

        return false;
    };

    /**
     * Adds a tag to the removedTags or addedTags property array.
     * @param {"removedTags" | "addedTags"} tagListType
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     */
    const addToTagsList = (
        classId: string,
        tagListType: 'removedTags' | 'addedTags',
        iri: string,
        tag: TagEdit
    ) => {
        const classObject = useClassView().getClassData.value;

        if (!classObject) return;
        if (!tagsChanges.value[classId][iri]) set(tagsChanges.value, iri, {});

        classesWithTagEdits.value[classId] = classObject;

        if (!tagsChanges.value[classId][iri][tagListType]) {
            set(tagsChanges.value[classId][iri], tagListType, [tag]);
        } else {
            tagsChanges.value[classId][iri][tagListType].push(tag);
        }
    };

    /**
     * Removes a tag to the removedTags or addedTags property array.
     * @param {"removedTags" | "addedTags"} tagListType
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     */
    const removeFromTagsList = (
        classId: string,
        tagListType: 'removedTags' | 'addedTags',
        iri: string,
        tag: TagEdit
    ) => {
        for (const [index, removedTag] of tagsChanges.value[classId][iri][
            tagListType
        ].entries()) {
            if (removedTag.iri !== tag.iri && removedTag.value !== tag.value)
                continue;

            tagsChanges.value[classId][iri][tagListType].splice(index, 1);

            return;
        }
    };

    /**
     * Checks if a tag is already listed in the removedTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     * @return {boolean}
     */
    const isInRemovedTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        return isInTagsList(classId, propertyIri, 'removedTags', tag);
    };

    /**
     * Checks if a tag is already listed in the addedTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     * @return {boolean}
     */
    const isInAddedTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        return isInTagsList(classId, propertyIri, 'addedTags', tag);
    };

    /**
     * Checks if a tag is already listed in the originalTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     * @return {boolean}
     */
    const isInOriginalTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        return isInTagsList(classId, propertyIri, 'originalTags', tag);
    };

    /**
     * Insert a tag in the removedTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     */
    const addToRemovedTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        addToTagsList(classId, 'removedTags', propertyIri, tag);
    };

    /**
     * Removes a tag from the removedTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     */
    const removeFromRemovedTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        return removeFromTagsList(classId, 'removedTags', propertyIri, tag);
    };

    /**
     * Insert a tag in the addedTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     */
    const addToAddedTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        addToTagsList(classId, 'addedTags', propertyIri, tag);
    };

    /**
     * Removes a tag from the addedTags property.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {TagEdit} tag
     */
    const removeFromAddedTags = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        return removeFromTagsList(classId, 'addedTags', propertyIri, tag);
    };

    /**
     * Updates a tag value in the addedTags array.
     * @param {string} classId
     * @param {string} propertyIri
     * @param {string} tagIri
     * @param {string} tagNewValue
     * @param {string} tagOldValue
     */
    const updateAddedTagValue = (
        classId: string,
        propertyIri: string,
        tagIri: string,
        tagNewValue: string,
        tagOldValue: string
    ) => {
        const addedTags = tagsChanges.value[classId][propertyIri].addedTags;

        for (const addedTag of addedTags) {
            if (addedTag.iri !== tagIri) continue;
            if (addedTag.value !== tagOldValue) continue;

            addedTag.value = tagNewValue;

            return;
        }
    };

    /**
     * Keep tracks of added tags.
     * @param {string} classId
     * @param {string} propertyIri
     * @param {TagEdit} tag
     */
    const trackTagAddition = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        if (isInRemovedTags(classId, propertyIri, tag)) {
            removeFromRemovedTags(classId, propertyIri, tag);
        }

        if (
            !isInOriginalTags(classId, propertyIri, tag) &&
            !isInAddedTags(classId, propertyIri, tag)
        ) {
            addToAddedTags(classId, propertyIri, tag);
        }
    };

    /**
     * Keep tracks of removed tags.
     * @param {string} classId
     * @param {string} propertyIri
     * @param {TagEdit} tag
     */
    const trackTagRemoval = (
        classId: string,
        propertyIri: string,
        tag: TagEdit
    ) => {
        if (isInAddedTags(classId, propertyIri, tag)) {
            removeFromAddedTags(classId, propertyIri, tag);
        }

        if (
            !isInRemovedTags(classId, propertyIri, tag) &&
            isInOriginalTags(classId, propertyIri, tag)
        ) {
            addToRemovedTags(classId, propertyIri, tag);
        }
    };

    /**
     * Keeps track of edited tags.
     * @param {string} classId
     * @param {string} propertyIri
     * @param {TagEdit} tag
     */
    const trackTagEditing = (
        classId: string,
        propertyIri: string,
        tag: TagEdit,
        previousTag: TagEdit | undefined
    ) => {
        if (!previousTag) {
            console.error(
                "A tag can not be edited if it's previous value is not specified"
            );
            return;
        }

        const tagIri = tag.iri || '';
        const tagNewValue = tag.value as string;
        const tagOldValue = previousTag?.value as string;

        if (isInAddedTags(classId, propertyIri, previousTag)) {
            updateAddedTagValue(
                classId,
                propertyIri,
                tagIri,
                tagNewValue,
                tagOldValue
            );
        }

        if (
            !isInAddedTags(classId, propertyIri, tag) &&
            !isInOriginalTags(classId, propertyIri, tag)
        ) {
            addToAddedTags(classId, propertyIri, tag);
        }

        if (!isInRemovedTags(classId, propertyIri, previousTag)) {
            addToRemovedTags(classId, propertyIri, previousTag);
        }
    };

    /**
     * Remove all edits from the tagsChanges object.
     */
    const resetTagsChanges = () => {
        tagsChanges.value = {};
        classesWithTagEdits.value = {};
    };

    /**
     * Keeps track of tags changes, to be then used to display tags changes before committing edits.
     * @param {'add'|'remove'|'edit'} action The type of action to be tracked.
     * @param {string} classId
     * @param {string} propertyIri The iri of the property the tag belongs to.
     * @param {string} propertyValue
     * @param {TagEdit} tag
     * @param {TagEdit|undefined} previousTag
     * @param {TagEdit[]} previousTags a snapshot of the tags before the editing
     */
    const trackTagsChanges = async (
        action: 'add' | 'remove' | 'edit',
        classId: string,
        propertyIri: string,
        propertyValue: string,
        previousTags: TagEdit[] | [],
        tag: components['schemas']['Tag'],
        previousTag: components['schemas']['Tag'] | undefined = undefined
    ) => {
        if (!tagsChanges.value[classId]) {
            set(tagsChanges.value, classId, {});
        }

        if (!tagsChanges.value[classId][propertyIri]) {
            set(tagsChanges.value[classId], propertyIri, {});
        }

        const propertyTagChanges = tagsChanges.value[classId][propertyIri];
        const originalTags = propertyTagChanges.originalTags || previousTags;

        set(propertyTagChanges, 'propertyValue', propertyValue);
        set(propertyTagChanges, 'originalTags', originalTags);

        const tagEdit = { ...tag, propertyValue };

        if (action === 'remove') {
            trackTagRemoval(classId, propertyIri, tagEdit);
        }

        if (action === 'add') {
            trackTagAddition(classId, propertyIri, tagEdit);
        }

        if (action === 'edit') {
            trackTagEditing(classId, propertyIri, tagEdit, {
                ...previousTag,
                propertyValue,
            });
        }

        await countClassChanges();
    };

    /**
     * Initialise empty values of the editChanges variable.
     * @param {string} propertyIri
     * @param {string} propName
     * @param {string[]} previousValue
     * @param {string[]} newValue
     * @param {boolean} overridePreviousValue
     * @param {boolean} trackDifference
     */
    const initialiseClassChanges = (
        propertyIri: string,
        propName: string,
        previousValue: string[],
        newValue: string[],
        overridePreviousValue = false,
        trackDifference = false
    ) => {
        const classData = useClassView().getClassData.value;

        if (!classData) return;
        if (!editChanges.value) return;

        const classId = classData.id as string;

        if (!editChanges.value[classId]) {
            set(editChanges.value, classId, {
                live: classData,
                previous: {},
                previousByIri: {},
                history: {},
            });
        }

        const classSnapshot = editChanges.value[classId] as ClassSnapshot;

        if (trackDifference && !classSnapshot.history) {
            set(classSnapshot, 'history', {});
        }

        if (trackDifference && !classSnapshot.live) {
            set(classSnapshot, 'live', classData);
        }

        if (trackDifference && !classSnapshot.history[propertyIri]) {
            set(classSnapshot.history, propertyIri, []);
        }

        if (
            !overridePreviousValue &&
            classSnapshot.previous &&
            classSnapshot.previous[propertyIri]
        )
            return;

        if (!classSnapshot.previous) {
            set(classSnapshot, 'previous', {
                [propertyIri]: previousValue,
            });
        }

        // Original value for the property in question if not overwritten.
        if (!classSnapshot.previous[propertyIri]) {
            classSnapshot.previous[propertyIri] = previousValue;
        }
    };

    /**
     * Keeps track of changes applied to a property identified by iri.
     * @param {string} propertyIri
     * @param {string} propName
     * @param {string[]} originalValues
     * @param {string[]} newValue
     * @param {boolean} overridePreviousValue
     * @param {boolean} trackDifference
     * @return {boolean}
     */
    const trackChanges = async (
        propertyIri: string,
        propName: string,
        previousValue: string,
        originalValues: string[],
        newValue: string[],
        overridePreviousValue = false,
        trackDifference = false,
        lang: string | undefined = undefined
    ) => {
        const classData = useClassView().getClassData.value;
        if (!classData) return;
        if (!editChanges.value) return;

        const classId = classData.id as string;

        let isMeaningfulChange = false;

        initialiseClassChanges(
            propertyIri,
            propName,
            originalValues,
            newValue,
            overridePreviousValue,
            trackDifference
        );

        if (trackDifference) {
            const classSnapshot = editChanges.value[classId] as ClassSnapshot;

            if (!classSnapshot.newestChanges) classSnapshot.newestChanges = {};

            const previousValues = cloneDeep(
                classSnapshot.history[propertyIri]
            ) as string[];
            const history = classSnapshot.history[propertyIri] as string[];
            const difference = propValuesDifference(
                newValue,
                originalValues
            ) as string[];

            const newValueString = difference[0];

            const newKey = base64Encode(
                classId + propertyIri + lang + newValueString + 'new'
            );
            const oldKey = base64Encode(
                classId + propertyIri + lang + previousValue + 'old'
            );

            const originalValue =
                classSnapshot.newestChanges[oldKey] || previousValue;
            const isNewValue = !originalValues.includes(originalValue);

            classSnapshot.newestChanges[newKey] = originalValue;

            delete classSnapshot.newestChanges[oldKey];

            updateTagEditsPropertyValue(
                classId,
                propertyIri,
                previousValue,
                newValueString
            );

            classSnapshot.history[propertyIri] = [
                ...difference,
                ...history,
            ].filter((value) => {
                if (value === originalValue) return true;
                return value !== previousValue;
            });

            const propertyValueHistory = classSnapshot.history[
                propertyIri
            ] as string[];

            if (propertyValueHistory.length > 1 && isNewValue) {
                const differenceWithPreviousValues = intersection(
                    previousValues,
                    propertyValueHistory
                ) as string[];
                const previousValueString = differenceWithPreviousValues[0];
                const originalValueIndex =
                    propertyValueHistory.indexOf(originalValue);
                const previousValueStringIndex =
                    propertyValueHistory.indexOf(previousValueString);

                if (
                    originalValueIndex > -1 &&
                    newValueString !== originalValue
                ) {
                    propertyValueHistory.splice(originalValueIndex, 1);
                }

                if (
                    previousValueStringIndex > -1 &&
                    newValueString !== previousValueString &&
                    originalValue !== previousValueString
                ) {
                    propertyValueHistory.splice(previousValueStringIndex, 1);
                }
            }

            // Keeps track of precisely which items have been updated.
            // TODO: this should actually happen within the classItem view so that saving and error states are also limited to the edited array item.
            isMeaningfulChange = !!difference.length;

            if (isMeaningfulChange) {
                if (classData.editState) {
                    if (!classData.editState[propertyIri])
                        set(classData.editState, propertyIri, {});

                    const classEditState = classData.editState[
                        propertyIri
                    ] as PropertyListEditState;

                    set(
                        classEditState,
                        'history',
                        classSnapshot.history[propertyIri]
                    );

                    if (!classEditState['original']) {
                        set(classEditState, 'original', originalValues);
                    }
                } else {
                    console.warn(
                        `The class "${classData.primaryLabel}" with ID ${classId} has not been asigned any edit state. Skipping history update.`
                    );
                }

                // New edit action is complementary to a previous one => the two cancel each other out and removes the prev action from history instead.
            } else {
                const classEditState = classData.editState as Record<
                    string,
                    PropertyListEditState
                >;
                const classSnapshotHistory = classSnapshot.history[
                    propertyIri
                ] as string[];
                const newValueIndex =
                    classSnapshotHistory.indexOf(newValueString);
                const originalValueIndex =
                    classSnapshotHistory.indexOf(originalValue);

                classSnapshotHistory.splice(newValueIndex, 1);
                classSnapshotHistory.splice(originalValueIndex, 1);
            }
        }

        refreshEditCount.value = !refreshEditCount.value;
        await countClassChanges();
        return isMeaningfulChange;
    };

    /**
     * Checks if a given property value has a tag.
     * @param {definitions["PropertyValue"]} property
     * @param {definitions["Tag"]} tagToCheck
     * @return {boolean}
     */
    const propertyValueHasTag = (
        property: components['schemas']['PropertyValue'],
        tagToCheck: components['schemas']['Tag']
    ) => {
        if (!property.tags) return false;

        return property.tags.some((tag) => {
            if (tagToCheck.iri !== tag.iri) return false;
            if (tagToCheck.value !== tag.value) return false;

            return true;
        });
    };

    /**
     * Given a property object updates a given tag to it's new value.
     * @param {definitions["PropertyValue"]} property - A pointer to a property value inside the classData reactive object
     * @param {definitions["Tag"]} previousTag - The tags we are modifying
     * @param {definitions["Tag"]} newTag - The updated tag
     */
    const updatePropertyValueTags = (
        property: components['schemas']['PropertyValue'],
        previousTag: components['schemas']['Tag'],
        newTag: components['schemas']['Tag']
    ) => {
        const propertyTags = property.tags;

        propertyTags &&
            propertyTags.forEach((propertyTag) => {
                if (newTag.type !== propertyTag.type) return;
                if (previousTag.value !== propertyTag.value) return;
                propertyTag.value = newTag.value;
            });
    };

    /**
     * Adds a tag to a given property value, which is a pointer to a property inside the classData global object.
     * @param {definitions["PropertyValue"]} property
     * @param {definitions["Tag"]} newTag
     */
    const addTagToPropertyValueTags = (
        property: components['schemas']['PropertyValue'],
        newTag: components['schemas']['Tag']
    ) => {
        if (propertyValueHasTag(property, newTag)) return;

        if (!property.tags) {
            set(property, 'tags', []);
        }

        const propertyTags = property.tags as components['schemas']['Tag'][];

        propertyTags.push(newTag);
    };

    /**
     * Retrieves the edited annotation properties and returns them as an array of iris..
     * @type {ComputedRef<string[]>}
     */
    const getEditedAnnotationProperties = computed(() => {
        const classData = useClassView().getClassData.value;

        if (!classData) return [];
        if (!classData.editState) return [];

        const annotationPropertiesIris =
            useOntology().getCurrentAnnotationPropertiesIris.value;
        const editedPropertiesIris = Object.keys(classData.editState);
        const editedAnnotationPropertiesIris = intersection(
            annotationPropertiesIris,
            editedPropertiesIris
        );

        return editedAnnotationPropertiesIris;
    });

    /**
     * Reactively sets all the properties of a propertyValue to the of another give propertyValue.
     * @param {definitions["PropertyValueVM"]} targetProperty
     * @param {definitions["PropertyValueVM"]} baseProperty
     */
    const changePropertyValue = (
        targetProperty: components['schemas']['PropertyValueVM'],
        baseProperty: components['schemas']['PropertyValueVM']
    ) => {
        Object.entries(targetProperty).forEach(([key, value]) => {
            const propertyKey = key as Keys<
                components['schemas']['PropertyValueVM']
            >;

            set(targetProperty, propertyKey, baseProperty[propertyKey]);
        });
    };

    /**
     * Rollbacks e given log collection of ClassSnapshots.
     * @param {Record<string, ClassSnapshot> | undefined} logs
     * @param {boolean} resetData
     */
    const rollbackLogs = (
        logs: Record<string, ClassSnapshot> | undefined,
        resetData = true
    ) => {
        if (!logs) return;

        forOwn(logs, (classSnapshot) => {
            if (resetData) {
                Object.keys(classSnapshot.previous).forEach((propertyIri) => {
                    const origValue = classSnapshot.previous[
                        propertyIri
                    ] as components['schemas']['PropertyValueVM'];
                    const propertyValues = classSnapshot.live.propertyValues;
                    propertyValues &&
                        propertyValues.forEach((property) => {
                            changePropertyValue(property, origValue);
                        });
                });
            }

            // Resets the edit progress indicator.
            classSnapshot.live.editState = {};
        });
    };

    /**
     * Rolls back current edits.
     * @param {boolean} resetData
     */
    const rollbackEdits = () => {
        const currentPrimaryLabel =
            useClassView().getClassData.value?.primaryLabel;

        usePropertyList().resetPropertyList();
        useClassView().resetClassData();

        const originalPrimaryLabel =
            useClassView().getClassData.value?.primaryLabel;

        if (currentPrimaryLabel && originalPrimaryLabel) {
            useObservers().notifyObservers('PRIMARY_LABEL_UPDATED', {
                oldValue: currentPrimaryLabel,
                newValue: originalPrimaryLabel,
            });
        }

        resetTagsChanges();
    };

    /**
     * Roll backs current errors.
     * @param {boolean} resetData
     */
    const rollbackErrors = (resetData = true) => {
        const logs = editErrors.value as Record<string, ClassSnapshot>;

        rollbackLogs(logs, resetData);
    };

    /**
     * Roll backs all logs, both edits and errors.
     * @param {boolean} resetData
     */
    const rollback = (resetData = true) => {
        rollbackEdits();
        rollbackErrors(resetData);
    };

    /**
     * Modifies an existing label.
     * @param {string} newValue
     * @param {boolean} returnAxiosPromise
     * @return {Promise<AxiosResponse<EditLabelsResponse> | EditLabelsResponse>}
     */
    const modifyLabel = (newValue: string) => {
        const classId = useClassView().getClassElasticSearchId.value;
        const ontologyId = useClassView().getClassOntologyId.value;

        if (!classId || !ontologyId) return;

        const bodyParams = {
            newValue: newValue,
            transactionId: useEdits().getTransactionId.value,
        };

        return editLabels({ classId, ontologyId }, bodyParams);
    };

    const resetChangesMadeCount = () => {
        changesMade.value = 0;
    };

    const countClassChanges = async () => {
        const transactionId = getTransactionId.value;
        let count = 0;
        if (!transactionId) return count;
        const editedClasses = await getSuggestionsForTransaction({
            transactionId,
        });

        const promises = editedClasses.map((editData) => {
            if (
                typeof editData.lastOntologyEdit?.id !== 'undefined' &&
                typeof editData.lastOntologyEdit.ontologyId !== 'undefined'
            ) {
                return compareEditWithCurrent({
                    editId: editData.lastOntologyEdit.id,
                    ontologyId: editData.lastOntologyEdit.ontologyId,
                });
            }
        });

        const resolvedClassEdits = await Promise.all(promises);

        for (const edit of resolvedClassEdits) {
            if (
                typeof edit !== 'undefined' &&
                (edit.propertyValueChanges?.length || edit.changesArray?.length)
            ) {
                count++;
                if (typeof edit.ontologyClassId === 'undefined') continue;

                if (activeEdits.value[edit.ontologyClassId]) continue;
                const name = edit.ontologyClassId;
                const record: Record<
                    string,
                    components['schemas']['OntologySummaryVM']
                > = {};

                if (
                    !edit.comparedWith?.primaryID ||
                    !edit.comparedWith?.primaryLabel
                ) {
                    const obj = {
                        id: '',
                        primaryID: '',
                        primaryLabel: '',
                        sourceUniqueID: '',
                    } as Record<string, string>;

                    edit.changesArray?.forEach((change) => {
                        if (!change.fieldName || !change.value) return;
                        const fieldName = change.fieldName as string;
                        obj[fieldName] = change.value;
                    });

                    activeEdits.value = Object.assign(activeEdits.value, {
                        ...activeEdits.value,
                        obj,
                    });
                } else {
                    record[name] = { ...edit.comparedWith };
                    activeEdits.value = Object.assign(activeEdits.value, {
                        ...activeEdits.value,
                        ...record,
                    });
                }
            }
        }

        changesMade.value = count;
        return count;
    };

    const createReverseMappings = async (
        ontologyID: string,
        message: string,
        createReverseMappings: boolean
    ) => {
        let validationChecksum = useValidation().getChecksum();
        if (!validationChecksum) validationChecksum = '';
        return await commitSuggestion(
            { ontologyId: ontologyID, transactionId: getTransactionId.value },
            { message },
            { createReverseMappings, validationChecksum }
        );
    };

    const canProceed = async () => {};

    return {
        activeEdits,
        getClassesWithEdits,
        getCurrentTransaction,
        getEditIsActive,
        getEditChanges,
        getTagChanges,
        getEditErrors,
        getClassChanges,
        getTransactionId,
        getRelationsHaveChanged,
        getNonCancellingEdits,
        getNewClasses,
        getChangedClasses,
        getObsoleteClasses,
        getEditedAnnotationProperties,
        hasTagChanges,
        countEditedClasses,
        getClassesWithTagsEditsIds,
        getClassesWithTagEdits,
        getAddedClassesIds,
        getRemovedClassesIds,
        getIsSuggestionMode,
        isNewClass,
        isObsoleteClass,
        getIsRecoveryMode,
        setIsSuggestionMode,
        setIsRecoveryMode,
        getListOfClassChanges,
        getEditedClassesIds,
        isClassNew,
        setListOfClassChanges,
        setClassesWithEdits,
        setCurrentTransaction,
        refreshCurrentTransaction,
        getCurrentClassState,
        pushListOfClassChanges,
        trackChanges,
        trackTagsChanges,
        trackState,
        classHasEdits,
        setEditChanges,
        setEditErrors,
        setEditIsActive,
        setTransactionId,
        setRelationsHaveChanged,
        resetTagsChanges,
        addTagToPropertyValueTags,
        updatePropertyValueTags,
        modifyLabel,
        getAddedTagsByPropertyIriAndValue,
        getRemovedTagsByPropertyIriAndValue,
        propertyHasTagEdits,
        rollback,
        getMergeEditChanges,
        setMergeEditChanges,
        pushMergeEditChange,
        hasMergeEditChanges,
        countClassChanges,
        resetChangesMadeCount,
        changesMade,
        createReverseMappings,
        canProceed,
        block,
        setIsSlowModeEnabled,
        getIsSlowModeEnabled,
    };
};
