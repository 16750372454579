import { Observer } from '@/ts';

/**
 * Updates the app version on the footer.
 * @param {Record<string, unknown>} args
 * @return {boolean}
 */
export const updateAppVersion: Observer = (args) => {
    const { updateVersion } = args as unknown as { updateVersion: () => void };

    updateVersion();

    return true;
};
