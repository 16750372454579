import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type LoadOntologyFromMetadataPathParams = operations["loadOntologyFromMetadataUsingPOST"]["parameters"]["path"];
type LoadOntologyFromMetadataQueryParams = operations["loadOntologyFromMetadataUsingPOST"]["parameters"]["query"];
type LoadOntologyFromMetadataResponse = operations["loadOntologyFromMetadataUsingPOST"]["responses"]["202"]['content']["*/*"];

/**
 * Load an ontology using metadata stored in CENtree. If you want to load the latest version from S3 version should be equal to latest. If you want to load the version that was previously loaded, version should be equal to latestLoadedVersion
 * @param {string} ontologyId
 * @param {string} version
 * @param {string} fileUrl
 * @param {string} loadType
 * @return {LoadOntologyFromMetadataResponse}
*/
export const loadOntologyFromMetadata = async (
    { ontologyId }: LoadOntologyFromMetadataPathParams,
    queryParams?: LoadOntologyFromMetadataQueryParams,
) => {
    const url = `/ontology/${ontologyId}/load`;
    const response = await Axios.post(url, { params: queryParams });

    return response && response.data as LoadOntologyFromMetadataResponse;
}
