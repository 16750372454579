/* global sessionStorage */
/* global localStorage */

import Vue from 'vue';
import Vuex from 'vuex';
import { pick } from 'lodash';

import auth from './auth';
import edits from './edits';
import search from './search';
import clipboard from './clipboard';
import notifications from './notifications';
import suggestions from './suggestions';
import management from './management';

import * as MutationTypes from './mutation-types';

Vue.use(Vuex);

export const state = {
    // Maps storage to login mode (whether the user is remembered)
    storageMap: { true: localStorage, false: sessionStorage },

    // Remembers the header's and footer's height and serves as an indicator of their rendering.
    headerHeight: 0,
    footerHeight: 0,

    // If true, API or app errors are not notified globally
    isPreventGlobalError: false,

    // Unchanged document title text (eg: without a count badge)
    currTitle: '',

    // Image URL for the standard favicon (eg: without a count badge)
    faviconUrl: require('../assets/favicon.png'),

    // Marks when the app can carry on with the normal rendering of views.
    resolveStart: () => {},
    startPromise: null,
};

const getters = {
    mutationTypes: () => MutationTypes,
    startPromise: (state) => state.startPromise,
};

const mutations = {
    [MutationTypes.APP_INIT](state) {
        state.startPromise = new Promise((resolve, reject) => {
            state.resolveStart = resolve;
        });
    },

    [MutationTypes.APP_STARTED](state) {
        state.resolveStart();
    },
};

const actions = {
    /**
     * Initialises everything to do with the app's state.
     */
    appInit({ commit, dispatch }) {
        commit(MutationTypes.APP_INIT);
        dispatch('initToken');
        dispatch('setNormalFavicon');
    },

    /**
     * Signals the app's readiness to be interacted with (eg: routing).
     */
    appStarted({ commit }) {
        commit(MutationTypes.APP_STARTED);
    },

    /**
     * Reverts the favicon to its normal, initial state and initialises the app start's promise.
     * @param {Object} context - Context object for this store.
     */
    setNormalFavicon({ state }) {
        const faviconEl = document.getElementById('favicon');
        faviconEl.type = 'image/png';
        faviconEl.href = state.faviconUrl;
    },

    /**
     * Modifies the current favicon to show the typical red badge for pending actions
     * @see {@link https://medium.com/@alperen.talaslioglu/building-dynamic-favicon-with-javascript-223ad7999661}
     */
    setBadgedFavicon() {
        const faviconEl = document.getElementById('favicon');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const imgEl = document.createElement('img');

        imgEl.src = faviconEl.href;
        imgEl.onload = () => {
            const faviconSize = imgEl.width;

            canvas.width = faviconSize;
            canvas.height = faviconSize;

            // Draw Original Favicon as Background
            context.drawImage(imgEl, 0, 0, faviconSize, faviconSize);

            // Draw red badge
            context.beginPath();
            context.arc(
                canvas.width - faviconSize / 4,
                faviconSize / 4,
                faviconSize / 4,
                0,
                2 * Math.PI,
                false
            );
            context.fillStyle = '#ff0000';
            context.fill();

            faviconEl.href = canvas.toDataURL('image/png');
        };
    },
};

const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        auth,
        edits,
        search,
        clipboard,
        notifications,
        suggestions,
        management,
    },
});

// Preserves and manages the state object of a pre-defined subset of modules as a JSON string using browser storage.
// Empty arrays equate to all the properties inside a module.
const preserveModules = {
    search: [],
    auth: [
        'hasEdited',
        'hasViewedClass',
        'hasRemoved',
        'hasReviewed',
        'hasRootMax',
    ],
    notifications: ['lastRead'],
};
state.unsubscribe = store.subscribe((mutation, state) => {
    const storage = state.storageMap[state.auth.isRemember];
    const otherStorage = state.storageMap[!state.auth.isRemember];

    // Initialises states from stored counterparts
    if (mutation.type === MutationTypes.AUTH_INIT) {
        Object.keys(state).forEach((moduleName) => {
            const savedModule = storage.getItem(
                process.env.VUE_APP_NAME + '_' + moduleName
            );

            if (savedModule && preserveModules.hasOwnProperty(moduleName)) {
                Object.assign(state[moduleName], JSON.parse(savedModule));
            }

            // Remove any properties left when logging in with a different "remember me" setting
            otherStorage.removeItem(
                process.env.VUE_APP_NAME + '_' + moduleName
            );
        });

        // Local updates should only be performed while logged in
    } else if (state.auth.status === 'loggedin') {
        Object.keys(state).forEach((moduleName) => {
            if (preserveModules.hasOwnProperty(moduleName)) {
                let copiedModule = state[moduleName];

                if (preserveModules[moduleName].length) {
                    copiedModule = pick(
                        copiedModule,
                        preserveModules[moduleName]
                    );
                }
                storage.setItem(
                    process.env.VUE_APP_NAME + '_' + moduleName,
                    JSON.stringify(copiedModule)
                );
            }
        });
    }
});

export default store;
