import { List, xor, intersection } from 'lodash';

/**
 * Works out the list of symmetric differences between two versions of a given value.
 * The result should therefore be independent of whether there has been an addition, removal of items.
 * NOTE: if replacements have taken place, the replaced items are appended to the list as removed.
 * @param {List<unknown|null|undefined>|string} newValue - Most recent version of the value.
 * @param {List<unknown|null|undefined>|string} oldValue - Immediately earlier version of the value.
 * @return {unknown[]}
 */
export const propValuesDifference = (
    newValue: List<unknown | null | undefined> | string,
    oldValue: List<unknown | null | undefined> | string
) => {
    if (typeof newValue !== 'string' || typeof oldValue !== 'string') {
        return xor(newValue, oldValue);
    }

    if (newValue === oldValue) return [];

    return [newValue, oldValue];
};
