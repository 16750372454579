/**
 * Applies selected search filters by dispatching the `exact` and `obsolete` actions.
 * @param {Object} to
 * @param {Object} store
 * @param {Function} next
 */
const applySearchFilters = ({ to, store, next }) => {
    if (to.params.hasOwnProperty('exact')) {
        store.dispatch('exact', to.params.exact);
    }

    if (to.params.hasOwnProperty('obsolete')) {
        store.dispatch('obsolete', to.params.obsolete);
    }

    next();
};

export default applySearchFilters;
