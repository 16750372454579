<template>
    <span class="text-actions d-inline-block">
        <copy-button
            class="ml-2 action-btn secondary-btn"
            :targetName="copyTargetName"
            :targetEl="copyTargetEl"
            :targetText="copyFullText"
            @mouseenter.native="highlightEl(copyTargetEl(), true)"
            @mouseleave.native="highlightEl(copyTargetEl(), false)" />

        <!-- Optional: search by default -->
        <slot>
            <span
                v-if="isSearchActions"
                @mouseenter="highlightEl(searchTargetEl(), true)"
                @mouseleave="highlightEl(searchTargetEl(), false)">
                <font-awesome-icon
                    class="action-btn secondary-btn"
                    icon="external-link-square-alt"
                    flip="horizontal"
                    v-b-tooltip.hover="{
                        title: 'Replace the current search',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @mousedown.stop.prevent="onSearchReplace"
                    @mouseup.stop.prevent="() => {}"
                    @click.stop.prevent="() => {}" />
                <font-awesome-icon
                    class="action-btn route-btn"
                    icon="search"
                    v-b-tooltip.hover="{
                        title: `Search for this ${searchTargetName}`,
                        delay: { show: showDelay, hide: 0 },
                    }"
                    @click.stop.prevent="onSearch" />
            </span>
        </slot>

        <!-- External link button -->
        <font-awesome-icon
            v-if="url"
            class="link-btn action-btn route-btn"
            v-b-tooltip.hover="{
                title: isDownload ? 'Download this file' : 'Open the website',
                delay: { show: showDelay, hide: 0 },
            }"
            :icon="isDownload ? 'download' : 'external-link-alt'"
            @click.prevent.stop="onLink" />
        <slot name="append"></slot>
    </span>
</template>

<script>
import CopyButton from '@/components/ui/CopyButton';

export default {
    name: 'TextActions',
    components: { CopyButton },
    props: {
        copyTargetName: {
            type: String,
            required: true,
        },

        copyFullText: {
            type: String,
        },

        copyTargetEl: {
            type: Function,
            required: true,
        },

        url: {
            type: String,
            default: '',
        },

        isDownload: {
            type: Boolean,
            default: false,
        },

        isSearchActions: {
            type: Boolean,
            default: true,
        },

        searchTargetName: {
            type: String,
        },

        searchTargetEl: {
            type: Function,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    computed: {
        copyTargetText: function () {
            return this.getTargetText(this.copyTargetEl());
        },

        searchTargetText: function () {
            let value = this.getTargetText(this.searchTargetEl());
            if (value === '') value = this.searchTargetEl();
            return value;
        },
    },

    methods: {
        /**
         * Toggles the highlighting effect applied to a given element.
         * @param {Object} highlightEl - DOM element object for the child element to be highlighted.
         * @param {boolean} isHighlight - False if the highlight effect is being removed.
         */
        highlightEl(highlightEl, isHighlight) {
            if (highlightEl && highlightEl.classList) {
                highlightEl.classList.toggle('highlighted', isHighlight);
            }
        },

        /**
         * Get the text contents for a target element.
         * @param {Object} highlightEl - DOM element object for the target element.
         */
        getTargetText(targetEl) {
            if (targetEl && targetEl.textContent) {
                return targetEl.textContent.trim();
            } else {
                return '';
            }
        },

        onLink() {
            let tab;

            if (this.isDownload) {
                window.location.href = this.url;
            } else {
                tab = window.open(this.url, '_blank');
                tab.focus();
            }
        },

        /**
         * Re-renders the view with the search results corresponding to the current item's primary label.
         * NOTE: In some cases, the rendering happens without a mouseleave event. Hence the explicit call to its handler here.
         */
        onSearch() {
            this.highlightEl(this.searchTargetEl(), false);
            this.$router.push({
                name: 'search-query',
                params: { query: this.searchTargetText },
            });
        },

        onSearchReplace() {
            this.$eventBus.$emit('typeahead:set', this.searchTargetText);
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.text-actions {
    user-select: none;
}

.action-btn {
    margin: 0 0.1rem;
    color: $secondary;
    opacity: 0.5;
    cursor: pointer;

    &:hover:not(.termite-btn.outside-edit) {
        opacity: 1;
    }
}

.route-btn:hover {
    color: $link-hover-color;
}

.link-btn {
    margin-bottom: 0.1rem;
    font-size: 0.95em;
}
</style>
