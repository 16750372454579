<template>
    <span class="property-value d-flex flex-row-reverse align-items-center">
        <!-- Remove row button -->
        <font-awesome-icon
            v-if="isEditable && propName !== 'labels' && !isEditHistory"
            class="stacked-remove ml-2"
            icon="times-circle"
            :id="this.buildTestClass('delete--propertyValue')"
            @click="$emit('value:delete', value, property)" />

        <!-- Relationship entries -->
        <template v-if="isClass && !isUnresolvedID">
            <class-name
                ref="classWrapper"
                class="stacked-item-name flex-grow-1"
                :isNew="isClassNew"
                :isObsolete="isClassObsolete"
                :ontologyID="propOntoID"
                :primaryID="value"
                :classesPageSize="classesPageSize"
                :isLink="!isNoClassLink && !isClassNew && !isClassObsolete"
                :isGlobalScope="isGlobalClassScope"
                :initClass="initClass"
                @class:unresolved="isUnresolvedID = true"
                v-on="$listeners"
                :type="isInstance ? 'instance' : 'class'"
                @class:resolved="$emit('class:resolved', $event)">
                <text-actions
                    slot="actions"
                    :copyTargetName="humanReadable('shortFormIDs')"
                    :copyTargetEl="
                        () =>
                            $refs.classWrapper &&
                            $refs.classWrapper.$refs.shortID[0]
                    "
                    :searchTargetName="humanReadable('primaryLabel')"
                    :searchTargetEl="
                        () =>
                            $refs.classWrapper &&
                            $refs.classWrapper.$refs.termName[0]
                    "
                    @mouseenter.native.stop="
                        $refs.classWrapper.$el.classList.add(
                            'class-actions-hovered'
                        )
                    "
                    @mouseleave.native.stop="
                        $refs.classWrapper.$el.classList.remove(
                            'class-actions-hovered'
                        )
                    " />
            </class-name>

            <child-icon
                v-if="isInitClass && isClassJump"
                class="class-jump flex-shrink-0 mr-2"
                v-b-tooltip.hover="{
                    title: 'Show this class on the tree',
                    delay: { show: showDelay, hide: 0 },
                }"
                @click.native.prevent.stop="
                    $emit('class:jump', initClass.id)
                " />

            <strong
                v-else
                class="flex-shrink-0 stacked-item-bullet"
                v-html="bulletCharacter" />
        </template>

        <!-- Instance entries -->
        <template v-else-if="isInstance">
            <span
                class="flex-grow-1 d-inline-block stacked-item-name tags-header"
                :class="{ 'word-break': isLink }">
                <font-awesome-icon
                    class="tags-icon"
                    :icon="headerIcon"
                    :class="headerIconClass"
                    @click="toggleCollapsed" />

                <editable-text
                    @click.native="onClickInstance(value.primaryID)"
                    class="instance-value"
                    ref="editText"
                    :contentEditable="false"
                    :text="value.primaryLabel"
                    :isNormalise="true"
                    :maxLength="maxLength"
                    :placeholder="
                        $pluralize.singular(
                            upperFirst(humanReadable(propName))
                        ) + '...'
                    " />
                <text-actions
                    :copyTargetName="
                        $pluralize.singular(humanReadable(propName))
                    "
                    :copyTargetEl="() => $refs.editText.containerEl"
                    :copyFullText="value.primaryID"
                    :searchTargetName="
                        $pluralize.singular(humanReadable(propName))
                    "
                    :searchTargetEl="() => $refs.editText.containerEl"
                    :isSearchActions="isTextSearch" />

                <b-collapse
                    v-if="isSchemaVersion2"
                    :visible="showTags"
                    :isEditable="isEditable">
                    <property-value-tags
                        :tags="tagsWithValue"
                        :isEditable="isEditable"
                        :property="property"
                        :isSavingDialogue="isSavingDialogue"
                        :propertyValue="value.primaryLabel" />
                </b-collapse>
            </span>
        </template>

        <!-- Other entries -->
        <template v-else>
            <span
                class="flex-grow-1 d-inline-block stacked-item-name tags-header"
                :class="{ 'word-break': isLink }">
                <font-awesome-icon
                    class="tags-icon"
                    :icon="headerIcon"
                    :class="headerIconClass"
                    @click="toggleCollapsed" />

                <editable-text
                    ref="editText"
                    :isSaving="true"
                    :isSuccess="isEditSuccess && !isUnchanged"
                    :isError="isEditError && !isUnchanged"
                    :contentEditable="isEditable"
                    :text="value"
                    :isNormalise="true"
                    :maxLength="maxLength"
                    :placeholder="
                        $pluralize.singular(
                            upperFirst(humanReadable(propName))
                        ) + '...'
                    "
                    @valueUpdated="handleValueUpdated($event, property)" />
                <text-actions
                    :copyTargetName="
                        $pluralize.singular(humanReadable(propName))
                    "
                    :copyTargetEl="() => $refs.editText.containerEl"
                    :copyFullText="value"
                    :url="isValidUrl"
                    :searchTargetName="
                        $pluralize.singular(humanReadable(propName))
                    "
                    :searchTargetEl="() => $refs.editText.containerEl"
                    :isSearchActions="isTextSearch" />

                <b-collapse
                    v-if="isSchemaVersion2"
                    :visible="showTags"
                    :isEditable="isEditable">
                    <property-value-tags
                        :tags="tagsWithValue"
                        :isEditable="isEditable"
                        :property="property"
                        :isSavingDialogue="isSavingDialogue"
                        :propertyValue="value" />
                </b-collapse>
            </span>
        </template>
    </span>
</template>

<script>
import { findIndex } from 'lodash';

import ClassName from '@/components/ui/ClassName';
import ChildIcon from '@/components/ui/ChildIcon';
import TextActions from '@/components/ui/TextActions';
import EditableText from '@/components/ui/EditableText';
import PropertyValueTags from './PropertyValueTags';
import { useClassView, useEdits } from '@/compositions';
import router from '@/router';

export default {
    name: 'PropertyValue',
    components: {
        ClassName,
        ChildIcon,
        TextActions,
        EditableText,
        PropertyValueTags,
    },

    props: {
        isEditHistory: {
            type: Boolean,
            default: false,
        },
        property: {
            type: Object,
        },
        // Primary ID of the class whose property is being probed
        propPrimID: {
            type: String,
            required: true,
        },

        // Ontology of the class whose property is being probed
        propOntoID: {
            type: String,
        },

        propName: {
            type: String,
            required: true,
        },

        value: {
            type: [String, Object],
            default: '',
        },

        // Character length limit for truncated values
        maxLength: {
            type: Number,
            default: 999999,
        },

        // Page size for class name results when resolving globally
        classesPageSize: {
            type: Number,
            default: 7,
        },

        bulletCharacter: {
            type: String,
            default: '&bull;',
        },

        // Whether name resolution for a class should be across ontologies
        isGlobalClassScope: {
            type: Boolean,
            default: false,
        },

        // Initial non-empty class data to bypass name resolution.
        initClass: {
            required: true,
        },

        // The property value could be a class (but not necessarily)
        isClass: {
            type: Boolean,
            default: false,
        },

        // True if the display of this class externally can be forced
        isClassJump: {
            type: Boolean,
            default: true,
        },

        // The class, irrespective of its state, is not to be actionable
        isNoClassLink: {
            type: Boolean,
            default: false,
        },

        isEditSaving: {
            type: Boolean,
            default: false,
        },

        isEditSuccess: {
            type: Boolean,
            default: false,
        },

        isEditError: {
            type: Boolean | String,
            default: false,
        },

        isUnchanged: {
            type: Boolean,
            default: false,
        },

        isTextSearch: {
            type: Boolean,
            default: true,
        },

        newClasses: {
            type: Array,
        },

        obsoleteClasses: {
            type: Array,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
        isSavingDialogue: {
            type: Boolean,
            default: false,
        },
        tags: {
            type: Array,
            required: false,
        },
        isInstance: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // True if the property value represents a class with unknown primary ID
            isUnresolvedID: false,
            collapsed: false,
        };
    },

    computed: {
        isEditable() {
            return useEdits().getEditIsActive.value;
        },
        isLink() {
            return this.isUrl(this.value);
        },

        isClassNew() {
            if (typeof this.value !== 'string') return;
            return findIndex(this.newClasses, { primaryID: this.value }) !== -1;
        },

        isClassObsolete() {
            if (typeof this.value !== 'string') return;
            return (
                findIndex(this.obsoleteClasses, { primaryID: this.value }) !==
                -1
            );
        },
        isValidUrl() {
            return this.isLink ? this.value : '';
        },

        isInitClass() {
            return this.initClass && this.initClass.hasOwnProperty('id');
        },

        /**
         * Determines whether we should show tags for the selected property value.
         * @return {boolean}
         */
        showTags() {
            if (useClassView().isSchemaVersion1.value) return false;

            return !this.collapsed;
        },

        /**
         * Determines which icon we should use for the property value header (chevron if it shows tags)
         * @return {string}
         */
        headerIcon() {
            return this.tagsWithValue && this.tagsWithValue.length
                ? 'chevron-right'
                : 'minus';
        },

        /**
         * Determines the class of a property value header based on the state.
         * @return {'tags-icon--collapsed'|'tags-icon--expanded'}
         */
        headerIconClass() {
            if (!this.tagsWithValue || !this.tagsWithValue.length)
                return 'tags-icon--no-children';
            return this.collapsed
                ? 'tags-icon--collapsed'
                : 'tags-icon--expanded';
        },

        /**
         * Retrieves tags that have a value.
         * @return {*[]}
         */
        tagsWithValue() {
            if (!this.tags || !this.tags.length) return [];
            return this.tags.filter((tag) => {
                return !!tag.value;
            });
        },
    },

    watch: {
        propPrimID: 'reset',
    },

    beforeCreate: function () {
        this.$options.components.ClassName = require('./ClassName.vue').default;
    },

    methods: {
        /**
         * Handles the valueUpdated event emitted by the EditableText component.
         * @param {Event} event
         * @param {definitions["CustomProperty"]} property
         */
        handleValueUpdated(event, property) {
            const text = this.getText(event);

            if (text === null) return;

            this.$emit('value:update', text, property);
        },

        /**
         * Retrieves the text out of the valueUpdated event target.
         * @param {Event} event
         */
        getText(event) {
            const target = event.target;

            if (target.classList.contains('text-container'))
                return target.innerText;

            const textContainer = target.querySelector('.text-container');

            return textContainer && textContainer.innerText;
        },
        reset() {
            this.unresolvedID = false;
        },

        /**
         * Toggles the state (collapsed/expanded) of a property value header.
         */
        toggleCollapsed() {
            if (!this.tags || !this.tags.length) return;

            this.collapsed = !this.collapsed;
        },

        onClickInstance(instance) {
            router.push({
                name: 'instance',
                params: {
                    ontologyID: this.propOntoID,
                    primaryID: instance,
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.property-value {
    .class-jump {
        color: $secondary;
        opacity: 0.7;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }

    .stacked-item-bullet {
        margin-right: 0.5rem;
        font-size: 1.5rem;
        line-height: 1rem;
        width: 0.95rem;
        text-align: center;
        color: $text-muted;
    }

    .tags-icon {
        transition: transform 0.3s ease-in-out;
        margin-right: 10px;
        cursor: pointer;

        &--expanded {
            transform: rotate(90deg);
        }

        &--no-children {
            cursor: default;
        }
    }

    .stacked-item-name {
        &:not(:hover) .text-actions {
            opacity: 0.3;
        }

        &.class-actions-hovered ::v-deep .name-label {
            color: $secondary;
        }
    }

    .stacked-remove {
        color: $info;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            color: $danger !important;
            opacity: 1;
        }

        &:hover + .stacked-item-name ::v-deep * {
            color: $danger;
        }
    }

    .instance-value {
        color: #6533cb;
        cursor: pointer;
    }
}
</style>
