import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import middlewarePipeline from './middlewarePipeline';

import applyModes from '../middlewares/applyModes';
import requiresAuth from '../middlewares/requiresAuth';
import applySearchFilters from '../middlewares/applySearchFilters';
import performLogout from '../middlewares/perfomLogout';
import requiresGuest from '../middlewares/requiresGuest';
import authenticateUser from '../middlewares/authenticateUser';
import { getRouterHash } from '@/utils';
import refreshUserToken from '@/middlewares/refreshUserToken';
import { useEdits } from '@/compositions';

Vue.use(Router);

const globalMiddleware = [refreshUserToken, authenticateUser, applyModes];

// SOURCE: https://scotch.io/tutorials/vue-authentication-and-route-handling-using-vue-router
const router = new Router({
    mode: getRouterHash() ? 'hash' : 'history',
    routes: [
        {
            path: '',
            redirect: '/search',
        },
        {
            path: '/',
            redirect: '/search',
        },
        {
            path: '/login',
            component: () =>
                import(
                    /* webpackChunkName: "auth-main" */ '@/pages/AuthMain.vue'
                ),
            children: [
                {
                    name: 'login',
                    path: '',
                    component: () =>
                        import(
                            /* webpackChunkName: "auth-login" */ '@/pages/AuthMain/AuthLogin.vue'
                        ),
                    meta: {
                        middleware: [requiresGuest],
                    },
                },
                {
                    name: 'reset',
                    path: 'reset',
                    alias: '/reset',
                    meta: {
                        middleware: [requiresGuest],
                    },

                    component: () =>
                        import(
                            /* webpackChunkName: "auth-reset" */ '@/pages/AuthMain/AuthReset.vue'
                        ),
                },
                {
                    name: 'register',
                    path: 'register',
                    alias: '/signup',
                    meta: {
                        middleware: [requiresGuest],
                    },

                    component: () =>
                        import(
                            /* webpackChunkName: "auth-reg" */ '@/pages/AuthMain/AuthReg.vue'
                        ),
                },
            ],
        },

        // AUTH DATA CLEARANCE: flushes all auth data and shows login with subsequent redirection if applicable.
        // NOTE: If the redirecting route is the same as the current one, nothing will happen unless login is forced
        {
            path: '/logout',
            name: 'logout',
            meta: {
                middleware: [requiresAuth, performLogout],
            },
        },

        // PAGINATED SEARCH RESULTS LIST: remembers exact/obsolete state and page number with page 1 as default.
        {
            name: 'search',
            path: '/search',
            component: () =>
                import(
                    /* webpackChunkName: "search-list" */ '@/pages/SearchList.vue'
                ),
            meta: {
                middleware: [requiresAuth],
            },
        },
        {
            name: 'search-query',
            path: '/search/:query',
            redirect: (to) => ({
                name: 'search-page-n',
                params: {
                    query: to.params.query,
                    number: 1,
                    obsolete: false,
                    exact: false,
                },
            }),
        },
        {
            name: 'search-exact',
            path: '/search/:query/exact',
            redirect: (to) => ({
                name: 'search-page-n',
                params: {
                    query: to.params.query,
                    number: 1,
                    obsolete: false,
                    exact: true,
                },
            }),
        },
        {
            name: 'search-obsolete',
            path: '/search/:query/obsolete',
            redirect: (to) => ({
                name: 'search-page-n',
                params: {
                    query: to.params.query,
                    number: 1,
                    obsolete: true,
                    exact: false,
                },
            }),
        },
        {
            name: 'search-obsolete-exact',
            path: '/search/:query/obsolete/exact',
            redirect: (to) => ({
                name: 'search-page-n',
                params: {
                    query: to.params.query,
                    number: 1,
                    obsolete: true,
                    exact: true,
                },
            }),
        },
        {
            name: 'search-page',
            path: '/search/:query/page',
            redirect: '/search/:query/page/1',
        },
        {
            name: 'search-page-n',
            path: '/search/:query/page/:number',
            component: () =>
                import(
                    /* webpackChunkName: "search-list" */ '@/pages/SearchList.vue'
                ),
            meta: {
                middleware: [requiresAuth, applySearchFilters],
            },
        },

        // PAGINATED ONTOLOGIES LIST: remembers page number with page 1 as default
        {
            name: 'ontologies-entry',
            path: '/ontologies',
            redirect: '/ontologies/page/1',
        },
        {
            name: 'ontologies-page',
            path: '/ontologies/page',
            redirect: '/ontologies/page/1',
        },
        {
            name: 'ontologies',
            path: '/ontologies/page/:number',
            component: () =>
                import(
                    /* webpackChunkName: "onto-list" */ '@/pages/OntoList.vue'
                ),
            meta: {
                middleware: [requiresAuth],
            },
        },

        // CLASS VIEW: reused for single ontology view when no primary ID specified
        {
            name: 'ontology',
            path: '/ontologies/:ontologyID',
            redirect: '/ontologies/:ontologyID/classes',
        },
        {
            name: 'classes',
            path: '/ontologies/:ontologyID/classes',
            component: () =>
                import(
                    /* webpackChunkName: "class-main" */ '@/pages/ClassMain.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },
        {
            name: 'class-by-short-id',
            path: '/ontology/:ontologyID/:shortID',
            component: () =>
                import(
                    /* webpackChunkName: "class-main" */ '@/pages/ClassMain.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },
        {
            name: 'class',
            path: '/ontologies/:ontologyID/classes/:primaryID?',
            component: () =>
                import(
                    /* webpackChunkName: "class-main" */ '@/pages/ClassMain.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },

        //PROPERTIES
        {
            name: 'properties',
            path: '/ontologies/:ontologyID/properties',
            component: () =>
                import(
                    /* webpackChunkName: "property-main-wrapper" */ '@/pages/PropertyMainWrapper.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },
        {
            name: 'property',
            path: '/ontologies/:ontologyID/properties/:primaryID?',
            component: () =>
                import(
                    /* webpackChunkName: "property-main-wrapper" */ '@/pages/PropertyMainWrapper.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },

        //Instances
        {
            name: 'instances',
            path: '/ontologies/:ontologyID/instances',
            component: () =>
                import(
                    /* webpackChunkName: "property-main-wrapper" */ '@/pages/InstanceMainWrapper.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },
        {
            name: 'instance',
            path: '/ontologies/:ontologyID/instances/:primaryID?',
            component: () =>
                import(
                    /* webpackChunkName: "property-main-wrapper" */ '@/pages/InstanceMainWrapper.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },

        // SPARQL ENDPOINTs
        {
            name: 'sparql',
            path: '/sparql',
            component: () =>
                import(/* webpackChunkName: "sparql" */ '@/pages/Sparql.vue'),
            meta: {
                middleware: [requiresAuth],
            },
        },
        {
            name: 'sparql-ontology',
            path: '/sparql/:ontologyID',
            component: () =>
                import(/* webpackChunkName: "sparql" */ '@/pages/Sparql.vue'),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },

        // SUGGESTION REVIEW
        {
            name: 'suggestion',
            path: '/ontologies/:ontologyID/suggestions/:transactionID',
            component: () =>
                import(
                    /* webpackChunkName: "class-main" */ '@/pages/ClassMain.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },

        // RECOVERY REVIEW
        {
            name: 'recovery',
            path: '/ontologies/:ontologyID/recovery/:recoveryTransactionID',
            component: () =>
                import(
                    /* webpackChunkName: "class-main" */ '@/pages/ClassMain.vue'
                ),
            props: true,
            meta: {
                middleware: [requiresAuth],
            },
        },

        // ROUTE ERROR
        {
            name: 'error',
            path: '*',
            component: () =>
                import(
                    /* webpackChunkName: "error-main" */ '@/pages/ErrorMain.vue'
                ),
        },
    ],
});

router.beforeEach((to, from, next) => {
    // Waits until it is certain whether the user is logged in or out.
    store.getters.startPromise.then(() => {
        // Change the title of the page according to route
        // NOTE: Last matched route is normally the most relevant. Hence the reversing.
        // SOURCE: https://alligator.io/vuejs/vue-router-modify-head/
        const mostRelevantName = to.matched
            .slice()
            .reverse()
            .find((record) => record.name).name;
        if (mostRelevantName) {
            document.title = `${process.env.VUE_APP_NAME} | ${mostRelevantName}`;
            store.currTitle = document.title;
        }

        next();
    });
});

router.beforeEach((to, from, next) => {
    store.getters.startPromise.then(() => {
        if (!to.meta.middleware && !globalMiddleware) {
            return next();
        }

        const middleware = [...globalMiddleware, ...(to.meta.middleware || [])];

        const context = {
            to,
            from,
            next,
            store,
        };

        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1),
        });
    });
});

router.beforeEach(async (to, from, next) => {
    if (
        useEdits().getEditIsActive.value &&
        useEdits().countEditedClasses.value > 0
    ) {
        const res = await checkActiveEdits();
        if (res) next();
    } else {
        next();
    }
});

const checkActiveEdits = () => {
    return new Promise((resolve) => {
        router.app.$eventBus.$emit(
            'notification:show',
            'critical',
            'Tree editing is about to be aborted. As a consequence, all changes will be lost. Do you wish to continue?',
            'Recent changes',
            [
                {
                    text: 'Back to editing',
                    action: (toast) => {
                        router.app.$eventBus.$emit('notification:close', toast);
                        router.app.$nextTick(() => {
                            resolve(false);
                        });
                    },
                },
                {
                    text: 'Discard changes',
                    action: (toast) => {
                        router.app.$eventBus.$emit('notification:close', toast);
                        router.app.$nextTick(() => {
                            localStorage.removeItem('uncommittedTransaction');
                            useEdits().block.value = true;
                            useEdits().rollback();
                            // If this line is not here, then when cancelled out of the edit mode the merge changes will still be registered
                            if (useEdits().hasMergeEditChanges.value)
                                useEdits().setMergeEditChanges([]);
                            useEdits().resetChangesMadeCount();
                            useEdits().setEditIsActive(false);
                            useEdits().setIsRecoveryMode(false);
                            resolve(true);
                        });
                    },
                },
            ],
            'centerTop'
        );
    });
};

export default router;
