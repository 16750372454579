import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type TermitePropertiesResponse = operations["getTermitePropertiesUsingGET_1"]["responses"]["200"]['content']["*/*"];

/**
 * Get the list of TERMite switches
 * @return {TermitePropertiesResponse}
*/
export const getTermiteProperties = async () => {
    const url = `/ontology/termite/switches`;
    const response = await Axios.get(url);

    return response && response.data as TermitePropertiesResponse;
}
