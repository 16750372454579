var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"property-value d-flex flex-row-reverse align-items-center"},[(_vm.isEditable && _vm.propName !== 'labels' && !_vm.isEditHistory)?_c('font-awesome-icon',{staticClass:"stacked-remove ml-2",attrs:{"icon":"times-circle","id":this.buildTestClass('delete--propertyValue')},on:{"click":function($event){return _vm.$emit('value:delete', _vm.value, _vm.property)}}}):_vm._e(),(_vm.isClass && !_vm.isUnresolvedID)?[_c('class-name',_vm._g({ref:"classWrapper",staticClass:"stacked-item-name flex-grow-1",attrs:{"isNew":_vm.isClassNew,"isObsolete":_vm.isClassObsolete,"ontologyID":_vm.propOntoID,"primaryID":_vm.value,"classesPageSize":_vm.classesPageSize,"isLink":!_vm.isNoClassLink && !_vm.isClassNew && !_vm.isClassObsolete,"isGlobalScope":_vm.isGlobalClassScope,"initClass":_vm.initClass,"type":_vm.isInstance ? 'instance' : 'class'},on:{"class:unresolved":function($event){_vm.isUnresolvedID = true},"class:resolved":function($event){return _vm.$emit('class:resolved', $event)}}},_vm.$listeners),[_c('text-actions',{attrs:{"slot":"actions","copyTargetName":_vm.humanReadable('shortFormIDs'),"copyTargetEl":function () { return _vm.$refs.classWrapper &&
                        _vm.$refs.classWrapper.$refs.shortID[0]; },"searchTargetName":_vm.humanReadable('primaryLabel'),"searchTargetEl":function () { return _vm.$refs.classWrapper &&
                        _vm.$refs.classWrapper.$refs.termName[0]; }},nativeOn:{"mouseenter":function($event){$event.stopPropagation();return _vm.$refs.classWrapper.$el.classList.add(
                        'class-actions-hovered'
                    )},"mouseleave":function($event){$event.stopPropagation();return _vm.$refs.classWrapper.$el.classList.remove(
                        'class-actions-hovered'
                    )}},slot:"actions"})],1),(_vm.isInitClass && _vm.isClassJump)?_c('child-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: 'Show this class on the tree',
                delay: { show: _vm.showDelay, hide: 0 },
            }),expression:"{\n                title: 'Show this class on the tree',\n                delay: { show: showDelay, hide: 0 },\n            }",modifiers:{"hover":true}}],staticClass:"class-jump flex-shrink-0 mr-2",nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('class:jump', _vm.initClass.id)}}}):_c('strong',{staticClass:"flex-shrink-0 stacked-item-bullet",domProps:{"innerHTML":_vm._s(_vm.bulletCharacter)}})]:(_vm.isInstance)?[_c('span',{staticClass:"flex-grow-1 d-inline-block stacked-item-name tags-header",class:{ 'word-break': _vm.isLink }},[_c('font-awesome-icon',{staticClass:"tags-icon",class:_vm.headerIconClass,attrs:{"icon":_vm.headerIcon},on:{"click":_vm.toggleCollapsed}}),_c('editable-text',{ref:"editText",staticClass:"instance-value",attrs:{"contentEditable":false,"text":_vm.value.primaryLabel,"isNormalise":true,"maxLength":_vm.maxLength,"placeholder":_vm.$pluralize.singular(
                        _vm.upperFirst(_vm.humanReadable(_vm.propName))
                    ) + '...'},nativeOn:{"click":function($event){return _vm.onClickInstance(_vm.value.primaryID)}}}),_c('text-actions',{attrs:{"copyTargetName":_vm.$pluralize.singular(_vm.humanReadable(_vm.propName)),"copyTargetEl":function () { return _vm.$refs.editText.containerEl; },"copyFullText":_vm.value.primaryID,"searchTargetName":_vm.$pluralize.singular(_vm.humanReadable(_vm.propName)),"searchTargetEl":function () { return _vm.$refs.editText.containerEl; },"isSearchActions":_vm.isTextSearch}}),(_vm.isSchemaVersion2)?_c('b-collapse',{attrs:{"visible":_vm.showTags,"isEditable":_vm.isEditable}},[_c('property-value-tags',{attrs:{"tags":_vm.tagsWithValue,"isEditable":_vm.isEditable,"property":_vm.property,"isSavingDialogue":_vm.isSavingDialogue,"propertyValue":_vm.value.primaryLabel}})],1):_vm._e()],1)]:[_c('span',{staticClass:"flex-grow-1 d-inline-block stacked-item-name tags-header",class:{ 'word-break': _vm.isLink }},[_c('font-awesome-icon',{staticClass:"tags-icon",class:_vm.headerIconClass,attrs:{"icon":_vm.headerIcon},on:{"click":_vm.toggleCollapsed}}),_c('editable-text',{ref:"editText",attrs:{"isSaving":true,"isSuccess":_vm.isEditSuccess && !_vm.isUnchanged,"isError":_vm.isEditError && !_vm.isUnchanged,"contentEditable":_vm.isEditable,"text":_vm.value,"isNormalise":true,"maxLength":_vm.maxLength,"placeholder":_vm.$pluralize.singular(
                        _vm.upperFirst(_vm.humanReadable(_vm.propName))
                    ) + '...'},on:{"valueUpdated":function($event){return _vm.handleValueUpdated($event, _vm.property)}}}),_c('text-actions',{attrs:{"copyTargetName":_vm.$pluralize.singular(_vm.humanReadable(_vm.propName)),"copyTargetEl":function () { return _vm.$refs.editText.containerEl; },"copyFullText":_vm.value,"url":_vm.isValidUrl,"searchTargetName":_vm.$pluralize.singular(_vm.humanReadable(_vm.propName)),"searchTargetEl":function () { return _vm.$refs.editText.containerEl; },"isSearchActions":_vm.isTextSearch}}),(_vm.isSchemaVersion2)?_c('b-collapse',{attrs:{"visible":_vm.showTags,"isEditable":_vm.isEditable}},[_c('property-value-tags',{attrs:{"tags":_vm.tagsWithValue,"isEditable":_vm.isEditable,"property":_vm.property,"isSavingDialogue":_vm.isSavingDialogue,"propertyValue":_vm.value}})],1):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }