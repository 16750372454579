import {
    getOntologyPropertyChildren,
    getPropertyPathsFromRootToChildren,
    getRootProperties,
} from '@/api-v2';
import { PropertyTree } from '@/ts';
import { computed, ref, Ref } from '@vue/composition-api';

const treeApiData: Ref<PropertyTree> = ref([]);
const scrollSize = ref(20);
const scrollPage = ref(0);
const propertyChildrenTotals: Ref<
    Record<string, { total: number; nextPage: number }>
> = ref({});

export const usePropertyTreeService = () => {
    /**
     * Holds the original request data unchanged
     */
    const getApiTreeData = computed(() => {
        return treeApiData.value;
    });

    /**
     * BE CAREFUL! You probably want to be changing the treeExtendedData instead.
     * This is used for setting the original state from the API.
     * @see setTreeDataExtended as an alternative
     * @param _treeData
     */
    const setApiTreeData = (_treeData: PropertyTree) => {
        treeApiData.value = _treeData;
    };

    const getPropertyChildrenTotals = computed(() => {
        return propertyChildrenTotals.value;
    });

    const getPagedRootProperties = async (ontologyId: string) => {
        const queryParams = {
            size: scrollSize.value,
            from: scrollPage.value,
        };

        const res = await getRootProperties(
            {
                ontologyId: ontologyId,
            },
            queryParams
        );

        if (typeof res.elements === 'undefined') {
            console.error('No nodes found in response');
            return;
        }
        if (!res.elements.length) return res;

        scrollPage.value += scrollSize.value;

        return res;
    };

    /**
     * Gets the paths from root for a given ID
     * @param {string} ontologyId
     * @param primaryId the primary id of the path you're trying to find
     * @returns {Promise<PropertyPathsFromRootToChildrenResponse>}
     */
    const getPathsFromRoot = async (ontologyId: string, primaryId: string) => {
        return getPropertyPathsFromRootToChildren(
            { ontologyId },
            { primaryId }
        );
    };

    const getPropertyChildren = async (
        propertyId: string,
        ontologyId: string,
        from = 0,
        size = 10
    ) => {
        //keeps track of which properties have children
        if (propertyChildrenTotals.value[propertyId]) {
            from = propertyChildrenTotals.value[propertyId].nextPage;
        }

        const res = await getOntologyPropertyChildren(
            {
                propertyId,
                ontologyId,
            },
            { from, size }
        );
        if (res.total === 0) return res.elements;

        propertyChildrenTotals.value = {
            ...propertyChildrenTotals.value,
            [propertyId]: { nextPage: from + size, total: res.total ?? 0 },
        };

        return res.elements;
    };

    const getPropertyChildrenById = async (params: {
        ontologyId: string;
        propertyId: string;
    }) => {
        const res = await getOntologyPropertyChildren(params);
        return res.elements;
    };

    const clearPropertyServiceTreeCache = () => {
        setApiTreeData([]);
        scrollPage.value = 0;
        propertyChildrenTotals.value = {};
    };

    return {
        getRootPropertiesPaged: getPagedRootProperties,
        getPropertyChildren,
        getApiTreeData,
        setApiTreeData,
        clearPropertyServiceTreeCache,
        getPropertyChildrenById,
        getPathsFromRoot,
        getPropertyChildrenTotals,
    };
};
