import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type RefreshTokenBodyParams = operations["refreshTokenUsingPOST"]["requestBody"]["content"]["application/json"];
type RefreshTokenResponse = operations["refreshTokenUsingPOST"]["responses"]["200"]['content']["*/*"];

/**
 * refreshToken
 * @param {RefreshTokenPathParams} bodyParams
 * @return {RefreshTokenResponse}
*/
export const refreshToken = async (
    bodyParams: RefreshTokenBodyParams,
) => {
    const url = `/authenticate/token/refresh`;
    const response = await Axios.post(url, bodyParams);

    return response && response.data as RefreshTokenResponse;
}
