import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyDesignPatternsPathParams = operations["getOntologyDesignPatternsUsingGET"]["parameters"]["path"];
type OntologyDesignPatternsResponse = operations["getOntologyDesignPatternsUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get the design patterns of a given ontology
 * @param {string} ontologyId
 * @return {OntologyDesignPatternsResponse}
*/
export const getOntologyDesignPatterns = async (
    { ontologyId }: OntologyDesignPatternsPathParams,
) => {
    const url = `/ontology/${ontologyId}/designPatterns`;
    const response = await Axios.get(url);

    return response && response.data as OntologyDesignPatternsResponse;
}
