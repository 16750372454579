import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type CommitSuggestionPathParams = operations["commitSuggestionUsingPOST"]["parameters"]["path"];
type CommitSuggestionQueryParams = operations["commitSuggestionUsingPOST"]["parameters"]["query"];
type CommitSuggestionBodyParams = operations["commitSuggestionUsingPOST"]["requestBody"]["content"]["application/json"];
type CommitSuggestionResponse = operations["commitSuggestionUsingPOST"]["responses"]["200"]['content']["*/*"];

/**
 * commitSuggestion
 * @param {string} ontologyId
 * @param {string} transactionId
 * @param {boolean} createReverseMappings
 * @param {string} validationChecksum
 * @param {CommitSuggestionPathParams} bodyParams
 * @return {CommitSuggestionResponse}
*/
export const commitSuggestion = async (
    { ontologyId, transactionId }: CommitSuggestionPathParams,
    bodyParams: CommitSuggestionBodyParams,
    queryParams?: CommitSuggestionQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/edits/transactions/${transactionId}/commit`;
    const response = await Axios.post(url, bodyParams, { params: queryParams });

    return response && response.data as CommitSuggestionResponse;
}
