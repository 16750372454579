import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllLabelsPathParams = operations["deleteAllLabelsUsingDELETE"]["parameters"]["path"];
type AllLabelsBodyParams = operations["deleteAllLabelsUsingDELETE"]["requestBody"]["content"]["application/json"];
type AllLabelsResponse = operations["deleteAllLabelsUsingDELETE"]["responses"]["201"]['content']["*/*"];

/**
 * Remove all labels ( expect for the primary label ) from the specified class
 * @param {string} ontologyId
 * @param {string} classId
 * @param {AllLabelsPathParams} bodyParams
 * @return {AllLabelsResponse}
*/
export const deleteAllLabels = async (
    { ontologyId, classId }: AllLabelsPathParams,
    bodyParams: AllLabelsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/labels`;
    const response = await Axios.delete(url, { data: bodyParams });

    return response && response.data as AllLabelsResponse;
}
