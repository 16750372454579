import { ref, Ref } from '@vue/composition-api';
import { components } from '@/ts/ApiSpecs';
import { usePropertyValues } from '@/compositions/usePropertyValues';
import { extractPropertyLanguageTag } from '@/utils';
import { HARDCODED_TAGS } from '@/config';
import { useOntology } from '@/compositions/useOntology';

export const useEditTagsModal = (props: Record<string, unknown>) => {
    const irisWithDropdown = [
        'http://ontology.scibite.com/ontology/termite/switch',
        'http://www.w3.org/1999/02/22-rdf-syntax-ns#datatype',
    ];
    const dataTypeValues: string[] = [
        'http://www.w3.org/2001/XMLSchema#string',
        'http://www.w3.org/2001/XMLSchema#double',
        'http://www.w3.org/2001/XMLSchema#boolean',
        'http://www.w3.org/2001/XMLSchema#int',
        'http://www.w3.org/2001/XMLSchema#long',
        'http://www.w3.org/2001/XMLSchema#float',
    ];

    const dropDownItems: Ref<string[]> = ref([]);
    const dropDownText: Ref<string | undefined> = ref();
    const dropDownValue: Ref<string | undefined> = ref();
    const selectedTag: Ref<components['schemas']['Tag'] | undefined> = ref();
    const currentTagType: Ref<string> = ref('');
    const currentTagIri: Ref<string> = ref('');
    const currentTagValue: Ref<string> = ref('');
    const disableInput: Ref<boolean> = ref(false);
    const errorMessage: Ref<string> = ref('');

    let tagsWithValue: components['schemas']['Tag'][] = [];

    const property =
        props.property as unknown as components['schemas']['PropertyValue'];
    const propertyIri = props.propertyIri as unknown as string;
    const propertyName = props.propertyName as unknown as string;
    const propertyValue = props.propertyValue as unknown as string;
    const lang = extractPropertyLanguageTag(property);

    const hardcodedIris = HARDCODED_TAGS.map((tag) => tag.iri);

    const tagsInDropdown =
        useOntology().getAvailableTags.value?.filter((tag) => {
            return !hardcodedIris.includes(tag.iri);
        }) || [];

    if (propertyIri) {
        tagsWithValue =
            usePropertyValues().getTagsByPropertyIri(
                propertyIri,
                propertyValue,
                lang
            ) || [];
    } else {
        tagsWithValue =
            usePropertyValues().getTagsByPropName(
                propertyName,
                propertyValue,
                lang
            ) || [];
    }

    /**
     * Updates the tags array when a new tag is added or a tag is removed.
     */
    const updateTagsDropdown = () => {
        const tagsWithValuesIris = tagsWithValue.map((tag) => tag.iri);
        const tagsInDropdownIris = tagsInDropdown.map((tag) => tag.iri);

        HARDCODED_TAGS.forEach((tag) => {
            const tagHasValue = tagsWithValuesIris.includes(tag.iri);
            const tagIsInDropdown = tagsInDropdownIris.includes(tag.iri);
            const tagShouldBeInDropdown = !tagHasValue || tag.multipleAllowed;

            const addTagToDropdown = () => {
                tagsInDropdown.push(tag);
                tagsInDropdownIris.push(tag.iri);
            };

            const removeTagFromDropdown = () => {
                const tagIndex = tagsInDropdown.findIndex(
                    (availableTag) => availableTag.iri === tag.iri
                );
                const tagIriIndex = tagsInDropdownIris.findIndex(
                    (availableTagsIri) => availableTagsIri === tag.iri
                );

                tagsInDropdown.splice(tagIndex, 1);
                tagsWithValuesIris.splice(tagIriIndex, 1);
            };

            if (tagShouldBeInDropdown && !tagIsInDropdown) {
                addTagToDropdown();
            } else if (!tagShouldBeInDropdown && tagIsInDropdown) {
                removeTagFromDropdown();
            }
        });
    };

    return {
        irisWithDropdown,
        dataTypeValues,
        dropDownItems,
        dropDownText,
        dropDownValue,
        selectedTag,
        currentTagType,
        currentTagIri,
        currentTagValue,
        disableInput,
        errorMessage,
        tagsWithValue,
        tagsInDropdown,
        updateTagsDropdown,
    };
};
