/**
 * Applies selected search filters by dispatching the `exact` and `obsolete` actions.
 * @param {Object} to
 * @param {Object} store
 * @param {Function} next
 */
const applyModes = ({ to, store, next }) => {
    if (to.params.hasOwnProperty('recoveryTransactionID')) {
        store.dispatch('setRecoveryMode', true);
        return next();
    }

    store.dispatch('setRecoveryMode', false);
    next();
};

export default applyModes;
