import { orderBy, filter, findIndex, find } from 'lodash';

import ApiEdit from '@/api/edit';

import * as MutationTypes from './mutation-types';

const state = {
    // List of suggestions sent by the current user
    suggestions: [],

    // Possible suggestion statuses
    statuses: ['OPEN', 'REJECTED', 'APPROVED', 'CANCELLED'],

    // Number of suggestions still open
    openCount: 0,

    // Number of suggestions that have been rejected.
    rejectedCount: 0,
};

const mutations = {
    [MutationTypes.SUGGESTIONS_UPDATE](state) {
        state.suggestions = orderBy(
            state.suggestions,
            ['status', (suggestion) => new Date(suggestion.creationDate)],
            ['desc', 'desc']
        );
        state.openCount = filter(state.suggestions, { status: 'OPEN' }).length;
        state.rejectedCount = filter(state.suggestions, {
            status: 'REJECTED',
        }).length;
    },

    [MutationTypes.SUGGESTIONS_ADD](state, { suggestions }) {
        state.suggestions.unshift(...suggestions);
    },

    [MutationTypes.SUGGESTIONS_DEL](state, { suggestionID }) {
        const suggestionIndex = findIndex(state.suggestions, {
            transactionId: suggestionID,
        });
        state.suggestions.splice(suggestionIndex, 1);
    },

    [MutationTypes.SUGGESTIONS_STATUS](state, { suggestionID, newStatus }) {
        const suggestionIndex = findIndex(state.suggestions, {
            transactionId: suggestionID,
        });
        state.suggestions[suggestionIndex].status = newStatus;
    },

    [MutationTypes.SUGGESTIONS_CLEAR](state) {
        state.suggestions = [];
        state.openCount = 0;
        state.rejectedCount = 0;
    },
};

// TODO: move the suggestion structure into the helper funtions (the parameter should be the whole suggestion object)
const getters = {
    suggestions: (state) => state.suggestions,
    suggestion: (state) => (suggestionID) =>
        find(state.suggestions, { transactionId: suggestionID }),
    openCount: (state) => state.openCount,
    rejectedCount: (state) => state.rejectedCount,
    allStatuses: (state) => state.statuses,
    isRejected: (state) => (status) => status === 'REJECTED',
    isSent: (state) => (status) => status === 'OPEN',
    isPending: (state) => (status) =>
        status === 'OPEN' || status === 'REJECTED',
    humanStatus: (state) => (status) => {
        if (status === 'CANCELLED') {
            return 'withdrawn';
        } else {
            return status.toLowerCase();
        }
    },
};

const actions = {
    suggestionsLoad({ commit, getters }) {
        return ApiEdit.suggestions({
            username: getters.username,
            pageSize: 50,
        }).then((response) => {
            const suggestions = response.data;
            commit(MutationTypes.SUGGESTIONS_ADD, { suggestions });
            commit(MutationTypes.SUGGESTIONS_UPDATE);
        });
    },

    suggestionDel({ commit, getters }, { suggestionID, ontologyID, comment }) {
        return ApiEdit.transaction({
            transactionID: suggestionID,
            ontologyID,
            verb: 'cancel',
            comment,
        }).then(() => {
            commit(MutationTypes.SUGGESTIONS_DEL, { suggestionID });
            commit(MutationTypes.SUGGESTIONS_UPDATE);
        });
    },

    suggestionRejected({ commit }, suggestionID) {
        commit(MutationTypes.SUGGESTIONS_STATUS, {
            suggestionID,
            newStatus: 'REJECTED',
        });
        commit(MutationTypes.SUGGESTIONS_UPDATE);
    },

    suggestionsClear({ commit }) {
        commit(MutationTypes.SUGGESTIONS_CLEAR);
    },

    suggestionsReload({ dispatch }) {
        dispatch('suggestionsClear');
        dispatch('suggestionsLoad');
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
