import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyClassesPathParams = operations["getOntologyClassesUsingGET"]["parameters"]["path"];
type OntologyClassesQueryParams = operations["getOntologyClassesUsingGET"]["parameters"]["query"];
type OntologyClassesResponse = operations["getOntologyClassesUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get a Scroll with a list of all classes for a given ontology. The first call is done without a scrollId. Then call it again with the scrollId from the first call.
 * @param {string} ontologyId
 * @param {string} scrollId
 * @param {integer} size
 * @return {OntologyClassesResponse}
*/
export const getOntologyClasses = async (
    { ontologyId }: OntologyClassesPathParams,
    queryParams?: OntologyClassesQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/classes`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as OntologyClassesResponse;
}
