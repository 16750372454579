var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"text-actions d-inline-block"},[_c('copy-button',{staticClass:"ml-2 action-btn secondary-btn",attrs:{"targetName":_vm.copyTargetName,"targetEl":_vm.copyTargetEl,"targetText":_vm.copyFullText},nativeOn:{"mouseenter":function($event){_vm.highlightEl(_vm.copyTargetEl(), true)},"mouseleave":function($event){_vm.highlightEl(_vm.copyTargetEl(), false)}}}),_vm._t("default",function(){return [(_vm.isSearchActions)?_c('span',{on:{"mouseenter":function($event){_vm.highlightEl(_vm.searchTargetEl(), true)},"mouseleave":function($event){_vm.highlightEl(_vm.searchTargetEl(), false)}}},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: 'Replace the current search',
                    delay: { show: _vm.showDelay, hide: 0 },
                }),expression:"{\n                    title: 'Replace the current search',\n                    delay: { show: showDelay, hide: 0 },\n                }",modifiers:{"hover":true}}],staticClass:"action-btn secondary-btn",attrs:{"icon":"external-link-square-alt","flip":"horizontal"},on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSearchReplace.apply(null, arguments)},"mouseup":function($event){$event.stopPropagation();$event.preventDefault();return (function () {}).apply(null, arguments)},"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {}).apply(null, arguments)}}}),_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: ("Search for this " + _vm.searchTargetName),
                    delay: { show: _vm.showDelay, hide: 0 },
                }),expression:"{\n                    title: `Search for this ${searchTargetName}`,\n                    delay: { show: showDelay, hide: 0 },\n                }",modifiers:{"hover":true}}],staticClass:"action-btn route-btn",attrs:{"icon":"search"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSearch.apply(null, arguments)}}})],1):_vm._e()]}),(_vm.url)?_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
            title: _vm.isDownload ? 'Download this file' : 'Open the website',
            delay: { show: _vm.showDelay, hide: 0 },
        }),expression:"{\n            title: isDownload ? 'Download this file' : 'Open the website',\n            delay: { show: showDelay, hide: 0 },\n        }",modifiers:{"hover":true}}],staticClass:"link-btn action-btn route-btn",attrs:{"icon":_vm.isDownload ? 'download' : 'external-link-alt'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onLink.apply(null, arguments)}}}):_vm._e(),_vm._t("append")],2)}
var staticRenderFns = []

export { render, staticRenderFns }