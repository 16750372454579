import { DefaultAnnotationPropertyName } from '@/ts';

type AnnotationPropertyLookup = Record<
    DefaultAnnotationPropertyName,
    { readable: string; name: string }
>;

/**
 * Lookup object to match annotationProperties as contained in OntologySummaryVM to the names contained in OntologyMetadataJSONBean
 * @type {AnnotationPropertyLookup}
 */
export const ANNOTATION_PROPERTY_LOOKUP: AnnotationPropertyLookup = {
    textualDefinitionProperties: {
        name: 'textualDefinitions',
        readable: 'Textual definitions',
    },
    synonymProperties: {
        name: 'synonyms',
        readable: 'Synonyms',
    },
    mappingProperties: {
        name: 'mappings',
        readable: 'Mappings',
    },
    allLabelProperties: {
        name: 'labels',
        readable: 'Labels',
    },
};
