/**
 * Marks up the parent of a given target so that no hover effect on the parent is applied when said target is hovered.
 * @param {Object} event - DOM object for the mouse enter/leave action.
 * @param {boolean} isEnter - True if the mouse has entered the target.
 * @param {number} levelsUp - How far up the parent element is.
 */
export const markParentAsNoHover = (
    event: MouseEvent,
    isEnter: boolean,
    levelsUp = 1,
    className = 'no-hover'
) => {
    if (!event.target) return;

    let parentEl = event.target as HTMLElement | null;

    while (levelsUp) {
        parentEl = parentEl ? parentEl.parentElement : null;
        levelsUp--;
    }

    parentEl && parentEl.classList.toggle(className, isEnter);
};
