/**
 * Determines if a given property is of a custom type. If so, it returns the type and
 * the name of the property, in that order.
 * NOTE: This assumes that custom properties have up to one level of nesting.
 * @param {string} propName - Name of the property.
 * @return {boolean | RegExpMatchArray}
 */
export const isCustomProperty = (propName: string) => {
    if (!propName || typeof propName !== 'string') {
        return false;
    }

    const customPropWrappers = [
        process.env.VUE_APP_ANNOTATION_WRAPPER,
        process.env.VUE_APP_RELATIONAL_WRAPPER,
    ];
    const regexp = new RegExp(`(${customPropWrappers.join('|')}).(.+)`);
    const match = propName.match(regexp);

    if (match) {
        match.shift();
        return match;
    } else {
        return false;
    }
};
