<template>
    <div
        class="search-settings"
        :id="buildTestClass('filter--search-settings')">
        <b-dropdown-header :id="titleId" class="search-settings-title d-flex">
            <span class="flex-grow-1 text-light filter-title">{{
                settingsTitle
            }}</span>
            <slot>
                <b-button
                    class="collapse-btn ml-3 p-0 border-0 bg-transparent"
                    @click="
                        $emit(isSettingsShown ? 'filter:close' : 'filter:open')
                    ">
                    <font-awesome-icon
                        :icon="isSettingsShown ? 'minus' : 'plus'"
                        class="align-middle" />
                </b-button>
            </slot>
        </b-dropdown-header>
        <b-tooltip
            v-if="!isSettingsShown"
            triggers="hover"
            :target="titleId"
            :delay="{ show: showDelay, hide: 0 }">
            <strong>Settings</strong>: {{ $store.getters.filters.searchType }}.
            {{ $store.getters.filters.isObsolete ? 'obsolete' : 'active' }}
            terms that match
            {{ $store.getters.filters.isExact ? 'exactly' : 'fuzzily' }}.
        </b-tooltip>
        <div v-show="isSettingsShown" class="search-settings-controls">
            <div class="py-1 px-3 filter-header text-muted">Search Type</div>
            <b-form-radio-group
                class="px-3 py-1"
                v-model="$store.getters.filters.searchType">
                <b-form-radio :value="'class'" class="d-block radio-item">
                    <font-awesome-icon class="svg-icon" icon="code" size="sm" />
                    Class
                </b-form-radio>
                <b-form-radio class="d-block radio-item" :value="'property'">
                    <font-awesome-icon class="svg-icon" icon="link" size="sm" />
                    Relation
                </b-form-radio>
                <b-form-radio :value="'instance'" class="d-block radio-item">
                    <font-awesome-icon
                        class="svg-icon"
                        icon="equals"
                        size="sm" />
                    Instance
                </b-form-radio>
            </b-form-radio-group>
            <hr />
            <div class="pb-1 px-3 mt-1 filter-header text-muted">Term type</div>
            <b-form-radio-group
                class="px-3 py-1"
                v-model="$store.getters.filters.isObsolete">
                <b-form-radio :value="false" class="d-block radio-item">
                    <font-awesome-icon
                        class="svg-icon"
                        icon="check-circle"
                        size="sm" />
                    Active
                </b-form-radio>
                <b-form-radio :value="true" class="d-block radio-item">
                    <font-awesome-icon
                        class="svg-icon"
                        icon="exclamation-circle"
                        size="sm" />
                    Obsolete
                </b-form-radio>
            </b-form-radio-group>
            <hr />
            <div class="py-1 px-3 filter-header text-muted">Match mode</div>
            <b-form-radio-group
                class="px-3 py-1"
                v-model="$store.getters.filters.isExact">
                <b-form-radio :value="false" class="d-block radio-item">
                    <fuzzy-icon class="svg-icon" />
                    Fuzzy
                </b-form-radio>
                <b-form-radio :value="true" class="d-block radio-item">
                    <font-awesome-icon
                        class="svg-icon"
                        icon="crosshairs"
                        size="sm" />
                    Exact
                </b-form-radio>
            </b-form-radio-group>
        </div>
    </div>
</template>

<script>
import FuzzyIcon from '@/components/ui/FuzzyIcon';

export default {
    name: 'SearchSettings',
    components: { FuzzyIcon },
    props: {
        settingsTitle: {
            type: String,
            default: 'Settings',
        },

        isSettingsShown: {
            type: Boolean,
            default: true,
        },

        summaryTooltipTarget: {
            type: String,
            default: '',
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    data() {
        return {
            titleId: this.summaryTooltipTarget || this.$uuid.v4(),
        };
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';
.search-settings {
    width: auto;
}
.search-settings-title {
    color: #fff;
    background: $secondary;
}

.collapse-btn {
    margin-top: -0.4rem;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
}

.filter-header {
    font-size: 0.875rem;
    font-weight: 600;
}

.filter-title {
    font-weight: 500;
}

.dropdown-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    .dropdown-header p {
        line-height: 1.35rem;
    }
}

label.custom-control-label ::v-deep {
    display: inline-block;
    justify-content: left !important;
    color: blue !important;
}

.custom-control ::v-deep .custom-control-label {
    justify-content: left !important;
    padding-left: 4px;
}

.svg-icon {
    margin-top: -0.1em;
    fill: currentColor;
}
</style>
