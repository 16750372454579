<template>
    <span
        class="full-text"
        :class="{
            'internal-url': isInternalLink && !isEditMode,
        }">
        <span class="prefix" v-if="label && !isEditMode">
            {{ label }}
        </span>

        <vue-truncate
            ref="vueTruncate"
            class="editable-text"
            :class="{
                'edit-saving': isSaving,
                'edit-success': isSuccess,
                'edit-error': isError,
                'max-size': text.length === maxLength,
                'external-url': isLink,
                'internal-url': isInternalLink && !isEditMode,
            }"
            v-b-popover.hover="{
                placement: 'top',
                html: true,
                content: errorToHtml,
                delay: { show: showDelay, hide: 0 },
                disabled: !errorToHtml,
            }"
            collapsed-text-class="collapsed"
            action-class="toggle-text"
            clamp="More"
            less="Less"
            :text="text"
            :length="maxLength"
            :key="renderCount.value"
            @click.native="onClickEditableText($event)"
            @keydown.enter.native.prevent="forceBlur($event)"
            @dblclick.native="forceFocus($event)" />
    </span>
</template>

<script>
import {
    computed,
    getCurrentInstance,
    nextTick,
    onMounted,
    ref,
    watch,
} from '@vue/composition-api';
import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import VueTruncate from 'vue-truncate-collapsed';
import { useEdits } from '@/compositions';
import Chip from '@/components/ui/Chip';
import router from '@/router';
import { isValidUrl } from '@/utils';

export default defineComponent({
    name: 'EditableText',
    components: { Chip, VueTruncate },
    props: {
        // Primary ID of the class whose name is to be resolved
        primaryID: {
            type: String,
            default: '',
        },

        maxLength: {
            type: Number,
            default: 999999,
        },

        text: {
            type: String,
            required: true,
        },

        // Normalises the contents of the editable area on blur.
        isNormalise: {
            type: Boolean,
            default: false,
        },

        isRequired: {
            type: Boolean,
            default: false,
        },

        contentEditable: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: '',
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        // Change transaction is still in progress
        isSaving: {
            type: Boolean,
            default: false,
        },

        // Change in text was transacted succesfully
        isSuccess: {
            type: Boolean,
            default: false,
        },

        // Error from failed transaction for change
        isError: {
            type: [String, Boolean],
            default: false,
        },

        // Shows an the error message if the change failed
        isErrorMsg: {
            type: Boolean,
            default: false,
        },

        isLink: {
            type: Boolean,
            default: false,
        },

        isInternalLink: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: false,
        },
        ontologyID: {
            type: String,
            required: false,
        },
    },
    setup(props, { refs, emit }) {
        // DOM element for the text container
        const containerEl = ref(null);
        const instance = getCurrentInstance();

        // Allows re-rendering of the component
        const renderCount = ref(0);
        const isFocused = ref(false);
        const previousValue = ref();

        const isEditMode = computed(() => {
            return useEdits().getEditIsActive.value;
        });

        const onClickEditableText = ($event) => {
            if ((!props.isLink && !props.isInternalLink) || isEditMode.value)
                return cancelEvent($event);

            if (isValidUrl(props.text)) return window.open(props.text);

            return router.push({
                name: 'class',
                params: {
                    ontologyID: props.ontologyID,
                    primaryID: props.primaryID,
                },
            });
        };

        /**
         * Finds the element holding the text irrespective of truncation state and marks it out.
         */
        const setContainer = () => {
            watch(
                () => refs.vueTruncate.show,
                (isShow) => {
                    setCotonainerEl();

                    // Removes whitespaces due to sloppy markup in the plugin.
                    if (!isShow) {
                        normaliseText();
                    }
                },
                { immediate: false }
            );

            // Is needed to set up containers when on edit mode
            setCotonainerEl();
        };

        const setCotonainerEl = () => {
            containerEl.value =
                refs.vueTruncate.$el.firstElementChild.firstElementChild;
            containerEl.value.classList.add('text-container');
        };

        /**
         * Prevents the DOM event on the toggle button to bubble up.
         * @param {Object} event - Object for the DOM event.
         */
        const cancelEvent = (event) => {
            if (event.target.classList.contains('toggle-text')) {
                event.stopPropagation();
                event.preventDefault();
            }
        };

        /**
         * Sets up the markup properties to activate inline edition immediately irrespective of the truncation state.
         * It does so by re-applying editability properties on text toggle and automatically expanding text on
         * focus.
         */
        const setEditableMarkup = () => {
            setEditableOnToggle();
            refs.vueTruncate.$on('hook:updated', setEditableOnToggle);
            refs.vueTruncate.$el.addEventListener(
                'focusin',
                toggleFullText.bind(instance, true)
            );
            refs.vueTruncate.$el.addEventListener('focusout', (e) => {
                toggleFullText.bind(instance, false);
                emit('valueUpdated', { event: e, previousValue: props.text });
            });

            const button = refs.vueTruncate.$el.querySelector('button');
            if (button) {
                button.addEventListener('focusin', () => {
                    containerEl.value.focus();
                    setEditableOnToggle();
                });
            }
        };

        const toggleFullText = (isFocus, event) => {
            const isLong = refs.vueTruncate.textClass === 'collapsed';
            const isTruncated = !refs.vueTruncate.show;

            if (isFocus && isLong && isTruncated) {
                containerEl.value.addEventListener(
                    'focusout',
                    function (event) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                );
                refs.vueTruncate.toggle();
                nextTick(() => {
                    containerEl.value.focus();
                });
            } else {
                containerEl.value.parentElement.classList.toggle(
                    'edit-focus',
                    isFocus
                );
            }
        };

        /**
         * Finds the element acting as the text container and makes it editable, irrespective of its collapse state.
         * NOTE: The truncate plugin destroys any previous markup for the text container. Hence the re-applying of the editable
         * attribute.
         */
        const setEditableOnToggle = async () => {
            if (!containerEl.value) {
                await nextTick();
            }
            // Sets the properties
            containerEl.value.contentEditable = true;

            if (props.placeholder) {
                containerEl.value.setAttribute(
                    'placeholder',
                    props.placeholder
                );
            }
            containerEl.value.spellcheck = false;

            // Carries out optional sanitation chores on blur.
            if (props.isNormalise) {
                containerEl.value.addEventListener('focusout', normaliseText);
            }
            if (props.isRequired) {
                containerEl.value.addEventListener('focusout', restoreOnEmpty);
            }
        };

        /**
         * Programmatically triggers a blur event (and ensuing focusout) after another one.
         * @param {Object} event - DOM object for the directing event (eg: enter keypress).
         */
        const forceBlur = (event) => {
            event.target.blur();
        };

        /**
         * Programmatically sets the focus on the text only after it is made editable.
         * Any previous selection will also be cleared.
         * @param {Object} event - DOM object for the directing event.
         */
        const forceFocus = (event) => {
            const unwatch = watch(
                () => props.contentEditable,
                () => {
                    const selection = window.getSelection();

                    if (selection.toString()) {
                        selection.removeAllRanges();
                    }

                    event.target.focus();
                    unwatch();
                }
            );
        };

        /**
         * Strips the current text contents of consecutive whitespaces.
         */
        const normaliseText = () => {
            containerEl.value.textContent = containerEl.value.textContent
                .replace(/\s{2,}/g, ' ')
                .trim();
        };

        /**
         * Prevents blanks by restoring old value.
         * @param {Object} event - DOM object for the directing event.
         */
        const restoreOnEmpty = (event) => {
            if (!event.target.textContent.trim()) {
                event.target.textContent = props.text;
            }
        };

        const errorToHtml = computed(() => {
            return (
                props.isErrorMsg &&
                props.isError &&
                '<span class="text-danger">' + props.isError + '</span>'
            );
        });

        /**
         * Since the plugin's default state for any text (be it long enough to require truncation or not) is the "not show" one,
         * it reverts back to that state if the new text is short.
         * @param {string} newValue - String which the text contents are changed to.
         */
        watch(
            () => props.text,
            (newValue) => {
                const truncateComp = refs.vueTruncate;
                if (
                    truncateComp.show &&
                    newValue.length < truncateComp.length
                ) {
                    truncateComp.toggle();
                }
            }
        );

        /**
         * Re-renders whenever the text is not editable so as to avoid inconsistencies between truncation state and editability.
         * Also keeps track of plugin's DOM elements throughout its lifecycle.
         */
        watch(
            () => props.contentEditable,
            (isEditable) => {
                if (isEditable) {
                    setEditableMarkup();
                } else {
                    renderCount.value++;
                    nextTick(setContainer);
                }
            }
        );

        // Fixes limitations with plugin: adds ellipsis for truncated plain-text.
        onMounted(() => {
            const component = refs.vueTruncate;
            component.$options.components.truncate = function (string) {
                return component.truncate(string, string.length);
            };

            // Editability hasn't changed yet and text container initialisation has to be forced.
            setContainer();

            // Only modifies the DOM if edits are about to take place.
            if (props.contentEditable) {
                setEditableMarkup();
            }
        });

        return {
            containerEl,
            renderCount,
            isFocused,
            errorToHtml,
            onClickEditableText,
            forceFocus,
            forceBlur,
            isEditMode,
        };
    },
});
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

div,
::v-deep div {
    display: inline;
    margin-right: -0.125rem;
    margin-left: -0.125rem;
}

::v-deep [contentEditable='true'] {
    padding-right: 1px; // avoids Firefox issue with cursor falling off the right edge of text
    outline: none;
    border-bottom: 1px dashed $info;
    white-space: pre-wrap;

    &:empty:before {
        content: '';
        color: $text-muted;
        opacity: 0.6;

        &[placeholder] {
            content: attr(placeholder);
        }
    }

    &:focus {
        color: $body-color;
        background: transparent !important;

        &:empty {
            display: inline-block;
            min-width: 0.25em;
            line-height: 1.1em;
        }
    }

    &:focus,
    &:hover {
        border-bottom-style: solid;
        cursor: text;
    }
}

::v-deep .toggle-text {
    display: inline-block;
    color: lighten($secondary, 8%) !important;
    font-size: 0.9em;
    font-weight: 500;
    user-select: none;
    transition: $btn-transition;

    &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin: 0 0.2rem;
        vertical-align: middle;
        border-style: solid;
        border-width: 0.4em 0.4em 0 0.4em;
        border-color: lighten($secondary, 8%) transparent transparent
            transparent;
        transform: rotate(180deg);
        transition: $btn-transition;
    }

    &:hover {
        color: $secondary !important;

        &:after {
            border-color: $secondary transparent transparent transparent;
        }
    }
}

::v-deep .edit-focus .toggle-text {
    pointer-events: none;
    opacity: 0.5;
}

// The plugin shows the toggle even when the text is equal to the max
.max-size ::v-deep .toggle-text {
    display: none;
}

.edit-saving ::v-deep [contentEditable] {
    border-bottom-style: solid;
    @include pulse-border-animation;
}

.edit-error ::v-deep [contentEditable] {
    border-bottom: 2px solid $danger;
}

.external-url ::v-deep {
    color: $url-color;
    .text-container:hover {
        background: unset !important;
    }
    &:hover {
        cursor: pointer;
        color: $primary;
    }
}

.internal-url ::v-deep {
    color: $primary !important;
    .text-container:hover {
        background: unset !important;
    }
    &:hover {
        cursor: pointer;
    }
    .prefix {
        margin-right: 5px;
        color: $url-color;
    }
}

.edit-success ::v-deep [contentEditable] {
    border-bottom: 2px solid $info;
}

.badge ::v-deep {
    padding: 4px 10px !important;
}

.full-text:hover .prefix {
    color: $primary;
}

@include pulse-border-keyframes($info);
</style>
