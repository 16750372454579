import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type SearchClassesByIdentifiersInAllOntologiesOneQueryQueryParams = operations["searchClassesByIdentifiersInAllOntologiesOneQueryUsingPOST"]["parameters"]["query"];
type SearchClassesByIdentifiersInAllOntologiesOneQueryBodyParams = operations["searchClassesByIdentifiersInAllOntologiesOneQueryUsingPOST"]["requestBody"]["content"]["application/json"];
type SearchClassesByIdentifiersInAllOntologiesOneQueryResponse = operations["searchClassesByIdentifiersInAllOntologiesOneQueryUsingPOST"]["responses"]["200"]['content']["*/*"];

/**
 * Performs exact search by primaryId or shortFormId across multiple ontologies using one ElasticSearch query. This endpoint searches for at most 100 identifiers. From and size parameters are for all identifiers together
 * @param {integer} from
 * @param {integer} size
 * @param {SearchClassesByIdentifiersInAllOntologiesOneQueryPathParams} bodyParams
 * @return {SearchClassesByIdentifiersInAllOntologiesOneQueryResponse}
*/
export const searchClassesByIdentifiersInAllOntologiesOneQuery = async (
    bodyParams: SearchClassesByIdentifiersInAllOntologiesOneQueryBodyParams,
    queryParams?: SearchClassesByIdentifiersInAllOntologiesOneQueryQueryParams,
) => {
    const url = `/search/ids`;
    const response = await Axios.post(url, bodyParams, { params: queryParams });

    return response && response.data as SearchClassesByIdentifiersInAllOntologiesOneQueryResponse;
}
