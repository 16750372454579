import { usePropertyTree } from '@/compositions/propertyView/usePropertyTree';
import { usePropertyTreeService } from '@/compositions/propertyView/usePropertyTreeService';
import { computed, ref, Ref } from '@vue/composition-api';
import { components } from '@/ts/ApiSpecs';
import {
    useOntology,
    usePropertyBatching,
    usePropertyList,
    usePropertyValues,
} from '@/compositions';
import { ClassDataExtended, OntologyMetaDataJSONBeanExtended } from '@/ts';
import { getOntologyMetadata } from '@/api-v2';

export const usePropertyView = () => {
    const classData: Ref<ClassDataExtended | undefined> = ref();

    /* Used to clear all property data on leaving the route where the view is rendered
     * Hook into this if any data needs to be cleared when the route is changed
     */
    const clearAllData = () => {
        usePropertyTree().clearPropertyTreeCache();
        usePropertyTreeService().clearPropertyServiceTreeCache();
        usePropertyBatching().clearCache();
    };

    /**
     * Getter for the currently selected class.
     * @type {ComputedRef<ClassDataExtended | undefined>}
     */
    const getPropertyData = computed((): ClassDataExtended | undefined => {
        return classData.value;
    });

    const setPropertyData = async (data: ClassDataExtended) => {
        classData.value = data;

        const currentOntology = useOntology().getCurrentOntology.value;

        if (!currentOntology) {
            const ontologyId = classData.value?.sourceUniqueID;

            if (ontologyId) {
                const response = await getOntologyMetadata({ ontologyId });
                const ontology = response.ontologyMetadataJSONBean;
                useOntology().setCurrentOntology(
                    ontology as OntologyMetaDataJSONBeanExtended
                );
            }
        }

        //updatePrimaryLabelIri();
        usePropertyValues().initialisePropertyValuesByIri();
        usePropertyValues().initialisePropertyValuesByCamelGroupName();
        usePropertyList().buildPropertyList();

        //mustShowRelationalProperties.value = [...RELATIONSHIP_PROPS];
    };

    const resetPropertyData = () => {
        //mustShowRelationalProperties.value = [...RELATIONSHIP_PROPS];
    };

    /**
     * Convenience computed reference to the current ontology data.
     * @type {ComputedRef<definitions["OntologyMetadataVM"]|undefined>}
     */
    const getOntologyData = computed(
        (): components['schemas']['OntologyMetadataJSONBean'] | undefined => {
            return useOntology().getCurrentOntology
                .value as components['schemas']['OntologyMetadataJSONBean'];
        }
    );

    return {
        clearAllData,
        getSelectedProperty: usePropertyTree().getSelectedProperty,
        getOntologyData,
        setPropertyData,
        getPropertyData,
        resetPropertyData,
    };
};
