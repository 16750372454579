import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToAugFilePathParams = operations["exportToAugFileUsingGET"]["parameters"]["path"];
type ExportToAugFileResponse = operations["exportToAugFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * exportToAugFile
 * @param {string} ontologyId
 * @return {ExportToAugFileResponse}
*/
export const exportToAugFile = async (
    { ontologyId }: ExportToAugFilePathParams,
) => {
    const url = `/ontologies/${ontologyId}/exportAsTermiteAugFile`;
    const response = await Axios.get(url);

    return response && response.data as ExportToAugFileResponse;
}
