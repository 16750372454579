import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyMetadataSummaryPathParams = operations["getOntologyMetadataSummaryUsingGET"]["parameters"]["path"];
type OntologyMetadataSummaryResponse = operations["getOntologyMetadataSummaryUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * getOntologyMetadataSummary
 * @param {string} ontologyId
 * @return {OntologyMetadataSummaryResponse}
*/
export const getOntologyMetadataSummary = async (
    { ontologyId }: OntologyMetadataSummaryPathParams,
) => {
    const url = `/ontologies/${ontologyId}/ontologyMetadataSummary`;
    const response = await Axios.get(url);

    return response && response.data as OntologyMetadataSummaryResponse;
}
