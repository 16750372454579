/**
 * Guards routes that require the user to be not authenticated (to be just a guest).
 * If the user is logged in we redirect to the root url.
 * @param {Object} store
 * @param {Function} next
 */
const requiresGuest = ({ store, next }) => {
    if (store.getters.hasToken) {
        next('/');
    } else {
        next();
    }
};

export default requiresGuest;
