import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToQttFlatFileV2PathParams = operations["exportToQttFlatFileV2UsingPOST"]["parameters"]["path"];
type ExportToQttFlatFileV2QueryParams = operations["exportToQttFlatFileV2UsingPOST"]["parameters"]["query"];
type ExportToQttFlatFileV2BodyParams = operations["exportToQttFlatFileV2UsingPOST"]["requestBody"]["content"]["application/json"];
type ExportToQttFlatFileV2Response = operations["exportToQttFlatFileV2UsingPOST"]["responses"]["202"]['content']["*/*"];

/**
 * exportToQttFlatFileV2
 * @param {string} ontologyId
 * @param {boolean} qttTemplateExport
 * @param {ExportToQttFlatFileV2PathParams} bodyParams
 * @return {ExportToQttFlatFileV2Response}
*/
export const exportToQttFlatFileV2 = async (
    { ontologyId }: ExportToQttFlatFileV2PathParams,
    bodyParams: ExportToQttFlatFileV2BodyParams,
    queryParams?: ExportToQttFlatFileV2QueryParams,
) => {
    const url = `/ontologies/${ontologyId}/exportAsQttFlatFile_V2`;
    const response = await Axios.post(url, bodyParams, { params: queryParams });

    return response && response.data as ExportToQttFlatFileV2Response;
}
