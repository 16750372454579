import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type EditPropertyValuePathParams = operations["editPropertyValueUsingPUT"]["parameters"]["path"];
type EditPropertyValueBodyParams = operations["editPropertyValueUsingPUT"]["requestBody"]["content"]["application/json"];
type EditPropertyValueResponse = operations["editPropertyValueUsingPUT"]["responses"]["201"]['content']["*/*"];

/**
 * Edit a property with iri = propertyIri and value = propertyValue and optional lang = the given language in standard format. If the property does not exist, it will be created. If the property contains tags, they will be kept
 * @param {string} ontologyId
 * @param {string} classId
 * @param {EditPropertyValuePathParams} bodyParams
 * @return {EditPropertyValueResponse}
*/
export const editPropertyValue = async (
    { ontologyId, classId }: EditPropertyValuePathParams,
    bodyParams: EditPropertyValueBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/properties`;
    const response = await Axios.put(url, bodyParams);

    return response && response.data as EditPropertyValueResponse;
}
