import { isDate } from 'lodash';
import moment from 'moment';

//TODO Replace with flag variable that can be toggled in the Admin UI.
const isDateFormatEnabled = true;

/**
 * Formats a date to the applications standard formatting.
 * @param {string} date - Single date to be formatted.
 */
export const formatDate = (inputDate: string) => {
    if (isDateFormatEnabled && isDate(inputDate)) {
        return moment(inputDate).format('dddd, MMMM Do YYYY, h:mm');
    }
    return inputDate;
};
