import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologiesThatAcceptMappingsFromOntologyIdForUserPathParams = operations["getOntologiesThatAcceptMappingsFromOntologyIdForUserUsingGET"]["parameters"]["path"];
type OntologiesThatAcceptMappingsFromOntologyIdForUserResponse = operations["getOntologiesThatAcceptMappingsFromOntologyIdForUserUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get all ontologies that accept reverse mappings from the given ontology and can be edited by the logged user
 * @param {string} ontologyId
 * @return {OntologiesThatAcceptMappingsFromOntologyIdForUserResponse}
*/
export const getOntologiesThatAcceptMappingsFromOntologyIdForUser = async (
    { ontologyId }: OntologiesThatAcceptMappingsFromOntologyIdForUserPathParams,
) => {
    const url = `/ontologies/${ontologyId}/ontologiesThatAcceptMappingsForUser`;
    const response = await Axios.get(url);

    return response.data as OntologiesThatAcceptMappingsFromOntologyIdForUserResponse;
}
