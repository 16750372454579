import VueCompositionAPI, {
    computed,
    ComputedRef,
    ref,
    Ref,
    set,
} from '@vue/composition-api';
import Vue from 'vue';
import { components } from '@/ts/ApiSpecs';
import { ClassDataExtended, PropertyListEditState } from '@/ts';
import { useClassTree } from '@/compositions/useClassTree';
import { cloneDeep, find, get, isEmpty, orderBy, pick, trim } from 'lodash';
import { ANNOTATION_PROPS, CLASS_TABS, RELATIONSHIP_PROPS } from '@/config';
import { useStore } from '@/compositions/useStore';
import { useOntology } from '@/compositions/useOntology';
import { usePropertyValues } from '@/compositions/usePropertyValues';
import { usePropertyList } from '@/compositions/usePropertyList';
import { getOntologyMetadata } from '@/api-v2';
import { OntologyMetaDataJSONBeanExtended } from '@/ts/interfaces/OntologyMetaDataExtended';
import { useEdits } from '@/compositions/useEdits';

Vue.use(VueCompositionAPI);

let classDataSnapshot: ClassDataExtended | undefined = undefined;
const classData: Ref<ClassDataExtended | undefined> = ref();
const classCache: Ref<Record<string, ClassDataExtended> | undefined> = ref();
const primaryLabelIri: Ref<string | undefined> = ref();
const mustShowRelationalProperties: Ref<string[]> = ref(RELATIONSHIP_PROPS);

export const useClassView = () => {
    /**
     * Convenience computed reference to the current ontology data.
     * @type {ComputedRef<definitions["OntologyMetadataVM"]|undefined>}
     */
    const getOntologyData = computed(
        (): components['schemas']['OntologyMetadataJSONBean'] | undefined => {
            return useOntology().getCurrentOntology
                .value as components['schemas']['OntologyMetadataJSONBean'];
        }
    );

    /**
     * Getter for the currently selected class.
     * @type {ComputedRef<ClassDataExtended | undefined>}
     */
    const getClassData = computed((): ClassDataExtended | undefined => {
        return classData.value;
    });

    /**
     * Getter for the current class cache.
     * @type {ComputedRef<Record<string, ClassDataExtended> | undefined>}
     */
    const getClassCache = computed(
        (): Record<string, ClassDataExtended> | undefined => {
            return classCache.value;
        }
    );

    /**
     * Getter the current class ontology id.
     * @type {ComputedRef<string|undefined>}
     */
    const getClassOntologyId = computed(() => {
        return classData.value && classData.value.sourceUniqueID;
    });

    /**
     * Getter for the class elasticsearch id.
     * @type {ComputedRef<string|undefined>}
     */
    const getClassElasticSearchId = computed(() => {
        return classData.value && classData.value.id;
    });

    /**
     * Getter for the class primary id (iri).
     * @type {ComputedRef<any>}
     */
    const getClassPrimaryId = computed(() => {
        return classData.value && classData.value.primaryID;
    });

    /**
     * Gets a collection of the current class' propertyValues indexed by property name.
     * @type {ComputedRef<Record<string, definitions["PropertyValueVM"][]>|undefined>}
     */
    const getClassTagsByPropName = computed(() => {
        const propertyValuesByNameCollection: Record<
            string,
            components['schemas']['PropertyValueVM'][]
        > = {};

        if (!classData.value || !classData.value.propertyValues) return;

        classData.value.propertyValues.forEach((propertyValue) => {
            if (!propertyValue.name) return;

            const propertyValueName = propertyValue.name as string;

            propertyValuesByNameCollection[propertyValueName] =
                propertyValuesByNameCollection[propertyValueName] || [];
            propertyValuesByNameCollection[propertyValueName].push(
                propertyValue
            );
        });

        return propertyValuesByNameCollection;
    });

    /**
     * Gets the current class' relational properties as contained in the Class metadata.
     * @type {ComputedRef<any>}
     */
    const getCLassSpecificRelationalProperties = computed(() => {
        return classData.value && classData.value.relationalProperties;
    });

    /**
     * Gets the current class' default relational properties.
     * @type {ComputedRef<any>}
     */
    const getDefaultRelationalProperties = computed(() => {
        return classData.value && pick(classData.value, RELATIONSHIP_PROPS);
    });

    /**
     * Gets all current class' relational properties.
     * @type {ComputedRef<any>}
     */
    const getRelationalProperties = computed(() => {
        if (!classData.value) return;
        const classSpecificProperties =
            getCLassSpecificRelationalProperties.value || {};
        const defaultRelationalProperties =
            getDefaultRelationalProperties.value || {};

        return { ...classSpecificProperties, ...defaultRelationalProperties };
    });

    const removeRelationalProperties = (name: string) => {
        if (!classData.value) return;
        set(classData.value, name, []);
    };

    /**
     * Adds a relational property to the classData and to the relational properties list.
     * @param {string} name
     * @param {string[]} value
     */
    const addRelationalProperty = (name: string, value: string[]) => {
        if (!classData.value) return;

        set(classData.value, name, value);

        if (!RELATIONSHIP_PROPS.includes(name))
            mustShowRelationalProperties.value.push(name);
    };

    /**
     * Gets the current class' annotation properties as contained in the Class metadata.
     * @type {ComputedRef<any>}
     */
    const getClassSpecificAnnotationProperties = computed(() => {
        if (!classData.value) return;

        return classData.value.annotationProperties;
    });

    /**
     * Gets the current class' default annotation properties.
     * @type {ComputedRef<undefined | Pick<ClassDataExtended | undefined, never>>}
     */
    const getDefaultAnnotationProperties = computed(() => {
        return classData.value && pick(classData.value, ANNOTATION_PROPS);
    });

    /**
     * Get all che current class' annotation properties.
     * @type {ComputedRef<any>}
     */
    const getAnnotationProperties = computed(() => {
        const classSpecificAnnotationProperties =
            getClassSpecificAnnotationProperties.value || {};
        const defaultAnnotationProperties =
            getDefaultAnnotationProperties.value || {};

        return {
            ...classSpecificAnnotationProperties,
            ...defaultAnnotationProperties,
        };
    });

    const getMustShowRelationalProperties = computed(() => {
        return mustShowRelationalProperties.value;
    });

    /**
     * Get an annotation property by property name;
     * @param {string} propertyName
     * @return {any}
     */
    const getAnnotationPropertyByName = (propertyName: string) => {
        if (!getAnnotationProperties.value) return;
        return getAnnotationProperties.value[propertyName];
    };

    /**
     * Gets the current class' relational property by name.
     * @param {string} propertyName
     * @return {any}
     */
    const getRelationalPropertyByName = (propertyName: string) => {
        if (!getRelationalProperties.value) return;

        return getRelationalProperties.value[propertyName];
    };

    /**
     * Gets the current value of a property by name.
     * @todo Duplicates the functionality of getPropValue() replace one of the two (getPropValue is more recent and probably more simple and correct)
     * @param {string} propertyName
     * @return {any}
     */
    const getPropertyValue = (propertyName: string) => {
        if (!classData.value) {
            return;
        }

        const classRootProperty = classData.value[propertyName];

        if (classRootProperty) return classRootProperty;

        const relationalProperty = getRelationalPropertyByName(propertyName);

        if (relationalProperty) return relationalProperty;

        const annotationProperty = getAnnotationPropertyByName(propertyName);

        if (annotationProperty) return annotationProperty;
    };
    /**
     * Given a property name, retrieves tags for that property.
     * @param {string} propertyName
     * @return {definitions["PropertyValueVM"]|undefined}
     */
    const getPropTags = (propertyName: string) => {
        return (
            getClassTagsByPropName.value &&
            getClassTagsByPropName.value[propertyName]
        );
    };

    const classHasTags = computed(() => {
        return !isEmpty(getClassTagsByPropName.value);
    });

    const getShortDisplayName = computed(() => {
        return classData.value && classData.value.shortDisplayName;
    });

    const getPropEditState = (propName: string) => {
        const editState = useClassView().getEditState;

        if (!editState.value) return false;

        return editState.value[propName];
    };

    /**
     * Gets the edit state of the current class.
     * @type {ComputedRef<PropertyListEditState|undefined>}
     */
    const getEditState: ComputedRef<
        Record<string, PropertyListEditState | undefined> | undefined
    > = computed(() => {
        return classData.value && classData.value.editState;
    });

    const getPrimaryLabel = computed(() => {
        return classData.value && classData.value.primaryLabel;
    });

    const getPrimaryId = computed(() => {
        return classData.value && classData.value.primaryID;
    });

    /**
     * Builds an array of strings containing propertyType.propertyName.
     * @param {string} propertyTypeName - Name of the property that wraps the ones to be flattened.
     * Eg. "relationalProperties" or "annotationProperties"
     * @return {string[]}
     */
    const flattenProps = (propertyTypeName: string) => {
        if (!classData.value) return;

        const propertyValues: Record<string, unknown> =
            classData.value[propertyTypeName] || {};

        const props = orderBy(Object.keys(propertyValues));

        return props.map((propName) => propertyTypeName + '.' + propName);
    };

    /**
     * Lists all relational properties names.
     * @type {ComputedRef<any[] | string[]>}
     */
    const getRelationalPropertiesNames = computed(() => {
        if (!classData.value) return [];

        const relationalPropertiesNames = classData.value.relationalProperties
            ? Object.keys(classData.value.relationalProperties)
            : [];

        return [...relationalPropertiesNames, ...RELATIONSHIP_PROPS];
    });

    /**
     * Flattened names for standard and custom relational properties. Initialises relationships tab to
     * all relationship properties if none defined.
     * NOTE: the order of the array determines the rendering order.
     */
    const getFlatRelationshipProps = computed(() => {
        const relationalPropertiesType: string =
            process.env.VUE_APP_RELATIONAL_WRAPPER || '';
        const customProps = flattenProps(relationalPropertiesType) || [];

        const relationshipProps = RELATIONSHIP_PROPS.concat(customProps);

        const relationshipsTab = find(CLASS_TABS, {
            title: 'relationships',
        }) as { title: string; properties: string[] };

        if (
            relationshipsTab &&
            relationshipsTab.properties &&
            !relationshipsTab.properties.length
        ) {
            relationshipsTab.properties = relationshipProps;
        }

        return relationshipProps;
    });

    /**
     *
     * @param {Keys<definitions["OntologyJSONBean"]>} propertyName
     * @param {definitions["OntologyJSONBean"]} classData
     * @return {boolean}
     */
    const isRelationshipProperty = (
        propertyName: string,
        classData: ClassDataExtended | undefined = undefined
    ) => {
        classData = classData || useClassView().getClassData.value;

        if (!classData) return false;
        return (
            !!classData[propertyName] ||
            !!(
                classData.relationalProperties &&
                classData.relationalProperties[propertyName]
            )
        );
    };

    /**
     * If experimental flag is enabled.
     * @type {boolean}
     * @todo Remove hard-coded `true`
     */
    const experimentalFlagOn = computed((): boolean => {
        return true;
        return useStore().getters.experimental;
    });

    /**
     * Determines which schema version should we use. If experimental flag is off, always use schema v1.
     * @type {ComputedRef<number>}
     */
    const whichSchemaVersion = computed(() => {
        if (!experimentalFlagOn.value) return 1;

        return isSchemaVersion2.value ? 2 : 1;
    });

    /**
     * Gets the current schema version.
     * @type {ComputedRef<number|undefined>}
     */
    const getSchemaVersion = computed(() => {
        return classData.value && classData.value.schemaVersion;
    });

    /**
     * Checks if the current schema version number equals 2.
     * @type {ComputedRef<undefined | boolean>}
     */
    const isSchemaVersion2 = computed(() => {
        // @ts-ignore
        return classData.value && classData.value.schemaVersion >= 2;
    });

    /**
     * Checks if the current schema version number equals 1.
     * @type {ComputedRef<undefined | boolean>}
     */
    const isSchemaVersion1 = computed(() => {
        // @ts-ignore
        return classData.value && classData.value.schemaVersion === 1;
    });

    /**
     * Determines if we should use schemaversion 2.
     * @type {ComputedRef<boolean>}
     */
    const useSchemaVersion2 = computed(() => {
        return whichSchemaVersion.value >= 2;
    });

    /**
     * Computes the primary label's IRI.
     * @type {ComputedRef<any>}
     */
    const computePrimaryLabelIri = computed(() => {
        if (!classData.value) return;
        if (!classData.value.propertyValues) return;

        const primaryLabelValue = classData.value.primaryLabel;
        const labelIris = useOntology().getLabelIris.value || ([] as string[]);

        for (const propertyValue of classData.value.propertyValues) {
            const propertyIri = propertyValue.iri as string;

            if (!labelIris || !labelIris.includes(propertyIri)) continue;
            if (propertyValue.value !== primaryLabelValue) continue;

            return propertyValue.iri;
        }
    });

    /**
     * Sets the primary label's IRI when the class is first loaded.
     */
    const updatePrimaryLabelIri = () => {
        const labelIri = computePrimaryLabelIri.value;

        primaryLabelIri.value = labelIri;
    };

    /**
     * Retrieves the primary label IRI.
     * @type {ComputedRef<string | undefined>}
     */
    const getPrimaryLabelIri = computed(() => {
        return primaryLabelIri.value;
    });

    const resetClassData = () => {
        classData.value = cloneDeep(classDataSnapshot);
        mustShowRelationalProperties.value = [...RELATIONSHIP_PROPS];
    };

    const setClassData = async (data: ClassDataExtended) => {
        classData.value = data;
        classDataSnapshot = cloneDeep(data);

        const currentOntology = useOntology().getCurrentOntology.value;

        if (!currentOntology) {
            const ontologyId = classData.value?.sourceUniqueID;

            if (ontologyId) {
                const response = await getOntologyMetadata({ ontologyId });
                const ontology = response.ontologyMetadataJSONBean;
                useOntology().setCurrentOntology(
                    ontology as OntologyMetaDataJSONBeanExtended
                );
            }
        }

        updatePrimaryLabelIri();
        usePropertyValues().initialisePropertyValuesByIri();
        usePropertyValues().initialisePropertyValuesByCamelGroupName();
        usePropertyList().buildPropertyList();

        mustShowRelationalProperties.value = [...RELATIONSHIP_PROPS];
    };

    /**
     * Sets the classCache object.
     * @param {Record<string, ClassDataExtended>} data
     */
    const setClassCache = (
        data: Record<string, ClassDataExtended> | Record<string, never>
    ) => {
        classCache.value = data;
    };

    /**
     * Sets the current class object primary label.
     * This will reactively change the class title and tree.
     * @param {string} primaryLabel
     */
    const setPrimaryLabel = (primaryLabel: string) => {
        classData.value && set(classData.value, 'primaryLabel', primaryLabel);
    };

    /**
     * Gets the parent property name of a property in the class object.
     * For custom relational properties this will be `relationalProperties`
     * while for custom annotations `annotationProperties`.
     * @param {string} propName
     * @return {''|'relationalProperties'|'annotationProperties'}
     */
    const getPropertyParentName = (propName: string) => {
        if (useOntology().isCustomAnnotationProperty(propName))
            return 'annotationProperties';
        if (useOntology().isCustomRelationalProperty(propName))
            return 'relationalProperties';
        return '';
    };

    /**
     * Sets a single property value.
     * @param {string} propName
     * @param newValue
     * @todo Consider not to use `set` but simple assignment
     * @version up to 1.6
     */
    const setPropValue = async (propName: string, newValue: unknown) => {
        classData.value && set(classData.value, propName, newValue);
        await useEdits().countClassChanges();
    };

    /**
     * Retrieve a property value.
     * If schema 2 is detected will first look into the propertyValues array.
     * @param {string} propName
     * @return {string[] | (string | undefined)[]}
     */
    const getPropValue = (propName: string): string[] | undefined => {
        if (!classData.value) return;

        if (whichSchemaVersion.value >= 2) {
            const propValue = usePropertyValues().getPropertyByName(propName);
            if (propValue) return propValue;
        }

        const propertyParentName = getPropertyParentName(propName);

        if (propertyParentName)
            return (
                (get(
                    classData.value[propertyParentName],
                    propName
                ) as string[]) || []
            );

        return (get(classData.value, propName) as string[]) || [];
    };
    /**
     * Given a prop name and a value to add/remove, builds and return the updated value
     * (without updating the class data).
     * @param {string} propName
     * @param {string | string[]} newValue
     * @return {string[]|undefined}
     */
    const buildNewPropValueArray = (
        propName: string,
        value: string,
        action: 'remove' | 'add'
    ): string[] | undefined => {
        if (!classData.value) return;

        const currentPropValue = getPropValue(propName);

        if (action === 'add') {
            return currentPropValue && currentPropValue.concat(value);
        }

        if (action === 'remove') {
            return (
                currentPropValue &&
                currentPropValue.filter((item) => trim(item) !== trim(value))
            );
        }
    };

    const buildNewPropValueArrayFromIri = (
        iri: string,
        value: string,
        action: 'remove' | 'add'
    ): string[] | undefined => {
        if (!classData.value) return;

        const currentPropValue =
            usePropertyValues().getPropertyValuesByIri(iri) || [];

        if (action === 'add') {
            return currentPropValue && currentPropValue.concat(value);
        }

        if (action === 'remove') {
            return (
                currentPropValue &&
                currentPropValue.filter((item) => trim(item) !== trim(value))
            );
        }
    };

    /**
     * Adds a new item to the current class data.
     * @param {string} propName
     * @param {string} value
     * @return {string[]}
     */
    const addItemToPropList = (propName: string, value: string) => {
        const updatedPropValue = buildNewPropValueArray(propName, value, 'add');

        classData.value && set(classData.value, propName, updatedPropValue);

        return updatedPropValue;
    };

    /**
     * Removes an item from the current class data.
     * @param {string} propName
     * @param {string} valueToRemove
     * @return {string[]}
     */
    const removeItemFromPropList = (
        propName: string,
        valueToRemove: string
    ) => {
        const updatedPropValue = buildNewPropValueArray(
            propName,
            valueToRemove,
            'remove'
        );

        classData.value && set(classData.value, propName, updatedPropValue);

        return updatedPropValue;
    };

    /**
     * Looks for a class in the tree, if it's not found look for a class in the classCache.
     * @param {string} primaryId
     * @return {ClassDataExtended|undefined}
     * @todo Check if the commented text is needed
     */
    const getLoadedClass = (primaryId: string) => {
        const resolvedClass = useClassTree().searchClassInTree(primaryId);

        // if (resolvedClass && typeof resolvedClass !== 'boolean') {
        //     cacheClass(primaryId, resolvedClass);
        // } else if (classCache.value && classCache.value[primaryId]) {
        //     resolvedClass = classCache.value[primaryId];
        //     delete resolvedClass.editState;
        // }

        return resolvedClass || undefined;
    };

    //#region Utility classes
    /**
     * Given a property name, determines if there are annotations against it.
     * @param {string} propName
     * @returns {boolean}
     */
    const propertyHasAnnotations = (propName: string) => {
        const propertyValuesByName = getClassTagsByPropName.value;
        if (!propertyValuesByName) return false;

        return !!propertyValuesByName[propName];
    };

    /**
     * Tests if a single array-type property item has changed.
     * @param {string} propItemValue - Value of the specific item in the array-type property.
     * @return {boolean}
     */
    const isPropValueUnchanged = (propName: string, propItemValue: string) => {
        const propEditState =
            getEditState.value && getEditState.value[propName];
        const history = propEditState && (propEditState.history as string);

        return history && history.indexOf(propItemValue) === -1;
    };

    /**
     * Checks if a property defers to an annotation property (custom or default).
     * @param {string} propName
     * @return {boolean}
     */
    const isAnnotationProperty = (propName: string) => {
        const annotationPropertyNames =
            useOntology().currentAnnotationPropertyNames.value;

        if (!annotationPropertyNames) return;

        return annotationPropertyNames.includes(propName);
    };

    /**
     * Checks if a property name refers to a default annotation property.
     * @param {string} propName
     * @return {boolean}
     */
    const isDefaultAnnotationProp = (propName: string) => {
        return ANNOTATION_PROPS.includes(propName);
    };

    /**
     * Updates the primary label in all relevant objects that refers to it:
     * 1. OntologyJsonBean.primaryLabel
     * 2. OntologyJsonBean.propertyValues
     * 3. PropertyList
     * @param {string} oldValue
     * @param {string} newValue
     */
    const updatePrimaryLabel = (oldValue: string, newValue: string) => {
        const classData = useClassView().getClassData.value;
        const propertyValues = classData?.propertyValues;

        if (!classData || !propertyValues) return;

        // Updates the current class object's primaryLabel
        setPrimaryLabel(newValue);

        let primaryLabelIri = '';

        // Updates the primary label in the propertyValues array
        for (const property of propertyValues) {
            if (property.name !== 'primary label') continue;

            set(property, 'value', newValue);
            primaryLabelIri = property.iri as string;
            break;
        }

        // Updates the primary label in the propertyList object
        usePropertyList().updateValue(
            'labels',
            primaryLabelIri,
            oldValue,
            newValue
        );
    };

    return {
        getOntologyData,
        getClassData,
        getClassCache,
        getClassTagsByPropName,
        getRelationalProperties,
        getEditState,
        getClassOntologyId,
        getClassElasticSearchId,
        getClassPrimaryId,
        classHasTags,
        getShortDisplayName,
        getPrimaryLabel,
        getPrimaryId,
        getFlatRelationshipProps,
        getSchemaVersion,
        isSchemaVersion2,
        isSchemaVersion1,
        experimentalFlagOn,
        whichSchemaVersion,
        getPrimaryLabelIri,
        getMustShowRelationalProperties,
        useSchemaVersion2,
        getRelationalPropertiesNames,
        propertyHasAnnotations,
        isPropValueUnchanged,
        resetClassData,
        setClassData,
        setClassCache,
        getLoadedClass,
        getPropTags,
        setPropValue,
        setPrimaryLabel,
        getPropEditState,
        isRelationshipProperty,
        buildNewPropValueArray,
        buildNewPropValueArrayFromIri,
        addItemToPropList,
        removeItemFromPropList,
        getPropValue,
        getPropertyValue,
        updatePrimaryLabel,
        addRelationalProperty,
        getClassSpecificAnnotationProperties,
        getAnnotationProperties,
        removeRelationalProperties,
    };
};
