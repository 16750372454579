import Axios from 'axios';

const ENDPOINT = '/search';
const env = process.env;

export default {
    results({
        query,
        pageStart = 0,
        pageSize = env.VUE_APP_SEARCH_SIZE,
        ontologies = [],
        isExact = false,
        isObsolete = false,
        searchType = 'class',
    }) {
        let url = ENDPOINT;

        if (searchType === 'property') {
            url = url + '/properties';
        } else if (searchType === 'instance') {
            url = url + '/instances';
        }

        if (isObsolete) {
            url = url + '/obsolete';
        }

        if (isExact) {
            url = url + '/exact';
        }

        return Axios.get(url, {
            params: {
                q: query,
                from: pageStart,
                size: pageSize,
                ontology: ontologies.join(','),
            },
        });
    },

    suggestions({ query, ontologies, isExact, isObsolete, searchType }) {
        return this.results({
            query,
            pageSize: env.VUE_APP_SUGGESTION_POOL,
            ontologies,
            isExact,
            isObsolete,
            searchType,
        });
    },

    /**
     * Searches classes by exact label.
     *
     * @param {String} ontologyID
     * @param {String} label
     * @returns {AxiosPromise<any>}
     */
    byLabel(ontologyID, label) {
        const url = `${ENDPOINT}/${ontologyID}/exactLabel`;

        return Axios.get(url, {
            params: { label },
        });
    },

    classesByPrimaryIds(ontologyID, primaryIds) {
        const url = `${ENDPOINT}/${ontologyID}/primaryIds`;

        return Axios.post(url, primaryIds);
    },

    propertiesByPrimaryIds(ontologyID, primaryIds) {
        const url = `${ENDPOINT}/properties/${ontologyID}/primaryIds`;

        return Axios.post(url, primaryIds);
    },

    obsoleteClasses(ontologyID, query, size = 10, from = 0) {
        const url = `${ENDPOINT}/obsolete/exact`;

        return Axios.get(url, {
            params: {
                ontology: ontologyID,
                q: query,
                size,
                from,
            },
        });
    },
};
