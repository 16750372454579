import CryptoJS from 'crypto-js';

/**
 * Use base64 encryption to encode a string.
 * @param {string} text
 * @return {string}
 */
export const base64Encode = (text: string) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};
