/**
 * A lookup object to map property names contained in definitions["CustomProperty"] to
 * camel-cased property names.
 * @type {{synonym: string, mapping: string, 'primary label': string, label: string, 'textual definition': string}}
 */
export const PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP: Record<string, string> =
    {
        'primary label': 'labels',
        label: 'labels',
        synonym: 'synonyms',
        'textual definition': 'textualDefinitions',
        mapping: 'mappings',
    };
