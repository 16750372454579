import { upperFirst, words } from 'lodash';
import { computed, Ref, ref, set } from '@vue/composition-api';
import { useClassView } from '@/compositions/useClassView';
import { components } from '@/ts/ApiSpecs';
import { useOntology } from '@/compositions/useOntology';
import {
    DEFAULT_PROPERTY_NAMES,
    PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP,
} from '@/config';
import { extractPropertyLanguageTag, isValidUrl } from '@/utils';
import { useEdits } from '@/compositions/useEdits';

const propertyValuesByIris: Ref<
    Record<string, string[]> | Record<string, never>
> = ref({});
const propertyValuesByPropGroupName: Ref<
    Record<string, Record<string, string[]>> | Record<string, never>
> = ref({});
const propertyValuesByCamelGroupName: Ref<{ iri: string[]; name?: string }[]> =
    ref([]);

/**
 * Handles the `propertyValues` property of the current class as defined in schema version 2.
 */
export const usePropertyValues = () => {
    const classData = useClassView().getClassData;

    const propertyValues = computed(() => {
        return classData.value && classData.value.propertyValues;
    });

    /**
     * Checks if the current class has values against the propertyValues tag.
     * @type {ComputedRef<boolean>}
     */
    const hasPropertyValues = computed((): boolean => {
        return !!(
            classData.value &&
            classData.value.propertyValues &&
            classData.value.propertyValues.length
        );
    });

    const getPropertyValuesByNames = computed(() => {
        if (!propertyValues.value) return {};

        const propertyValuesByNameCollection: Record<
            string,
            components['schemas']['PropertyValueVM'][]
        > = {};

        propertyValues.value.forEach((propertyValue) => {
            if (!propertyValue.name) return;

            propertyValuesByNameCollection[propertyValue.name] =
                propertyValuesByNameCollection[propertyValue.name] || [];
            propertyValuesByNameCollection[propertyValue.name].push(
                propertyValue
            );
        });

        return propertyValuesByNameCollection;
    });

    /**
     * Gets annotation properties contained in the propertyValues.
     * @type {ComputedRef<any>}
     * @todo Fix typescript issue and remove tsignore
     */
    const getAnnotationProperties = computed(() => {
        if (!hasPropertyValues.value || !propertyValues.value) return [];

        const annotationPropertyIris =
            useOntology().currentAnnotationProperties.value;

        if (!annotationPropertyIris) return [];

        return propertyValues.value.filter((property) => {
            return (
                property.iri &&
                annotationPropertyIris.find(
                    (propertyIri) => property.iri === propertyIri.iri
                )
            );
        });
    });

    /**
     *
     * @param {string} annotationPropertyName
     * @return {definitions["PropertyValueVM"]|undefined}
     */
    const getAnnotationPropertyByName = (annotationPropertyName: string) => {
        if (!getAnnotationProperties.value.length) return;
        let prop: components['schemas']['PropertyValueVM'] | undefined;

        for (const property of getAnnotationProperties.value) {
            if (property && property.name === annotationPropertyName) {
                prop = property;
                break;
            }
        }

        return prop;
    };

    /**
     * Retrieves the property value from the `propertyValue` property by name.
     * @param {String} propName The property name.
     * @todo probably not used, check and remove
     * @returns {Array<Stringæ>|undefined}
     */
    const getPropertyNames = (propName: string) => {
        const propertyValuesByNames = getPropertyValuesByNames.value;

        if (!propertyValuesByNames[propName]) return;

        const propertyValuesName: string[] = [];

        const values = propertyValuesByNames[
            propName
        ] as components['schemas']['PropertyValueVM'][];

        values.forEach((prop) => {
            if (!prop.value) return;

            propertyValuesName.push(prop.value);

            // this.propertyAnnotations[propName] = this.propertyAnnotations[propName] || {}
            // this.propertyAnnotations[propName][propValue.value] = propValue.tags;
        });

        return propertyValuesName;
    };

    const setPropertyValuesByIri = (valuesByIri: Record<string, string[]>) => {
        propertyValuesByIris.value = valuesByIri;
    };

    const setPropertyValuesByPropertyGroupName = (
        valuesByGroupName: Record<string, Record<string, string[]>>
    ) => {
        propertyValuesByPropGroupName.value = valuesByGroupName;
    };

    /**
     *
     * @type {ComputedRef<Record<string, string[]>>}
     */
    const initialisePropertyValuesByIri = () => {
        if (!classData.value || !classData.value.propertyValues) return {};

        const propertyValuesByIrisTemp: Record<string, string[]> = {};
        const propertyValuesByPropNamesTemp: Record<
            string,
            Record<string, string[]>
        > = {};
        const classPropertiesTemp: components['schemas']['CustomProperty'][] =
            [];

        classData.value.propertyValues.forEach((propValue) => {
            if (!propValue || !propValue.value || !propValue.iri) return;

            const propertyGroup = getPropertyGroupByIri(propValue.iri);

            if (!propertyValuesByIrisTemp[propValue.iri])
                propertyValuesByIrisTemp[propValue.iri] = [];
            if (propertyGroup && !propertyValuesByPropNamesTemp[propertyGroup])
                propertyValuesByPropNamesTemp[propertyGroup] = {};
            if (
                propertyGroup &&
                !propertyValuesByPropNamesTemp[propertyGroup][propValue.iri]
            )
                propertyValuesByPropNamesTemp[propertyGroup][propValue.iri] =
                    [];

            propertyValuesByIrisTemp[propValue.iri].push(propValue.value);
            if (propertyGroup)
                propertyValuesByPropNamesTemp[propertyGroup][
                    propValue.iri
                ].push(propValue.value);
        });

        propertyValuesByIris.value = {};

        setPropertyValuesByIri(propertyValuesByIrisTemp);
        setPropertyValuesByPropertyGroupName(propertyValuesByPropNamesTemp);
    };

    const initialisePropertyValuesByCamelGroupName = () => {
        if (!classData.value || !classData.value.propertyValues) return;

        // const classPropertiesTemp: { iri: string[], name: string }[] = [];
        const classPropertiesTemp:
            | Record<string, { iri: string[]; name?: string }>
            | Record<string, never> = {};

        classData.value.propertyValues.forEach((propValue) => {
            if (!propValue || !propValue.value || !propValue.iri) return;

            const propertyGroup = getPropertyGroupByIri(propValue.iri);
            const propertyNameCamel =
                propertyGroup &&
                (PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP[propertyGroup] ||
                    propertyGroup);

            if (!propertyNameCamel) return;

            if (!classPropertiesTemp[propertyNameCamel]) {
                classPropertiesTemp[propertyNameCamel] = {
                    iri: [propValue.iri],
                    name: propertyNameCamel,
                };
            } else if (
                !classPropertiesTemp[propertyNameCamel].iri.includes(
                    propValue.iri
                )
            ) {
                classPropertiesTemp[propertyNameCamel].iri.push(propValue.iri);
            }
        });

        const defaultRelationalProperties = useOntology()
            .getDefaultRelationalProperties.value as unknown as {
            iri: string[];
            name?: string;
        }[];
        propertyValuesByCamelGroupName.value = [
            ...Object.values(classPropertiesTemp),
            ...defaultRelationalProperties,
        ];
    };

    /**
     * Gets a property from the `propertyValues` prop of the class.
     * @param {string} propertyName
     * @return {string[]|undefined}
     * @since 2.0
     */
    const getPropertyValuesByIri = (
        propIri: string,
        classData:
            | components['schemas']['OntologySummaryVM']
            | undefined = undefined
    ) => {
        classData = classData || useClassView().getClassData.value;
        if (!classData || !classData.propertyValues || !propIri) return;

        return propertyValuesByIris.value[propIri];
    };

    const getPropertyValuesNames = () => {
        if (!classData.value || !classData.value.propertyValues) return [];
        const propertyValuesNames: string[] = [];

        classData.value.propertyValues.forEach((propertyValue) => {
            if (!propertyValue.name) return;
            if (!propertyValuesNames.includes(propertyValue.name)) {
                propertyValuesNames.push(propertyValue.name);
            }
        });

        return propertyValuesNames;
    };

    /**
     * Checks if a property name is one of the default ones.
     * @param {string} propertyName
     * @return {boolean}
     */
    const isDefaultPropertyName = (propertyName: string) => {
        return DEFAULT_PROPERTY_NAMES.includes(propertyName);
    };

    /**
     * Given a iri gets the string after the hash in human readable format.
     * @param {string} propIri
     * @return {string}
     */
    const extractPropertyNameFromIri = (propIri: string) => {
        const iriHash = propIri.substr(propIri.lastIndexOf('#') + 1);
        const iriInWords = words(iriHash).join(' ');

        return upperFirst(iriInWords);
    };

    /**
     * Gets the property metadata name (eg. 'textualDefinitions', 'synonyms')
     * @param {string} propIri
     * @return {string}
     */
    const getPropertyGroupName = (propIri: string) => {
        if (!classData.value || !classData.value.propertyValues || !propIri)
            return;

        const annotationProperties =
            useOntology().currentAnnotationProperties.value;
        let propertyGroupName = '';

        annotationProperties.some((property) => {
            const annotationProperty =
                property as components['schemas']['CustomProperty'];

            if (!annotationProperty.iri) return false;
            if (!annotationProperty.iri.includes(propIri)) return false;

            propertyGroupName = annotationProperty.name || '';
            return true;
        });

        return propertyGroupName;
    };

    /**
     *
     * @param {string} propIri
     * @return {string}
     */
    const getPropertyNameByIri = (propIri: string) => {
        if (!classData.value || !classData.value.propertyValues || !propIri)
            return;

        const propertyValues = classData.value
            .propertyValues as components['schemas']['PropertyValueVM'][];

        let propertyName: string | undefined;

        propertyValues.some((propValue) => {
            if (propValue.iri !== propIri && propValue.value) return false;

            propertyName = propValue.name;
            return true;
        });

        if (!propertyName) {
            return extractPropertyNameFromIri(propIri);
        }

        return propertyName;
    };

    const getPropertyGroupByIri = (propIri: string) => {
        if (!classData.value || !classData.value.propertyValues || !propIri)
            return;

        const propertyValues = classData.value
            .propertyValues as components['schemas']['PropertyValueVM'][];

        let propertyGroupName: string | undefined;

        propertyValues.some((propValue) => {
            if (propValue.iri !== propIri) return false;
            propertyGroupName = propValue.name;

            return true;
        });

        return propertyGroupName;
    };

    /**
     * Gets a property identified by the combination of iri and value.
     * @param {string | string[]} propertyIri
     * @param {string} propertyValue
     * @return {any}
     */
    const getPropertyByIriAndValue = (
        propertyIri: string | string[],
        propertyValue: string,
        lang: string | undefined = undefined
    ): components['schemas']['PropertyValueVM'] | undefined => {
        if (!propertyValues.value) return;

        for (const property of propertyValues.value) {
            if (!property.iri) continue;

            // const propertyLang = extractPropertyLanguageTag(property);

            // COULD BREAK DUPLICATE FINDING
            // if (propertyLang !== lang) continue;

            if (
                property.iri === propertyIri &&
                property.value === propertyValue
            )
                return property;
            if (
                propertyIri.includes(property.iri) &&
                property.value === propertyValue
            )
                return property;
            continue;
        }
    };

    /**
     * Retrieves property values under a given property name.
     * Returns alternatively an array of iris, string values or the full property value object.
     * @param {string} propertyName
     * @param {"iri" | "value" | "object"} returnType
     * @param {{annotationProperties?: {[p: string]: string[]}, anonymousEquivalentClasses?: definitions["ClassExpression"][], anonymousSuperClasses?: definitions["ClassExpression"][], derivesFrom?: string[], developsFrom?: string[], entityType?: string, entityUniqueID?: string, equivalences?: string[], id?: string, mappings?: string[], numberOfChildren?: number, partOf?: string[], primaryID?: string, primaryLabel?: string, propertyValues?: definitions["PropertyValueVM"][], relationalProperties?: {[p: string]: string[]}, schemaVersion?: number, shortDisplayName?: string, shortFormIDs?: string[], sourceUniqueID?: string, superClasses?: string[], synonyms?: string[], textualDefinitions?: string[], typeOfNode?: "subClassOf" | "partOf" | "derivesFrom" | "developsFrom" | "equivalence"} | undefined} classData
     * @return {any[] | string[] | definitions["PropertyValueVM"][]}
     */
    const getPropertyValuesByName = (
        propertyName: string,
        returnType: 'iri' | 'value' | 'object',
        classData:
            | components['schemas']['OntologySummaryVM']
            | undefined = undefined
    ) => {
        classData = classData || useClassView().getClassData.value;

        if (!classData || !classData.propertyValues) return [];

        const propertyNameAsInPropertyValues =
            PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP['propertyName'];

        return classData.propertyValues.map((propertyValue) => {
            if (propertyValue.name === propertyNameAsInPropertyValues) {
                return returnType === 'object'
                    ? propertyValue
                    : propertyValue[returnType];
            }
        }) as string[] | components['schemas']['PropertyValueVM'][];
    };

    /**
     * Given a property name, returns its iri.
     * @param {string} propertyName
     * @return {undefined | string[]}
     */
    const getPropIriByName = (
        propertyName: string,
        classData:
            | components['schemas']['OntologySummaryVM']
            | undefined = undefined
    ) => {
        return getPropertyValuesByName(propertyName, 'iri', classData);
    };

    /**
     * Gets a property from the `propertyValues` prop of the class.
     * @param {string} propertyName
     * @since 2.0
     * @return {definitions["PropertyValueVM"]|undefined}
     */
    const getPropertyByName = (propertyName: string) => {
        const iri = useOntology().getIriByPropName(propertyName);

        return iri && getPropertyValuesByIri(iri);
    };

    /**
     * Gets the tags of a property by property iri and property value.
     * @param {string} propertyIri
     * @param {string} propertyValue
     * @param {"asc" | "desc" | false} sorted
     * @since 2.0
     * @todo Fix sorting
     * @return {definitions["Tag"][]}
     */
    const getTagsByPropertyIri = (
        propertyIri: string[] | string,
        propertyValue: string,
        lang: string | undefined = undefined
    ) => {
        const property = getPropertyByIriAndValue(
            propertyIri,
            propertyValue,
            lang
        ) as components['schemas']['PropertyValueVM'];
        if (!property) return;

        if (!property.tags) set(property, 'tags', []);

        return property.tags;
    };

    /**
     * Returns the property tags as a new object without referencing the original value.
     * @param {string} propertyIri
     * @param {string} propertyIri
     * @param {"asc" | "desc" | false} sorted
     * @return {definitions["Tag"][]}
     */
    const clonePropertyTags = (
        propertyIri: string,
        propertyValue: string,
        lang: string | undefined = undefined
    ): components['schemas']['Tag'][] => {
        const referenceTags =
            getTagsByPropertyIri(propertyIri, propertyValue, lang) || [];

        const stringifiedTags = JSON.stringify(referenceTags);

        return JSON.parse(stringifiedTags);
    };

    /**
     * Gets the language tag of a property identified by iri and value.
     * @param {string} propertyIri
     * @param {string} propertyValue
     * @return {string | undefined}
     */
    const getPropertyLanguageTag = (
        propertyIri: string,
        propertyValue: string
    ) => {
        const tags = getTagsByPropertyIri(propertyIri, propertyValue);
        const languageTags = tags && tags.filter((tag) => tag.type === 'LANG');
        const language = languageTags ? languageTags[0] : { value: '' };

        return language ? language.value : undefined;
    };

    /**
     * Gets the tags of a property by property name and property value.
     * @param {string} propName
     * @param {string} propertyValue
     * @since 2.0
     * @return {undefined | definitions["Tag"][] | any[]}
     */
    const getTagsByPropName = (
        propName: string,
        propertyValue: string,
        lang: string | undefined
    ) => {
        const propertyIri = getPropIriByName(propName) as string[];

        if (!propertyIri) return [];

        return getTagsByPropertyIri(propertyIri, propertyValue, lang);
    };

    /**
     * Gets a property's language
     * @param {string} propertyIri
     * @param {string} propertyValue
     * @return {string|undefined}
     */
    const getPropertyLanguage = (
        propertyIri: string,
        propertyValue: string
    ): string | undefined => {
        const property = getPropertyByIriAndValue(
            propertyIri,
            propertyValue
        ) as components['schemas']['PropertyValueVM'] & { lang: string };

        if (!property) return;

        return (
            property.lang || getPropertyLanguageTag(propertyIri, propertyValue)
        );
    };

    /**
     * Updates a property object identified by the combination iri and value.
     * @param {string} propertyIri
     * @param {string} propertyValue
     * @param {string} newValue
     * @param lang
     * @since 2.0
     */
    const updatePropertyValue = (
        propertyIri: string | string[],
        propertyValue: string,
        newValue: string,
        lang: string | undefined = undefined
    ) => {
        const property = getPropertyByIriAndValue(
            propertyIri,
            propertyValue,
            lang
        ) as components['schemas']['PropertyValueVM'];
        if (!property) return;

        property.value = newValue;
    };

    /**
     * Removes a property, identified by IRI and value, from the propertyValues array.
     * @param {string} propertyIri
     * @param {string} propertyValue
     * @since 2.0
     * @return {definitions["PropertyValueVM"][]|undefined} - Undefined if the property can't be removed, or the updated array.
     */
    const removePropertyValue = (
        propertyIri: string | string[],
        propertyValue: string
    ) => {
        if (!propertyValues.value) return;

        const values =
            propertyValues.value as components['schemas']['PropertyValueVM'][];

        values.forEach((property, index) => {
            if (
                property.iri !== propertyIri ||
                property.value !== propertyValue
            )
                return;

            values.splice(index, 1);
        });

        return values;
    };

    /**
     * Adds a property value to the propertyValues object.
     * @param {string} iri
     * @param {string} value
     * @param {string | undefined} propertyIri
     * @return {definitions["PropertyValueVM"][]}
     */
    const addPropertyValue = (iri: string, value: string, name: string) => {
        if (!propertyValues.value) return;

        const values =
            propertyValues.value as components['schemas']['PropertyValueVM'][];

        values.push({ iri, name, value });

        return values;
    };

    /**
     * Removes a property, identified by name and value, from the propertyValues array.
     * @param {string} propertyName
     * @param {string} propertyValue
     * @since 2.0
     * @return {definitions["PropertyValueVM"][]|undefined} - Undefined if the property can't be removed, or the updated array.
     */
    const removePropertyValueByPropName = (
        propertyName: string,
        propertyValue: string
    ) => {
        const propertyIri = getPropIriByName(propertyName) as string[];

        if (!propertyIri) return;

        return removePropertyValue(propertyIri, propertyValue);
    };

    const isPrimaryLabel = (
        propertyValue: string,
        property: components['schemas']['CustomProperty'],
        propertyGroupName: string
    ): boolean => {
        if (propertyGroupName !== 'Label') return false;
        if (propertyValue === useClassView().getPrimaryLabel.value) return true;

        return false;
    };

    const isClickableUrl = (url: string) => {
        if (useEdits().getEditIsActive.value) return false;
        return isValidUrl(url);
    };

    return {
        getPropertyValuesByNames,
        getAnnotationProperties,
        propertyValues,
        propertyValuesByIris,
        propertyValuesByPropGroupName,
        propertyValuesByCamelGroupName,
        getAnnotationPropertyByName,
        getPropertyNames,
        getPropertyByName,
        getPropertyNameByIri,
        getPropertyGroupName,
        getPropertyValuesByIri,
        getPropertyByIriAndValue,
        getTagsByPropertyIri,
        getPropertyValuesNames,
        clonePropertyTags,
        getTagsByPropName,
        getPropIriByName,
        updatePropertyValue,
        removePropertyValue,
        removePropertyValueByPropName,
        addPropertyValue,
        getPropertyLanguage,
        initialisePropertyValuesByIri,
        initialisePropertyValuesByCamelGroupName,
        isPrimaryLabel,
        isClickableUrl,
    };
};
