<template>
    <div
        class="class-summary"
        :class="{ 'highlighted-summary': highlightProps.length }">
        <div
            v-for="(propName, index) in serialPropNames"
            class="summary-property"
            :class="{
                'highlighted-property': highlightProps.indexOf(propName) !== -1,
            }"
            :key="propName">
            <template v-if="getPropValue(propName, $attrs).length">
                <strong
                    class="property-prefix mr-1"
                    :class="{
                        'd-none': hidePropertyPrefix.indexOf(propName) !== -1,
                    }">
                    {{ upperFirst(humanReadable(propName))
                    }}<span class="font-weight-normal">:</span> </strong
                ><!--
                --><editable-text
                    v-if="propLengthMax[index] !== 0"
                    ref="propValue"
                    :text="
                        serialPropValue(
                            propName,
                            allUpperFirst.indexOf(propName) !== -1,
                            hideFullStop.indexOf(propName) === -1
                        )
                    "
                    :maxLength="
                        Array.isArray(propLengthMax)
                            ? propLengthMax[index]
                            : propLengthMax
                    " /><!--
                -->
                <span v-else ref="propValue">{{
                    serialPropValue(
                        propName,
                        allUpperFirst.indexOf(propName) !== -1,
                        hideFullStop.indexOf(propName) === -1
                    )
                }}</span>
            </template>
            <template v-else-if="isPlaceholder">
                No {{ $pluralize.singular(humanReadable(propName)) }} found.
            </template>
        </div>
        <slot></slot>

        <span v-if="isShowBadges">
            <b-badge v-if="isRootClass && !isObsolete" variant="dark" pill
                >Root class</b-badge
            >

            <template v-if="hasEditState">
                <b-badge v-if="isObsolete" class="obsolete-badge" pill
                    >Obsolete class</b-badge
                >
                <b-badge v-else-if="isNew" variant="info" pill
                    >New class</b-badge
                >
            </template>

            <template v-if="$attrs.schemaVersion === 1">
                <span v-for="propName in hasBadgeProperties" :key="propName">
                    <b-badge
                        tabindex="0"
                        href="#"
                        variant="secondary"
                        :id="`${propName}-${$attrs.id}-${global_randomID}`"
                        :pill="!isPopover"
                        :class="[
                            'property-badge align-middle mr-1',
                            `${propName}-badge`,
                            {
                                'custom-badge': isCustomProperty(propName),
                                'annotations-badge':
                                    annotationProps.indexOf(propName) !== -1,
                                'relationship-badge':
                                    relationshipProps.indexOf(propName) !== -1,
                            },
                        ]"
                        @click="isPopover && onPropBadge($event)"
                        @blur="isPopover && offPropBadge($event)">
                        <span class="align-middle">
                            {{ createBadgeString(propName) }}
                        </span>
                        <span class="badge badge-light property-count">
                            {{ getPropValue(propName, $attrs).length }}
                        </span>
                    </b-badge>

                    <!-- Clicking property list -->
                    <b-popover
                        v-if="isPopover"
                        placement="bottom"
                        triggers="hover click blur"
                        boundary="viewport"
                        :target="`${propName}-${$attrs.id}-${global_randomID}`"
                        :delay="{ show: 300, hide: 0 }"
                        @show="onPopoverShow">
                        <property-value
                            v-for="(value, index) in getPropValue(
                                propName,
                                $attrs
                            )"
                            :key="`propName-${index}`"
                            :propName="
                                $pluralize.singular(humanReadable(propName))
                            "
                            :propOntoID="$attrs.sourceUniqueID"
                            :propPrimID="$attrs.primaryID"
                            :value="value"
                            :isClass="
                                propName === 'mappings' ||
                                relationshipProps.indexOf(propName) !== -1
                            "
                            :isGlobalClassScope="propName === 'mappings'"
                            :initClass="{}" />
                    </b-popover>
                </span>
            </template>
            <template v-else>
                <!-- Property badge -->
                <span
                    v-for="(length, propName) in hasBadgeProperties"
                    :key="propName">
                    <b-badge
                        v-if="propName && length"
                        tabindex="0"
                        href="#"
                        variant="secondary"
                        :key="`${propName}-${length}`"
                        :id="`${propName}-${$attrs.id}-${global_randomID}`"
                        :pill="!isPopover"
                        :class="[
                            'property-badge align-middle mr-1',
                            `${propName}-badge`,
                            {
                                'custom-badge': isCustomProperty(propName),
                                'annotations-badge':
                                    annotationProps.indexOf(propName) !== -1,
                                'relationship-badge':
                                    relationshipProps.indexOf(propName) !== -1,
                            },
                        ]"
                        @click="isPopover && onPropBadge($event)"
                        @blur="isPopover && offPropBadge($event)">
                        <template v-if="!createBadgeString(propName)">
                            <span
                                class="align-middle"
                                style="color: black !important">
                                {{ createBadgeString(propName) }}
                            </span>
                            <span class="badge badge-light property-count">
                                {{ length }}
                            </span>
                        </template>
                    </b-badge>

                    <!-- Clicking property list -->
                    <b-popover
                        v-if="isPopover"
                        placement="bottom"
                        triggers="hover click blur"
                        boundary="viewport"
                        :target="`${propName}-${$attrs.id}-${global_randomID}`"
                        :delay="{ show: 300, hide: 0 }"
                        @show="onPopoverShow">
                        <property-value
                            v-for="(value, index) in getPropValue(
                                propName,
                                $attrs
                            )"
                            :is-saving-dialogue="true"
                            :key="`propName-${index}`"
                            :propName="
                                $pluralize.singular(humanReadable(propName))
                            "
                            :propOntoID="$attrs.sourceUniqueID"
                            :propPrimID="$attrs.primaryID"
                            :value="value"
                            :isClass="
                                propName === 'mappings' ||
                                relationshipProps.indexOf(propName) !== -1
                            "
                            :isGlobalClassScope="propName === 'mappings'"
                            :initClass="{}" />
                    </b-popover>
                </span>
            </template>

            <template v-if="hasEditState && !isObsolete">
                <div v-if="hasError.length" class="text-danger">
                    <b-badge variant="danger" pill>Errors</b-badge>
                    {{ upperFirst(humanReadable(hasError).join(', ')) }}.
                </div>

                <div v-if="hasEdit.length && !isNew" class="text-info">
                    <b-badge variant="info" pill>Changed</b-badge>
                    {{ upperFirst(humanReadable(hasEdit).join(', ')) }}.
                </div>
            </template>
        </span>
    </div>
</template>

<script>
import { truncate, upperFirst } from "lodash";

import EditableText from "@/components/ui/EditableText";
import PropertyValue from "@/components/ui/PropertyValue";
import { humanReadable } from "@/utils";
import { ANNOTATION_PROPS, PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP, RELATIONSHIP_PROPS } from "@/config";

export default {
    name: 'ClassSummary',
    components: { EditableText, PropertyValue },
    inheritAttrs: false,
    props: {
        // Names for properties that should be serialised
        // NOTE: Flattened property names expected (eg: annotations.propertyName).
        serialPropNames: {
            type: Array,
            default: function () {
                return _.without(ANNOTATION_PROPS, 'mappings');
            },
        },

        annotationWrapper: {
            type: String,
            default: process.env.VUE_APP_ANNOTATION_WRAPPER,
        },

        relationshipWrapper: {
            type: String,
            default: process.env.VUE_APP_RELATIONAL_WRAPPER,
        },

        stdAnnotationProps: {
            type: Array,
            default: function () {
                return ANNOTATION_PROPS;
            },
        },

        stdRelationshipProps: {
            type: Array,
            default: function () {
                return RELATIONSHIP_PROPS;
            },
        },

        hidePropertyPrefix: {
            type: Array,
            default: function () {
                return ['textualDefinitions'];
            },
        },

        hideFullStop: {
            type: Array,
            default: function () {
                return ['primaryID'];
            },
        },

        allUpperFirst: {
            type: Array,
            default: function () {
                return ['textualDefinitions'];
            },
        },

        highlightProps: {
            type: Array,
            default: function () {
                return ['textualDefinitions'];
            },
        },

        // Maximum character length for truncated property values.
        propLengthMax: {
            type: [Number, Array],
            default: function () {
                return [115, 65];
            },
        },

        // True if empty properties should be signposted.
        isPlaceholder: {
            type: Boolean,
            default: true,
        },

        // True if class property/type badges are to be shown
        isShowBadges: {
            type: Boolean,
            default: true,
        },

        // True if superclasses badge is to be shown
        isSuperClassBadges: {
            type: Boolean,
            default: true,
        },

        // True if a summary popover for every property badge is to be displayed
        isPopover: {
            type: Boolean,
            default: false,
        },

        isNew: {
            type: Boolean,
            default: false,
        },

        isObsolete: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isRootClass: function () {
            return (
                this.$attrs &&
                this.$attrs.superClasses &&
                !this.$attrs.superClasses.length
            );
        },

        /**
         * Lists all annotation property names including custom ones, which are flattened.
         */
        annotationProps: function () {
            const customAnnoProps = this.flattenProps(
                this.annotationWrapper,
                this.$attrs
            );
            return this.stdAnnotationProps.concat(customAnnoProps);
        },

        /**
         * Lists all relationship property names including custom ones, which are flattened.
         */
        relationshipProps: function () {
            const customRelProps = this.flattenProps(
                this.relationshipWrapper,
                this.$attrs
            );
            const relProps = this.stdRelationshipProps.concat(customRelProps);

            if (this.isSuperClassBadges) {
                return relProps;
            } else {
                return _.without(relProps, 'superClasses');
            }
        },

        hasBadgePropertiesV1: function () {
            const props = this.annotationProps.concat(this.relationshipProps);
            _.pull(props, ...this.serialPropNames);
            return props.filter(
                (propName) => this.getPropValue(propName, this.$attrs).length
            );
        },

        /**
         * Determines if there are any non-blank, non-serialised class properties to be represented as badges and returns them.
         */
        hasBadgeProperties: function () {
            const classData = this.$attrs;

            if (classData.schemaVersion === 1) {
                return this.hasBadgePropertiesV1;
            }

            const propertyValues = classData.propertyValues;
            const nonEmptyProperties = {};

            //Looks through each default relationship property
            for (const relationshipKey of RELATIONSHIP_PROPS) {
                if (typeof classData[relationshipKey] === 'undefined') continue;
                if (!classData[relationshipKey].length) continue;
                nonEmptyProperties[relationshipKey] =
                    classData[relationshipKey].length;
            }

            //Looks through the custom ontology relationships
            if (typeof classData.relationalProperties === 'undefined') return;
            for (const [relationshipKey, relationshipValue] of Object.entries(
                classData.relationalProperties
            )) {
                if (typeof relationshipValue === 'undefined') continue;
                nonEmptyProperties[relationshipKey] = relationshipValue.length;
            }

            //Looks through all of the annotation properties custom or default
            for (const property of propertyValues) {
                const defaultCamelPropName =
                    PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP[property.name]; //label
                const camelPropName = defaultCamelPropName
                    ? defaultCamelPropName
                    : `annotationProperties.${property.name}`;

                if (!nonEmptyProperties[camelPropName]) {
                    nonEmptyProperties[camelPropName] = 1;
                } else {
                    nonEmptyProperties[camelPropName]++;
                }
            }

            return nonEmptyProperties;
        },

        /**
         * Determines if any of the class' edited properties have errors.
         */
        hasError: function () {
            const editState = this.$attrs.editState;
            return Object.keys(editState).filter((property) => {
                return (
                    !editState[property].isSaving && editState[property].error
                );
            });
        },

        /**
         * Determines if any of the class' edited properties has been commited successfully.
         */
        hasEdit: function () {
            const editState = this.$attrs.editState;
            return Object.keys(editState).filter((property) => {
                return (
                    !editState[property].error &&
                    editState[property].isSaving === false
                );
            });
        },

        hasEditState: function () {
            return !_.isEmpty(this.$attrs.editState);
        },
    },

    methods: {
        /**
         * Serialises the values of a certain property as dictionary-like entries for the same term.
         * @param {string} propName - Name of the property.
         * @param {boolean} isAllUpperFirst - True if the first character of every entry should be made upper case.
         * @param {boolean} isFullStop - True if a full stop should be added at the end of the string if not present already.
         */
        serialPropValue(propName, isAllUpperFirst, isFullStop) {
            let propValue = this.$attrs[propName];

            if (Array.isArray(propValue)) {
                propValue = propValue
                    .map((value) => {
                        if (isAllUpperFirst) {
                            return this.upperFirst(value);
                        } else {
                            return value;
                        }
                    })
                    .join(' \u25CF ');
            }

            if (isFullStop) {
                return this.addFullStop(propValue);
            } else {
                return propValue;
            }
        },

        /**
         * Marks any property popover up so that it can be styled differently from other popovers.
         * @param {Object} event - DOM object for the directing event.
         */
        onPopoverShow(event) {
            const popoverEl = event.relatedTarget || event.target;
            popoverEl.classList.add('actionable');
        },

        /**
         * Makes actionable property badges toggable by adding a class when on the enabled state.
         * @param {Object} event - DOM object for the directing event.
         */
        onPropBadge(event) {
            const targetEl = event.currentTarget;

            if (targetEl.classList.contains('pressed')) {
                targetEl.classList.remove('pressed');
            } else {
                targetEl.classList.add('pressed');
            }
        },

        /**
         * Makes sure the actionable property badge is stripped of any toggle state class immediately after losing focus.
         * @param {Object} event - DOM object for the directing event.
         */
        offPropBadge(event) {
            const targetEl = event.currentTarget;
            targetEl.classList.remove('pressed');
        },

        createBadgeString(string) {
            if (typeof string !== 'string') {
                console.warn(string, `createBadgeString got a ${string}`);
                return;
            }
            let newString = humanReadable(string);
            newString = truncate(newString, 15);
            newString = upperFirst(newString);
            return newString;
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.summary-property:not(.highlighted-property) {
    color: $text-muted;

    .highlighted-summary & {
        font-size: 90%;
    }
}

.property-prefix,
::v-deep .toggle-text {
    display: inline-block;
    user-select: none;
}

.badge {
    &:not(:first-child):not(.property-count) {
        margin-left: 0.25rem;
    }

    &.obsolete-badge {
        background: transparent;
        border: 1px solid $dark;
        color: darken($dark, 5%);
    }

    &.property-badge {
        &:hover,
        &:focus {
            box-shadow: none;
        }

        &.pressed {
            box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.2);
        }

        &.custom-badge {
            background: transparent !important;

            .property-count {
                background: $secondary;
                color: $white;
            }

            &.annotations-badge {
                border: 1px solid $secondary;
                color: $secondary;

                &:hover,
                &:focus {
                    border-color: darken($secondary, 5%);
                    color: darken($secondary, 10%);

                    .property-count {
                        background: darken($secondary, 10%);
                    }
                }
            }
        }

        &.relationship-badge {
            &.custom-badge {
                border: 1px solid $tertiary;
                color: darken($tertiary, 5%);

                .property-count {
                    background: $tertiary;
                }

                &:hover,
                &:focus {
                    border-color: darken($tertiary, 15%);
                    color: darken($tertiary, 20%);

                    .property-count {
                        background: darken($tertiary, 15%);
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
// TODO: remove once custom classes supported
.popover[tabindex] {
    // Use a higher visual footprint for popovers that allow interaction within.
    &.actionable {
        max-width: 31rem;
        z-index: 1000;
    }

    // Reduce horizontal footprint of property list entries.
    .stacked-item-bullet {
        margin-right: 0.25rem !important;
    }
}
</style>
