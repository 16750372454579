import {
    defaults,
    intersection,
    isEmpty,
    keyBy,
    map,
    pick,
    reject,
    some,
    sortBy,
    union,
} from 'lodash';

import ApiOntology from '@/api/ontology';

import * as MutationTypes from './mutation-types';

const state = {
    // Indexed naming info of available ontologies
    ontoIndex: {},

    // IDs of ontologies previously visited
    pastOntoIDs: [],

    // Core properties of ontologies whose loading request is still in progress
    loadingOntos: [],

    // Map of currently applied filters
    filters: {
        ontologies: [],
        isExact: false,
        isObsolete: false,
        searchType: 'class',
    },

    // Query string of latest search
    query: '',
};

const mutations = {
    // The list of ontologies available to the app have been loaded
    [MutationTypes.SEARCH_INDEXONTOS](state, { ontoNames }) {
        state.ontoIndex = keyBy(ontoNames, (onto) => onto.ontologyUniqueID);
    },

    // Adds an entry to the ontology index
    [MutationTypes.SEARCH_INDEXADD](state, { ontoName }) {
        const newIndex = Object.assign({}, state.ontoIndex);

        newIndex[ontoName.ontologyUniqueID] = ontoName;
        state.ontoIndex = newIndex;
    },

    // Removes a certain ontology from the index
    [MutationTypes.SEARCH_INDEXDEL](state, { ontoID }) {
        const newIndex = Object.assign({}, state.ontoIndex);

        delete newIndex[ontoID];
        state.ontoIndex = newIndex;
    },

    // Adds an entry to the list of ontologies being loaded
    [MutationTypes.SEARCH_LOADSTART](state, { ontology }) {
        state.loadingOntos = union(state.loadingOntos, [ontology]);
    },

    // Removes an entry from the list of ontologies being loaded
    [MutationTypes.SEARCH_LOADEND](state, { ontoID }) {
        state.loadingOntos = reject(state.loadingOntos, {
            ontologyUniqueID: ontoID,
        });
    },

    // Destroys the lookup table for ontology data.
    [MutationTypes.SEARCH_FLUSHONTOS](state) {
        state.ontoIndex = {};
    },

    // One or several new ontologies have been visited/set as filters
    [MutationTypes.SEARCH_PASTONTOS](state, { ontoIDs }) {
        state.pastOntoIDs = ontoIDs;
    },

    // Clears ontology selection
    [MutationTypes.SEARCH_RESETONTOS](state) {
        state.filters.ontologies = [];
    },

    // Looks for class occurrances of the query
    [MutationTypes.SEARCH_TYPE](state, { searchType }) {
        state.filters.searchType = searchType;
    },

    // Looks for exact occurrances of the query
    [MutationTypes.SEARCH_EXACT](state, { isExact }) {
        state.filters.isExact = isExact;
    },

    // Looks for obsolete classes only
    [MutationTypes.SEARCH_OBSOLETE](state, { isObsolete }) {
        state.filters.isObsolete = isObsolete;
    },

    // The filters for the current search have been changed
    [MutationTypes.SEARCH_FILTERS](state, { filters }) {
        state.filters = filters;
    },

    // A search has been issued with a given query
    [MutationTypes.SEARCH_QUERY](state, { query }) {
        state.query = query;
    },
};

const getters = {
    ontoIndex: (state) => state.ontoIndex,
    hasOntoIndex: (state) => !isEmpty(state.ontoIndex),
    ontoIDs: (state) => Object.keys(state.ontoIndex),
    ontoData: (state) => (id) => {
        const data = state.ontoIndex[id];
        if (data) {
            return data;
        } else {
            return {
                ontologyLongDisplayName: '',
                ontologyShortDisplayName: '',
            };
        }
    },
    hasOnto: (state) => (id) =>
        !!(
            state.ontoIndex[id] &&
            state.ontoIndex[id].hasOwnProperty('ontologyLongDisplayName')
        ),
    pastOntoIDs: (state) => state.pastOntoIDs,
    hasPastOntos: (state) => state.pastOntoIDs.length,
    loadingOntoIDs: (state) => map(state.loadingOntos, 'ontologyUniqueID'),
    filters: (state) => state.filters,
    filteringOntoIDs: (state, getters) =>
        sortBy(
            state.filters.ontologies.map((ontoID) => {
                const ontoData = getters.ontoData(ontoID);
                return ontoData.ontologyShortDisplayName;
            })
        ),
    hasFilters: (state) =>
        !isEmpty(state.filters) &&
        some(state.filters, (filter) => filter.length),
    query: (state) => state.query,
    isExact: (state) => state.filters.isExact,
    searchType: (state) => state.filters.searchType,
    isObsolete: (state) => state.filters.isObsolete,
};

const actions = {
    indexOntos({ dispatch, commit, state, getters }) {
        return new Promise((resolve, reject) => {
            ApiOntology.names()
                .then((response) => {
                    const ontoNames = response.data;
                    const ontoIDs = map(ontoNames, 'ontologyUniqueID');

                    commit(MutationTypes.SEARCH_INDEXONTOS, { ontoNames });

                    // Guarantees consistency when the list of loaded ontologies changes without user intervention (e.g. through backend)
                    // NOTE: in that case, there may be recent ontologies that are no longer loaded or in the process of being loaded.
                    dispatch(
                        'ontosTouched',
                        intersection(ontoIDs, state.pastOntoIDs)
                    );
                    ontoIDs.forEach((loadedOntoID) => {
                        if (getters.loadingOntoIDs.indexOf(loadedOntoID) > -1) {
                            dispatch('loadOntoEnd', loadedOntoID);
                        }
                    });

                    resolve(response);
                })
                .catch(reject);
        });
    },

    addOntoName({ commit }, ontology) {
        const ontoName = pick(
            ontology,
            'ontologyLongDisplayName',
            'ontologyShortDisplayName',
            'ontologyUniqueID'
        );
        const ontoID = ontoName.ontologyUniqueID;

        // Defaults are guaranteed to allow ontology loading even if details are partial.
        defaults(ontoName, {
            ontologyLongDisplayName: ontoID,
            ontologyShortDisplayName: ontoID,
        });
        commit(MutationTypes.SEARCH_INDEXADD, { ontoName });
    },

    delOntoName({ dispatch, commit }, ontoID) {
        commit(MutationTypes.SEARCH_INDEXDEL, { ontoID });
    },

    loadOntoStart({ commit }, ontology) {
        commit(MutationTypes.SEARCH_LOADSTART, { ontology });
    },

    loadOntoEnd({ commit }, ontoID) {
        commit(MutationTypes.SEARCH_LOADEND, { ontoID });
    },

    flushOntos({ commit }) {
        commit(MutationTypes.SEARCH_FLUSHONTOS);
    },

    ontosTouched({ commit }, ontoIDs) {
        ontoIDs = sortBy(ontoIDs);
        commit(MutationTypes.SEARCH_PASTONTOS, { ontoIDs });
    },

    filter({ commit }, filters) {
        commit(MutationTypes.SEARCH_FILTERS, filters);
    },

    resetOntos({ commit }) {
        commit(MutationTypes.SEARCH_RESETONTOS);
    },

    query({ commit }, query) {
        commit(MutationTypes.SEARCH_QUERY, { query });
    },

    searchType({ commit }, searchType) {
        commit(MutationTypes.SEARCH_TYPE, { searchType });
    },

    exact({ commit }, isExact) {
        commit(MutationTypes.SEARCH_EXACT, { isExact });
    },

    obsolete({ commit }, isObsolete) {
        commit(MutationTypes.SEARCH_OBSOLETE, { isObsolete });
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
