export const interceptWhitelist = [
    {
        path: '/ontologies/*',
        statuses: [404],
        wildcard: true,
    },
    {
        path: '/ontology/*/designPatterns',
        statuses: [404],
        wildcard: true,
    },
];
