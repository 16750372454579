<template>
    <slide
        ref="menu"
        right
        noOverlay
        :closeOnNavigation="true"
        :class="{ open: isMenuShown }"
        :isOpen="isMenuShown"
        :burgerIcon="false"
        :crossIcon="false"
        disableOutsideClick
        @closeMenu="onMenuClose"
        :id="buildTestClass('btn--close-side-menu')">
        <div class="menu-header d-flex">
            <b-button
                class="close-btn flex-shrink-0 border-0 rounded-0"
                variant="primary"
                :style="{ height: $store.headerHeight + 'px' }"
                >&times;</b-button
            >
            <b-button
                class="user-btn d-inline-flex flex-grow-1 overflow-hidden text-decoration-none"
                variant="link"
                :style="{ height: $store.headerHeight + 'px' }"
                :href="adminUrl + '/settings'"
                target="_blank">
                <font-awesome-icon
                    class="avatar mr-2"
                    :icon="['far', 'user-circle']" />
                <span class="user-info text-left overflow-hidden">
                    <span
                        class="user-name d-block text-truncate font-weight-bold"
                        >{{ $store.getters.fullname }}</span
                    >
                    <span>{{ $store.getters.username }}</span>
                </span>
            </b-button>
        </div>

        <!-- NOTIFICATIONS -->
        <b-card
            class="border-0 rounded-0"
            :id="buildTestClass('card--notifications')"
            no-body
            :class="{ 'flex-shrink-1 overflow-hidden': isNotificationsShown }"
            @click="onDrawerItem">
            <b-card-header
                class="p-0 border-0 bg-transparent text-white"
                role="tab">
                <b-button
                    :id="this.buildTestClass('btn--notifications')"
                    class="d-flex align-items-center rounded-0 text-left"
                    block
                    v-b-toggle.accordion-notifications
                    variant="secondary">
                    <font-awesome-icon icon="bell" />
                    <span class="flex-grow-1 text-uppercase"
                        >Notifications</span
                    >
                    <b-badge
                        v-if="$store.getters.unreadCount"
                        pill
                        variant="danger"
                        class="unread-notifications-btn">
                        {{ $store.getters.unreadCount }}
                    </b-badge>
                    <b-button
                        v-else-if="
                            isNotificationsShown &&
                            $store.getters.notifications.length
                        "
                        variant="danger"
                        size="sm"
                        class="clear-btn border-0 px-1 py-0"
                        @click.prevent.stop="onClearAll">
                        Clear all
                    </b-button>
                </b-button>
            </b-card-header>
            <b-collapse
                id="accordion-notifications"
                role="tabpanel"
                v-model="isNotificationsShown">
                <b-list-group
                    class="drawer-list"
                    flush
                    data-empty-message="No recent entries">
                    <b-list-group-item
                        v-for="(notification, index) in $store.getters
                            .notifications"
                        class="d-flex align-items-center p-3"
                        :class="{
                            'new-notification': index <= newNotificationIndex,
                        }"
                        :id="buildTestClass('card--notifications-' + index)"
                        :key="notification.uniqueId">
                        <!-- Remove notification -->
                        <a
                            v-if="notification.uniqueId"
                            class="drawer-item-remove text-muted"
                            @click.stop.prevent="
                                $store.dispatch(
                                    'notificationDel',
                                    notification.uniqueId
                                )
                            ">
                            <span class="small">Clear</span>
                            <font-awesome-icon icon="times-circle" />
                        </a>

                        <!-- Notification entry -->
                        <small class="drawer-item-info flex-grow-1 w-100">
                            <!-- Notification type -->
                            <span
                                class="drawer-item-type"
                                :class="`text-${$store.getters.notificationClass(
                                    notification
                                )}`">
                                <template
                                    v-if="
                                        $store.getters.category(
                                            notification.type
                                        ) === 'suggestion'
                                    ">
                                    <font-awesome-layers
                                        class="suggestions-icon mx-1">
                                        <font-awesome-icon
                                            icon="comment"
                                            transform="grow-10" />
                                        <font-awesome-icon
                                            icon="pen"
                                            transform="shrink-5"
                                            class="text-white"
                                            :style="{ marginTop: '-.05em' }" />
                                    </font-awesome-layers>
                                </template>
                                <font-awesome-icon
                                    v-if="
                                        $store.getters.category(
                                            notification.type
                                        ) === 'export'
                                    "
                                    icon="file-export" />
                                <font-awesome-icon
                                    v-if="
                                        $store.getters.category(
                                            notification.type
                                        ) === 'import'
                                    "
                                    icon="file-import" />
                                <font-awesome-icon
                                    v-if="
                                        $store.getters.category(
                                            notification.type
                                        ) === 'delete'
                                    "
                                    icon="trash-alt" />
                                <font-awesome-icon
                                    v-if="
                                        $store.getters.category(
                                            notification.type
                                        ) === 'global'
                                    "
                                    icon="info-circle" />
                                <span class="text-uppercase">{{
                                    $store.getters.notificationTitle(
                                        notification
                                    )
                                }}</span>
                            </span>

                            <!-- Notification text -->
                            <span
                                v-if="notification.message"
                                class="drawer-item-message d-block">
                                {{
                                    truncate(
                                        notification.message ||
                                            notification.type,
                                        messageMax
                                    )
                                }}
                            </span>

                            <!-- Relative timestamp -->
                            <span
                                v-if="notification.timestamp"
                                class="drawer-item-time text-muted"
                                v-b-tooltip.hover.bottom="{
                                    title: humanReadable(
                                        new Date(notification.timestamp)
                                    ),
                                    boundary: 'window',
                                    delay: { show: showDelay, hide: 0 },
                                }">
                                <font-awesome-icon :icon="['far', 'clock']" />
                                {{
                                    new Date(notification.timestamp)
                                        | moment('from')
                                }}
                            </span>

                            <!-- Notification action -->
                            <b-link
                                v-if="isNotificationAction(notification)"
                                class="action-link view-link float-right"
                                :to="notification.actionPath">
                                {{
                                    $store.getters.category(
                                        notification.type
                                    ) === 'suggestion'
                                        ? 'Review'
                                        : 'View'
                                }}
                            </b-link>

                            <b-form
                                v-else-if="
                                    $store.getters.category(
                                        notification.type
                                    ) === 'export'
                                "
                                id="side-menu-download-form"
                                method="post"
                                target="_blank"
                                :action="
                                    getExportUrlPath(notification.message)
                                ">
                                <input
                                    id="side-menu-download-form-input"
                                    type="hidden"
                                    :name="'Authorization'"
                                    :value="$store.getters.token" />
                                <p
                                    id="side-menu-download-form-submit"
                                    v-on:click="performFormDownload()"
                                    class="action-link view-link float-right">
                                    Download
                                </p>
                                <a
                                    class="side-menu-download-form-anchor"
                                    id="side-menu-download-form-anchor"
                                    :href="
                                        getExportUrlPath(notification.message)
                                    ">
                                </a>
                            </b-form>
                        </small>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </b-card>

        <!-- SUGGESTIONS -->
        <b-card
            class="border-0 rounded-0"
            :id="buildTestClass('card--suggestions')"
            no-body
            :class="{ 'flex-shrink-1 overflow-hidden': isSuggestionsShown }"
            @click="onDrawerItem">
            <b-card-header
                class="p-0 border-0 bg-transparent text-white"
                role="tab">
                <b-button
                    :id="this.buildTestClass('suggestions')"
                    class="d-flex align-items-center rounded-0 text-left"
                    block
                    v-b-toggle.accordion-suggestions
                    variant="secondary">
                    <font-awesome-layers class="suggestions-icon">
                        <font-awesome-icon icon="comment" transform="grow-10" />
                        <font-awesome-icon
                            icon="pen"
                            transform="shrink-5"
                            class="text-secondary"
                            :style="{ marginTop: '-.05em' }" />
                    </font-awesome-layers>

                    <span class="flex-grow-1 text-uppercase"
                        >My suggestions</span
                    >

                    <b-badge
                        v-if="$store.getters.rejectedCount"
                        pill
                        variant="danger"
                        class="rejected-suggestions-btn"
                        @click.stop="
                            isSuggestionsShown = true;
                            currStatusIndex = 1;
                        ">
                        {{ $store.getters.rejectedCount }}
                    </b-badge>
                </b-button>
            </b-card-header>
            <b-collapse
                id="accordion-suggestions"
                role="tabpanel"
                v-model="isSuggestionsShown">
                <b-tabs v-model="currStatusIndex" card>
                    <b-tab
                        title-item-class="flex-fill"
                        title-link-class="suggestions-tab text-info text-center py-1">
                        <template slot="title">
                            <font-awesome-icon :icon="['far', 'paper-plane']" />
                            <small class="tab-title">Sent</small>
                        </template>
                    </b-tab>
                    <b-tab
                        title-item-class="flex-fill"
                        title-link-class="suggestions-tab text-danger text-center py-1 mx-2">
                        <template slot="title">
                            <font-awesome-icon icon="ban" />
                            <small class="tab-title">Rejected</small>
                        </template>
                    </b-tab>
                    <b-tab
                        title-item-class="flex-fill"
                        title-link-class="suggestions-tab text-success text-center py-1">
                        <template slot="title">
                            <font-awesome-icon icon="check" />
                            <small class="tab-title">Approved</small>
                        </template>
                    </b-tab>
                </b-tabs>
                <b-list-group
                    class="drawer-list"
                    flush
                    data-empty-message="No recent entries">
                    <b-list-group-item
                        v-for="suggestion in suggestionList"
                        class="d-flex align-items-center p-3"
                        :key="suggestion.transactionId">
                        <!-- Suggestion entry -->
                        <small class="drawer-item-info flex-grow-1">
                            <!-- Suggestion state -->
                            <b-badge
                                v-if="suggestion.ontologyId"
                                class="action-link onto-link mr-1"
                                variant="primary"
                                :class="{
                                    'not-loaded': !$store.getters.hasOnto(
                                        suggestion.ontologyId
                                    ),
                                }"
                                :to="
                                    $store.getters.hasOnto(
                                        suggestion.ontologyId
                                    )
                                        ? `/ontologies/${suggestion.ontologyId}`
                                        : ''
                                "
                                v-b-tooltip.hover="{
                                    title: $store.getters.ontoData(
                                        suggestion.ontologyId
                                    ).ontologyLongDisplayName,
                                    delay: { show: showDelay, hide: 0 },
                                }">
                                <template
                                    v-if="
                                        $store.getters.hasOnto(
                                            suggestion.ontologyId
                                        )
                                    ">
                                    {{
                                        $store.getters.ontoData(
                                            suggestion.ontologyId
                                        ).ontologyShortDisplayName
                                    }}
                                </template>
                                <template v-else>
                                    {{ suggestion.ontologyId }}
                                </template>
                            </b-badge>
                            <span
                                class="drawer-item-type"
                                :class="{
                                    'text-danger': $store.getters.isRejected(
                                        suggestion.status
                                    ),
                                    'text-info': $store.getters.isSent(
                                        suggestion.status
                                    ),
                                    'text-success': !$store.getters.isPending(
                                        suggestion.status
                                    ),
                                }">
                                {{ suggestion.size }}
                                {{ 'class' | pluralize(suggestion.size) }}
                                changed
                            </span>

                            <!-- Suggestion text -->
                            <span
                                class="drawer-item-message d-block"
                                :class="{
                                    'text-muted':
                                        !suggestion.description.length,
                                }">
                                {{
                                    truncate(
                                        suggestion.description ||
                                            'No comment provided',
                                        messageMax
                                    )
                                }}
                            </span>

                            <!-- Suggestion action -->
                            <span
                                class="drawer-item-time text-muted"
                                v-b-tooltip.hover.bottom="{
                                    title: humanReadable(
                                        new Date(suggestion.creationDate)
                                    ),
                                    boundary: 'window',
                                    delay: { show: showDelay, hide: 0 },
                                }">
                                <font-awesome-icon :icon="['far', 'clock']" />
                                {{
                                    new Date(suggestion.creationDate)
                                        | moment('from')
                                }}
                            </span>
                            <b-link
                                v-if="
                                    $store.getters.isPending(suggestion.status)
                                "
                                :to="{
                                    name: 'suggestion',
                                    params: {
                                        ontologyID: suggestion.ontologyId || '',
                                        transactionID: suggestion.transactionId,
                                        initialData: { suggestion },
                                    },
                                }"
                                class="action-link view-link float-right">
                                Review
                            </b-link>
                        </small>
                    </b-list-group-item>
                </b-list-group>
            </b-collapse>
        </b-card>

        <!-- MANAGER CENTRE -->
        <b-card
            no-body
            class="border-0 rounded-0"
            :id="buildTestClass('card--manager-centre')">
            <b-card-header
                class="p-0 border-0 bg-transparent text-white"
                role="tab">
                <b-button
                    :class="this.buildTestClass('manager-centre')"
                    class="d-flex flex-row align-items-center rounded-0 text-left collapsed"
                    block
                    variant="secondary"
                    @click="onClickNavigateToAdmin">
                    <font-awesome-icon icon="cog" />
                    <span class="flex-grow-1 text-uppercase"
                        >Manager centre</span
                    >
                </b-button>
            </b-card-header>
        </b-card>

        <!-- API REFERENCE-->
        <b-card
            no-body
            class="border-0 rounded-0"
            :id="buildTestClass('card--api-ref')">
            <b-card-header
                class="p-0 border-0 bg-transparent text-white"
                role="tab">
                <b-button
                    :class="this.buildTestClass('api-reference')"
                    class="d-flex flex-row align-items-center rounded-0 text-left collapsed"
                    block
                    variant="secondary"
                    @click="onClickApiReference">
                    <font-awesome-icon icon="puzzle-piece" />
                    <span class="flex-grow-1 text-uppercase"
                        >Api reference</span
                    >
                </b-button>
            </b-card-header>
        </b-card>

        <!-- SPARQL EDITOR-->
        <b-card
            no-body
            class="border-0 rounded-0"
            :id="buildTestClass('card--sparql-editor')">
            <b-card-header
                class="p-0 border-0 bg-transparent text-white"
                role="tab">
                <b-button
                    :class="this.buildTestClass('btn--sparql-editor')"
                    class="d-flex flex-row align-items-center rounded-0 text-left collapsed"
                    block
                    variant="secondary"
                    :to="{ name: 'sparql' }"
                    target="_blank">
                    <font-awesome-icon icon="bolt" />
                    <span class="flex-grow-1 text-uppercase"
                        >SPARQL Editor</span
                    >
                </b-button>
            </b-card-header>
        </b-card>

        <!-- LOGOUT -->
        <b-card
            no-body
            class="border-0 rounded-0"
            :id="buildTestClass('card--logout')">
            <b-card-header
                class="p-0 border-0 bg-transparent text-white"
                role="tab">
                <b-button
                    :class="this.buildTestClass('log-out')"
                    class="d-flex flex-row align-items-center rounded-0 text-left collapsed"
                    block
                    variant="secondary"
                    @click="onLogout">
                    <font-awesome-icon icon="sign-out-alt" />
                    <span class="flex-grow-1 text-uppercase">Log out</span>
                </b-button>
            </b-card-header>
        </b-card>
    </slide>
</template>

<script>
import { pick, startCase } from 'lodash';
import { Slide } from 'vue-burger-menu';
import { findHostedExportUrl } from '@/utils/ontoexport/findHostedExportUrl.ts';

export default {
    name: 'SlideMenu',
    components: {
        Slide,
    },
    props: {
        isMenuShown: {
            type: Boolean,
            default: false,
        },

        pendingClassCount: {
            type: Number,
            default: 0,
        },

        messageMax: {
            type: Number,
            default: 70,
        },

        adminUrl: {
            type: String,
            default: process.env.VUE_APP_ADMIN_URL,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    data() {
        return {
            // True if the notifications drawer is open.
            isNotificationsShown: false,

            // True if the suggestions drawer is open.
            isSuggestionsShown: false,

            // Suggestion's tab index currently on display. 0 for 'sent', 1 for 'rejected', 2 for 'approved'
            currStatusIndex: 0,

            // Index for the oldest new (previously unread) notification
            newNotificationIndex: -1,
        };
    },

    computed: {
        suggestionList() {
            const filterStatus =
                this.$store.getters.allStatuses[this.currStatusIndex];
            const list = this.$store.getters.suggestions.filter(
                (suggestion) => {
                    return suggestion.status === filterStatus;
                }
            );

            return list;
        },
    },

    watch: {
        // Makes sure the notifications list is hidden when the menu is next opened.
        isMenuShown(isShown) {
            if (!isShown) {
                this.isNotificationsShown = false;
                this.isSuggestionsShown = false;
            }

            // Adds a click listener to close the menu if not user does not click inside the menu
            document.addEventListener('click', this.clickedOutsideMenu);
        },

        // Sets all notifications as read in bulk if the list is shown for the first time.
        isNotificationsShown: 'markAllRead',

        // Displays rejected suggestions by default when revealing the list of suggestions if there are no open ones.
        isSuggestionsShown(isShown) {
            if (isShown) {
                this.isNotificationsShown = false;

                if (
                    this.$store.getters.rejectedCount &&
                    !this.$store.getters.openCount
                ) {
                    this.currStatusIndex = 1;
                }
            }
        },

        // Keeps track of read and new states for notifications
        '$store.getters.unreadCount'(newCount) {
            if (newCount) {
                this.newNotificationIndex = newCount - 1;

                // If there is a new notification and the list of notifications is on display, marks it as read straight away.
                if (this.isMenuShown) {
                    this.markAllRead(this.isNotificationsShown);
                }
            }
        },

        // Updates the suggestion list every time a new notification is received in real-time.
        '$store.getters.newLiveNotification'(notification) {
            const getters = this.$store.getters;
            const category = getters.category(notification.type);

            // TODO: this should be more surgical. Instead of reloading, only the suggestion concerned should be updated.
            if (category === 'suggestion') {
                this.$store.dispatch('suggestionsReload');

                // If the user profile has changed but the current session was not from a recent login, logout is required.
            } else if (category === 'user' && !this.$store.getters.password) {
                const logoutTimer = 7500;

                this.$eventBus.$emit(
                    'notification:show',
                    'warning',
                    'Profile permissions have been changed, to take effect the app will now log you out.',
                    'Profile change detected',
                    [],
                    'centerTop',
                    { timeout: logoutTimer }
                );

                setTimeout(() => {
                    this.$router.push({
                        name: 'logout',
                        params: {
                            redirect: pick(this.$route, ['name', 'params']),
                        },
                    });
                }, logoutTimer);
            }
        },
    },

    /**
     * Fetches all notifications so far and registers for new ones. Also, avoids menu overflow
     * after submenu expansion.
     */
    mounted() {
        this.$store.dispatch('suggestionsLoad').finally(() => {
            this.$store.dispatch('notificationUnread').finally(() => {
                this.$store.dispatch('notificationOpen');
            });
        });

        this.$el.getElementsByTagName('nav')[0].className =
            'd-flex flex-column h-100';
    },

    /**
     * Clears both the notifications and suggestions lists.
     */
    destroyed() {
        this.$store.dispatch('notificationClose');
        this.$store.dispatch('suggestionsClear');
        this.newNotificationIndex = -1;
    },

    methods: {
        performFormDownload: function () {
            const downloadForm = document.getElementById(
                'side-menu-download-form'
            );
            const authInput = document.getElementById(
                'side-menu-download-form-input'
            );

            const anchor = document.getElementById(
                'side-menu-download-form-anchor'
            );

            const url = downloadForm.getAttribute('action') || '';
            if (url.indexOf('s3.') !== -1) {
                downloadForm.method = 'GET';
                authInput.remove();
                anchor.click();
            } else {
                //Set the Auth Input value
                authInput.value = this.$store.getters.token;
                downloadForm.submit();
            }
        },

        /**
         * Redirects to a page in the admin app.
         * @param {string} path - Route within the admin app.
         */
        onAdmin(path = '') {
            const tab = window.open(this.adminUrl + path, 'manager');
            tab.focus();
        },
        onClickNavigateToAdmin() {
            window.open(this.adminUrl, '_blank').focus();
        },

        getExportUrlPath(message) {
            const url = findHostedExportUrl(
                message,
                process.env.VUE_APP_BASE_URL
            );

            return url;
        },

        /**
         * Method that is called when the menu closes
         * Changes the side menu to closed and disables click listener for outside menu clicks
         */
        onMenuClose() {
            this.$emit('update:isMenuShown', false);
            document.removeEventListener('click', this.clickedOutsideMenu);
        },

        /**
         * Cancels propagation of any event except for that coming from the action link.
         * This is to allow the automatic closing of the menu when clicking on a menu entry.
         * @param {Object} event - DOM object for the directing event.
         */
        onDrawerItem(event) {
            const targetEl = event.target;

            if (!targetEl.classList.contains('action-link')) {
                event.stopPropagation();
            }
        },

        /**
         * Removes all notifications from the list permanently.
         */
        onClearAll() {
            this.$store.dispatch('notificationClear');
            this.newNotificationIndex = -1;
        },

        /**
         * Navigates to the api reference page
         */
        onClickApiReference() {
            window.open(this.adminUrl + '/admin/swagger', '_blank').focus();
        },
        /**
         * Makes sure the menu is closed before proceeding with logout.
         */
        onLogout() {
            this.$emit('update:isMenuShown', false);
            this.$router.push('' + '/logout');
        },

        /**
         * Opens a drawer list on demand.
         * @param {string} drawerName - Name of list to display.
         */
        reveal(drawerName) {
            this[`is${startCase(drawerName)}Shown`] = true;

            if (
                this.$store.getters.rejectedCount &&
                drawerName === 'suggestions'
            ) {
                this.currStatusIndex = 1;
            }
        },

        /**
         * Marks all notifications as read as soon as these are rendered.
         * @param {boolean} isShown - True if the notifications drawer is open.
         */
        markAllRead(isShown) {
            if (isShown) {
                this.isSuggestionsShown = false;

                if (this.$store.getters.unreadCount) {
                    this.$store.dispatch('notificationAllRead');
                }
            }
        },

        /**
         * Determines if a notification should display an action link.
         * @param {Object} notification The notification object
         * @returns {boolean}
         */
        isNotificationAction(notification) {
            const hasActionPath = !!notification.actionPath;
            const hasBeenApproved =
                notification.message.indexOf('approved') !== -1;
            const hasBeenWithdrawn = notification.type === 'SUGGEST_WITHDRAWN';

            return hasActionPath && !hasBeenApproved && !hasBeenWithdrawn;
        },

        /**
         * Checks if the mouse click was outside
         * @param {event} e - Mouse click event
         */
        clickedOutsideMenu(e) {
            if (e.target.classList.contains('grayed-out')) {
                this.$emit('update:isMenuShown', false);
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.side-menu-download-form-anchor {
    display: none;
}

::v-deep .bm-menu {
    overflow-x: visible;
    z-index: $zindex-fixed !important;
    padding-top: 0;
    background-color: darken($secondary, 8.5%);

    .menu-header {
        flex: 0 0 auto; // compatibility with Safari
        margin-left: 0;

        .open & {
            margin-left: -4rem;
        }

        .close-btn {
            width: 4rem;
            font-size: 2rem;
        }

        .user-btn {
            .avatar {
                font-size: 2.5rem;
            }

            .user-name {
                line-height: 1.2rem;
            }
        }
    }

    .card-header {
        .clear-btn,
        .rejected-suggestions-btn,
        .unread-notifications-btn {
            &:not(:hover) {
                background: darken($danger, 15%);
            }
        }

        .btn-block {
            padding: 0.75rem 1.25rem;
            border: 0;
            border-top: 1px solid $secondary;
            background: darken($secondary, 8.5%);

            &:hover,
            &:not(.collapsed) {
                background: $secondary;
            }

            svg {
                margin-right: 0.9rem;
            }

            .suggestions-icon {
                margin-right: 1.1rem;
            }
        }
    }

    .collapse.show {
        overflow: auto;
    }

    .card {
        flex: 0 0 auto; // compatibility with Safari

        &:last-of-type .card-header .btn {
            border-bottom: 1px solid $secondary;
        }
    }

    .suggestions-icon {
        font-size: 0.74rem;

        svg {
            margin-left: 0;
        }
    }

    .suggestions-tab {
        padding: 0.5rem 0;
        background: rgba($secondary, 0.1);

        &.active,
        &:hover {
            background: $white;
        }

        &:not(.active) {
            border-bottom: 1px solid $gray-300;
        }

        .tab-title {
            font-weight: 500;
        }
    }

    .tab-pane {
        display: none;
    }

    .drawer-list {
        &:empty:before {
            content: attr(data-empty-message);
            display: block;
            padding: 1rem;
            text-align: center;
            color: $text-muted;
        }

        .list-group-item {
            &:first-child {
                border-top: 0;
            }

            &:last-child {
                border-radius: 0 !important;
            }

            &.new-notification {
                background: lighten($info, 60%);
            }

            .drawer-item-remove {
                position: absolute;
                top: 0.12rem;
                right: 0.25rem;

                span {
                    display: none;
                }

                svg {
                    opacity: 0.5;
                }

                &:hover {
                    color: $danger !important;
                    opacity: 1;
                    cursor: pointer;

                    span {
                        display: inline-block;
                    }

                    svg {
                        opacity: 1;
                    }
                }

                &:hover + .drawer-item-info,
                &:hover + .drawer-item-info * {
                    color: $danger !important;
                }
            }

            .drawer-item-message {
                margin: 0.1rem 0 0.15rem 0;
                font-weight: 500;
            }

            .drawer-item-time {
                vertical-align: text-bottom !important;
            }

            .onto-link {
                border: 1px solid $primary;

                &.not-loaded {
                    border: 1px solid $text-muted;
                    background: transparent;
                    opacity: 0.5;
                }
            }

            .view-link {
                color: $link-hover-color;

                &:hover,
                &:focus {
                    color: darken($link-hover-color, 6%);
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
