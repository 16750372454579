<template>
    <main
        id="app-region centreeRevamp"
        :class="`test-route-${$route.name}` + ' ' + 'centree-revamp'">
        <router-view />
        <app-dialog v-bind="dialogueProps" />
    </main>
</template>

<script>
import { omit } from 'lodash';
import notificationsMixin from './mixins/notificationsMixin';
import ApiManagement from '@/api/management';
import { getRouterHash } from '@/utils';
import AppDialog from '@/components/ui/common/AppDialog';
import { useAppDialog, useEventBus, useObservers } from '@/compositions';

export default {
    name: 'App',
    components: { AppDialog },
    computed: {
        dialogueProps: function () {
            return useAppDialog().getConfirmProps.value;
        },
    },
    // Outputs the environment the app is currently running in for debugging purposes
    created() {
        console.log(getRouterHash());
        useEventBus().setEventBus(this.$eventBus);
        if (process.env.NODE_ENV === 'development') {
            console.log(process.env);
        } else {
            console.log(
                omit(
                    process.env,
                    'VUE_APP_HELP_EMAIL',
                    'VUE_APP_HELP_URL',
                    'VUE_APP_TERMS_URL',
                    'VUE_APP_NAME_TOKEN',
                    'VUE_APP_USERNAME_REGEX',
                    'VUE_APP_NAME',
                    'VUE_APP_COMPANY_NAME',
                    'VUE_APP_APP_MOTTO',
                    'VUE_APP_PWD_MIN_LENGTH'
                )
            );
        }
    },

    // Marks the end of the loading process and triggers fade-in effect
    mounted() {
        ApiManagement.getManagementData().then((managementData) => {
            this.$store.dispatch('setManagementData', managementData);

            useObservers().notifyObservers('MANAGEMENT_DATA_LOADED');
        });
        const splashEl = document.getElementById('splash-panel');
        splashEl &&
            splashEl.addEventListener(this.whichTransitionEvent, () => {
                document.body.classList.add('loaded');
                document.body.classList.remove('loading');
            });
        splashEl.style.opacity = 0;
    },
    mixins: [notificationsMixin],
};
</script>

<style lang="scss">
@import 'scss/centree';
@import 'scss/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

main#centreeRevamp {
    background-color: #000000 !important;
    .shadow-sm {
        box-shadow: 0 0.2rem 0.2rem rgb(0 0 0 / 15%) !important;
    }
}
//Fixes the more and less button styles
button.toggle-text {
    background: transparent !important;
    border: none !important;
}
/* GLOBAL CUSTOM STYLES */
// Removes browser's default focus highlighting
:focus {
    outline: none !important;
}

// Allows for annotation-style blocks of text;
.small,
small {
    font-size: 85% !important;
}

// Convenience class for less-than-strong bolding
.soft-bold {
    font-weight: 500;
}

// Only external links should be underlined on hover
a:not([rel='external']) {
    &,
    &:hover,
    &:focus {
        text-decoration: none !important;
    }
}

// Removes selection and the default shadow and/or outline on active/focused buttons, textareas
textarea,
button,
.btn {
    outline: none !important;
    box-shadow: none !important;
}
textarea {
    user-select: text; // "none" doesn't play well with Safari
}
button,
.btn {
    user-select: none;
}

// Removes the clear button on any input field on IE and Chrome
// SOURCE: https://blog.maximerouiller.com/post/remove-the-x-from-internet-explorer-and-chrome-input-type-search/
input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

// Background for secondary in-field dropdown variants
.btn.btn-gray {
    background: $gray-200;
    color: $gray-600;
    transition: $btn-transition;

    &:hover {
        color: $body-color;
    }
}

// Convenience classes to expose custom or pre-existing colours for other uses
.bg-gray {
    background: #899198 !important;
}
.text-tertiary {
    color: $tertiary !important;
}

// Makes interaction with checkboxes and dropdown control labels self-evident
.dropdown-item:hover,
.custom-control:hover {
    .custom-control-label:not(.no-hover):before {
        background-color: lighten($primary, 15%);
    }

    .custom-control-label:not(.no-hover) {
        cursor: pointer;
    }
}
.custom-checkbox,
.custom-radio {
    .custom-control-input:checked ~ .custom-control-label {
        font-weight: 500;
    }

    .custom-control-input:disabled ~ .custom-control-label {
        opacity: 0.5;
    }
}
.dropdown-menu,
.result-facet {
    background: darken($white, 2%) !important;
}
.dropdown-header .dropdown-close-btn {
    margin-top: -0.4rem;
}

// Removes the fake background for button links
.btn-link-primary,
.btn-link-secondary {
    background-color: transparent;
}

// Gives highlighted text the same background as marked contents
.highlighted,
*::selection {
    background: $mark-bg !important;
}

// Alias for the default "danger" text color.
.text-error {
    color: $danger !important;
}

// Applies a grayed-out layer on top of existing content and makes it behave as disabled.
.grayed-out:after {
    content: '';
    z-index: calc(#{$zindex-sticky} + 1);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba($dark, 0.5);
}

// Creates enough padding to allow for fixed top/bottom bars
.main-spacer {
    padding: $header-spacer 1rem 1rem 1rem;
}

// Makes stroke a bit thicker and same color as app's name
.app-logo {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 1px;
}

// Uses text to replicate Wikipedia's logo as close as possible
.wikipedia-logo:before {
    content: 'W';
    font-family: Hoefler Text, Baskerville Old Face, Garamond, Times New Roman,
        serif;
}

// Restores Bootstrap class for labels, removed in v4
.control-label {
    max-width: 100%;
    margin-bottom: 0 !important;
    font-size: 0.9em;
    font-weight: 700;
}

// Restores Fontawesome's icon alignment overwritten by Bootstrap
.svg-inline--fa {
    vertical-align: -0.125em !important;
}

// Antialising for rounded borders
.form-control:not(:focus) {
    box-shadow: 0 0 0.1rem 0px white inset, 0 0 0.1rem 0px white;
}

// Forces break and wrapping of continuously long strings
.word-break {
    word-break: break-all;
}

// Brings the popover in line with app's typography and styles
.popover[tabindex] {
    max-width: 21.5rem;
    font-family: inherit;

    .popover-header {
        font-size: 0.9rem;
    }
}

// Relationship badge colours
.badge.relationship-badge {
    &.superClasses-badge {
        background: #6c6e68;
        &:hover,
        &:focus {
            background: darken(#6c6e68, 12%);
        }
    }

    &.partOf-badge {
        background: #c6a06d;
        &:hover,
        &:focus {
            background: darken(#c6a06d, 15%);
        }
    }

    &.derivesFrom-badge {
        background: #bb6841;
        &:hover,
        &:focus {
            background: darken(#bb6841, 12%);
        }
    }

    &.developsFrom-badge {
        background: #787730;
        &:hover,
        &:focus {
            background: darken(#787730, 7%);
        }
    }

    &.equivalences-badge {
        background: #6fa09c;
        &:hover,
        &:focus {
            background: darken(#6fa09c, 15%);
        }
    }
}

// Relationship bubble colours
.partOf-list .show-relationship-badge:before,
.partOf-node:before {
    background: #c6a06d;
}

.derivesFrom-list .show-relationship-badge:before,
.derivesFrom-node:before {
    background: #bb6841;
}

.developsFrom-list .show-relationship-badge:before,
.developsFrom-node:before {
    background: #787730;
}

.equivalences-list .show-relationship-badge:before,
.equivalence-node:before {
    background: #6fa09c;
}

/* APP LAYOUT */
#splash-panel {
    .loaded & {
        display: none;
    }
}

.loading {
    font-size: 2.25rem;

    &.centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:before {
        @include spinner;
    }

    &:after {
        content: 'Loading...';
        margin-left: 1em;
        font-weight: 300;
        color: $gray-400;
    }

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
}

// TODO: scoped styles on the modal don't work. Why?
#save-modal .modal-content {
    max-height: 85%;
}
</style>
