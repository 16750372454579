import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type OntologyPropertyChildrenPathParams = operations["getOntologyPropertyChildrenUsingGET"]["parameters"]["path"];
type OntologyPropertyChildrenQueryParams = operations["getOntologyPropertyChildrenUsingGET"]["parameters"]["query"];
type OntologyPropertyChildrenResponse = operations["getOntologyPropertyChildrenUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get child properties for a given property URI within a specific ontology
 * @param {string} propertyId
 * @param {string} ontologyId
 * @param {integer} from
 * @param {integer} size
 * @return {OntologyPropertyChildrenResponse}
*/
export const getOntologyPropertyChildren = async (
    { propertyId, ontologyId }: OntologyPropertyChildrenPathParams,
    queryParams?: OntologyPropertyChildrenQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/properties/${propertyId}/children`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as OntologyPropertyChildrenResponse;
}
