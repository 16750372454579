import { cloneDeep } from 'lodash';
import {
    AnnotationPropertyAlias,
    PropertyListObject,
    PropertyValueVmExtended,
    ResolvedMapping,
} from '@/ts';
import {
    useClassView,
    usePropertyBatching,
    usePropertyValues,
} from '@/compositions';
import { components } from '@/ts/ApiSpecs';
import { computed, ref, Ref, set } from '@vue/composition-api';
import {
    ANNOTATION_PROPS,
    MAX_DISPLAY_PROPERTIES,
    PROPERTY_BLACKLIST,
    PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP,
    RELATIONSHIP_PROPS,
} from '@/config';
import { extractPropertyLanguageTag, iriToShortText } from '@/utils';
import { useClassTree, useEdits, useObservers, useOntology, useStore } from '.';
import { deletePropertyValue, editPropertyValue, createPropertyValue } from '@/api-v2';

const propertyList: Ref<PropertyListObject | undefined> = ref();
const emptyListItems: Ref<
    { iri: string; readable: string; propertyGroup: string }[]
> = ref([]);
let propertyListSnapshot: PropertyListObject | undefined = undefined;
const resolvedMappings: Ref<ResolvedMapping[]> = ref([]);
const isMappingsResolved: Ref<boolean> = ref(false);
const totalAnnotationProperties = ref(0);

/**
 * Handles the list of properties displayed for a given class.
 */
export const usePropertyList = () => {
    /**
     * Sets the property list object.
     * @param {PropertyListObject} newPropertyList
     */
    const setPropertyList = (newPropertyList: PropertyListObject) => {
        propertyList.value = newPropertyList;
    };

    /**
     * Gets the current property list.
     * @return {PropertyListObject | undefined}
     */
    const getPropertyList = computed(() => {
        return propertyList.value;
    });

    const propertyBlackList = computed(() => {
        const propertyValues =
            useClassView().getClassData.value?.propertyValues;
        if (typeof propertyValues === 'undefined') return [];
        return propertyValues.filter(
            (propertyValue) =>
                propertyValue.iri &&
                PROPERTY_BLACKLIST.includes(propertyValue.iri)
        );
    });

    const getResolvedMappings = computed(() => {
        return resolvedMappings.value;
    });

    const setResolvedMappings = (mappings: ResolvedMapping[]) => {
        resolvedMappings.value = mappings;
    };

    const pushResolvedMappings = (mapping: ResolvedMapping) => {
        resolvedMappings.value.push(mapping);
    };

    const getIsMappingsResolved = computed(() => {
        return isMappingsResolved.value;
    });

    const setIsMappingsResolved = (isResolved: boolean) => {
        isMappingsResolved.value = isResolved;
    };

    //This needs to be called when propertyList gets unmounted otherwise a build up of properties will happen
    const resetResolvedMappings = () => {
        setResolvedMappings([]);
        setIsMappingsResolved(false);
    };

    /**
     * Restores the original property list before current transaction edits.
     */
    const resetPropertyList = () => {
        propertyList.value = propertyListSnapshot;
    };

    /**
     * Retrieves property values from the current class and builds the propertyList object.
     */
    const oldBuildPropertyList = () => {
        propertyList.value = undefined;
        const classData = useClassView().getClassData
            .value as components['schemas']['OntologySummaryVM'];

        if (!classData || !classData.propertyValues) return;

        const annotationProperties =
            useOntology().currentAnnotationProperties.value;
        const allIris = useOntology().getCurrentAnnotationPropertiesIris.value;

        const propertyValues =
            classData.propertyValues as components['schemas']['PropertyValueVM'][];

        // Adding all default property groups to make sure they appear on top.
        const defaultPropertyGroups = [
            'labels',
            'textualDefinitions',
            'synonyms',
            'mappings',
        ];

        const list: PropertyListObject = {};

        defaultPropertyGroups.forEach(
            (propertyGroup) => (list[propertyGroup] = {})
        );

        propertyValues.forEach((propertyValue) => {
            if (!propertyValue.iri) return;

            if (PROPERTY_BLACKLIST.includes(propertyValue.iri)) return;

            let propertyGroupName = '';
            if (
                propertyValue.name &&
                PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP[propertyValue.name]
            ) {
                propertyGroupName =
                    PROPERTY_NAMES_AS_PROPERTY_VALUES_LOOKUP[
                        propertyValue.name
                    ];
            }

            if (propertyGroupName === '') {
                propertyGroupName =
                    usePropertyValues().getPropertyGroupName(
                        propertyValue.iri
                    ) ||
                    propertyValue.name ||
                    '';
            }

            if (!propertyGroupName) return;

            if (!list[propertyGroupName]) {
                list[propertyGroupName] = {};
            }

            if (!list[propertyGroupName][propertyValue.iri]) {
                list[propertyGroupName][propertyValue.iri] = [];
            }

            const iriIndex = allIris.indexOf(propertyValue.iri);
            if (iriIndex) allIris.splice(iriIndex, 1);

            list[propertyGroupName][propertyValue.iri].push(propertyValue);
        });

        annotationProperties.forEach((property) => {
            if (!property.name) return;
            if (defaultPropertyGroups.includes(property.name)) return;

            const iri = property?.iri ?? '';

            if (
                annotationProperties.length <=
                    getMaxPropertiesToShow('annotation') &&
                !list[property.name]
            ) {
                list[property.name] = { [iri]: [] };
            }
        });

        const defaultAnnotationProperties =
            useOntology().getDefaultAnnotationProperties.value;

        // This loop is for annotations that do not have a value yet but are part of the defaults
        // This will push an iri with an empty value so a user is still able to add a new property
        defaultAnnotationProperties &&
            defaultAnnotationProperties.forEach((property) => {
                if (
                    !property.iri ||
                    !property.iri.length ||
                    !property.iri[0] ||
                    !property.name
                )
                    return;

                const iri = property.iri[0];

                if (!list[property.name]) list[property.name] = {};
                if (!list[property.name][iri]) list[property.name][iri] = [];

                if (!list[property.name][iri].length) {
                    list[property.name][iri].push({
                        iri,
                        value: '',
                        name: iriToShortText(iri),
                    });
                }
            });

        // emptyListItems.value = emptyIris;
        //
        // propertyListSnapshot = cloneDeep(list);
        //
        // setPropertyList(list);
        return list;
    };

    const buildPropertyList = () => {
        const propertyValues = useClassView().getClassData.value
            ?.propertyValues as PropertyValueVmExtended[];
        const annotationPropertiesWithAliases =
            useOntology().getAllDefaultAnnotationPropertiesWithAliases.value;
        if (!propertyValues?.length) return;
        totalAnnotationProperties.value = 0;

        //Set up default group names
        const propertyList: PropertyListObject = {
            labels: {},
            textualDefinitions: {},
            synonyms: {},
            mappings: {},
        };

        //Create a keystore, so we can find the group name from the iri
        const irisToGroupNameMap = {} as Record<string, string>;

        for (const propertyValue of propertyValues) {
            const iri = propertyValue?.iri;
            const name = propertyValue?.name || '';
            let alias = '';
            let groupName = '';
            if (!iri) continue;

            //If the property should not be included in the propertyList
            if (PROPERTY_BLACKLIST.includes(iri)) continue;

            //Build a keystore to find where each iri belongs
            if (irisToGroupNameMap[iri]) {
                groupName = irisToGroupNameMap[iri];
            } else {
                //Probably a better way of writing this but this essentially will fallback onto the iri to give us a name
                // if all else fails
                groupName = useOntology().lookUpIriToName.value[iri];
                groupName = groupName
                    ? groupName
                    : findGroupNameInOntologyAnnotationProperties(iri);
                groupName = groupName ? groupName : name;
                groupName = groupName ? groupName : iriToShortText(iri);

                irisToGroupNameMap[iri] = groupName;
            }

            //Set up groupNames in the propertyList
            propertyList[groupName] = propertyList[groupName] ?? {};
            //If iri does not exist in the groupName object then add an empty array to enable pushing to the array
            propertyList[groupName][iri] = propertyList[groupName][iri] ?? [];

            //Find Aliases or generate one
            if (annotationPropertiesWithAliases[groupName]) {
                const propertyWithAlias = annotationPropertiesWithAliases[
                    groupName
                ].find((property) => property.iri === iri);
                alias = propertyWithAlias?.alias ?? '';
            } else {
                alias = propertyValue?.name ?? '';
            }
            propertyValue.alias = alias;

            //Add the new property to the respective groupName and iri. E.G Synonyms[iri] = list of properties that belong to an iri
            propertyList[groupName][iri].push(propertyValue);
            totalAnnotationProperties.value += 1;
            if (typeof propertyValue.value === 'undefined') continue;
            usePropertyBatching().addStringToSearch(propertyValue.value);
        }

        /**
         * Finds all default ontology annotations that have not been picked up by search the classData
         * If the default ontology annotations are not in the class annotations then put them into the list
         * This only applies on defaults like, labels, synonyms, mappings, definitions and not custom annotations
         */

        propertyListSnapshot = propertyList;
        useEdits().setIsSlowModeEnabled(totalAnnotationProperties.value > 1000);

        setPropertyList(propertyList);

        //triggers the internal IRI search
        if (usePropertyBatching().isSearchIdle.value) {
            const searchRes = usePropertyBatching().searchPropertyBatches();
            if (!searchRes) console.warn('Internal URL search failed');
        }
    };

    /**
     * This will return all properties with values and empties that are part of the ontology annotations
     * This is typically used for the add property dialogue list.
     */
    const getAllAnnotationPropertiesWithEmpties = () => {
        if (!getPropertyList.value) return;
        //Need to clone otherwise the loop will edit the original object reference
        const builtPropertyList = cloneDeep(getPropertyList.value);

        const customAnnotationProperties =
            useOntology().getCurrentOntology.value?.customAnnotationProperties;
        const annotationPropertiesWithAliases =
            useOntology().getAllDefaultAnnotationPropertiesWithAliases.value;
        if (!customAnnotationProperties || !annotationPropertiesWithAliases)
            return;

        //Go through each default ontology annotation property and add any empty iris
        Object.keys(annotationPropertiesWithAliases).forEach(
            (defaultAnnotationKey) => {
                if (!annotationPropertiesWithAliases[defaultAnnotationKey])
                    return;
                annotationPropertiesWithAliases[defaultAnnotationKey].forEach(
                    (property) => {
                        if (
                            !builtPropertyList[defaultAnnotationKey][
                                property.iri
                            ]
                        ) {
                            builtPropertyList[defaultAnnotationKey][
                                property.iri
                            ] = [];
                            builtPropertyList[defaultAnnotationKey][
                                property.iri
                            ].push(property);
                        }
                    }
                );
            }
        );

        //Go through each custom ontology annotation property and add empty iris
        for (const customProperty of customAnnotationProperties) {
            if (!customProperty.name || !customProperty.iri) continue;
            if (!builtPropertyList[customProperty.name]) {
                builtPropertyList[customProperty.name] = {};
                builtPropertyList[customProperty.name][customProperty.iri] = [];
                builtPropertyList[customProperty.name][customProperty.iri].push(
                    customProperty
                );
            }
        }

        return builtPropertyList;
    };

    const getFilteredAnnotations = computed(() => {
        if (!getPropertyList.value) return;
        const builtPropertyList = cloneDeep(getPropertyList.value);
        const customAnnotationProperties =
            useOntology().getCurrentOntology.value?.customAnnotationProperties;
        const annotationPropertiesWithAliases =
            useOntology().getAllDefaultAnnotationPropertiesWithAliases.value;
        if (!customAnnotationProperties || !annotationPropertiesWithAliases)
            return;

        const maxAnnotationProperties = getMaxPropertiesToShow('annotation');
        let emptyAnnotationPropertyCount = 0;

        //Go through each default ontology annotation property and add any empty iris
        Object.keys(annotationPropertiesWithAliases).forEach(
            (defaultAnnotationKey) => {
                if (!annotationPropertiesWithAliases[defaultAnnotationKey])
                    return;
                annotationPropertiesWithAliases[defaultAnnotationKey].forEach(
                    (property) => {
                        if (
                            builtPropertyList[defaultAnnotationKey][
                                property.iri
                            ] ||
                            emptyAnnotationPropertyCount >=
                                maxAnnotationProperties
                        )
                            return;

                        builtPropertyList[defaultAnnotationKey][property.iri] =
                            [];
                        builtPropertyList[defaultAnnotationKey][
                            property.iri
                        ].push(property);
                        emptyAnnotationPropertyCount++;
                    }
                );
            }
        );

        const cachedBuiltList = cloneDeep(builtPropertyList);

        //Go through each custom ontology annotation property and add empty iris
        for (const customProperty of customAnnotationProperties) {
            if (emptyAnnotationPropertyCount >= maxAnnotationProperties) break;
            if (!customProperty.name || !customProperty.iri) continue;
            if (builtPropertyList[customProperty.name]) continue;

            builtPropertyList[customProperty.name] = {};
            builtPropertyList[customProperty.name][customProperty.iri] = [];
            builtPropertyList[customProperty.name][customProperty.iri].push(
                customProperty
            );
            emptyAnnotationPropertyCount++;
        }

        return emptyAnnotationPropertyCount >= maxAnnotationProperties
            ? cachedBuiltList
            : builtPropertyList;
    });

    const findGroupNameInOntologyAnnotationProperties = (
        iri: string,
        annotationProperties?: Record<string, AnnotationPropertyAlias[]>
    ) => {
        const annotationPropertiesWithAliases =
            annotationProperties ||
            useOntology().getAllDefaultAnnotationPropertiesWithAliases.value;

        for (const key in annotationPropertiesWithAliases) {
            const foundIri = annotationPropertiesWithAliases[key].find(
                (property) => property.iri === iri
            );
            if (foundIri) {
                return foundIri.sectionName;
            }
        }
        return '';
    };

    const getEmptyListItems = () => {
        return emptyListItems.value;
    };

    /**
     * Determines the max number of properties an ontology can have without the need of hiding properties without
     * value in the properties list.
     * @param {"relational" | "annotation"} type
     * @return {number}
     */
    const getMaxPropertiesToShow = (type: 'relational' | 'annotation') => {
        const defaultPropertiesLength =
            type === 'relational'
                ? RELATIONSHIP_PROPS.length
                : ANNOTATION_PROPS.length;
        return MAX_DISPLAY_PROPERTIES + defaultPropertiesLength;
    };

    /**
     * Updates an existing value in the property list object.
     * @param {string} propertyGroupName
     * @param {string} propertyIri
     * @param {string} oldValue
     * @param {string} newValue
     */
    const updateValue = (
        propertyGroupName: string,
        propertyIri: string,
        oldValue: string,
        newValue: string
    ) => {
        const propertyListGroup =
            propertyList.value && propertyList.value[propertyGroupName];
        const propertyListValuesPerIri =
            propertyListGroup && propertyListGroup[propertyIri];

        if (!propertyListValuesPerIri) return;

        for (const property of propertyListValuesPerIri) {
            if (property.value !== oldValue) continue;

            property.value = newValue;

            break;
        }
    };

    /**
     * Adds a value to the property list object under a given property name and iri.
     * @param {string} propertyGroupName
     * @param {string} iri
     * @param {string} value
     */
    const addValue = (
        propertyGroupName: string,
        iri: string,
        value: string
    ) => {
        if (!propertyList.value) return;

        if (!propertyList.value[propertyGroupName]) {
            propertyList.value = {
                ...{ [propertyGroupName]: {} },
                ...propertyList.value,
            };
        }

        if (!propertyList.value[propertyGroupName][iri]) {
            set(propertyList.value[propertyGroupName], iri, []);
        }

        propertyList.value[propertyGroupName][iri].push({
            iri: iri,
            value: value,
            name: iriToShortText(iri),
        });
    };

    /**
     * Removes a value from the propertyList object.
     * @param {string} propertyGroupName
     * @param {string} iri
     * @param {string} value
     */
    const removeValue = (
        propertyGroupName: string,
        iri: string,
        value: string
    ) => {
        if (!propertyList.value) return;
        if (!propertyList.value[propertyGroupName]) return;
        if (!propertyList.value[propertyGroupName][iri]) return;

        const propertyValuesByIri = propertyList.value[propertyGroupName][iri];

        propertyValuesByIri.some((propertyValue, index) => {
            if (propertyValue.value !== value) return false;

            propertyValuesByIri.splice(index, 1);

            return true;
        });
    };

    /**
     * Given a property group name (eg. textualDefinitions, synonyms) extracts values from the
     * propertyList object.
     * @param {string} propName
     * @return {any[] | string[]}
     */
    const getPropertyValuesByPropName = (propName: string) => {
        if (!propertyList.value) return [];
        if (!propertyList.value[propName]) return [];

        const propertyValues: string[] = [];

        Object.values(propertyList.value[propName]).forEach((properties) => {
            properties.forEach((property) => {
                property.value && propertyValues.push(property.value);
            });
        });

        return propertyValues;
    };

    ///Removes all values of a given property
    const removePropertyValuesByPropName = (propName: string) => {
        if (!propName) return;
        if (!propertyList.value) return;
        if (!propertyList.value[propName]) return;

        const iri = Object.keys(propertyList.value[propName])[0];
        if (propName === 'labels') {
            propertyList.value[propName][iri] = propertyList.value[propName][
                iri
            ].filter((label) => label.name === 'primary label');
        } else {
            propertyList.value[propName][iri] = [];
        }
    };

    const removeItemFromPropList = async (
        propItemValue: string,
        property: components['schemas']['CustomProperty'],
        propertyGroupName: string
    ) => {
        const classId = useClassView().getClassElasticSearchId.value;
        const ontologyId = useOntology().getCurrentOntologyId.value;

        if (!classId || !ontologyId) return;

        const propertyIri = property.iri || '';
        const propertyValue = propItemValue;
        const transactionId = useEdits().getTransactionId.value;
        const lang = extractPropertyLanguageTag(property);
        const pathParams = { classId, ontologyId };
        const bodyParams = { propertyIri, propertyValue, transactionId, lang };

        await deletePropertyValue(pathParams, bodyParams);

        const selectionMounted = useClassTree().selectionMounted;
        selectionMounted &&
            (await selectionMounted(!useStore().getters.hasRemoved));

        const oldValue =
            usePropertyValues().getPropertyValuesByIri(propertyIri) || [];

        const newValue = oldValue.filter((value) => value !== propertyValue);
        usePropertyValues().removePropertyValue(propertyIri, propertyValue);
        usePropertyList().removeValue(
            propertyGroupName,
            propertyIri,
            propertyValue
        );

        await useEdits().trackChanges(
            propertyIri,
            propertyGroupName,
            propItemValue,
            oldValue,
            newValue,
            false,
            true,
            lang
        );
    };

    // This is not a complete implementation - it is only used by the Termite toggle - see CENT-2911
    // It does not attempt to update the list (as these props are hidden)
    const addItemToPropList = async (
        propertyValue: string,
        property: components['schemas']['CustomProperty'],
        propertyGroupName: string
    ) => {
        const classId = useClassView().getClassElasticSearchId.value;
        const ontologyId = useOntology().getCurrentOntologyId.value;

        if (!classId || !ontologyId) return;

        const propertyIri = property.iri || '';
        const transactionId = useEdits().getTransactionId.value;
        const lang = extractPropertyLanguageTag(property);
        const pathParams = { classId, ontologyId };
        const bodyParams = { propertyIri, propertyValue, transactionId, lang };

        await createPropertyValue(pathParams, bodyParams);

        usePropertyValues().addPropertyValue(
            propertyIri,
            propertyValue,
            propertyGroupName
        );

        await useEdits().trackChanges(
            propertyIri,
            propertyGroupName,
            propertyValue,
            [],
            [propertyValue],
            false,
            true
        );
    };

    const handlePropertyValueUpdate = async (
        propertyValue: string,
        newPropertyValue: string,
        property: components['schemas']['CustomProperty'],
        propertyGroupName: string
    ) => {
        const classId = useClassView().getClassElasticSearchId.value;
        const ontologyId = useOntology().getCurrentOntologyId.value;

        if (!classId || !ontologyId) return;

        const propertyIri = property.iri || '';
        const transactionId = useEdits().getTransactionId.value;
        const lang = extractPropertyLanguageTag(property);
        const pathParams = { classId, ontologyId };
        const bodyParams = {
            propertyIri,
            propertyValue,
            newPropertyValue,
            transactionId,
            lang,
        };

        const oldValue =
            usePropertyValues().getPropertyValuesByIri(propertyIri) || [];

        const editResponse = await editPropertyValue(pathParams, bodyParams);

        const selectionMounted = useClassTree().selectionMounted;

        selectionMounted &&
            (await selectionMounted(!useStore().getters.hasEdited));
        const newValue = oldValue
            .filter((value) => value !== propertyValue)
            .concat(newPropertyValue);

        useEdits().setRelationsHaveChanged(true);

        const newClassCreated = !oldValue.length;
        const classHasMadeObsolete = !newValue.length;

        usePropertyValues().addPropertyValue(
            propertyIri,
            propertyValue,
            newPropertyValue
        );

        await usePropertyValues().updatePropertyValue(
            propertyIri,
            propertyValue,
            newPropertyValue,
            lang
        );

        await useEdits().trackChanges(
            propertyIri,
            propertyGroupName,
            propertyValue,
            oldValue,
            newValue,
            false,
            true
        );

        useEdits().trackState(
            propertyGroupName,
            newClassCreated,
            classHasMadeObsolete,
            editResponse
        );

        if (
            usePropertyValues().isPrimaryLabel(
                propertyValue,
                property,
                propertyGroupName
            )
        ) {
            await useObservers().notifyObservers('PRIMARY_LABEL_UPDATED', {
                oldValue: propertyValue,
                newValue: newPropertyValue,
            });
        }
    };

    return {
        propertyBlackList,
        buildPropertyList,
        getPropertyList,
        getPropertyValuesByPropName,
        getMaxPropertiesToShow,
        resetPropertyList,
        updateValue,
        addValue,
        removeValue,
        getEmptyListItems,
        removeItemFromPropList,
        addItemToPropList,
        handlePropertyValueUpdate,
        getResolvedMappings,
        setResolvedMappings,
        pushResolvedMappings,
        resolvedMappings,
        getIsMappingsResolved,
        setIsMappingsResolved,
        resetResolvedMappings,
        getAllAnnotationPropertiesWithEmpties,
        getFilteredAnnotations,
        removePropertyValuesByPropName,
    };
};
