var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"class-summary",class:{ 'highlighted-summary': _vm.highlightProps.length }},[_vm._l((_vm.serialPropNames),function(propName,index){return _c('div',{key:propName,staticClass:"summary-property",class:{
            'highlighted-property': _vm.highlightProps.indexOf(propName) !== -1,
        }},[(_vm.getPropValue(propName, _vm.$attrs).length)?[_c('strong',{staticClass:"property-prefix mr-1",class:{
                    'd-none': _vm.hidePropertyPrefix.indexOf(propName) !== -1,
                }},[_vm._v(" "+_vm._s(_vm.upperFirst(_vm.humanReadable(propName)))),_c('span',{staticClass:"font-weight-normal"},[_vm._v(":")])]),(_vm.propLengthMax[index] !== 0)?_c('editable-text',{ref:"propValue",refInFor:true,attrs:{"text":_vm.serialPropValue(
                        propName,
                        _vm.allUpperFirst.indexOf(propName) !== -1,
                        _vm.hideFullStop.indexOf(propName) === -1
                    ),"maxLength":Array.isArray(_vm.propLengthMax)
                        ? _vm.propLengthMax[index]
                        : _vm.propLengthMax}}):_c('span',{ref:"propValue",refInFor:true},[_vm._v(_vm._s(_vm.serialPropValue( propName, _vm.allUpperFirst.indexOf(propName) !== -1, _vm.hideFullStop.indexOf(propName) === -1 )))])]:(_vm.isPlaceholder)?[_vm._v(" No "+_vm._s(_vm.$pluralize.singular(_vm.humanReadable(propName)))+" found. ")]:_vm._e()],2)}),_vm._t("default"),(_vm.isShowBadges)?_c('span',[(_vm.isRootClass && !_vm.isObsolete)?_c('b-badge',{attrs:{"variant":"dark","pill":""}},[_vm._v("Root class")]):_vm._e(),(_vm.hasEditState)?[(_vm.isObsolete)?_c('b-badge',{staticClass:"obsolete-badge",attrs:{"pill":""}},[_vm._v("Obsolete class")]):(_vm.isNew)?_c('b-badge',{attrs:{"variant":"info","pill":""}},[_vm._v("New class")]):_vm._e()]:_vm._e(),(_vm.$attrs.schemaVersion === 1)?_vm._l((_vm.hasBadgeProperties),function(propName){return _c('span',{key:propName},[_c('b-badge',{class:[
                        'property-badge align-middle mr-1',
                        (propName + "-badge"),
                        {
                            'custom-badge': _vm.isCustomProperty(propName),
                            'annotations-badge':
                                _vm.annotationProps.indexOf(propName) !== -1,
                            'relationship-badge':
                                _vm.relationshipProps.indexOf(propName) !== -1,
                        } ],attrs:{"tabindex":"0","href":"#","variant":"secondary","id":(propName + "-" + (_vm.$attrs.id) + "-" + _vm.global_randomID),"pill":!_vm.isPopover},on:{"click":function($event){_vm.isPopover && _vm.onPropBadge($event)},"blur":function($event){_vm.isPopover && _vm.offPropBadge($event)}}},[_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.createBadgeString(propName))+" ")]),_c('span',{staticClass:"badge badge-light property-count"},[_vm._v(" "+_vm._s(_vm.getPropValue(propName, _vm.$attrs).length)+" ")])]),(_vm.isPopover)?_c('b-popover',{attrs:{"placement":"bottom","triggers":"hover click blur","boundary":"viewport","target":(propName + "-" + (_vm.$attrs.id) + "-" + _vm.global_randomID),"delay":{ show: 300, hide: 0 }},on:{"show":_vm.onPopoverShow}},_vm._l((_vm.getPropValue(
                            propName,
                            _vm.$attrs
                        )),function(value,index){return _c('property-value',{key:("propName-" + index),attrs:{"propName":_vm.$pluralize.singular(_vm.humanReadable(propName)),"propOntoID":_vm.$attrs.sourceUniqueID,"propPrimID":_vm.$attrs.primaryID,"value":value,"isClass":propName === 'mappings' ||
                            _vm.relationshipProps.indexOf(propName) !== -1,"isGlobalClassScope":propName === 'mappings',"initClass":{}}})}),1):_vm._e()],1)}):_vm._l((_vm.hasBadgeProperties),function(length,propName){return _c('span',{key:propName},[(propName && length)?_c('b-badge',{key:(propName + "-" + length),class:[
                        'property-badge align-middle mr-1',
                        (propName + "-badge"),
                        {
                            'custom-badge': _vm.isCustomProperty(propName),
                            'annotations-badge':
                                _vm.annotationProps.indexOf(propName) !== -1,
                            'relationship-badge':
                                _vm.relationshipProps.indexOf(propName) !== -1,
                        } ],attrs:{"tabindex":"0","href":"#","variant":"secondary","id":(propName + "-" + (_vm.$attrs.id) + "-" + _vm.global_randomID),"pill":!_vm.isPopover},on:{"click":function($event){_vm.isPopover && _vm.onPropBadge($event)},"blur":function($event){_vm.isPopover && _vm.offPropBadge($event)}}},[(!_vm.createBadgeString(propName))?[_c('span',{staticClass:"align-middle",staticStyle:{"color":"black !important"}},[_vm._v(" "+_vm._s(_vm.createBadgeString(propName))+" ")]),_c('span',{staticClass:"badge badge-light property-count"},[_vm._v(" "+_vm._s(length)+" ")])]:_vm._e()],2):_vm._e(),(_vm.isPopover)?_c('b-popover',{attrs:{"placement":"bottom","triggers":"hover click blur","boundary":"viewport","target":(propName + "-" + (_vm.$attrs.id) + "-" + _vm.global_randomID),"delay":{ show: 300, hide: 0 }},on:{"show":_vm.onPopoverShow}},_vm._l((_vm.getPropValue(
                            propName,
                            _vm.$attrs
                        )),function(value,index){return _c('property-value',{key:("propName-" + index),attrs:{"is-saving-dialogue":true,"propName":_vm.$pluralize.singular(_vm.humanReadable(propName)),"propOntoID":_vm.$attrs.sourceUniqueID,"propPrimID":_vm.$attrs.primaryID,"value":value,"isClass":propName === 'mappings' ||
                            _vm.relationshipProps.indexOf(propName) !== -1,"isGlobalClassScope":propName === 'mappings',"initClass":{}}})}),1):_vm._e()],1)}),(_vm.hasEditState && !_vm.isObsolete)?[(_vm.hasError.length)?_c('div',{staticClass:"text-danger"},[_c('b-badge',{attrs:{"variant":"danger","pill":""}},[_vm._v("Errors")]),_vm._v(" "+_vm._s(_vm.upperFirst(_vm.humanReadable(_vm.hasError).join(', ')))+". ")],1):_vm._e(),(_vm.hasEdit.length && !_vm.isNew)?_c('div',{staticClass:"text-info"},[_c('b-badge',{attrs:{"variant":"info","pill":""}},[_vm._v("Changed")]),_vm._v(" "+_vm._s(_vm.upperFirst(_vm.humanReadable(_vm.hasEdit).join(', ')))+". ")],1):_vm._e()]:_vm._e()],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }