<template v-if="text.length || showHidden">
    <li
        class="chip"
        :class="{ 'chip-more': showHidden }"
        :id="`${global_randomID}`"
        ref="editText"
        @click="clickDirect">
        {{ text }}
        <PropertyValuePopup
            v-if="!showHidden && showPopup"
            :primaryID="primaryID"
            :primaryLabel="label"
            :description="body"
            :synonyms="synonyms"
            :target="`${global_randomID}`"
            :direction="'bottom'" />
        <PopupChipsHidden
            v-if="showHidden && showPopup"
            :hiddenChips="hiddenChips"
            :target="`${global_randomID}`" />
    </li>
</template>

<script>
import router from '@/router';
import PropertyValuePopup from '@/components/ui/PropertyValuePopup';
import { computed } from '@vue/composition-api';
import PopupChipsHidden from '@/components/ui/PopupChipsHidden';
import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';

export default defineComponent({
    name: 'Chip',
    components: { PopupChipsHidden, PropertyValuePopup },
    props: {
        sourceUniqueID: {
            type: String,
            required: true,
        },
        primaryID: {
            type: String,
        },
        text: {
            type: String,
            required: true,
        },
        label: {
            type: String,
        },
        body: {
            type: String,
        },
        synonyms: {
            type: [],
        },
        hiddenChips: {
            type: [],
        },
        showPopup: {
            type: Boolean,
            default: true,
        },
        isOntology: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const showHidden = computed(() => {
            return typeof props.hiddenChips !== 'undefined';
        });

        const onClickChip = () => {
            if (props.isOntology) {
                router.push({
                    name: 'ontology',
                    params: {
                        ontologyID: props.sourceUniqueID,
                    },
                });
            } else {
                router.push({
                    name: 'class',
                    params: {
                        ontologyID: props.sourceUniqueID,
                        primaryID: props.primaryID,
                    },
                });
            }
        };

        const clickDirect = () => {
            if (!showHidden.value) onClickChip();
        };

        return {
            clickDirect,
            showHidden,
        };
    },
});
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

::v-deep.chip {
    color: #f0890b;
    border: 1px solid #f0890b;
    padding: 3px 4px;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 4px;
    margin-right: 5px;
    display: inline;
    margin-left: 0px !important;

    :hover ::v-deep {
        cursor: pointer;
        background-color: #f0890b !important;
        color: black !important;
    }
}
</style>
