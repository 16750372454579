import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type PropertyPathsFromRootToChildrenPathParams = operations["getPropertyPathsFromRootToChildrenUsingGET"]["parameters"]["path"];
type PropertyPathsFromRootToChildrenQueryParams = operations["getPropertyPathsFromRootToChildrenUsingGET"]["parameters"]["query"];
type PropertyPathsFromRootToChildrenResponse = operations["getPropertyPathsFromRootToChildrenUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get properties information on paths from root to a specific primary ID (specified by internal property URI) for a given ontology
 * @param {string} ontologyId
 * @param {string} primaryId
 * @param {integer} maximumNumberOfPaths
 * @return {PropertyPathsFromRootToChildrenResponse}
*/
export const getPropertyPathsFromRootToChildren = async (
    { ontologyId }: PropertyPathsFromRootToChildrenPathParams,
    queryParams: PropertyPathsFromRootToChildrenQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/properties/paths-from-root`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as PropertyPathsFromRootToChildrenResponse;
}
