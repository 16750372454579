import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type InstancesForClassPathParams = operations["getInstancesForClassUsingGET"]["parameters"]["path"];
type InstancesForClassQueryParams = operations["getInstancesForClassUsingGET"]["parameters"]["query"];
type InstancesForClassResponse = operations["getInstancesForClassUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * This endpoint takes in a classId and returns a list of all instances that are of that class type.
 * @param {string} ontologyId
 * @param {string} classId
 * @param {integer} from
 * @param {integer} size
 * @return {InstancesForClassResponse}
*/
export const getInstancesForClass = async (
    { ontologyId, classId }: InstancesForClassPathParams,
    queryParams?: InstancesForClassQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/instances`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as InstancesForClassResponse;
}
