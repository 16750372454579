<template>
    <span
        class="class-name-container"
        :class="{
            resolving: isLoading,
            'new-class': isNew,
            'obsolete-class': isObsolete,
            'multi-class': classList.length > 1,
        }">
        <template v-for="(classItem, index) in classList">
            <!-- Content for successful class resolution -->
            <template v-if="classItem.primaryLabel">
                <!-- Link to class ontology (local resolution only) -->
                <template v-if="!isGlobalScope && isOntoBadge">
                    <b-badge
                        v-if="
                            $store.getters.ontoData(classItem.sourceUniqueID)
                                .ontologyShortDisplayName
                        "
                        class="align-text-bottom"
                        :class="{
                            'chip-property': !type === 'property',
                        }"
                        variant="primary"
                        :key="`onto-${classItem.id}`"
                        :to="`/ontologies/${classItem.sourceUniqueID}`"
                        v-b-tooltip.hover="{
                            title: $store.getters.ontoData(
                                classItem.sourceUniqueID
                            ).ontologyLongDisplayName,
                            delay: { show: showDelay, hide: 0 },
                        }">
                        {{ classItem.shortDisplayName }}
                    </b-badge>
                    <font-awesome-icon
                        v-else
                        class="text-muted"
                        icon="minus-circle"
                        :key="`onto-${classItem.id}`"
                        v-b-tooltip.hover="{
                            title: 'The ontology is no longer loaded',
                            delay: { show: showDelay, hide: 0 },
                        }" />
                </template>

                <span class="class-name" :key="`name-${classItem.id}`">
                    <component
                        :id="`${global_randomID}-${classItem.id}`"
                        :is="isLink ? 'b-link' : 'span'"
                        :to="{
                            name: type,
                            params: {
                                ontologyID: classItem.sourceUniqueID,
                                primaryID: classItem.primaryID,
                                initialData: classItem,
                            },
                        }">
                        <!-- Label for class ontology (global resolution only) -->
                        <b-badge
                            v-if="isGlobalScope"
                            class="global-badge mr-1"
                            variant="light">
                            {{ classItem.shortDisplayName }}
                        </b-badge>

                        <!-- Class name -->
                        <span
                            v-if="
                                isSelf ||
                                classItem.primaryLabel !==
                                    initClass.primaryLabel
                            "
                            class="name-wrapper">
                            <!-- Shows the primary label for each class instance that does have a different label -->
                            <span
                                v-if="
                                    index === classList.length - 1 ||
                                    classItem.primaryLabel !==
                                        classList[index + 1].primaryLabel
                                "
                                ref="termName"
                                class="name-label px-1"
                                :class="buildTestClass('name-label')"
                                ><!--
                                --><slot>{{ classItem.primaryLabel }}</slot
                                ><!--
                            --></span>

                            <!-- Only for the last class instance -->
                            <span
                                v-if="index === classList.length - 1"
                                class="d-inline-block">
                                <span
                                    v-if="isShortID"
                                    ref="shortID"
                                    class="name-termid"
                                    :id="buildTestClass('term-id')"
                                    :class="{
                                        'name-termid-property': type,
                                    }"
                                    ><!--
                                    -->{{ termID(classItem)
                                    }}<!--
                                --></span>

                                <!-- Other action buttons -->
                                <slot name="actions"></slot>
                            </span>
                        </span>

                        <!-- Hover-on class summary -->
                        <b-popover
                            v-if="isSummary"
                            placement="bottom"
                            triggers="hover"
                            boundary="viewport"
                            :title="classItem.primaryID"
                            :target="`${global_randomID}-${classItem.id}`"
                            :delay="{ show: showDelay, hide: 0 }">
                            <class-summary
                                v-if="ontologyID && primaryID"
                                v-bind="classItem"
                                :isNew="isNew"
                                :isObsolete="isObsolete" />
                        </b-popover>
                    </component>
                </span>
            </template>

            <!-- Placeholder while name resolution takes place (local resolution only) -->
            <span
                v-else
                class="name-primaryid"
                :key="`placeholder-${classItem.id}`"
                >#{{ primaryID }}</span
            >
        </template>

        <!-- Placeholder while name resolution takes place (global resolution only) -->
        <span v-if="!classList.length" class="name-primaryid">{{
            primaryID
        }}</span>

        <!-- Paging for global class resolution (global resolution only) -->
        <b-button
            v-if="isGlobalScope && !isLastClassesPage && !isTruncate"
            variant="outline-secondary"
            size="sm"
            class="small ml-1 py-0 px-1 align-baseline"
            :class="{ pulsating: isLoading }"
            :disabled="isLoading"
            @mousedown.stop.prevent="onMoreClasses">
            {{ isLoading ? 'Loading classes...' : 'Load more' }}
        </b-button>
        <span v-else-if="isGlobalScope && !isLastClassesPage">...</span>
    </span>
</template>

<script>
import { filter, isEmpty, isFunction, remove, union } from 'lodash';

import ApiOntology from '@/api/ontology';
import ApiSearch from '@/api/search';
import { useClassTree, useEdits, usePropertyList } from '@/compositions';

export default {
    name: 'ClassName',
    components: { ClassSummary: () => import('@/components/ui/ClassSummary') },
    props: {
        // Primary ID of the class whose name is to be resolved
        primaryID: {
            type: String,
            default: '',
        },

        // Ontology ID the class occurs in.
        ontologyID: {
            type: String,
            default: '',
        },

        // Initial non-empty class data to bypass name resolution.
        initClass: {
            default: () => ({}),
        },

        // True if the ontology this class occurs in currently is to be shown.
        isOntoBadge: {
            type: Boolean,
            default: false,
        },

        // True if the primary ID is referring to classes within any ontology.
        isGlobalScope: {
            type: Boolean,
            default: false,
        },

        // Includes the resolved name for the class in the same ontology.
        isSelf: {
            type: Boolean,
            default: true,
        },

        // Size of the page containing global resolution results.
        classesPageSize: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SEARCH_SIZE),
        },

        // True if the class to be resolved is new.
        isNew: {
            type: Boolean,
            default: false,
        },

        // True if the class to be resolved is obsolete.
        isObsolete: {
            type: Boolean,
            default: false,
        },

        // True if short-form IDs for every occurance of the class is to be shown.
        isShortID: {
            type: Boolean,
            default: true,
        },

        // True if the resolved text + ontology for the class should be clickable.
        isLink: {
            type: Boolean,
            default: false,
        },

        // True if class details are to be shown on hover.
        isSummary: {
            type: Boolean,
            default: true,
        },

        // When true, it uses an ellipsis to cut short the list of resolved classes
        isTruncate: {
            type: Boolean,
            default: false,
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        property: {
            type: Object,
        },
        type: {
            type: String,
            default: 'class',
        },
    },

    data() {
        return {
            // collection of resolved classes across all ontologies with the same primary ID
            globalClasses: [],

            // total number of resolved classes with the same primary ID
            totalClasses: 0,

            // current page number for found resolved classes given a primary ID
            currClassesPage: 0,

            // class data fetched from the server
            pulledClass: {},

            // true if the request for class data is in progress
            isLoading: false,

            // true if class data had to be requested
            isPulled: false,
        };
    },

    computed: {
        classData: function () {
            if (isEmpty(this.initClass)) {
                return this.pulledClass;
            } else {
                return this.initClass;
            }
        },

        classList: function () {
            let resolvedList = [this.classData];

            if (this.isGlobalScope) {
                resolvedList = this.globalClasses;
                if (!this.isSelf) {
                    remove(resolvedList, { sourceUniqueID: this.ontologyID });
                }
            }

            return resolvedList;
        },

        isLastClassesPage() {
            return (
                this.classesPageSize * this.currClassesPage >= this.totalClasses
            );
        },
    },

    watch: {
        primaryID: 'reset',

        isLoading(isLoading) {
            if (!isLoading) {
                if (!this.classList.length) {
                    this.$emit('class:unresolved', this.primaryID);
                } else {
                    this.$emit('class:resolved', this.classList);
                }

                if (usePropertyList().getIsMappingsResolved.value) return;
                if (!this.property && !this.property?.iri) return;
                const primaryLabel =
                    this.classList[this.classList.length - 1]?.primaryLabel ||
                    this.property.value;

                usePropertyList().pushResolvedMappings({
                    ...this.property,
                    primaryLabel,
                });
            }
        },

        initClass() {
            if (!this.isGlobalScope) {
                this.checkClass();
            }
        },
    },

    created() {
        this.isPulled = false;
    },

    mounted() {
        this.checkClass();
    },

    methods: {
        reset() {
            this.globalClasses = [];
            this.totalClasses = 0;
            this.currClassesPage = 0;
            this.isPulled = false;
            this.pulledClass = {};
            this.checkClass();
        },

        /**
         * Retrieves class data corresponding to a given primary ID if not already available.
         * NOTE: during rendering, "initClass" may be a function and therefore would be interpreted as an empty object.
         * NOTE: classes with the same or different labels across different ontologies may have the same primary ID.
         */
        checkClass() {
            const params = {};
            let samePrimaryID, sameShortID;
            // The primary ID refers to any class in any ontology => grabs all classes with the same primary/short ID in any ontology.

            if (this.isGlobalScope && !this.isLoading) {
                params.isExact = true;
                params.query = this.primaryID;
                params.pageSize = this.classesPageSize;
                params.pageStart = this.currClassesPage * this.classesPageSize;

                this.isLoading = true;

                ApiSearch.results(params)
                    .then((response) => {
                        samePrimaryID = filter(response.data.elements, {
                            primaryID: params.query,
                        });
                        sameShortID = response.data.elements.filter(
                            (element) =>
                                element.shortFormIDs.indexOf(params.query) !==
                                -1
                        );
                        this.globalClasses.push(
                            ...union(samePrimaryID, sameShortID)
                        );

                        // If the primary ID has not been resolved already, mark this as requested.
                        if (isEmpty(this.initClass)) {
                            this.pulledClass = response.data;
                            this.isPulled = true;
                        }

                        // Only updates the page-related data if there are actual results after filtering.
                        if (this.globalClasses.length) {
                            this.totalClasses = response.data.total;
                            this.currClassesPage++;
                        }

                        // If no results, try with whatever locally resolved class available.
                        // NOTE: a newly added class may not still be included in any search.
                        if (!this.totalClasses && !isEmpty(this.initClass)) {
                            this.globalClasses.push(this.initClass);
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });

                // The scope is within the current ontology and the corresponding class data is not available => grabs that class specifically.
            } else if (
                this.initClass &&
                isEmpty(this.initClass) &&
                !isFunction(this.initClass) &&
                !this.isLoading &&
                !useEdits().isNewClass(this.primaryId)
            ) {
                if (this.primaryID === '' || this.ontologyID === '') return;
                params.ontologyID = this.ontologyID;
                params.primaryID = this.primaryID;
                this.isLoading = true;

                ApiOntology.query(params)
                    .then((response) => {
                        this.pulledClass = response.data;
                        this.isPulled = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else if (useEdits().isNewClass(this.primaryID)) {
                this.pulledClass = useClassTree().searchClassInTree(
                    this.primaryID
                );
                this.isPulled = true;
                this.isLoading = false;
            }
        },

        onMoreClasses() {
            this.checkClass();
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.multi-class {
    line-height: 1.7rem;
}

.class-name {
    &:not(:last-of-type) {
        .name-label:after {
            content: '\25CF';
            margin-left: 0.5em;
            margin-right: 0.25em;
            color: $text-muted !important;
            opacity: 0.4;
        }
    }

    &:not(:hover) {
        a .name-label {
            color: $secondary;
        }

        .global-badge {
            color: $link-hover-color;
            border: 1px solid $primary;
        }
    }

    &:hover {
        a .global-badge {
            color: $body-color;
            border: 1px solid transparent;
            background: darken($primary, 10%);
        }

        span .global-badge {
            pointer-events: none;
        }
    }

    .obsolete-class & .name-wrapper {
        .name-label,
        .name-termid {
            color: $gray-600;
        }
        .name-termid {
            text-decoration: line-through;
        }
    }

    .new-class & {
        color: $primary;

        .name-label {
            color: $info;
        }

        .global-badge {
            pointer-events: none;
            color: $info;
            border: 1px solid $info;
        }
    }
}

.name-primaryid {
    color: $text-muted;
    transition: $btn-transition;
}

.pulsating {
    border: 0;
    @include pulse-color-animation;
}

::v-deep.chip-property {
    color: white !important;
    background-color: $property !important;
}

::v-deep .name-termid-property {
    color: $property !important;
}

@include pulse-color-keyframes($secondary);
</style>
