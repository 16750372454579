import { Observer } from '@/ts';
import { useClassView } from '@/compositions';

export const updatePrimaryLabel: Observer = (args) => {
    const { oldValue, newValue, classMainData } = args as unknown as {
        oldValue: string;
        newValue: string;
        classMainData?: Record<string, unknown>;
    };

    if (!oldValue || !newValue || !classMainData || !classMainData.selectedData)
        return true;

    useClassView().updatePrimaryLabel(oldValue, newValue);

    if (classMainData && classMainData.selectedData) {
        const selectedData = classMainData.selectedData as Record<
            string,
            unknown
        >;
        selectedData.primaryLabel = newValue;
    }

    return true;
};
