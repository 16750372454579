import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type PropertyValuePathParams = operations["deletePropertyValueUsingDELETE"]["parameters"]["path"];
type PropertyValueBodyParams = operations["deletePropertyValueUsingDELETE"]["requestBody"]["content"]["application/json"];
type PropertyValueResponse = operations["deletePropertyValueUsingDELETE"]["responses"]["201"]['content']["*/*"];

/**
 * Delete a property with iri = propertyIri and value = propertyValue and optional lang = the given language in standard format.A deletion of a property with iri = http://ontology.scibite.com/ontology/termite/ignore or iri = http://scibite.com/skos/config#ignore will delete all properties with these IRIs
 * @param {string} ontologyId
 * @param {string} classId
 * @param {PropertyValuePathParams} bodyParams
 * @return {PropertyValueResponse}
*/
export const deletePropertyValue = async (
    { ontologyId, classId }: PropertyValuePathParams,
    bodyParams: PropertyValueBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/properties`;
    const response = await Axios.delete(url, { data: bodyParams });

    return response && response.data as PropertyValueResponse;
}
