import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type CreatePropertyValuePathParams = operations["createPropertyValueUsingPOST"]["parameters"]["path"];
type CreatePropertyValueBodyParams = operations["createPropertyValueUsingPOST"]["requestBody"]["content"]["application/json"];
type CreatePropertyValueResponse = operations["createPropertyValueUsingPOST"]["responses"]["201"]['content']["*/*"];

/**
 * Create a property with iri = propertyIri and value = propertyValue  and optional lang = the given language in standard format  and IRI tag if minting is enabled. If the property already exists, then ALREADY_REPORTED is returned
 * @param {string} ontologyId
 * @param {string} classId
 * @param {CreatePropertyValuePathParams} bodyParams
 * @return {CreatePropertyValueResponse}
*/
export const createPropertyValue = async (
    { ontologyId, classId }: CreatePropertyValuePathParams,
    bodyParams: CreatePropertyValueBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/properties`;
    const response = await Axios.post(url, bodyParams);

    return response && response.data as CreatePropertyValueResponse;
}
