import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type ExportToSkosFilePathParams = operations["exportToSkosFileUsingGET"]["parameters"]["path"];
type ExportToSkosFileQueryParams = operations["exportToSkosFileUsingGET"]["parameters"]["query"];
type ExportToSkosFileResponse = operations["exportToSkosFileUsingGET"]["responses"]["202"]['content']["*/*"];

/**
 * exportToSkosFile
 * @param {string} ontologyId
 * @param {string} skosFormat
 * @param {boolean} skosXL
 * @return {ExportToSkosFileResponse}
*/
export const exportToSkosFile = async (
    { ontologyId }: ExportToSkosFilePathParams,
    queryParams?: ExportToSkosFileQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/exportAsSkos`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as ExportToSkosFileResponse;
}
