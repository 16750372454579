import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type EditLabelsPathParams = operations["editLabelsUsingPOST"]["parameters"]["path"];
type EditLabelsBodyParams = operations["editLabelsUsingPOST"]["requestBody"]["content"]["application/json"];
type EditLabelsResponse = operations["editLabelsUsingPOST"]["responses"]["201"]['content']["*/*"];

/**
 * This endpoint has been deprecated. You can call {ontologyId}/classes/{classId}/properties to make sure that tags are preserved
 * @param {string} ontologyId
 * @param {string} classId
 * @param {EditLabelsPathParams} bodyParams
 * @return {EditLabelsResponse}
*/
export const editLabels = async (
    { ontologyId, classId }: EditLabelsPathParams,
    bodyParams: EditLabelsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/label`;
    const response = await Axios.post(url, bodyParams);

    return response && response.data as EditLabelsResponse;
}
