/**
 * Checks if a given string is a valid URL.
 * NOTE: this may give false positives but is pretty reliable in terms of wrongly flagging valid URLs.
 * @param {string} string - URL to be validated.
 * @see {@link https://stackoverflow.com/a/43467144}
 */
export const isValidUrl = (url: string) => {
    if (typeof url !== 'string') return false;
    const urlRegex = new RegExp(
        '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
    );

    let validUrl = false;
    try {
        const a = new URL(url);
        validUrl = true;
    } catch {
        validUrl = false;
    }
    return urlRegex.test(url) && validUrl;
};
