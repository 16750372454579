import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllTextualDefinitionsPathParams = operations["deleteAllTextualDefinitionsUsingDELETE"]["parameters"]["path"];
type AllTextualDefinitionsBodyParams = operations["deleteAllTextualDefinitionsUsingDELETE"]["requestBody"]["content"]["application/json"];
type AllTextualDefinitionsResponse = operations["deleteAllTextualDefinitionsUsingDELETE"]["responses"]["201"]['content']["*/*"];

/**
 * Remove all textual definitions from the specified class
 * @param {string} ontologyId
 * @param {string} classId
 * @param {AllTextualDefinitionsPathParams} bodyParams
 * @return {AllTextualDefinitionsResponse}
*/
export const deleteAllTextualDefinitions = async (
    { ontologyId, classId }: AllTextualDefinitionsPathParams,
    bodyParams: AllTextualDefinitionsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/textualDefinitions`;
    const response = await Axios.delete(url, { data: bodyParams });

    return response && response.data as AllTextualDefinitionsResponse;
}
