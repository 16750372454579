<template>
    <div class="onto-filter">
        <!-- HEADER -->
        <b-dropdown-header :id="titleId" class="onto-filter-title d-flex">
            <span class="flex-grow-1 text-light filter-title">{{
                filterTitle
            }}</span>
            <slot>
                <b-button
                    class="collapse-btn ml-3 p-0 border-0 bg-transparent"
                    @click="
                        $emit(
                            isOntoFilterShown ? 'filter:close' : 'filter:open'
                        )
                    ">
                    <font-awesome-icon
                        :icon="isOntoFilterShown ? 'minus' : 'plus'"
                        class="align-middle" />
                </b-button>
            </slot>
        </b-dropdown-header>

        <!-- SUMMARY -->
        <b-tooltip
            v-if="!isOntoFilterShown"
            triggers="hover"
            :target="titleId"
            :delay="{ show: showDelay, hide: 0 }">
            <span v-if="$store.getters.filters.ontologies.length">
                <strong>Search in</strong>:
                {{ $store.getters.filteringOntoIDs.join(', ') }}
            </span>
        </b-tooltip>

        <!-- LIST -->
        <div v-show="isOntoFilterShown" class="onto-filter-controls">
            <!-- No ontologies loaded -->
            <b-dropdown-header
                v-if="!$store.getters.hasOntoIndex"
                class="text-center">
                <p class="text-wrap">No ontologies have been loaded yet</p>
                <b-button variant="primary" to="/ontologies" size="sm">
                    Load new
                </b-button>
            </b-dropdown-header>

            <!-- Some ontologies loaded -->
            <template v-if="$store.getters.hasOntoIndex">
                <!-- Instant filter -->
                <onto-typeahead
                    class="mx-2 my-2"
                    variant="secondary"
                    @onto:query="onOntoQuery" />

                <!-- Checkbox list -->
                <b-form-checkbox-group
                    ref="ontoFilterList"
                    stacked
                    class="px-3 pt-2 pb-1 onto-filter-list"
                    :style="{ maxHeight: ontoEntriesMax * 1.56 + 'rem' }"
                    v-model="$store.getters.filters.ontologies">
                    <b-form-checkbox
                        v-for="(ontoID, index) in ontoFilterList"
                        v-if="
                            $store.getters.hasOnto(ontoID) &&
                            ontoQueryIDs.indexOf(ontoID) !== -1
                        "
                        :class="{
                            'border-top pt-1 mt-1':
                                ontoListSplitIndex === index,
                        }"
                        :value="ontoID"
                        :key="ontoID"
                        :id="buildTestClass('filter--onto-' + ontoID)">
                        <b-link
                            class="info-onto-link text-secondary float-right ml-1"
                            :to="{
                                name: 'ontology',
                                params: { ontologyID: ontoID },
                            }">
                            <font-awesome-icon
                                icon="arrow-right"
                                size="sm"
                                @mouseenter.stop="
                                    cancelParentHover($event, true, 2)
                                "
                                @mouseleave.stop="
                                    cancelParentHover($event, false, 2)
                                " />
                        </b-link>
                        <span
                            v-b-tooltip.hover="{
                                boundary: 'window',
                                title: $store.getters.ontoData(ontoID)
                                    .ontologyLongDisplayName,
                                delay: { show: showDelay, hide: 0 },
                            }">
                            {{
                                $store.getters.ontoData(ontoID)
                                    .ontologyShortDisplayName
                            }}
                        </span>
                    </b-form-checkbox>
                </b-form-checkbox-group>

                <!-- Bulk actions -->
                <div
                    v-if="$store.getters.hasOntoIndex"
                    class="px-2 py-1 mt-1 mb-1">
                    <b-button
                        class="onto-filter-reset border-0"
                        size="sm"
                        variant="primary"
                        @click="onOntoReset">
                        Clear all
                    </b-button>
                    <b-link
                        v-if="enableViewAll"
                        class="onto-filter-more float-right font-weight-normal"
                        size="sm"
                        @click="onOntoSeeAll">
                        View all
                    </b-link>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { sortBy, union } from 'lodash';

import OntoTypeahead from '@/components/ui/OntoTypeahead';

export default {
    name: 'OntoFilter',
    components: { OntoTypeahead },
    props: {
        filterTitle: {
            type: String,
            default: 'Ontologies',
        },

        isOntoFilterShown: {
            type: Boolean,
            default: true,
        },

        ontoEntriesMax: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MAX),
        },

        summaryTooltipTarget: {
            type: String,
            default: '',
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        enableViewAll: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            titleId: this.summaryTooltipTarget || this.$uuid.v4(),
            ontoQueryIDs: [],
            pristineOntoIDs: [],
            ontoFilterList: [],
            ontoListSplitIndex: -1,
        };
    },

    computed: {
        isSeparateOntoLists: function () {
            return (
                this.pristineOntoIDs.length &&
                this.$store.getters.pastOntoIDs.length
            );
        },

        ontoIndexIDs: function () {
            const ontoIDs = Object.keys(this.$store.getters.ontoIndex);
            return sortBy(ontoIDs);
        },
    },

    watch: {
        isOntoFilterShown(isShown) {
            if (!isShown) {
                this.updateOntoFilterList();
                this.backToTop();
            }
        },

        '$store.getters.ontoIndex'() {
            this.trackPristineOntos();
            this.updateOntoFilterList();
        },

        '$store.getters.pastOntoIDs': 'trackPristineOntos',
    },

    created() {
        this.trackPristineOntos();
        this.updateOntoFilterList();
    },

    mounted() {
        this.ontoQueryIDs = this.ontoFilterList;
    },

    methods: {
        /**
         * Determines the list of non-visited ontologies whenever the list of recent ones is updated.
         */
        trackPristineOntos() {
            this.pristineOntoIDs = this.ontoIndexIDs.filter((ontoID) => {
                return this.$store.getters.pastOntoIDs.indexOf(ontoID) === -1;
            });
        },

        /**
         * Updates the list of ontologies to be displayed and determines if a separator is necessary.
         */
        updateOntoFilterList() {
            this.ontoFilterList = this.$store.getters.pastOntoIDs.concat(
                this.pristineOntoIDs
            );
            this.ontoQueryIDs = union(this.ontoQueryIDs, this.pristineOntoIDs);

            if (this.isSeparateOntoLists) {
                this.ontoListSplitIndex =
                    this.$store.getters.pastOntoIDs.length;
            } else {
                this.ontoListSplitIndex = -1;
            }
        },

        backToTop() {
            const ontoFilterListEl =
                this.$refs.ontoFilterList && this.$refs.ontoFilterList.$el;

            if (ontoFilterListEl) {
                ontoFilterListEl.scrollTop = 0;
            }
        },

        onOntoQuery(query, results) {
            if (query.length) {
                this.ontoQueryIDs = results;
            } else {
                this.ontoQueryIDs = this.ontoFilterList;
            }
        },

        onOntoSeeAll() {
            this.$router.push('/ontologies');
            this.$emit('filter:close');
        },

        onOntoReset() {
            this.$store.dispatch('resetOntos');
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.onto-filter-title {
    color: #fff;
    background: $secondary;
}

.collapse-btn {
    margin-top: -0.4rem;
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
}

.dropdown-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    .dropdown-header p {
        line-height: 1.35rem;
    }
}

.filter-title {
    font-weight: 500;
}

.onto-filter-list {
    overflow: auto;
    background: rgba($dark, 0.025);

    .query-present ~ &:empty:before {
        content: 'No ontologies found';
        display: block;
        text-align: center;
        font-size: 0.85rem;
        color: $text-muted;
    }
}

.onto-filter-reset,
.onto-filter-more {
    line-height: 1;
}

.onto-filter-more {
    margin: 0.25rem 0;
    color: $link-hover-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: darken($link-hover-color, 6%);
    }
}

.custom-control-label {
    display: inline-block;
}

::v-deep .custom-control-label .info-onto-link {
    opacity: 0;

    &:hover {
        opacity: 1 !important;
        color: $link-hover-color !important;

        & + span {
            color: $link-hover-color !important;
        }
    }
}

::v-deep .custom-checkbox:hover .info-onto-link {
    opacity: 0.25;
}
</style>
