import Axios from 'axios';

const AUTH_ENDPOINT = '/authenticate';
const USER_ENDPOINT = '/account';

export default {
    login({ password, username, isRemember = false }) {
        return Axios.post(AUTH_ENDPOINT, {
            password,
            username,
            rememberMe: isRemember,
        });
    },

    getCallbackURL() {
        return encodeURI(`${window.location.origin}`);
    },

    requestToken(
        request = { authCode: null, oauthClient: null, rememberMe: false }
    ) {
        const callbackURL = this.getCallbackURL();
        return Axios.post(`${AUTH_ENDPOINT}/oauth`, {
            callbackURL,
            ...request,
        });
    },

    whois() {
        return Axios.get(AUTH_ENDPOINT);
    },

    user() {
        return Axios.get(USER_ENDPOINT);
    },

    getLogoutDetails() {
        return Axios.get(`${AUTH_ENDPOINT}/oauth/logoutdetails`);
    },

    getRedirectURL() {
        const callbackURL = this.getCallbackURL();
        const url = `${AUTH_ENDPOINT}/oauth/oauth/endpoint`;
        return Axios.get(url, {
            params: { callbackURL },
        });
    },
};
