/**
 * Contains a collection of utilities to help transitioning from schema v1 to v2.
 */
export const useV2Helpers = () => {
    /**
     * Given a property name, removes the parent object name expressed in dot notation, if any.
     * @param {string} propertyName
     * @return {string}
     */
    const removeDottedNotation = (propertyName: string) => {
        if (!propertyName.includes('.')) return propertyName;

        const pathArray = propertyName.split('.');

        return pathArray[1];
    };

    return { removeDottedNotation };
};
