export const HELP_HINTS = {
    rememberMe:
        'Keeps you logged in across tabs and after closing the browser.',
    primaryID:
        'URI form of the class ID. It is guaranteed to be globally unique.',
    shortFormIDs:
        'Abbreviated form of the class ID. It is only unique within a particular ontology.',
    primaryLabel:
        'The name of a particular ontology concept, usually the most common or popular name for the concept in the field.',
    textualDefinitions: 'One or more text descriptions of a class.',
    synonyms:
        'Alternative words that refer to a class, but which are often not as commonly used.',
    superClasses:
        'This class is a child of the following classes (e.g. lung cancer is a child of cancer).',
    mappings:
        'This class is cross-referenced to the following ones in this or other ontologies.',
    partOf: 'This class is a part of the following classes (e.g. thumb is part of hand).',
    derivesFrom:
        'This class can be biologically derived from the following classes (e.g. HeLa cell line is derived from cervical cancer cells).',
    developsFrom:
        'This class develops from the following classes (e.g. foetus develops from an embryo).',
    equivalences:
        'This class is part of a description involving the following.',
};
