import { kebabCase } from 'lodash';

export const useTestSelectors = (props: Record<string, unknown>) => {
    /**
     * Creates a test class string.
     * @param {string} componentName - It should be a component name for consistency
     * @param {string} namespace - Could be a component name or a html tag name or meaningful identifier
     * @return {string}
     */
    const buildTestClass = (componentName: string, namespace = '') => {
        const testId = props.testId as string;

        let testClass = 'testid__' + kebabCase(componentName) + `--${testId}`;
        testClass += namespace ? `__${namespace}` : '';
        testClass = testClass.replaceAll(' ', '__');

        return `${testClass}`;
    };

    return { buildTestClass };
};
