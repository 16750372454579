import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type InstanceByPrimaryIdPathParams = operations["getInstanceByPrimaryIdUsingGET"]["parameters"]["path"];
type InstanceByPrimaryIdQueryParams = operations["getInstanceByPrimaryIdUsingGET"]["parameters"]["query"];
type InstanceByPrimaryIdResponse = operations["getInstanceByPrimaryIdUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * This endpoint takes in a single instance primaryId and a single ontology ID and returns its data.
 * @param {string} ontologyId
 * @param {string} primaryId
 * @return {InstanceByPrimaryIdResponse}
*/
export const getInstanceByPrimaryId = async (
    { ontologyId }: InstanceByPrimaryIdPathParams,
    queryParams: InstanceByPrimaryIdQueryParams,
) => {
    const url = `/ontologies/${ontologyId}/instances/primaryId`;
    const response = await Axios.get(url, { params: queryParams });

    return response && response.data as InstanceByPrimaryIdResponse;
}
