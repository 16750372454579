import { refreshToken } from '@/api-v2';
import setTokenToState from '@/utils/setTokenToState';

const refreshUserToken = async ({ next, to }) => {
    if (to.name === 'logout') {
        return next();
    }

    let localToken = localStorage.getItem(process.env.VUE_APP_NAME_TOKEN);
    if (localToken && !localToken.startsWith('"')) {
        localToken = `"${localToken}"`;
    }

    const currentToken = JSON.parse(localToken);
    const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
    if (!currentToken || typeof rememberMe === 'undefined') return next();

    const newTokenRes = await refreshToken({
        id_token: currentToken,
        rememberMe,
    });

    if (typeof newTokenRes.id_token === 'undefined')
        console.warn('Could not refresh token');

    //Sets to localStorage and to the js
    setTokenToState(newTokenRes.id_token, rememberMe);

    next();
};

export default refreshUserToken;
