import { computed, ref } from '@vue/composition-api';

const eventBus: null | any = ref(null);
export const useEventBus = () => {
    const getEventBus = computed(() => {
        return eventBus.value;
    });

    const setEventBus = (_eventBus: any) => {
        eventBus.value = _eventBus;
    };

    return {
        getEventBus,
        setEventBus,
    };
};
