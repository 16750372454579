<template>
    <div class="typeahead-wrapper" :class="{ disabled: disabled }">
        <vue-bootstrap-typeahead
            ref="typeahead"
            inputClass="search-field"
            v-model="query"
            :placeholder="placeholder + '...'"
            :data="results"
            :serializer="serializer"
            :min-matching-chars="suggestionMinChars"
            :max-matches="suggestionMaxMatches"
            @keydown.down.native.prevent="onArrowDown"
            @keydown.up.native.prevent="onArrowUp"
            @keyup.right.native.prevent="onArrowRight"
            @keydown.enter.native.prevent="onEnter"
            @keydown.esc.native.prevent="onEsc">
            <!-- Prefix add-on -->
            <template slot="prepend">
                <slot name="prepend"></slot>
            </template>

            <!-- Suggestion item -->
            <template slot="suggestion" slot-scope="{ data, htmlText }">
                <div
                    class="suggestion-wrapper d-flex align-items-center"
                    @mouseover="onSuggestionHover($event, data)"
                    @mouseout="offSuggestionHover($event)">
                    <!-- Ontology identifier -->
                    <b-badge
                        v-if="isSuggestionScore && data.score"
                        class="score-badge"
                        variant="light"
                        pill>
                        {{ data.score }}
                    </b-badge>
                    <b-badge
                        v-else-if="getShortDisplayName(data)"
                        class="ontology-btn inner-btn btn-primary"
                        variant="primary"
                        @click.prevent.stop="
                            $refs.typeahead.handleHit({ data }, false)
                        "
                        v-b-tooltip.hover="{
                            title: getLongDisplayName(data),
                            delay: { show: showDelay, hide: 0 },
                            boundary: 'window',
                        }">
                        {{ getShortDisplayName(data) }}
                    </b-badge>
                    <font-awesome-icon
                        v-else
                        class="text-muted"
                        icon="minus-circle" />

                    <!-- Class identifiers -->
                    <div
                        class="suggestion-entry"
                        :id="`${global_randomID}-${data.id}`">
                        <span
                            class="suggestion-label"
                            :class="{ 'new-class': isNewClass(data) }">
                            <span
                                v-if="containsHtml(data.primaryLabel, htmlText)"
                                class="term-label"
                                v-html="getMatchedHtml(htmlText)" />
                            <span v-else class="term-label">{{
                                data.primaryLabel
                            }}</span>
                            <span
                                v-if="containsHtml(termID(data), htmlText)"
                                class="entity-link pl-1"
                                v-html="getMatchedHtml(htmlText)" />
                            <span v-else class="entity-link pl-1">{{
                                termID(data)
                            }}</span>
                        </span>
                        <span
                            v-if="
                                containsHtml(data.synonyms, htmlText) &&
                                !containsHtml(data.primaryLabel, htmlText)
                            "
                            class="synonym-label d-block text-truncate"
                            v-html="getMatchedHtml(htmlText)" />
                    </div>

                    <!-- Hover-on class summary -->
                    <b-popover
                        placement="bottom"
                        triggers="hover"
                        boundary="viewport"
                        :title="data.primaryID"
                        :target="`${global_randomID}-${data.id}`"
                        :delay="{ show: showDelay, hide: 0 }">
                        <class-summary
                            v-bind="data"
                            :isNew="isNewClass(data)" />
                    </b-popover>

                    <font-awesome-icon
                        class="autocomplete-btn inner-btn text-secondary"
                        icon="external-link-square-alt"
                        flip="horizontal"
                        size="lg"
                        @click.stop.prevent="
                            onAutocomplete(getMatchedText(htmlText), true)
                        " />
                </div>
            </template>

            <!-- Suffix add-on -->
            <template slot="append">
                <slot name="append" :query="query" :results="results"></slot>
            </template>
        </vue-bootstrap-typeahead>

        <!-- Query field inset -->
        <slot name="inset" :query="query" :results="results"></slot>

        <!-- Bottom link to all suggestions -->
        <div class="d-none">
            <slot name="view-all"></slot>
        </div>
    </div>
</template>

<script>
import { debounce, escape, escapeRegExp, isEmpty, maxBy } from 'lodash';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import ClassSummary from '@/components/ui/ClassSummary';
import { getRouterHash } from '@/utils';
import { useStore } from '@/compositions';

const PHRASE_SEPARATOR = '–'; // not hyphen but em-dash
const HIGHLIGHT_TAG = 'strong';

export default {
    name: 'ClassTypeahead',
    components: {
        VueBootstrapTypeahead,
        ClassSummary,
    },
    props: {
        initQuery: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        typeDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_TYPE_DEBOUNCE),
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        matchedTitle: {
            type: String,
            default: 'QUICK LINKS',
        },

        approximateTitle: {
            type: String,
            default: 'NO RESULTS. Did you mean...',
        },

        // Minimum number of characters for match filters to kick in. If 0, all results are shown when the field is empty.
        suggestionMinChars: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MIN_CHARS),
        },

        suggestionMaxMatches: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SUGGESTION_MAX),
        },

        defCharLimit: {
            type: Number,
            default: 120,
        },

        synCharLimit: {
            type: Number,
            default: 65,
        },

        disabled: {
            type: Boolean,
            deafult: false,
        },

        isRouterHash: {
            type: Boolean,
            default: getRouterHash(),
        },

        isGotoOnHit: {
            type: Boolean,
            default: true,
        },

        isOntoHitOnly: {
            type: Boolean,
            default: false,
        },

        isSuggestionScore: {
            type: Boolean,
            default: false,
        },

        // forces suggestions to be given only when the query occurs contiguously (no approximations)
        isForceExactMatch: {
            type: Boolean,
            default: false,
        },

        // allows discontiguous matches but does not offer probable suggestions
        isHideApproximations: {
            type: Boolean,
            default: false,
        },

        fetchSuggestions: {
            type: Function,
            default: (query) => [],
        },

        performAction: {
            type: Function,
            default: (query, results) => [],
        },

        searchType: {
            type: String,
            default: 'class',
        },

        shortDisplayName: {
            type: String,
            default: '',
        },
        forceClass: {
            type: Boolean,
            default: false,
        },
        forceProperty: {
            type: Boolean,
            default: false,
        },
        forceInstance: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            // text inside the search field
            query: '',

            // raw list of class data sets returned by the server for a given query.
            // NOTE: they may not match the query
            results: [],

            // flag indicating if the query was found in its entirety among the suggestions
            isExactMatch: false,

            suggestIndex: -1,
            activeSuggestionEls: [],
        };
    },

    computed: {
        /**
         * Truncated list of class datasets that match the query either exactly or approximately.
         */
        matchedItems: function () {
            return (
                this.$refs.typeahead &&
                this.$refs.typeahead.$refs.list.matchedItems
            );
        },

        /**
         * Suggestion item currently selected using keyboard navigation.
         */
        activeSuggestChild: function () {
            return this.$refs.typeahead.$refs.list.$children[this.suggestIndex];
        },

        classCheck: function () {
            if (this.forceClass) return true;
            else if (this.forceProperty || this.forceInstance) return false;
            return this.$store.getters.searchType;
        },
    },

    /**
     * Pre-emptively fetches search results whenever the field contents change to anything of minimum length. It also
     * supports dynamic changes to the disabled attribute.
     */
    watch: {
        query(newValue, oldValue) {
            this.checkFetch();
            this.$emit('change', newValue, oldValue);
        },

        disabled(newValue) {
            if (this.$refs.typeahead) {
                this.$refs.typeahead.$refs.input.disabled = newValue;
            }
        },

        matchedTitle(newValue) {
            this.$refs.typeahead.$refs.list.$el.setAttribute(
                'data-matched-title',
                newValue
            );
        },

        approximateTitle(newValue) {
            this.$refs.typeahead.$refs.list.$el.setAttribute(
                'data-approximate-title',
                newValue
            );
        },
    },

    /**
     * Prevents multiple requests being issued for each keystroke and closes the suggestion list after right-clicking
     * and then clicking somewhere else. The latter is a consequence of the plugin's default behaviour of cancelling any
     * blur event whose source is a suggestion item.
     */
    created() {
        this.onType = debounce(this.onType, this.typeDelay);
        window.addEventListener('mousedown', (event) => {
            const isListItemActive =
                document.activeElement.classList.contains('vbst-item');
            const typeahead = this.$refs.typeahead;

            if (typeahead && typeahead.isFocused && isListItemActive) {
                typeahead.isFocused = false;
            }
        });
    },

    mounted() {
        const thisComponent = this;

        /**
         * Manually corrects the dummy href of each suggestion's anchor wrapper on render and data update to allow its
         * opening in a new tab. The new URL is backed up as a data attribute, as is the corresponding ontology URL too.
         */
        const isRouterHash = this.isRouterHash;
        const listItemComp =
            this.$refs.typeahead.$refs.list.$options.components
                .VueBootstrapTypeaheadListItem;
        const generateHref = function () {
            let path = `/ontologies/${this.data.entityUniqueID}/${
                this.classCheck ? 'classes/' : 'properties/'
            }${this.urlToString(this.data.primaryID)}`;

            if (!this.data.entityUniqueID) {
                path = `/ontologies/${this.data.ontologyUniqueID}/${
                    this.classCheck ? 'classes/' : 'properties/'
                }`;
            }

            if (isRouterHash) {
                path = '#' + path;
            }
            this.$el.href = path;
            this.$el.setAttribute('data-class', path);
            this.$el.setAttribute(
                'data-onto',
                path.replace(/\/classes\/.+/, '')
            );
        };
        listItemComp.mounted = generateHref;
        listItemComp.watch = { 'data.primaryID': generateHref };

        // Extends the typeahead's default handler on blur to also reset the vertical cursor (i.e. the currently active suggestion).
        const oldBlurHandler = this.$refs.typeahead.handleBlur;
        this.$refs.typeahead.handleBlur = (event = {}) => {
            oldBlurHandler.call(this.$refs.typeahead, event);
            this.deactivateSuggests();
            this.suggestIndex = -1;
        };

        /**
         * Prevents the typeahead's default behaviour of updating the input and instead navigates to the corresponding ontology term, forcing
         * the closure of the results pane in the process.
         * @param {Object} resultAttrs - Text and data associated with the search result clicked on.
         * @param {Object} resultAttrs.data - Response data for that search result.
         * @param {boolean} isToTerm - True if the route to navigate to is the term's. False if it should be the parent ontology's.
         */
        this.$refs.typeahead.handleHit = ({ data }, isToTerm = true) => {
            if (isToTerm && useStore().getters.isObsolete) return;
            const toRoute = { params: { ontologyID: data.sourceUniqueID } };

            if (this.isGotoOnHit || this.isOntoHitOnly) {
                if (isToTerm && this.forceInstance) {
                    toRoute.name = 'instance';
                    toRoute.params.primaryID = data.primaryID;
                } else if (isToTerm && !this.isOntoHitOnly) {
                    toRoute.name = this.classCheck ? 'class' : 'property';
                    toRoute.params.primaryID = data.primaryID;
                } else if (!isToTerm) {
                    toRoute.name = 'ontology';
                }
                this.$router.push(toRoute);
            }

            this.$refs.typeahead.$refs.input.blur();
            this.$refs.typeahead.handleBlur();

            this.$emit('hit', data);
        };

        /**
         * Overrides the typeahead plugin's default behaviour of matching just contiguous sequences of query fragments to discriminate
         * irrelevant search results. Instead, it allows any query fragment to appear in any order and does not require a continuous
         * sequence. The overriding is done by shadowing the original getter up in the prototype chain. The original exact matching can be reverted to.
         */
        Object.defineProperty(this.$refs.typeahead.$refs.list, 'escapedQuery', {
            get: function () {
                const regexQuery = escapeRegExp(escape(this.query));
                const captureGroups = regexQuery.split(' ').map((fragment) => {
                    return '(?=.*(' + fragment + '))';
                });

                if (thisComponent.isForceExactMatch) {
                    return regexQuery;
                } else {
                    return '^' + captureGroups.join('') + '.*$';
                }
            },
        });

        /**
         * Fixes the typeahead plugin's rigidity towards highlighting search terms that are intersped with other words or in
         * different order. The overriding is done by shadowing the original getter up in the prototype chain.
         * NOTE: every word within a matched term will be individually highlighted. Eg: "w1 w2" => "<strong>w1</strong> <strong>w2</strong>"
         * @param {string} text - Combined string of response data.
         * @see {@link serializer}
         */
        Object.defineProperty(this.$refs.typeahead.$refs.list, 'highlight', {
            get: function () {
                return (text) => {
                    const sanitisedText = text
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;');
                    let escapedQuery;
                    let captureGroup;
                    let regex;

                    if (this.query.length === 0) {
                        return sanitisedText;
                    } else {
                        escapedQuery = escape(this.query);
                        captureGroup = `(${escapeRegExp(escapedQuery).replace(
                            / /g,
                            '|'
                        )})`;
                        regex = new RegExp(captureGroup, 'gi');

                        return sanitisedText.replace(
                            regex,
                            `<${HIGHLIGHT_TAG}>$&</${HIGHLIGHT_TAG}>`
                        );
                    }
                };
            },
        });

        /**
         * Extends the typeahead's default behaviour when looking for matched results so that, if none are matched, it returns
         * the first batch of results anyway. This is useful for near-miss cases where the query has some minor typos but the
         * server's responses are nonetheless relevant.
         */
        const listProto = Object.getPrototypeOf(
            this.$refs.typeahead.$refs.list
        );
        const oldMatchedItems = Object.getOwnPropertyDescriptor(
            listProto,
            'matchedItems'
        ).get;
        Object.defineProperty(this.$refs.typeahead.$refs.list, 'matchedItems', {
            get: function () {
                const matched = oldMatchedItems.call(this);
                const isExact = matched.length > 0;
                let suggestions;

                thisComponent.isExactMatch = isExact;
                thisComponent.$el.classList.toggle('matched', isExact);
                this.$el.classList.toggle('matched-list', isExact);

                // Does matching when some characters have been typed in.
                if (this.query.length) {
                    if (
                        !thisComponent.isForceExactMatch &&
                        !thisComponent.isHideApproximations &&
                        this.query.length > this.minMatchingChars &&
                        this.data.length &&
                        !isExact
                    ) {
                        this.$el.classList.add('approximate-list');
                        thisComponent.$el.classList.add('approximate');
                        suggestions = this.data.slice(0, this.maxMatches);
                    } else {
                        this.$el.classList.remove('approximate-list');
                        thisComponent.$el.classList.remove('approximate');
                        suggestions = matched;
                    }

                    // Bypasses matching if no character threshold has been provided and the field is empty.
                    // NOTE: this is what allows the automatic display of the suggestion list with no query.
                } else if (!this.minMatchingChars) {
                    suggestions = this.data.slice(0, this.maxMatches);
                    this.$el.classList.add('matched-list');
                }

                return suggestions;
            },
        });

        // Adds the attributes required for the suggestion list's dynamic headings
        this.$refs.typeahead.$refs.list.$el.setAttribute(
            'data-matched-title',
            this.matchedTitle
        );
        this.$refs.typeahead.$refs.list.$el.setAttribute(
            'data-approximate-title',
            this.approximateTitle
        );

        // Keeps tabs of currently active suggestions.
        this.activeSuggestionEls =
            this.$refs.typeahead.$refs.list.$el.getElementsByClassName(
                'list-group-item active'
            );

        // Disables the browser's default spellcheck and makes the field read-only on demand.
        this.$refs.typeahead.$refs.input.spellcheck = false;
        this.$refs.typeahead.$refs.input.disabled = this.disabled;

        // Fixes Chrome issue with caret position and interaction with Bootstrap's default styling.
        // NOTE: the plugin's default is a search input which seriously messes up with its aggregated styling.
        this.$refs.typeahead.$refs.input.type = 'text';
    },

    /**
     * Appends or removes a "view all" entry in the list of suggestions if such entry has been provided.
     */
    updated() {
        const viewSlot = this.$slots['view-all'];
        let listEl;

        if (viewSlot && this.$refs.typeahead) {
            listEl = this.$refs.typeahead.$refs.list.$el;

            if (!isEmpty(this.matchedItems)) {
                listEl.appendChild(viewSlot[0].elm);
            } else if (listEl.contains(viewSlot[0].elm)) {
                listEl.removeChild(viewSlot[0].elm);
            }
        }
    },

    methods: {
        getShortDisplayName(data) {
            if (
                this.$store.getters.ontoData(data.sourceUniqueID) &&
                this.$store.getters.ontoData(data.sourceUniqueID)
                    .ontologyShortDisplayName
            ) {
                return this.$store.getters.ontoData(data.sourceUniqueID)
                    .ontologyShortDisplayName;
            }
            return this.shortDisplayName;
        },
        getLongDisplayName(data) {
            if (
                this.$store.getters.ontoData(data.sourceUniqueID) &&
                this.$store.getters.ontoData(data.sourceUniqueID)
                    .ontologyShortDisplayName
            ) {
                return this.$store.getters.ontoData(data.sourceUniqueID)
                    .ontologyLongDisplayName;
            }

            return this.longDisplayName;
        },
        /**
         * Checks if a given class has been just created (assuming an editing cycle has occurred).
         * @param {Object} classData - Data object with all properties representative of the class in question.
         */
        isNewClass(classData) {
            return (
                classData.editState &&
                classData.editState.primaryLabel &&
                classData.editState.primaryLabel.isNew
            );
        },

        /**
         * Coalesces all relevant data into a single string so that not just primary labels are matched against a given
         * query but also synonyms and identifiers. While at it, it reverses the list of short form IDs to guarantee that
         * the user-friendly colon-based one is matched first always (since that's the preferred one for rendering purposes).
         * @param {Object} result - Data object from the API.
         * @see {@link getMatchedHtml}
         */
        serializer(result) {
            const serialShortIDs = result.shortFormIDs
                .reverse()
                .join(PHRASE_SEPARATOR);
            let synonyms = result.synonyms.slice();
            const querySynIndex = synonyms.indexOf(this.query);
            let serialSynonyms = '';
            let mappingIds = '';

            // Before serialising synonyms, it bubbles up any exact match to guarantee maximum relevance when rendering matches.
            if (querySynIndex > -1) {
                synonyms.unshift(synonyms.splice(querySynIndex, 1)[0]);
            }
            serialSynonyms = synonyms.join(PHRASE_SEPARATOR);

            /**
             * Looks for any mappings in the result and then joins the mappings so the search can find them.
             * This enables the mappings to be shown even when using a full ID.
             * */
            if (
                typeof result.mappings !== 'undefined' &&
                result.mappings.length
            ) {
                mappingIds = result.mappings.join(PHRASE_SEPARATOR);
            }

            return `${result.primaryLabel}${PHRASE_SEPARATOR}${serialShortIDs}${PHRASE_SEPARATOR}${serialSynonyms}${PHRASE_SEPARATOR}${result.primaryID}${PHRASE_SEPARATOR}${mappingIds}`;
        },

        /**
         * Gets the instance in the serialised data with the most matches for the search query, normally highlighted by means of HTML.
         * If there are no exact matches word-by-word, it defaults to the first piece of serialised data.
         * @param {string} html - Serialised data with the matched query highlighted in HTML.
         */
        getMatchedHtml(html) {
            const htmlPhrases = html.split(PHRASE_SEPARATOR);
            const highlightRegex = new RegExp(`<${HIGHLIGHT_TAG}>`, 'gi');
            const maxMatched = maxBy(htmlPhrases, (phrase) =>
                phrase.match(highlightRegex)
            );

            if (maxMatched) {
                return maxMatched;
            } else {
                return htmlPhrases[0];
            }
        },

        /**
         * Checks if a given piece of HTML is contained as a string in the specified property.
         * @param {string | [string]} propValue - Single values or list of values to compare with.
         * @param {string} html - HTML whose text equivalent is to be searched for in the property.
         */
        containsHtml(propValue, html) {
            const text = this.getMatchedText(html);
            let source = propValue;

            if (Array.isArray(propValue)) {
                source = propValue.join(PHRASE_SEPARATOR);
            }

            return source.search(new RegExp(escapeRegExp(text), 'i')) !== -1;
        },

        /**
         * Gets the text version of the matched fragment of serialised data by stripping it of the highlighting HTML tags/entitites.
         * @param {string} html - Serialised data with the matched query already highlighted by means of HTML.
         */
        getMatchedText(html) {
            const regex = new RegExp(`</?${HIGHLIGHT_TAG}>`, 'g');
            const tagStripped = this.getMatchedHtml(html).replace(regex, '');

            return tagStripped.replace(/&#(\d+);/g, (match, code) =>
                String.fromCharCode(code)
            );
        },

        /**
         * Resets all suggestions to their unselected state.
         */
        deactivateSuggests() {
            Array.from(this.activeSuggestionEls).forEach((activeEl) => {
                activeEl.classList.remove('active');
            });
        },

        /**
         * If there is a suggestion registered as selected, makes sure it is rendered properly.
         */
        setSuggestionActive() {
            if (this.activeSuggestionEls.length) {
                this.deactivateSuggests();
            }
            if (this.suggestIndex > -1) {
                this.activeSuggestChild.$el.classList.add('active');
            }
        },

        /**
         * Checks if a given query is meaningful enough before retrieving any suggestions, its length required to be above a
         * character threshold. Also used to update the list of suggestions programmatically in response to some external change.
         */
        checkFetch() {
            const trimmed = this.query.replace(/\s{2,}/g, ' ').trim();

            if (trimmed.length >= this.$props.suggestionMinChars) {
                this.suggestIndex = -1;
                this.onType(trimmed);
            } else {
                this.results = [];
            }
        },

        /**
         * Performs operations once keystrokes stop.
         * @param {string} query - Value of the search field.
         */
        onType(query) {
            this.fetchSuggestions(query).then((response) => {
                this.results = response.data.elements;
            });
        },

        isEmpty() {
            return this.query.trim().length === 0;
        },

        onAutocomplete(text, isFocus = false) {
            this.$refs.typeahead.inputValue = text;
            this.query = text;

            if (isFocus) {
                this.$refs.typeahead.$refs.input.focus();
            }
        },

        /**
         * Closes the results pane and carries out whatever main action has been pre-defined for the typeahead's field.
         * @param {string} query - Unsanitised contents of the typeahead field.
         */
        onAction(query) {
            if (
                this.matchedItems &&
                this.matchedItems.length &&
                this.forceProperty
            ) {
                this.$refs.typeahead.handleHit(this.matchedItems[0]);
            }

            const trimmed = query.replace(/\s{2,}/g, ' ').trim();

            if (trimmed.length) {
                this.$refs.typeahead.$refs.input.blur();
                this.performAction(trimmed, this.results);
            }
        },

        clear() {
            this.$refs.typeahead.inputValue = '';
            this.query = '';
        },

        onArrowUp() {
            const matchedLength = this.matchedItems.length;

            if (matchedLength) {
                this.suggestIndex = this.suggestIndex - 1;
                if (this.suggestIndex < -1) {
                    this.suggestIndex = matchedLength - 1;
                }
                this.setSuggestionActive();
            }
        },

        onArrowDown() {
            const matchedLength = this.matchedItems.length;

            if (matchedLength) {
                this.suggestIndex = this.suggestIndex + 1;
                if (this.suggestIndex >= matchedLength) {
                    this.suggestIndex = -1;
                }
                this.setSuggestionActive();
            }
        },

        onEnter() {
            if (
                this.matchedItems &&
                this.matchedItems.length &&
                this.forceProperty
            ) {
                this.$refs.typeahead.handleHit(this.matchedItems[0]);
            }

            if (this.suggestIndex > -1) {
                this.$refs.typeahead.handleHit(
                    this.matchedItems[this.suggestIndex]
                );
            } else {
                this.onAction(this.query);
            }
        },

        onArrowRight() {
            if (this.activeSuggestChild) {
                this.onAutocomplete(
                    this.activeSuggestChild.$el.getElementsByClassName(
                        'term-label'
                    )[0].textContent
                );
            }
        },

        /**
         * Hides the list of suggestions on pressing the escape key while preventing the default action.
         * This does not entail losing the field's focus.
         * NOTE: On some browsers (notably Chrome), pressing ESC clears the input by default.
         * NOTE: A side-effect of manually hiding the list on pressing escape is that it remains hidden unless focus is reset.
         */
        onEsc() {
            this.$refs.typeahead.$refs.list.$el.style.display = 'none';
        },

        /**
         * Checks if a given element, or its parent, has the provided class.
         * @param {Object} element - DOM element to be tested.
         * @param {string} className - Name of the class whose existence is being tested.
         */
        hasNearClass(element, className) {
            const parentEl = element.parentElement;
            return (
                element.classList.contains(className) ||
                parentEl.classList.contains(className)
            );
        },

        /**
         * Adds a differentiating class when the mouse enters any of the buttons in the suggestion list. This is to avoid underlining any text
         * and giving the false impression that the user is about to navigate to the suggested class instead of whatever other action
         * is associated with the button in question. It also changes the wrapping anchor's URL when hovering over the ontology name to support
         * new-tab linking.
         * @param {Object} event - DOM event object.
         * @param {Object} data - Class properties associated with the suggestion item hovered on.
         * @param {Object} data.entityUniqueID - Response data for that search result.
         */
        onSuggestionHover(event, { entityUniqueID }) {
            const currTarget = event.currentTarget;

            if (this.hasNearClass(event.target, 'inner-btn')) {
                currTarget.classList.add('button-hovered');

                if (this.hasNearClass(event.target, 'autocomplete-btn')) {
                    currTarget.classList.add('mute-non-term');
                }

                if (this.hasNearClass(event.target, 'ontology-btn')) {
                    currTarget.parentElement.href =
                        currTarget.parentElement.getAttribute('data-onto');
                }
            }
        },

        /**
         * The inverse of the above method
         */
        offSuggestionHover(event) {
            const currTarget = event.currentTarget;

            if (this.hasNearClass(event.target, 'inner-btn')) {
                event.currentTarget.classList.remove('button-hovered');

                if (this.hasNearClass(event.target, 'autocomplete-btn')) {
                    currTarget.classList.remove('mute-non-term');
                }

                if (this.hasNearClass(event.target, 'ontology-btn')) {
                    currTarget.parentElement.href =
                        currTarget.parentElement.getAttribute('data-class');
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.typeahead-wrapper {
    position: relative;
    ::v-deep .list-group {
        position: relative;
    }
    ::v-deep .search-field {
        z-index: auto !important;
    }

    // TODO: somehow bring list into the flow for mobile. Otherwise users cannot scroll. Either that or cancel the dropdown
    // and use the results region instead. Maybe use routing to direct the region's contents for non-desktop devices.
    ::v-deep .list-group {
        margin-left: 0 !important;
        padding-top: 0.4rem;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        // Makes the suggestions list expand beyond the length of the narrowish input on small phone screens
        position: fixed;
        left: 50%;
        right: 0px;
        width: 100% !important;
        min-width: 320px;
        max-width: 450px;
        max-height: none;
        transform: translateX(-50%);

        &:before {
            display: none;
            padding: 0.3rem 0.7rem;
            font-size: 0.85rem;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            border-bottom: 1px solid $gray-400;
            background: $white;
        }

        &.matched-list:before {
            content: attr(data-matched-title);
            color: $text-muted;
            display: block;
        }

        &.approximate-list:before {
            content: attr(data-approximate-title);
            color: rgba($danger, 0.8);
            display: block;
        }

        &.shadow:empty {
            box-shadow: none !important;
        }

        // Allows the suggestions list to be as wide as the input which is long enough on bigger phone screeens
        @media (min-width: 576px) {
            position: absolute;
            left: auto;
            right: auto;
            min-width: 400px;
            transform: none;
        }

        // Allows the suggestions list to be as wide as its contents. Text tends to be around 650px long at most.
        // Eg: "Palmoplantar keratoderma - XX sex reversal - predisposition to squamous cell carcinoma"
        @media (min-width: 1024px) {
            width: auto !important;
            min-width: auto;
            max-width: 650px;
        }

        .list-group-item {
            padding: 0.7rem 1.2rem;
            font-size: 0.9rem;
            border-color: rgba(0, 0, 0, 0.03);
            outline: 0;

            &:first-child {
                border-radius: 0;
            }

            &:last-of-type {
                border-bottom: 1px solid $gray-400;
            }

            &:hover
                .suggestion-wrapper:not(.button-hovered)
                .suggestion-label
                * {
                color: $link-hover-color;
            }

            .score-badge {
                background: $light-info;
            }

            .ontology-btn {
                padding: 0.3em 0.4em;
                font-size: 0.9em;
                font-weight: 500;
            }

            .mute-non-term {
                .ontology-btn {
                    opacity: 0.15;
                }
                .entity-link {
                    opacity: 0.35;
                }
            }

            .suggestion-entry {
                overflow: hidden;
                flex-grow: 1;
                margin: 0 0.6rem;
                white-space: nowrap;
                text-overflow: ellipsis;

                @media (min-width: 576px) {
                    white-space: normal;
                    text-overflow: clip;
                }

                .suggestion-label {
                    display: inline-block;

                    .term-label {
                        color: $secondary;
                    }

                    &.new-class .term-label {
                        &,
                        * {
                            color: $info !important;
                        }
                    }
                }

                .entity-link {
                    color: $link-hover-color;
                }

                .synonym-label {
                    font-size: 0.8em;
                    color: #999;

                    &:before {
                        content: 'Synonym: "';
                    }

                    &:after {
                        content: '"';
                    }
                }
            }

            .autocomplete-btn {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
