import { Observer } from '@/ts';

export const updatePrimaryLabelInClassItem: Observer = (args) => {
    const { newValue, classItemData } = args as unknown as {
        newValue: string;
        classItemData?: Record<string, unknown>;
    };

    if (!newValue || !classItemData) return true;

    classItemData.primaryLabel = newValue;

    return true;
};
