import { find, findIndex, remove, throttle } from 'lodash';

import ApiNotifications from '@/api/notifications';

import * as MutationTypes from './mutation-types';
import { ALERT_TYPES } from '@/config';
import { useSparql } from '@/compositions/useSparql';

const state = {
    // List of notifications
    notifications: [],

    // Notification just received in real-time
    newLiveNotification: {},

    // Username and timestamp for the notification last displayed (and therefore read)
    lastRead: [],

    // Number of undisplayed notifications (excluding alerts)
    unreadCount: 0,

    // Used for capturing notifications in real-time.
    eventSource: null,
};

const notificationBlackList = ['HEARTBEAT'];

const mutations = {
    [MutationTypes.NOTIFICATIONS_SOURCE](state, { source }) {
        state.eventSource = source;
    },

    // Assumes the notifications list is ordered by date, latest first.
    [MutationTypes.NOTIFICATIONS_ADD](state, { getters, notifications }) {
        const cloned = state.notifications.slice(0);

        notifications.forEach((notification) => {
            const category = getters.category(notification.type);

            if (!notification.error) {
                // If an ontology was loaded, it can be directly viewed from the corresponding notification.
                if (
                    category === 'import' ||
                    notification.type === 'INDEX_FOR_SPARQL'
                ) {
                    notification.actionPath = {
                        name: 'ontology',
                        params: { ontologyID: notification.id },
                    };
                    if (notification.type === 'IMPORT')
                        useSparql().setOntologyLoaded(notification.id);
                    else if (notification.type === 'INDEX_FOR_SPARQL') {
                        useSparql().setOntologyIndexed(notification.id);
                        notification.actionPath = {
                            name: 'sparql-ontology',
                            params: { ontologyId: notification.id },
                        };
                    }

                    // If a suggestion is still pending, it links to the transaction for review.
                } else if (
                    category === 'suggestion' &&
                    !getters.isEnd(notification.type)
                ) {
                    const ontoId = this.getters.suggestions.find(
                        (suggestion) =>
                            suggestion.transactionId === notification.id
                    )?.ontologyId;
                    notification.actionPath = {
                        name: 'suggestion',
                        params: {
                            ontologyID: ontoId || '',
                            transactionID: notification.id,
                        },
                    };
                }
            }
        });

        cloned.unshift(...notifications);

        for (let i = 0; i < cloned.length; i++) {
            if (notificationBlackList.includes(cloned[i].type)) {
                cloned.splice(i, 1);
            }
        }

        state.notifications = cloned;
    },

    [MutationTypes.NOTIFICATIONS_NEW](state, { getters, notification = null }) {
        let currUserLastRead;
        let lastReadIndex;

        // Notification has been recieved live
        if (notification) {
            if (notificationBlackList.includes(notification.type)) {
                return;
            }
            state.unreadCount = state.unreadCount + 1;
            state.newLiveNotification = notification;

            // New notifications were recieved asynchronously (unread count initialisation)
        } else {
            currUserLastRead =
                state.lastRead.find(
                    (idString) => idString.indexOf(getters.username) !== -1
                ) || '';
            currUserLastRead = currUserLastRead.replace(
                `${getters.username}_`,
                ''
            );
            lastReadIndex = findIndex(state.notifications, (notification) => {
                return (
                    new Date(notification.timestamp) <=
                    new Date(currUserLastRead)
                );
            });

            // Notifications have been read before...
            if (lastReadIndex > -1) {
                state.unreadCount = lastReadIndex;

                // No notifications read or none there in the first place...
            } else {
                state.unreadCount = state.notifications.length;
            }
        }
    },

    [MutationTypes.NOTIFICATIONS_READ](state, { getters }) {
        state.unreadCount = 0;

        if (state.notifications.length) {
            remove(
                state.lastRead,
                (idString) => idString.indexOf(getters.username) !== -1
            );
            state.lastRead.push(
                `${getters.username}_${new Date().toISOString()}`
            );
        }
    },

    [MutationTypes.NOTIFICATIONS_DEL](state, { notificationID }) {
        const notificationIndex = findIndex(state.notifications, {
            uniqueId: notificationID,
        });
        state.notifications.splice(notificationIndex, 1);
    },

    [MutationTypes.NOTIFICATIONS_CLEAR](state) {
        state.notifications = [];
        state.unreadCount = 0;
    },

    [MutationTypes.NOTIFICATIONS_CLOSE](state) {
        state.eventSource && state.eventSource.close();
        state.eventSource = null;
    },
};

const getters = {
    eventSource: (state) => state.eventSource,
    notifications: (state) => state.notifications,
    newLiveNotification: (state) => state.newLiveNotification,
    unreadCount: (state) => state.unreadCount,

    category: (state) => (type) => {
        const name = type.split('_')[0].toLowerCase();

        // Correct notation
        if (name === 'suggest') {
            return 'suggestion';
            // Downloadable items are all treated like exports
        } else if (name === 'export' || name === 'diff') {
            return 'export';
        } else if (name === 'index') {
            return 'index';
        } else {
            return name;
        }
    },

    isEnd: (state) => (type) => {
        switch (type) {
            case 'SUGGEST_APPROVED':
            case 'EXPORT_END':
            case 'DIFF_REPORT':
            case 'IMPORT':
            case 'DELETE':
            case 'UNLOAD':
            case 'INDEX_FOR_SPARQL':
                return true;
            default:
                return false;
        }
    },

    isEdit: (state, getters) => (type) =>
        getters.category(type) === 'suggestion',

    notificationTitle: (state, getters) => (notification) => {
        const type = notification.type;
        const category = getters.category(type);
        const isEnd = getters.isEnd(type);
        let prefix = '';
        let suffix = '';

        if (type === 'DIFF_REPORT') {
            prefix = 'comparison';
        } else {
            prefix = category;
        }

        if (notification.error) {
            suffix = 'failed';
        } else if (type === 'SUGGEST_WITHDRAWN') {
            suffix = 'withdrawn';
        } else if (type === 'SUGGEST_APPROVED') {
            suffix = 'approved';
        } else if (type === 'SUGGEST_REJECTED') {
            suffix = 'rejected';
        } else if (type === 'SUGGEST_SUBMITTED') {
            if (notification.issuer === getters.username) {
                suffix = 'sent';
            } else {
                suffix = `from ${notification.issuer}`;
            }
        } else if (isEnd) {
            suffix = 'completed';
        }

        return `${prefix} ${suffix}`;
    },

    notificationClass: (state, getters) => (notification) => {
        if (notification.error || notification.type === 'SUGGEST_REJECTED') {
            return 'error';
        } else if (
            getters.isEdit(notification.type) &&
            !getters.isEnd(notification.type)
        ) {
            return 'info';
        } else {
            return 'success';
        }
    },
};

const actions = {
    notificationOpen({ dispatch, commit, rootState, getters }) {
        const source = ApiNotifications.connect(rootState.auth.token);
        const store = this;
        let onMessageFn;

        // Keeps track of the event stream
        commit(MutationTypes.NOTIFICATIONS_SOURCE, { source });

        // Registers any new notification recieved while the app is active.
        onMessageFn = function (event) {
            const notification = JSON.parse(event.data);
            let category;
            let ontology;

            category = getters.category(notification.type);

            // If ontology loaded, marks it as such.
            if (category === 'import') {
                ontology = find(rootState.search.loadingOntos, {
                    ontologyUniqueID: notification.id,
                });

                // Even if the loading was triggered by a different user, the ontology is indexed.
                dispatch(
                    'addOntoName',
                    ontology || { ontologyUniqueID: notification.id }
                );
                dispatch('loadOntoEnd', notification.id);

                // If an ontology is removed, it makes sure it's not indexed anymore.
            } else if (
                category === 'unload' &&
                getters.hasOnto(notification.id)
            ) {
                dispatch('delOntoName', notification.id);

                const idxOfUnloadedOntology =
                    store.getters.filters.ontologies.indexOf(notification.id);

                if (idxOfUnloadedOntology !== -1) {
                    store.getters.filters.ontologies.splice(
                        idxOfUnloadedOntology
                    );
                }

                // If profile updated and session recent, refresh token and granular permissions.
            } else if (category === 'user' && getters.password) {
                dispatch('login', {
                    password: getters.password,
                    username: getters.username,
                    isRemember: getters.isRemember,
                    isUserRefresh: true,
                });
            }

            // Stops other logged in users recieving the notification that they did not
            if (
                category === 'import' &&
                notification.issuer !== getters.username
            ) {
                return;
            }

            commit(MutationTypes.NOTIFICATIONS_ADD, {
                getters,
                notifications: [notification],
            });

            // All new notifications are registered but only some are shown immediately
            commit(MutationTypes.NOTIFICATIONS_NEW, { getters, notification });
            if (ALERT_TYPES.indexOf(notification.type) !== -1) {
                store.$eventBus.$emit(
                    'notification:show',
                    getters.notificationClass(notification),
                    notification.message,
                    getters.notificationTitle(notification)
                );
            }
        };

        source.onmessage = onMessageFn;
    },

    notificationUnread({ commit, getters }) {
        return ApiNotifications.unread().then((response) => {
            const notifications = response.data;
            commit(MutationTypes.NOTIFICATIONS_ADD, { getters, notifications });
            commit(MutationTypes.NOTIFICATIONS_NEW, { getters });
        });
    },

    notificationAllRead({ commit, getters }) {
        commit(MutationTypes.NOTIFICATIONS_READ, { getters });
    },

    notificationDel({ commit }, notificationID) {
        return ApiNotifications.delete({ notificationID }).then(() => {
            commit(MutationTypes.NOTIFICATIONS_DEL, { notificationID });
        });
    },

    notificationClear({ commit }) {
        return ApiNotifications.clear().then(() => {
            commit(MutationTypes.NOTIFICATIONS_CLEAR);
        });
    },

    notificationClose({ commit }) {
        commit(MutationTypes.NOTIFICATIONS_CLEAR);
        commit(MutationTypes.NOTIFICATIONS_CLOSE);
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};
