import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type AllRelationsPathParams = operations["deleteAllRelationsUsingDELETE"]["parameters"]["path"];
type AllRelationsBodyParams = operations["deleteAllRelationsUsingDELETE"]["requestBody"]["content"]["application/json"];
type AllRelationsResponse = operations["deleteAllRelationsUsingDELETE"]["responses"]["201"]['content']["*/*"];

/**
 * Remove all relationships of a certain type from the specified classId. Relation can be : subClassesOf, equivalences, partOf, derivesFrom, developsFrom
 * @param {string} ontologyId
 * @param {string} classId
 * @param {string} relation
 * @param {AllRelationsPathParams} bodyParams
 * @return {AllRelationsResponse}
*/
export const deleteAllRelations = async (
    { ontologyId, classId, relation }: AllRelationsPathParams,
    bodyParams: AllRelationsBodyParams,
) => {
    const url = `/ontologies/${ontologyId}/classes/${classId}/${relation}`;
    const response = await Axios.delete(url, { data: bodyParams });

    return response && response.data as AllRelationsResponse;
}
