import {
    get,
    isDate,
    isEmpty,
    kebabCase,
    lowerCase,
    orderBy,
    upperFirst,
    xor,
} from 'lodash';
import testSelectors from './mixins/testSelectors';
import { NAME_MAPPINGS } from '@/config';
import { useClassView, useOntology, useV2Helpers } from '@/compositions';

window.appComponents = {};

export default {
    data() {
        return {
            // randomly generated ID (different across app instances)
            global_randomID: this.$uuid.v4(),

            // component-specific class
            compClass: '',

            propertyAnnotations: {}, // Tags
        };
    },

    props: {
        testId: {
            type: [String, Number],
            required: false,
        },
    },

    computed: {
        isSchemaVersion2: function () {
            return useClassView().whichSchemaVersion.value >= 2;
        },
        // Alias for determining whether a user is fully logged in.
        isLoggedIn: function () {
            return this.$store.getters.status === 'loggedin';
        },

        // Irons out the differences between browser-specific transition event names.
        // SOURCE: http://davidwalsh.name/css-animation-callback
        whichTransitionEvent() {
            const el = document.createElement('fakeelement');
            const transitions = {
                transition: 'transitionend',
                OTransition: 'oTransitionEnd',
                MozTransition: 'transitionend',
                WebkitTransition: 'webkitTransitionEnd',
            };
            let transName;

            for (transName in transitions) {
                if (el.style[transName] !== undefined) {
                    return transitions[transName];
                }
            }
        },

        hostUrl() {
            return location.origin;
        },

        isDev() {
            return process.env.NODE_ENV === 'development';
        },
    },

    /**
     * Adds a DOM-scoped attribute value to every component and builds an index of all of them.
     */
    mounted() {
        const compName = this.$options.name;
        let instances;

        // Some components may be conditionally rendered as comments. Hence the check for setAttribute.
        if (this.isDev && this.$el.setAttribute && compName) {
            this.compClass = 'test-' + kebabCase(compName);
            this.$el.classList.add(this.compClass);

            this.$nextTick(() => {
                instances = Array.prototype.slice.call(
                    document.getElementsByClassName(this.compClass)
                );
                this.$el.setAttribute('instance', instances.indexOf(this.$el));

                window.appComponents[this.compClass] = instances;
            });
        }
    },

    /**
     * Prevents the class from being removed after class bindings overwriting the className property on update.
     */
    updated() {
        if (this.isDev && this.$el.classList && this.compClass) {
            this.$el.classList.add(this.compClass);
        }
    },

    methods: {
        /**
         * Convenience method to detect changes in multiple properties specified with one statement.
         * It also works around Vue's limitation for dot-based path notations with whitespaces in them.
         * @param {string[]} propNames - List of names of properties to be watched. They may include spaces.
         * @param callback - Handler for the watched change.
         */
        $watchAll(propNames, callback) {
            return propNames.map((propName) => {
                const parse = () => get(this, propName);
                return this.$watch(parse, callback.bind(this, propName));
            });
        },

        /**
         * Works out the list of symmetric differences between two versions of a given value.
         * The result should therefore be independent of whether there has been an addition, removal of items.
         * NOTE: if replacements have taken place, the replaced items are appended to the list as removed.
         * @param {Object | string} newValue - Most recent version of the value.
         * @param {Object | string} oldValue - Immediately earlier version of the value.
         * @deprecated from v2.0, use utils/propValuesDifference() instead
         */
        difference(newValue, oldValue) {
            if (typeof newValue === 'string') {
                oldValue = oldValue.split ? oldValue.split(' ') : [];
                return xor(newValue.split(' '), oldValue);
            } else {
                return xor(newValue, oldValue);
            }
        },

        /**
         * Marks up the parent of a given target so that no hover effect on the parent is applied when said target is hovered.
         * @param {Object} event - DOM object for the mouse enter/leave action.
         * @param {boolean} isEnter - True if the mouse has entered the target.
         * @param {number} levelsUp - How far up the parent element is.
         * @deprecated From v2.0, use instead utils/dom/markParentAsNoHover
         */
        cancelParentHover(
            event,
            isEnter,
            levelsUp = 1,
            className = 'no-hover'
        ) {
            let parentEl = event.target;

            while (levelsUp) {
                parentEl = parentEl.parentElement;
                levelsUp--;
            }

            parentEl.classList.toggle(className, isEnter);
        },

        /**
         * Gets the user-friendly short ID for a given term.
         * NOTE: All classes are guaranteed to have a shortFormID property.
         * @param {Object} term - Dataset representative of the class term.
         */
        termID(term) {
            let isFoundShortFormID;

            if (isEmpty(term) || isEmpty(term.shortFormIDs)) {
                return '';
            } else {
                isFoundShortFormID = term.shortFormIDs.find(
                    (id) => id.indexOf(':') > -1
                );
                return isFoundShortFormID || term.shortFormIDs[0];
            }
        },

        /**
         * Normalises a set of strings to the shortest one or the first item.
         * @param {Array} stringList - Array of strings.
         * @param {string} emptyMessage - Message to be shown when the list is empty.
         */
        shortestString: function (stringList, emptyMessage) {
            const withoutBlanks = stringList.filter(Boolean);
            let shortest;

            if (withoutBlanks.length > 1) {
                shortest = withoutBlanks.reduce((def1, def2) => {
                    return def1.length < def2.length ? def1 : def2;
                });
            } else if (withoutBlanks.length === 1) {
                shortest = withoutBlanks[0];
            } else {
                shortest = emptyMessage;
            }

            return shortest;
        },

        /**
         * Normalises a set of strings to the longest one.
         * @param {Array} stringList - Array of strings.
         * @param {string} emptyMessage - Message to be shown when the list is empty.
         */
        longestString(stringList, emptyMessage) {
            let longest;

            if (stringList.filter(Boolean).length) {
                longest = stringList.reduce((def1, def2) => {
                    return def1.length > def2.length ? def1 : def2;
                });
            } else {
                longest = emptyMessage;
            }

            return longest;
        },

        upperFirst: upperFirst,

        /**
         * Adds a full stop to the end of a given string if none exists already.
         * @param {string} string - String to be modified.
         * @see {@link https://stackoverflow.com/a/27408876}
         */
        addFullStop(string) {
            if (typeof string !== 'string') return string;
            let trimmed = string.trim();

            if (!~['.', '!', '?', ';'].indexOf(trimmed[trimmed.length - 1])) {
                trimmed += '.';
            }
            return trimmed;
        },

        /**
         * Trims a given string up to a certain number of characters
         * @params {string} string - String of characters to truncate.
         * @pamars {number} charLimit - Maximum character length for the string.
         */
        truncate: function (string, charLimit = 999) {
            if (typeof string !== 'string') return string;
            if (string.length <= charLimit || !string.length) {
                return string;
            } else {
                return string.substring(0, charLimit) + '...';
            }
        },

        /**
         * Returns the value of a given property, regardless of whether it is custom-defined one or not
         * using schema version 1
         * Note: also returns things which are not properties like a class primary id.
         * @param {string} propName
         * @param {ClassDataExtended} classData
         * @return {PropertyListValue}
         */
        getPropValueV1(propName, classData = this.$props) {
            let path = propName;

            if (useOntology().isCustomAnnotationProperty(propName)) {
                path = `annotationProperties.${propName}`;
            }

            if (useOntology().isCustomRelationalProperty(propName)) {
                path = `relationalProperties.${propName}`;
            }

            const propertyValue = get(classData, path, []);
            return propertyValue;
        },

        /**
         * Returns the value of a given property, regardless of whether it is custom-defined one or not
         * using schema version 2
         * Note: also returns things which are not properties like a class primary id.
         * @todo decouple functionality to get primary id from getting properties.
         * @param propName
         * @return {any|string[]|*[]}
         */
        getPropValueV2(propName) {
            if (propName === 'primaryID')
                return useClassView().getClassPrimaryId.value;

            const classData = useClassView().getClassData.value;
            const propertyName = useV2Helpers().removeDottedNotation(propName);

            const propValue = [];

            for (const propertyValue of classData.propertyValues) {
                if (propertyName === propertyValue.name)
                    propValue.push(propertyValue.value);
            }

            return propValue;
        },

        /**
         * Returns the value of a given property, regardless of whether it is custom-defined one or not.
         * Note: also returns things which are not properties like a class primary id.
         * @param {any} property - Access property name in dot notation if applicable.
         * @param {ClassDataExtended|undefined} classData - Object whose property is to be accessed.
         * @todo decouple functionality to get primary id from getting properties.
         * @return {PropertyListValue|string[]}
         */
        getPropValue(propName, classData = undefined) {
            const isRelationalProperty = useClassView().isRelationshipProperty(
                propName,
                classData
            );

            if (
                useClassView().isSchemaVersion2.value &&
                !isRelationalProperty
            ) {
                if (propName === 'primaryID')
                    return useClassView().getClassPrimaryId.value;

                return this.getPropValueV2(propName);
            }

            classData = classData ? classData : this.$props;

            return this.getPropValueV1(propName, classData);
        },

        /**
         * Converts the names of the properties nested under a given object wrapper to dot notation.
         * @param {string} propWrapper - Name of the property that wraps the ones to be flattened.
         * @param {Object} targetObj - Parent object of the wrapping property.
         */
        flattenProps(propWrapper, targetObj) {
            const props = orderBy(Object.keys(targetObj[propWrapper] || {}));
            return props.map((propName) => propWrapper + '.' + propName);
        },

        /**
         * Determines if a given property is of a custom type. If so, it returns the type and
         * the name of the property, in that order.
         * NOTE: This assumes that custom properties have up to one level of nesting.
         * @param {string} propName - Name of the property.
         */
        isCustomProperty: function (propName) {
            if (!propName || typeof propName !== 'string') {
                return false;
            }

            const customPropWrappers = [
                process.env.VUE_APP_ANNOTATION_WRAPPER,
                process.env.VUE_APP_RELATIONAL_WRAPPER,
            ];
            const regexp = new RegExp(`(${customPropWrappers.join('|')}).(.+)`);
            const match = propName.match(regexp);

            if (match) {
                match.shift();
                return match;
            } else {
                return false;
            }
        },

        /**
         * Converts a name in camel-case to human-readable text. Also applicable to dates.
         * @param {string | object | [string] | [objects]} names - Single name or list of names to be converted.
         * @deprecated Since version 2.0, use instead src/utils/strings/humanReadable
         */
        humanReadable: function (names) {
            if (typeof names === 'undefined') return;
            const isList = Array.isArray(names);
            if (!isList) {
                names = [names];
            }

            let converted = [];
            for (let name of names) {
                if (typeof name !== 'string' && !isDate(name)) {
                    console.warn(name, 'humanReadable.js');
                    return;
                }

                let newString;
                let customPropMatch;

                if (isDate(name)) {
                    newString = this.$moment(name).format(
                        'dddd, MMMM Do YYYY, h:mm'
                    );
                } else {
                    // For dot-notated names, consider just the last key.
                    customPropMatch = this.isCustomProperty(name);

                    if (customPropMatch) {
                        converted.push(customPropMatch[1]);
                        break;
                        // Translate name with corresponding human-readable mapping. Otherwise, tidy up common acronyms.
                    } else {
                        // eslint-disable-next-line no-prototype-builtins
                        if (NAME_MAPPINGS.hasOwnProperty(name)) {
                            newString = NAME_MAPPINGS[name];
                        } else {
                            newString = lowerCase(
                                name.replace('IDs', 'ID')
                            ).replace('id', 'ID');
                        }
                    }
                }
                converted.push(newString);
            }

            if (!isList) {
                return converted[0];
            } else {
                return converted;
            }
        },

        /**
         * Encodes URLs in a similar fashion to Vue's router.
         * @param {string} url - Url to be converted.
         */
        urlToString: function (url) {
            return encodeURIComponent(url).replace('%3A', ':');
        },

        /**
         * Checks if a given string is a valid URL.
         * NOTE: this may give false positives but is pretty reliable in terms of wrongly flagging valid URLs.
         * @param {string} string - URL to be validated.
         * @return {boolean} - is a URL
         * @see {@link https://stackoverflow.com/a/43467144}
         */
        /* eslint-disable no-new */
        isUrl: function (url) {
            if (typeof url !== 'string') return false;
            const urlRegex = new RegExp(
                '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
            );

            let validUrl = false;
            try {
                let a = new URL(url);
                validUrl = true;
            } catch {
                validUrl = false;
            }
            return urlRegex.test(url) && validUrl;
        },
    },

    mixins: [testSelectors],
};
