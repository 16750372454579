<template>
    <b-popover
        placement="left"
        triggers="click"
        boundary="viewport"
        :delay="{ show: 0.5, hide: 100 }"
        :target="target">
        <section
            v-for="(match, index) in hiddenChips"
            class="section"
            :id="index"
            :key="index">
            <Chip
                class="chip-container"
                :text="match.shortDisplayName"
                :primaryID="match.primaryID"
                :sourceUniqueID="match.sourceUniqueID"
                :testId="'hidden-chip' + index"
                :label="match.primaryLabel"
                :body="
                    typeof match.textualDefinitions[0]
                        ? match.textualDefinitions[0]
                        : ''
                "
                :synonyms="match.synonyms">
            </Chip>
        </section>
    </b-popover>
</template>

<script>
import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';

export default defineComponent({
    name: 'PopupChipsHidden',
    components: {
        Chip: () => import('@/components/ui/Chip'),
    },
    props: {
        target: {
            type: String,
            required: true,
        },
        hiddenChips: {
            type: [],
            required: true,
        },
    },
});
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';
.section {
    display: flex;
    margin-bottom: 0.2em;
    min-height: 1.3em;
    margin-top: 0.2em;
    text-align: center;
}

.section .chip ::v-deep {
    margin-left: 5px !important;
    margin-bottom: 0.2em !important;
}
.popover popover-body ::v-deep {
    justify-content: center;
    text-align: center;
    display: flex;
    padding: 0em !important;
}

.hidden-chips-list {
    display: flex;
    flex-direction: column;
    max-height: 2.2em !important;
    max-width: 10em;
    text-align: center;
    justify-content: center;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    text-align: center;
}

.hidden-chips-list section {
    scroll-snap-align: start;
    width: 100%;
    text-align: center;
    display: flex;
}

.chip-container {
    text-align: center;
    justify-content: center;
    justify-self: center;
    width: 100%;
    display: flex;
}

.hidden-chips-list::-webkit-scrollbar {
}
</style>
