<template>
    <font-awesome-icon
        class="copy-btn"
        v-b-tooltip.hover="{
            title: `Copy ${targetName}`,
            delay: { show: showDelay, hide: 0 },
        }"
        :icon="isPressing ? 'clipboard-check' : 'clipboard'"
        @mouseup.stop.prevent="onCopy"
        @mousedown.stop.prevent="onPressing"
        @click.stop.prevent="() => {}" />
</template>

<script>
export default {
    name: 'CopyButton',
    props: {
        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        targetName: {
            type: String,
            default: '',
        },

        targetText: {
            type: String,
            default: '',
        },

        // Returns the DOM element for the wrapper of the text contents to be copied
        targetEl: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            isPressing: false,
        };
    },

    methods: {
        onPressing() {
            this.isPressing = true;
        },

        /**
         * Selects and copies the contents of the target element to the clipboard if not copied yet.
         */
        onCopy() {
            const targetEl = this.targetEl();
            let targetText = this.targetText;

            if (!this.targetText) {
                targetText = this.selectElContents(targetEl);
            }

            this.$copyText(targetText).then(() => {
                this.$emit('copy');
                this.isPressing = false;

                this.$eventBus.$emit(
                    'notification:show',
                    'info',
                    'Copied to clipboard',
                    '',
                    [],
                    'centerBottom',
                    { timeout: 1500 }
                );
            });
        },

        /**
         * Select all the content of an element (contenteditable or not) for future copy.
         * @param {Object} el - DOM element.
         * @see {@link https://stackoverflow.com/a/6150060}
         */
        selectElContents(el) {
            const currSelection = window.getSelection();
            const range = document.createRange();

            range.selectNodeContents(el);
            currSelection.removeAllRanges();
            currSelection.addRange(range);

            return currSelection.toString();
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.copy-btn {
    cursor: pointer;
    vertical-align: -0.075em !important;
}
</style>
