import { computed, Ref, ref } from '@vue/composition-api';
import {
    LiqourTreePropertyNode,
    OntologyProperty,
    OntologyPropertyExtended,
    PathsFromRootTree,
    PropertyForTree,
    PropertyTree,
    PropertyTreeExtended,
    PropertyTreeNode,
} from '@/ts';
import { usePropertyTreeService } from '@/compositions/propertyView/usePropertyTreeService';

const treeExtendedData: Ref<PropertyTreeExtended> = ref([]);
const selectedProperty: Ref<OntologyProperty | null> = ref(null);
const pathFromRootTree: Ref<PathsFromRootTree | null> = ref(null);
const propertyTreeKey = ref(0);
const propertyTreeTotal = ref(0);

const hasBeenOnClassTree = ref(false);
const hasBeenOnPropertyTree = ref(false);

export const usePropertyTree = () => {
    const { getPropertyChildren } = usePropertyTreeService();

    /**
     * This is to prevent the memory leak inside of hierarchy tree, setting prototypes that still exist when changing to property tree
     * @param {boolean} _hasBeenOnClassTree
     */
    const setHasBeenOnClassTree = (_hasBeenOnClassTree: boolean) => {
        hasBeenOnClassTree.value = _hasBeenOnClassTree;
    };

    /**
     * This is to prevent the memory leak inside of hierarchy tree, setting prototypes that still exist when changing to property tree
     * @param {boolean} _hasBeenOnPropertyTree
     */
    const setHasBeenOnPropertyTree = (_hasBeenOnPropertyTree: boolean) => {
        hasBeenOnPropertyTree.value = _hasBeenOnPropertyTree;
    };

    /**
     * This is to prevent the memory leak inside of hierarchy tree, setting prototypes that still exist when changing to property tree
     */
    const getHasBeenOnClassTree = computed(() => {
        return hasBeenOnClassTree.value;
    });

    /**
     * This is to prevent the memory leak inside of hierarchy tree, setting prototypes that still exist when changing to property tree
     */
    const getHasBeenOnPropertyTree = computed(() => {
        return hasBeenOnPropertyTree.value;
    });

    const getTreeDataExtended = computed(() => {
        return treeExtendedData.value;
    });

    const setTreeDataExtended = (_treeDataExtended: PropertyTreeExtended) => {
        treeExtendedData.value = _treeDataExtended;
    };

    const getSelectedProperty = computed(() => {
        return selectedProperty.value;
    });

    const setSelectedProperty = (_property: OntologyProperty) => {
        selectedProperty.value = { ..._property };
    };

    const getPathFromRootTree = computed(() => {
        return pathFromRootTree.value;
    });

    const setPathFromRootTree = (_pathFromRootTree: PathsFromRootTree) => {
        pathFromRootTree.value = { ..._pathFromRootTree };
    };

    /**
     * Turns the tree api data to the extended version.
     * This can be used to add any data the UI tree might need.
     * Adds text field so the LiqourTree can use the text as the UI label
     */
    const processTreeToExtended = (treeData: PropertyTree) => {
        const extendedTree: PropertyTreeExtended = [];

        for (let i = 0; i < treeData.length; i++) {
            extendedTree.push({
                value: treeData[i].value,
                leaves: treeData[i].leaves,
                text: treeData[i].value?.primaryLabel,
            });
        }

        return extendedTree;
    };

    /**
     * A function that returns an extended formatted version of the initial property tree
     * CAUTION MUTATES PASSED ARGS
     * @param {PropertyForTree}  tree
     * @returns {PropertyTreeExtended[]}
     */
    const processPathsFromRootToTreeExtended = (tree: PropertyForTree) => {
        const stack = [tree] as PropertyTreeExtended;

        if (stack[0].value) {
            stack[0].value.isPathFromRootRoot = true;
        }

        while (stack.length) {
            const node = stack.pop() as PropertyTreeNode;
            node.text = node?.value?.primaryLabel;

            if (node?.value?.id === selectedProperty?.value?.id) {
                node.state = {
                    selected: true,
                };
            }

            node?.leaves && stack.push(...node?.leaves);
        }

        return [tree] as PropertyTreeExtended;
    };

    const processPropertyChildrenToTreeExtended = (
        childrenProperties: OntologyPropertyExtended[]
    ): PropertyTreeExtended => {
        const extendedTree: PropertyTreeExtended = [];

        for (let i = 0; i < childrenProperties.length; i++) {
            extendedTree.push({
                leaves: [],
                value: childrenProperties[i],
                text: childrenProperties[i].primaryLabel,
            });
        }

        return extendedTree;
    };

    const getPropertyTreeRefreshKey = computed(() => {
        return propertyTreeKey.value;
    });

    const getPropertyChildrenBatch = async (
        propertyTree: PropertyTreeExtended,
        ontologyId: string,
        checkChildren: boolean
    ) => {
        const childPropertyReqs = [];

        for (let i = 0; i < propertyTree.length; i++) {
            if (checkChildren && propertyTree[i]?.value?.childrenChecked)
                continue;
            childPropertyReqs.push(
                getPropertyChildren(
                    propertyTree[i]?.value?.id ?? '',
                    ontologyId
                )
            );
        }

        return await Promise.allSettled(childPropertyReqs);
    };

    const getNodePropertyChildrenBatch = async (
        childrenNodes: LiqourTreePropertyNode[],
        ontologyId: string,
        checkChildren: boolean
    ) => {
        const childPropertyReqs = [];

        for (let i = 0; i < childrenNodes.length; i++) {
            if (checkChildren && childrenNodes[i].data.childrenChecked)
                continue;

            childrenNodes[i].data.childrenChecked = true;
            childPropertyReqs.push(
                getPropertyChildren(childrenNodes[i].data.id ?? '', ontologyId)
            );
        }

        return await Promise.allSettled(childPropertyReqs);
    };

    const getPathsFromRootPropertyChildrenBatch = async (
        pathsFromRoot: PathsFromRootTree,
        ontologyId: string
    ) => {
        const childPropertyReqs = [];
        const propStack = [pathsFromRoot];
        const keyValuePropertyStore: Record<string, PathsFromRootTree> = {};

        while (propStack.length) {
            const property = propStack.pop();
            if (!property) continue;

            childPropertyReqs.push(
                getPropertyChildren(property.value?.id ?? '', ontologyId)
            );

            if (property.value?.primaryID) {
                keyValuePropertyStore[property?.value?.primaryID] = property;
            }
            property.leaves && propStack.push(...property.leaves);
        }

        return await Promise.allSettled(childPropertyReqs);
    };

    const refreshPropertyTree = () => {
        propertyTreeKey.value += 1;
    };

    const setPropertyTreeKey = (_key: number) => {
        propertyTreeKey.value = _key;
    };

    const getPropertyTreeTotal = computed(() => {
        return propertyTreeTotal.value;
    });

    const setPropertyTreeTotal = (_total: number) => {
        propertyTreeTotal.value = _total;
    };

    const clearPropertyTreeCache = () => {
        setTreeDataExtended([]);
        setPropertyTreeKey(0);
        setPropertyTreeTotal(0);
        selectedProperty.value = null;
    };

    const search = (tree: PropertyTreeExtended, property: OntologyProperty) => {
        const stack = [...tree];
        while (stack.length) {
            const node = stack.pop();

            if (node?.value?.id === property?.id) return node;

            node?.leaves && stack.push(...node?.leaves);
        }
        return null;
    };

    const convertToFakeRootTree = (
        tree: PropertyTreeExtended
    ): PropertyTreeExtended => {
        return [
            {
                leaves: tree,
                value: {
                    id: 'fakeRoot',
                    primaryID: 'fakeRoot',
                },
                text: 'fakeRoot',
            },
        ];
    };

    return {
        getTreeDataExtended,
        setTreeDataExtended,
        processTreeToExtended,
        getPropertyTreeTotal,
        getPropertyTreeRefreshKey,
        refreshPropertyTree,
        getPropertyChildrenBatch,
        clearPropertyTreeCache,
        processPropertyChildrenToTreeExtended,
        search,
        getSelectedProperty,
        setSelectedProperty,
        setPropertyTreeTotal,
        getNodePropertyChildrenBatch,
        convertToFakeRootTree,
        processPathsFromRootToTreeExtended,
        getPathFromRootTree,
        setPathFromRootTree,
        getPathsFromRootPropertyChildrenBatch,
        getHasBeenOnClassTree,
        setHasBeenOnClassTree,
        getHasBeenOnPropertyTree,
        setHasBeenOnPropertyTree,
    };
};
