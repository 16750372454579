import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type IndexedOntologiesResponse = operations["getIndexedOntologiesUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * Get indexed ontologies on SPARQL server
 * @return {IndexedOntologiesResponse}
*/
export const getIndexedOntologies = async () => {
    const url = `/sparql/ontologies`;
    const response = await Axios.get(url);

    return response && response.data as IndexedOntologiesResponse;
}
