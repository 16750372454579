import Axios from 'axios';
import { operations } from '@/ts/ApiSpecs';

type CompareEditWithCurrentPathParams = operations["compareEditWithCurrentUsingGET"]["parameters"]["path"];
type CompareEditWithCurrentResponse = operations["compareEditWithCurrentUsingGET"]["responses"]["200"]['content']["*/*"];

/**
 * compareEditWithCurrent
 * @param {string} ontologyId
 * @param {integer} editId
 * @return {CompareEditWithCurrentResponse}
*/
export const compareEditWithCurrent = async (
    { ontologyId, editId }: CompareEditWithCurrentPathParams,
) => {
    const url = `/ontologies/${ontologyId}/edits/${editId}/compareWithCurrent`;
    const response = await Axios.get(url);

    return response && response.data as CompareEditWithCurrentResponse;
}
