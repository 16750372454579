import { isDate } from 'lodash';
import {
    formatDate,
    isCustomProperty,
    nameFromIri,
    propertyNameFromString,
    replaceCamelCasing,
} from '@/utils';
import { NAME_MAPPINGS } from '@/config';

/**
 * Converts a name in camel-case to human-readable text. Also applicable to dates.
 * @param {string | object | [string] | [objects]} names - Single name or list of names to be converted.
 */
export const humanReadable = (names, isIri = false) => {
    if (typeof names === 'undefined') return;
    const isList = Array.isArray(names);
    if (!isList) {
        names = [names];
    }

    let converted = [];
    for (let name of names) {
        if (typeof name !== 'string' && !isDate(name)) {
            console.warn(name, 'humanReadable.js');
            return;
        }

        if (isIri) {
            name = nameFromIri(name);
        }

        let newString = name;
        let customPropMatch;

        if (isDate(name)) {
            newString = formatDate(name);
        } else {
            // For dot-notated names, consider just the last key.
            customPropMatch = isCustomProperty(name);

            if (customPropMatch) {
                converted.push(customPropMatch[1]);
                break;
                // Translate name with corresponding human-readable mapping. Otherwise, tidy up common acronyms.
            } else {
                // eslint-disable-next-line no-prototype-builtins
                if (NAME_MAPPINGS.hasOwnProperty(name)) {
                    newString = propertyNameFromString(name);
                }
            }
        }
        newString = replaceCamelCasing(newString);
        converted.push(newString);
    }

    if (!isList) {
        return converted[0];
    } else {
        return converted;
    }
};
